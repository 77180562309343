import React from 'react';
import {useSelector} from 'react-redux';

import {analytics} from '../../src/services/analytics';
import {devicesSelector} from '../redux/bleSlice';
import {Device} from '../types';
import {isLimitedEdition} from '../utilityFunctions/isLimitedEdition';

export const useTrackUser = () => {
  const devices = useSelector(devicesSelector);

  React.useEffect(() => {
    // same device might appear multiple times
    const devicesMap = devices.reduce<
      Record<
        string,
        Pick<Device, 'modelNumberString' | 'serialNumberString' | 'product'>
      >
    >((obj, {modelNumberString, serialNumberString, product}) => {
      if (!serialNumberString) return obj;

      obj[serialNumberString] = {
        modelNumberString,
        serialNumberString,
        product,
      };

      return obj;
    }, {});

    const countOfTotalDevice = Object.keys(devicesMap).length;
    // TODO: When toad is added we need to check this
    const countOfPeakProDevices = Object.values(devicesMap).length;
    const countOfFlagshipPeakProDevices =
      Object.values(devicesMap).filter(isLimitedEdition).length;

    analytics.trackUser(user =>
      user
        .set('total devices count', countOfTotalDevice)
        .set('peak pro count', countOfPeakProDevices)
        .set('flagship peak pro count', countOfFlagshipPeakProDevices),
    );
  }, [devices.length]);
};

import {useIsFocused} from '@react-navigation/native';
import React from 'react';

import {useSpinner} from '../../components';
import {Screens, Strings, appColors} from '../../constants';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';

interface ScreenProps extends RootStackScreenProps<typeof Screens.Loading> {}

export const LoadingScreen: React.FC<ScreenProps> = () => {
  const isFocused = useIsFocused();

  useSpinner({
    isVisible: isFocused,
    color: appColors.white,
    text: Strings.LOADING_DATA,
  });

  return null;
};

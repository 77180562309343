import React from 'react';
import {
  Animated,
  LayoutChangeEvent,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import {appColors} from '../../../../../constants';
import {useAnim} from '../../../../../lib/hooks';
import {XAndY} from '../util/getDabXAndYAxis';

const Bar = React.memo(function Bar({
  index,
  height,
  animate,
  color,
  animationSpeed,
  radius,
  xAndY,
  max,
  val,
}: {
  index: number;
  height: number;
  animate?: boolean;
  color?: string;
  animationSpeed?: number;
  radius?: number;
  xAndY: XAndY<number>;
  max: number;
  val: number;
}) {
  const [value, startAnim, isAnimDone] = useAnim();
  const [rValue, startRAnim] = useAnim();

  const timeToDismiss = index >= val ? max - (index - val) - val : index;

  //Calculation to match the animation speed set
  const speedToReduce =
    (val - 4 < 0 ? -(val - 4 - 0) : val + 4 > max ? val + 4 - max : 0) * 0.1 +
    1.3;

  const halfSpeed = (animationSpeed ?? 100) / speedToReduce;

  React.useEffect(() => {
    if (animate) {
      setTimeout(() => {
        startAnim(height, halfSpeed ?? 100, false);
      }, index * halfSpeed);
    }
  }, [index, animate, halfSpeed, height]);

  React.useEffect(() => {
    if (isAnimDone) {
      setTimeout(
        () => {
          startRAnim(1, halfSpeed, false);
          // Calculated time for a fade out effect
        },
        (max - index) * (halfSpeed ?? 100) + (halfSpeed ?? 100) * timeToDismiss,
      );
    }
  }, [isAnimDone, halfSpeed]);

  return (
    <Animated.View
      style={{
        flex: 1,
        height: value,
        backgroundColor: color ?? 'white',
        borderRadius: radius,
        opacity:
          !!xAndY.y || !isAnimDone
            ? 1
            : rValue.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0],
              }),
        transform: [
          {
            translateX: xAndY.y,
          },
        ],
      }}
    />
  );
});

const Circle = ({
  size,
  color,
  style,
  onLayout,
  children,
}: {
  children?: React.ReactNode;
  size: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
  onLayout?: (event: LayoutChangeEvent) => void;
}) => {
  return (
    <Animated.View
      style={[
        {height: size, width: size, backgroundColor: color, borderRadius: 100},
        style,
      ]}
      onLayout={onLayout}>
      {children}
    </Animated.View>
  );
};

export const Odometer = ({
  size,
  innerRadius,
  innerColor,
  baseLines,
  activeLines,
  lineSize,
  xAndY,
  value,
  style,
  innerComp,
  leftComp,
  leftCompStyle,
  rightComp,
  rightCompStyle,
  animateAll,
  animationSpeed,
}: {
  size?: number;
  innerRadius?: number;
  innerColor?: string;
  baseLines?: {
    color?: string;
    radius?: number;
  };
  activeLines?: {
    color?: string;
    radius?: number;
  };
  lineSize?: number;
  xAndY: XAndY<number>[];
  value: number;
  style?: StyleProp<ViewStyle>;
  innerComp?: React.ReactNode;
  leftComp?: React.ReactNode;
  leftCompStyle?: StyleProp<ViewStyle>;
  rightComp?: React.ReactNode;
  rightCompStyle?: StyleProp<ViewStyle>;
  animateAll?: boolean;
  animationSpeed?: number;
}) => {
  const circleSize = size ?? 100;
  const halfCircle = circleSize / 2;

  return (
    <View
      style={[
        {
          position: 'relative',
          width: circleSize,
          height: circleSize,
          justifyContent: 'center',
          alignItems: 'center',
        },
        style,
      ]}>
      <Circle
        size={circleSize}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: halfCircle,
          zIndex: 99,
        }}>
        <View
          style={[
            {
              position: 'absolute',
              height: '100%',
              width: '100%',
              zIndex: 9999,
              alignItems: 'flex-start',
              justifyContent: 'center',
              top: 11,
            },
            leftCompStyle,
          ]}>
          {leftComp}
        </View>
        <View
          style={[
            {
              position: 'absolute',
              height: '100%',
              width: '100%',
              zIndex: 9999,
              alignItems: 'flex-end',
              justifyContent: 'center',
              top: 11,
            },
            rightCompStyle,
          ]}>
          {rightComp}
        </View>
        <Circle
          size={innerRadius ?? 70}
          style={{
            position: 'relative',
            zIndex: 999,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            top: 2,
          }}>
          <View
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              zIndex: 9999,
              alignItems: 'center',
              justifyContent: 'center',
              top: -4,
            }}>
            {innerComp}
          </View>
          <View
            style={{
              flex: 1,
              width: '100%',
              backgroundColor: innerColor ?? appColors.black,
            }}
          />
          <View style={{flex: 1, width: '100%'}} />
        </Circle>
        <Circle
          size={circleSize}
          color={'transparent'}
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {xAndY.map((v, i) => {
            return (
              <View
                key={i}
                style={{
                  width: '100%',
                  height: lineSize ?? 3,
                  flexDirection: 'row',
                  position: 'absolute',
                  zIndex: -1,
                  transform: [
                    {
                      rotate:
                        (i === 0 ? 181 : i === xAndY.length - 1 ? 179 : 180) +
                        (180 / (xAndY.length - 1)) * i +
                        'deg',
                    },
                  ],
                }}>
                <View style={{flex: 1, height: '100%'}} />
                <Bar
                  index={i}
                  xAndY={v}
                  animate={animateAll || i <= value + 4}
                  height={lineSize ?? 3}
                  color={activeLines?.color}
                  radius={activeLines?.radius}
                  max={xAndY.length - 1}
                  animationSpeed={(animationSpeed ?? 1000) / xAndY.length}
                  val={value}
                />
              </View>
            );
          })}
        </Circle>
      </Circle>
      <Circle
        size={circleSize}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: halfCircle,
        }}>
        <Circle
          size={circleSize}
          color={'transparent'}
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: -1,
          }}>
          {xAndY.map((_, i) => (
            <View
              key={i}
              style={{
                width: '100%',
                height: lineSize ?? 3,
                flexDirection: 'row',
                position: 'absolute',
                zIndex: -1,
                transform: [
                  {
                    rotate:
                      (i === 0 ? 181 : i === xAndY.length - 1 ? 179 : 180) +
                      (180 / (xAndY.length - 1)) * i +
                      'deg',
                  },
                ],
              }}>
              <View style={{flex: 1, height: '100%'}} />
              <View
                style={{
                  flex: 1,
                  height: '100%',
                  backgroundColor: baseLines?.color ?? 'transparent',
                  borderRadius: baseLines?.radius,
                }}
              />
            </View>
          ))}
        </Circle>
      </Circle>
    </View>
  );
};

import {Constants, appColors} from '../constants';
import {convertHexToRgbArray} from './convertHexToRgbArray';

export const convertHexToHsl = (hue: string) => {
  const {whiteHue} = Constants.SLIDER_WHITE_VALUES;

  if (hue.toUpperCase() === appColors.white) {
    return whiteHue;
  } else {
    // Convert hex to RGB first
    const colorArr = convertHexToRgbArray(hue);
    let r = colorArr[0],
      g = colorArr[1],
      b = colorArr[2];

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin;
    let h = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    h /= 360;

    return h;
  }
};

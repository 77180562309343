import React from 'react';
import {StyleSheet} from 'react-native';

import {
  ButtonText,
  StyledButton,
  StyledButtonProps,
  StyledIcon,
} from '../../../../../components';
import {appColors} from '../../../../../constants';
import {addOpacityToColorHex} from '../../../../../lib/utilityFunctions';

const iconSize = 12;

interface Props extends StyledButtonProps {
  previous?: boolean;
}

export const GalleryNavigationButton: React.FC<
  React.PropsWithChildren<Props>
> = ({previous, title, ...rest}) => (
  <StyledButton {...rest} style={styles.buttonContainer}>
    {!!previous && (
      <StyledIcon
        style={styles.icon}
        containerSize={iconSize}
        size={iconSize}
        color={addOpacityToColorHex(appColors.white, 0.5)}
        name="chevronLeft"
      />
    )}

    <ButtonText
      style={[
        styles.buttonText,
        {
          color: previous
            ? addOpacityToColorHex(appColors.white, 0.5)
            : appColors.black,
        },
      ]}>
      {title}
    </ButtonText>

    {!previous && (
      <StyledIcon
        style={styles.icon}
        containerSize={iconSize}
        size={iconSize}
        color={appColors.black}
        name="chevronRight"
      />
    )}
  </StyledButton>
);

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    flex: 1,
  },
  buttonText: {
    padding: 0,
    paddingTop: 0,
    fontSize: 14,
  },
  icon: {
    padding: 0,
  },
});

import {Text} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';

export const MoodLightFadedText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  lineHeight: 16,
  letterSpacing: 0.5,
  opacity: 0.6,
  textTransform: 'uppercase',
  color: appColors.white,
  fontWeight: '400',
});

import {useIsFocused} from '@react-navigation/native';
import {GLSL, Node, Shaders} from 'gl-react';
import React from 'react';
import {LayoutChangeEvent, PixelRatio, View} from 'react-native';

import {borealis} from '../assets/images';
import {HeatProfileCoreImage} from '../components';
import {Constants, appColors} from '../constants';
import {convertHexToVector} from '../lib/convertHexToVector';
import {makeImgToCacheUri} from '../lib/copyImgToCacheAsync';
import {useAppState} from '../lib/hooks/useAppState';
import styled from '../lib/styled';
import {Theme} from '../lib/types';
import {Surface} from '../shims/Surface';
import {guardianTheme, opalTheme} from '../themes';

const {IS_NATIVE_ANDROID} = Constants;

const borealisImg = IS_NATIVE_ANDROID
  ? {
      uri: makeImgToCacheUri('borealis'),
    }
  : borealis;

const shaders = Shaders.create({
  animatedGlow: {
    frag: GLSL`
    #ifdef GL_ES
    precision mediump float;
    #endif

    #define TWO_PI 6.28
    #define PI 3.14

    uniform sampler2D u_texture[1];
    uniform vec2      u_textureResolution[1];
    uniform int       u_md_colors_cnt;
    uniform vec3      u_md_colors[6];
    uniform float     u_md_angle_shifts[7];
    uniform vec3      u_color; //shader color
    uniform vec3      u_ringColor; //when not in disco, uses profile color for opal or black for non opal
    uniform vec3      u_theme_color; //used for tick marks
    uniform bool      u_is_disco;
    uniform bool      u_is_rotating;
    uniform vec2      u_resolution;
    uniform float     u_time;
    uniform float     u_radius;
    uniform float     u_intensity;
    uniform float     u_seconds;
    uniform float     u_warm;
    uniform float     u_transitionPercentage;

    const float sharpness = 60.0;
    const float brightnessInner = 1.2;
    const float brightnessOuter = 1.7;
    const float brightnessFalloff = 0.45;
    const int   blurRayDepth = 24;
    const float zoom = .8;
    const float ring = 0.97;
    const float ringInner = 0.92;
    const float tickThickness = 0.2;
    const float tickLength = 28.0;

    vec3 deform(in vec2 pos, in int textureId, in float time)
    {
      vec2 xy = vec2(0.0);
      vec2 uv = vec2(0.0);

      if (textureId == 0) {
        xy = cos( vec2(u_intensity, sin(u_intensity + 68.7)) * time + pos );
      } else {
        xy = cos( vec2(sin(u_intensity) * .5, u_intensity + 0.6) * time + pos * 1.9 );
      }
      float r = sqrt(sin(dot(xy,xy)));
      uv = pos * sqrt(2. + r * r);
      return texture2D(u_texture[0], uv * 0.12 + 0.5).xyz;
    }

    vec3 get_mood_light_color(in vec3 c, float angle) {
      vec3 clr = vec3(1.0);
      float n_angle = mod(angle, 6.0);
      int angle_int = int(n_angle);
      n_angle = n_angle - float(angle_int);
      float proportion = 1.0 / float(u_md_colors_cnt);
      float d = PI/2.0;
      float r = d / proportion;

      if (u_md_colors_cnt == 1) {
        clr = u_md_colors[0];
      } else if (n_angle <= proportion * 1.0) {
        clr = mix(u_md_colors[0], u_md_colors[1], abs(sin(n_angle*r)));
      } else if (n_angle <= proportion * 2.0) {
        if (u_md_colors_cnt == 2) {
          clr = mix(u_md_colors[0], u_md_colors[1], abs(sin(n_angle*r)));
        } else if (u_md_colors_cnt > 2) {
          clr = mix(u_md_colors[2], u_md_colors[1], abs(sin(n_angle*r)));
        }
      } else if (n_angle <= proportion * 3.0) {
        if (u_md_colors_cnt == 3) {
          clr = mix(u_md_colors[2], u_md_colors[0], abs(sin(n_angle*r)));
        } else if (u_md_colors_cnt > 3) {
          clr = mix(u_md_colors[2], u_md_colors[3], abs(sin(n_angle*r)));
        }
      } else if (n_angle <= proportion * 4.0) {
        if (u_md_colors_cnt == 4) {
          clr = mix(u_md_colors[0], u_md_colors[3], abs(sin(n_angle*r)));
        } else if (u_md_colors_cnt > 4) {
          clr = mix(u_md_colors[4], u_md_colors[3], abs(sin(n_angle*r)));
        }
      } else if (n_angle <= proportion * 5.0) {
        if (u_md_colors_cnt == 5) {
          clr = mix(u_md_colors[4], u_md_colors[0], abs(sin(n_angle*r)));
        } else if (u_md_colors_cnt > 5) {
          clr = mix(u_md_colors[4], u_md_colors[5], abs(sin(n_angle*r)));
        }
      } else if (n_angle <= proportion * 6.0 && u_md_colors_cnt == 6) {
        clr = mix(u_md_colors[0], u_md_colors[5], abs(sin(n_angle*r)));
      }
      clr = clr*clr*(3.0-2.0*clr);
      return c.z * mix(vec3(1.0), clr, c.y);
    }

    float get_mood_angle_shift() {
      if (u_md_colors_cnt == 1) {
        return u_md_angle_shifts[1];
      } else if (u_md_colors_cnt == 2) {
        return u_md_angle_shifts[2];
      } else if (u_md_colors_cnt == 3) {
        return u_md_angle_shifts[3];
      } else if (u_md_colors_cnt == 4) {
        return u_md_angle_shifts[4];
      } else if (u_md_colors_cnt == 5) {
        return u_md_angle_shifts[5];
      } else if (u_md_colors_cnt == 6) {
        return u_md_angle_shifts[6];
      } else {
        return u_md_angle_shifts[0];
      }
    }

    vec3 hsb2rgb(in vec3 c) {
      vec3 rgb = clamp(
        abs(
          mod(
            c.x*6.0+vec3(0.0,4.0,2.0),
            6.0
          )-3.0
        )-1.0,
        0.0,
        1.0
      );
      rgb = rgb*rgb*(3.0-2.0*rgb);
      return c.z * mix( vec3(1.0), rgb, c.y);
    }

    vec3 get_color(bool isRainbow) {
      vec2 st = gl_FragCoord.xy/u_resolution;

      // Use polar coordinates instead of cartesian
      vec2 toCenter = vec2(0.5)-st;
      float angle = -atan(toCenter.y,toCenter.x);

      if (u_is_rotating) {
        // Rotate angle clockwise over time
        angle = angle + (u_time / 600.0);
      } else {
        // Rotate angle to match up with opal
        angle = angle + get_mood_angle_shift();
      }

      // Map the angle (-PI to PI) to the Hue (from 0 to 1)
      if (isRainbow) {
        return hsb2rgb(vec3((angle/TWO_PI)+0.5,1.0,1.0));
      } else {
        return get_mood_light_color(vec3((angle/TWO_PI)+0.5,1.0,1.0), angle/TWO_PI);
      }
    }

    void main() {
      float time = u_time / (325. * u_warm); // human timescale
      vec2 pos = -1.0 + 2.0 * gl_FragCoord.xy / u_resolution.xy; // pos from center of view
      pos = pos * ( 2. / zoom);
      float radius = u_radius;
      float r = length(pos);

      vec3 textureBlur = vec3(0.0);

      vec2 distanceToCenter = (vec2(0.0,0.0)-pos)/sharpness / 1.5;

      float radialValue = 1.0;
      vec2  nextPos = pos;
      for(int i = 0; i < blurRayDepth; i++)
      {
        vec3 res = vec3(0.);
        if (i / 3 == 0 ) {
          res = deform(nextPos, r < ring ? 0 : 1, time / 5.);
          nextPos += distanceToCenter + .07;
        } else {
          res = deform(nextPos, 1, time * (u_intensity * 1.2));
          nextPos += distanceToCenter * 2.;
        }
          textureBlur += radialValue * smoothstep( 0.0, 1.0, res );
          radialValue *= 0.99;

      }
      textureBlur *= (r < ring ? brightnessInner : brightnessOuter) / 10.;

      vec3 shader_color = vec3(1.0);
      if (u_md_colors_cnt == 0) {
        // Non Mood light profiles - mix with rainbow based on transition percentage
        shader_color = mix(u_color, get_color(true), u_transitionPercentage);
      } else {
        // Moodlight profiles
        if (u_is_disco) {
          // mix multi colors with rainbow based on transition percentage
          shader_color = mix(get_color(false), get_color(true), u_transitionPercentage);
        } else {
          // get multi colors
          shader_color = get_color(false);
        }
      }

      if (shader_color.r>0.8&&shader_color.g>0.8&&shader_color.b>0.8 && u_md_colors_cnt == 0)
        shader_color = vec3(0.9);

      // combining texture characteristics with profile color
      vec4 final = vec4(mix(textureBlur * 5.0, shader_color * shader_color * 1.0, 0.9), 1.0);

      //darken farther away from center
      float factor = r < ring ?
        (ring - r) * brightnessFalloff  / u_intensity * 20.0:
        (r - ring) * brightnessFalloff / u_intensity * 1.;
      final = mix(final, vec4(0.), 1.0 - pow(3.1, -8.0 * factor));

      // outer ring
      if (abs(r - ring) < 0.022)
      if (u_md_colors_cnt == 0) {
        final = mix(final, vec4(mix(u_ringColor, get_color(true), u_transitionPercentage), 1), smoothstep(ring - 0.022, ring, r) - smoothstep(ring, ring + 0.022, r));
      } else {
        if (u_is_disco) {
          final = mix(final, vec4(mix(get_color(false), get_color(true), u_transitionPercentage), 1), smoothstep(ring - 0.022, ring, r) - smoothstep(ring, ring + 0.022, r));
        } else {
          final = mix(final, vec4(get_color(false), 1), smoothstep(ring - 0.022, ring, r) - smoothstep(ring, ring + 0.022, r));
        }
      }
      // tick marks
      float a = (atan(pos.y, pos.x) + PI) / 2. / PI + 0.25; // angle from 0 to 1
      r = length(pos);
      float sections = floor(u_seconds);
      float sector = 1.0 / sections; // size of sector, i.e. 0.0167 for 60 seconds
      if (r  < u_radius - 0.01 && r > u_radius - 0.01 - tickLength / 1000.)
        final = mix(final, vec4(u_theme_color, 1.0), smoothstep(-tickThickness * 0.01, -tickThickness * 0.008, mod(a, sector)) - smoothstep(tickThickness * 0.008, tickThickness * 0.01, mod(a, sector)));

      gl_FragColor = final;
    }
    `,
  },
});

export type AnimatedHaloProps = {
  theme: Theme;
  colors: string[];
  isMoodAnimating?: boolean;
  isMoodLight?: boolean;
  intensity?: number;
  seconds?: number;
  active?: boolean;
  innerComponent?: any;
  activeProfile?: boolean;
  warmUp?: boolean;
  isDisco?: boolean;
  haloDiameter?: number;
  onCoreImageLayout?: (layout: {height: number; y: number}) => void;
};

export const calculateInnerContainerDiameter = (haloDiameter: number) => {
  return haloDiameter / 2.8 + 20;
};
export const DEFAULT_HALO_DIAMETER = 620;
const DEFAULT_INNER_CONTAINER_DIAMETER = calculateInnerContainerDiameter(
  DEFAULT_HALO_DIAMETER,
);

const fps = 7.5;
const fpsInterval = 1000 / fps;

export const AnimatedHalo = (props: AnimatedHaloProps) => {
  const {
    colors,
    theme,
    isMoodAnimating = false,
    isMoodLight = false,
    intensity,
    seconds = 0,
    innerComponent,
    active = true,
    warmUp = false,
    isDisco = false,
    haloDiameter = DEFAULT_HALO_DIAMETER,
    onCoreImageLayout,
  } = props;
  const {primaryColor, heatProfileCoreImageTheme, dabTickTheme} = theme;
  const isOpal = theme === opalTheme;
  const isGuardian = theme === guardianTheme;
  const isFocused = useIsFocused();
  const resetTime = 15064.0; // The animation stops running at around 65 seconds so resetTime is a hardcoded reset time point that looks smooth
  const [time, setTime] = React.useState(0.0);
  const [transitionPercentage, setTransitionPercentage] = React.useState(0.0);
  const timer = React.useRef<NodeJS.Timeout>();
  const transitionTimer = React.useRef<NodeJS.Timeout>();
  const transitionDuration = 1000;
  const innerContainerDiameter = calculateInnerContainerDiameter(haloDiameter);
  let paddedMoodColors = Array.from('#'.repeat(6));
  paddedMoodColors = paddedMoodColors.map((_, i) => {
    return colors[i] || appColors.white;
  });
  const moodLightAnimationShift = [
    0.0,
    0.0,
    -3.14 / 1.8,
    -3.14 / 1.2,
    -3.14 / 1.5,
    -3.14 / 1.4,
    -3.14 / 1.7,
  ];
  // Delay display of shader by one render to improve performance on Android when
  // SweepingGradient is used
  const [shouldDisplayShader, setShouldDisplayShader] =
    React.useState(!IS_NATIVE_ANDROID);
  React.useEffect(() => setShouldDisplayShader(true), []);

  const startAnimating = React.useCallback(() => {
    timer.current = setInterval(
      () => setTime(time => (time > resetTime ? 0.0 : time) + fpsInterval),
      fpsInterval,
    );
  }, []);

  const stopAnimating = React.useCallback(
    () => timer.current && clearInterval(timer.current),
    [],
  );

  const appState = useAppState();

  React.useEffect(() => {
    if (appState === 'active') return startAnimating();
    if (appState === 'background') return stopAnimating();
  }, [appState, startAnimating, stopAnimating]);

  React.useEffect(() => {
    active && isFocused ? startAnimating() : stopAnimating();
    return () => {
      stopAnimating();
    };
  }, [active, isFocused, startAnimating, stopAnimating]);

  React.useEffect(() => {
    transitionTimer.current && clearInterval(transitionTimer.current);
    const start = isDisco;
    const percentageUnit =
      fpsInterval /
      (transitionDuration *
        (start ? 1 - transitionPercentage : transitionPercentage));

    const transitionInterval = start
      ? () => {
          setTransitionPercentage(transitionPercentage =>
            transitionPercentage < 1 - percentageUnit
              ? transitionPercentage + percentageUnit
              : 1.0,
          );
          transitionPercentage === 1.0 &&
            transitionTimer.current &&
            clearInterval(transitionTimer.current);
        }
      : () => {
          setTransitionPercentage(transitionPercentage =>
            transitionPercentage >= percentageUnit
              ? transitionPercentage - percentageUnit
              : 0.0,
          );
          transitionPercentage === 0.0 &&
            transitionTimer.current &&
            clearInterval(transitionTimer.current);
        };

    transitionTimer.current = setInterval(transitionInterval, fpsInterval);
  }, [isDisco]);

  const onLayout = (evt: LayoutChangeEvent) => {
    if (onCoreImageLayout) {
      // React Native and React Native Web seem to have target in different places
      const target = (evt.target ||
        (evt.nativeEvent as any).target) as unknown as View;

      // on animated screens it may measure too early
      setTimeout(() => {
        target.measure((_x, _y, _width, height, _pageX, pageY) => {
          onCoreImageLayout({height, y: pageY});
        });
      }, 200);
    }
  };

  return isFocused && shouldDisplayShader ? (
    <Container>
      <Surface
        style={{
          width: haloDiameter,
          height: haloDiameter,
          zIndex: 0,
          overflow: 'visible',
        }}>
        <Node
          shader={shaders.animatedGlow}
          uniforms={{
            u_texture: [borealisImg],
            u_resolution: [
              haloDiameter * PixelRatio.get(),
              haloDiameter * PixelRatio.get(),
            ],
            u_warm: warmUp ? 3 : 1,
            u_time: time,
            u_radius: 0.95,
            u_intensity: intensity || 0.3,
            u_seconds: seconds,
            u_transitionPercentage: transitionPercentage,
            u_color: convertHexToVector(colors[0] || '') || [0.12, 0.23, 0.53],
            u_ringColor:
              isOpal || isGuardian
                ? convertHexToVector(colors[0] || '') || [0.12, 0.23, 0.53]
                : convertHexToVector(dabTickTheme?.color || primaryColor),
            u_theme_color: (heatProfileCoreImageTheme &&
              heatProfileCoreImageTheme.tickColor &&
              convertHexToVector(heatProfileCoreImageTheme.tickColor)) ||
              convertHexToVector(dabTickTheme?.color || primaryColor) || [
                0.12, 0.23, 0.53,
              ],
            u_md_colors: paddedMoodColors.map(color =>
              convertHexToVector(color),
            ),
            u_md_angle_shifts: moodLightAnimationShift,
            u_md_colors_cnt: isMoodLight ? colors.length : 0,
            u_is_disco: isDisco,
            u_is_rotating: isMoodAnimating || isDisco,
          }}
        />
      </Surface>
      <HaloInner
        style={{
          width: innerContainerDiameter,
          height: innerContainerDiameter,
        }}>
        {heatProfileCoreImageTheme && (
          <OpalContainer>
            <HeatProfileCoreImage
              theme={heatProfileCoreImageTheme}
              colors={colors}
              style={{
                height: '100%',
                width: '100%',
                borderWidth: 4,
                borderColor: appColors.invisible,
                borderRadius: innerContainerDiameter,
              }}
              onLayout={onLayout}
            />
          </OpalContainer>
        )}
        {innerComponent}
      </HaloInner>
    </Container>
  ) : null;
};

const Container = styled(View)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const HaloInner = styled(View)({
  width: DEFAULT_INNER_CONTAINER_DIAMETER,
  height: DEFAULT_INNER_CONTAINER_DIAMETER,
  zIndex: 1,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  ...(IS_NATIVE_ANDROID && {
    // Padding to align progress bar on top of tick marks on Android
    paddingBottom: 1,
    paddingRight: 1,
  }),
});

const OpalContainer = styled(View)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 10,
});

import React from 'react';

import {Screens} from '../constants';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../navigation/navigators/HomeDrawerNavigator';

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<
    typeof Screens.StaticScreenInterstitial
  > {}

export interface StaticScreenInterstitialProps extends Props {
  onNavigatingAway: () => void;
}

export const StaticScreenInterstitial = (props: Props) => {
  const {component: Component, onNavigatingAway} = props.route.params ?? {};

  return <Component {...props} {...{onNavigatingAway}} />;
};

import {StaticScreenInterstitialProps} from '../../screens';
import {IPeakDevice} from '../ble2/v2/PeakDevice/IPeakDevice';
import {Device} from './Device';
import {User} from './User';

interface InterstitialBase<T extends 'static' | 'remote'> {
  key: string;
  startDate: Date;
  endDate?: Date;
  type: T;
}

type If = (options: {
  currentDevice?: Device;
  connectedDevice?: IPeakDevice;
  user?: User;
  launchCount?: number;
  country?: string;
}) => boolean;

interface StaticInterstitial extends InterstitialBase<'static'> {
  display: {if: If};
}

export interface StaticScreenInterstitial extends StaticInterstitial {
  display: {
    as: 'screen';
    if: If;
  };
  options: {
    screen: React.ComponentType<StaticScreenInterstitialProps>;
  };
}

export interface StaticModalInterstitial extends StaticInterstitial {
  display: {
    as: 'modal';
    if: If;
  };
  options: {
    modal: React.ComponentType;
  };
}

export interface RemoteModalInterstitial extends InterstitialBase<'remote'> {
  display: {as: 'modal'};
  options: {
    url: string;
  };
}

export type Interstitial =
  | StaticScreenInterstitial
  | StaticModalInterstitial
  | RemoteModalInterstitial;

export const isStaticScreenInterstitial = (
  interstitial: Interstitial,
): interstitial is StaticScreenInterstitial =>
  interstitial.type === 'static' && interstitial.display.as === 'screen';

export const isStaticModalInterstitial = (
  interstitial: Interstitial,
): interstitial is StaticModalInterstitial =>
  interstitial.type === 'static' && interstitial.display.as === 'modal';

export const isRemoteModalInterstitial = (
  interstitial: Interstitial,
): interstitial is RemoteModalInterstitial =>
  interstitial.type === 'remote' && interstitial.display.as === 'modal';

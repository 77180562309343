import React from 'react';

import {Screens} from '../../constants';
import {HeatProfileEditNavigator} from '../../navigation/navigators/RootStackNavigator';
import {analytics} from '../../src/services/analytics';
import {HeatProfileForm} from './HeatProfileForm';

export type Props = {
  showHeader?: boolean;
};

type ScreenProps = HeatProfileEditNavigator<typeof Screens.HeatProfileCreate>;

export const HeatProfileCreateScreen = ({route}: ScreenProps) => {
  const onSave = React.useCallback(() => {
    analytics.trackEvent('heat profile create', {});
  }, []);

  return <HeatProfileForm {...{onSave}} {...(route.params ?? {})} />;
};

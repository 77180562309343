import React from 'react';
import {Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {backgroundCleaningReminder} from '../../../assets/images';
import {ImgBackground, Modal, StyledButton} from '../../../components';
import {Constants, Messages, appColors} from '../../../constants';
import {useWatchDevice} from '../../../lib/hooks';
import {
  currentDeviceSelector,
  updateDeviceAppSettings,
} from '../../../lib/redux/bleSlice';
import styled from '../../../lib/styled';
import {analytics} from '../../../src/services/analytics';

export const CleaningReminder = () => {
  const dispatch = useDispatch();
  const device = useSelector(currentDeviceSelector);
  const deviceHits = useWatchDevice('hits');

  const resetReminder = () => {
    if (!device || !deviceHits) return;

    dispatch(
      updateDeviceAppSettings({
        id: device.id,
        lastCleanReminderDabCount: deviceHits,
        delay: 0,
      }),
    );
    Modal.close();
    analytics.trackEvent('clean rem reset', {});
  };

  const snooze = () => {
    if (!device || !deviceHits || !device.appSettings) return;

    const delay =
      (device.appSettings?.delay ?? 0) + (device.appSettings?.snoozeDabs ?? 10);

    if (delay === 0) {
      Modal.close();

      return;
    }

    dispatch(updateDeviceAppSettings({id: device.id, delay}));
    Modal.close();

    analytics.trackEvent('clean rem snooze', {});
  };

  return (
    <Window>
      <ImgBackground source={backgroundCleaningReminder} style={{flex: 1}}>
        <Container>
          <TextContainer>
            <TitleText>{Messages.CLEANING_REMINDER_TITLE}</TitleText>
            <DescriptionText>
              {Messages.CLEANING_REMINDER_MESSAGE}
            </DescriptionText>
          </TextContainer>
          <ButtonContainer>
            {/* TODO use same logic as cleaning reminder screen? */}
            <StyledButton
              title={Messages.CLEANING_REMINDER_RESET_BTN}
              onPress={resetReminder}></StyledButton>
            <DismissText onPress={snooze}>
              {Messages.CLEANING_REMINDER_SNOOZE_BTN}
            </DismissText>
          </ButtonContainer>
        </Container>
      </ImgBackground>
    </Window>
  );
};

const Window = styled(View)({
  width: '90%',
  height: '85%',
  borderRadius: 18,
  overflow: 'hidden',
});

const Container = styled(View)({
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  marginHorizontal: 11,
});

const TextContainer = styled(View)({
  marginTop: 30,
  alignItems: 'center',
});

const ButtonContainer = styled(View)({
  marginBottom: 50,
  alignItems: 'center',
});

const TitleText = styled(Text)({
  color: '#828282',
  fontSize: 14,
  letterSpacing: 3.18,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
});

const DescriptionText = styled(Text)({
  color: appColors.white,
  fontSize: 56,
  fontFamily: 'BigShouldersDisplay-Regular',
  fontWeight: Constants.IS_WEB ? '700' : '600',
  letterSpacing: 1,
  textAlign: 'center',
});

const DismissText = styled(Text)({
  color: appColors.white,
  fontSize: 14,
  marginTop: 20,
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
});

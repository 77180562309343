import React from 'react';
import {Image, ImageStyle, StyleProp} from 'react-native';

interface Props {
  uri: string;
  width?: number;
  height?: number;
  style?: StyleProp<ImageStyle>;
}

export const ScaledImage = ({uri, width, height, style}: Props) => {
  const [dimensions, setDimensions] = React.useState({width: 0, height: 0});

  React.useEffect(() => {
    Image.getSize(
      uri,
      (width, height) => {
        setDimensions({width, height});
      },
      error => console.error(error),
    );
  }, [uri]);

  const aspectRatio = dimensions.width / dimensions.height;

  const scaledWidth = width ?? (height ? height * aspectRatio : 0);
  const scaledHeight = height ?? (width ? width / aspectRatio : 0);

  if (!scaledHeight || !scaledWidth) return null;

  return (
    <Image
      source={{uri}}
      style={[style, {width: scaledWidth, height: scaledHeight}]}
    />
  );
};

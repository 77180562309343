// @ts-nocheck
// TODO fix types
import isFunction from 'lodash/isFunction';
import React from 'react';
import {
  ImageStyle,
  RegisteredStyle,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';

type StyleInput<P> =
  | (ViewStyle | TextStyle | ImageStyle)
  | ((props: P) => ViewStyle | TextStyle | ImageStyle);
type StyleReturn<P> = (
  input: StyleInput<P>,
) => (input: P & {children?: React.ReactNode}) => React.ReactElement<P>;

function styled<P>(Component: React.ComponentType<P>): StyleReturn<P> {
  return (input: StyleInput<P>) => {
    let earlyStyles: {styles: RegisteredStyle<any>};
    if (!isFunction(input)) {
      // optimization for most common use case, when input is a static style
      earlyStyles = StyleSheet.create({
        styles: input,
      });
    }
    return function styled(props: P) {
      const styles =
        earlyStyles ||
        StyleSheet.create({
          styles: input(props),
        });

      // tslint:disable-next-line
      const {style, children, ...rest} = props;
      let composed = [styles.styles];

      if (Array.isArray(style)) {
        composed = [...composed, ...style];
      } else if (style) {
        composed = [...composed, style];
      }

      return (
        <Component {...{style: composed}} {...rest}>
          {children}
        </Component>
      );
    };
  };
}

export default styled;

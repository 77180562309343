import React from 'react';
import {
  AlertButton,
  AlertOptions,
  LayoutChangeEvent,
  Text,
  TextInput,
  TextInputProps,
  TouchableHighlight,
  View,
  ViewStyle,
  useColorScheme,
} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';
import {Modal} from './Modal';

const BORDER_RADIUS = 12;

export type AlertInputButton = AlertButton & {
  isEnabled?: boolean;
};

type AlertButtonProps = AlertInputButton & {
  hasRoundedBottomLeft?: boolean;
  hasRoundedBottomRight?: boolean;
  width?: ViewStyle['width'];
};

export type AlertProps = {
  title: string;
  message?: string;
  buttons?: AlertInputButton[];
  options?: AlertOptions;
};

export type AlertInputProps = AlertProps &
  TextInputProps & {
    value?: string;
    placeholder?: string;
    maxLength?: number;
    onChangeText?: (text: string) => void;
    error?: boolean;
    errorMsg?: string;
  };

export const Alert = {
  alert: (
    title: string,
    message?: string,
    buttons?: AlertInputButton[],
    options?: AlertOptions,
  ) =>
    Modal.display({
      element: <AlertWindow {...{title, message, buttons, options}} />,
      onClose: options?.onDismiss,
    }),
};

export const AlertWindow = ({
  title,
  message,
  buttons,
  value,
  placeholder,
  maxLength,
  onChangeText,
  keyboardType,
  keyboardAppearance,
  error,
  errorMsg,
}: AlertInputProps) => {
  const [textHeight, setTextHeight] = React.useState(0);
  const [overflowHeight, setOverflowHeight] = React.useState(0);
  const isDarkMode = useColorScheme() === 'dark';

  const darkModeText = {
    color: isDarkMode ? appColors.white : appColors.black,
  };
  const darkModeContainer = {
    backgroundColor: isDarkMode
      ? appColors.darkGray
      : appColors.alertWindowBackground,
  };
  const darkModeInput = {
    backgroundColor: isDarkMode
      ? appColors.darkGray
      : appColors.alertTextInputBackground,
    color: isDarkMode ? appColors.gray : appColors.black,
    borderColor: isDarkMode ? appColors.darkGreyMedium : appColors.alertBorder,
  };
  const darkModeButtonSegment = {
    borderColor: isDarkMode ? appColors.darkGreyMedium : appColors.white,
  };
  const darkModeDivider = {
    backgroundColor: isDarkMode
      ? appColors.darkGreyMedium
      : appColors.alertBorder,
  };

  const ButtonHeightCheck = () => {
    return (
      <Button
        onLayout={(event: LayoutChangeEvent) => {
          const height = event.nativeEvent.layout.height;
          setOverflowHeight(height);
        }}
        style={{
          position: 'absolute',
        }}>
        <AlertButtonText style={{color: appColors.invisible}}>
          &nbsp;
        </AlertButtonText>
      </Button>
    );
  };

  const AlertButton = ({
    text,
    onPress,
    style,
    isEnabled = true,
    hasRoundedBottomLeft = false,
    hasRoundedBottomRight = false,
    width = '100%',
  }: AlertButtonProps) => (
    <Button
      onLayout={(event: LayoutChangeEvent) => {
        const height = event.nativeEvent.layout.height;
        height > textHeight && setTextHeight(height);
      }}
      underlayColor={
        isEnabled
          ? appColors.alertSelectedButton
          : appColors.alertWindowBackground
      }
      style={{
        borderBottomLeftRadius: hasRoundedBottomLeft ? BORDER_RADIUS : 0,
        borderBottomRightRadius: hasRoundedBottomRight ? BORDER_RADIUS : 0,
        width,
      }}
      onPress={() => {
        if (isEnabled && (style === 'cancel' || !error)) {
          onPress && onPress();
          Modal.close();
          setTextHeight(0);
        }
      }}>
      <AlertButtonText
        style={{
          color: isEnabled
            ? appColors.alertButtonText
            : appColors.alertSelectedButton,
        }}>
        {text}
      </AlertButtonText>
    </Button>
  );

  const buttonLayout = () => {
    if (buttons) {
      const hasCancelStyle = (button: AlertButton) =>
        button?.style === 'cancel';
      const cancelButtonIndex = buttons.findIndex(hasCancelStyle);
      const moveButton = (from: number, to: number) => {
        buttons.splice(to, 0, buttons.splice(from, 1)[0]);
      };

      if (buttons.length === 1) {
        return (
          <ButtonSegment style={darkModeButtonSegment}>
            {AlertButton({
              text: buttons[0].text,
              onPress: buttons[0].onPress,
              hasRoundedBottomLeft: true,
              hasRoundedBottomRight: true,
            })}
          </ButtonSegment>
        );
      } else if (buttons.length === 2) {
        if (textHeight > overflowHeight) {
          cancelButtonIndex >= 0 && moveButton(cancelButtonIndex, 1);
          return (
            <ButtonContainer>
              <ButtonSegment style={darkModeButtonSegment}>
                {AlertButton({
                  text: buttons[0].text,
                  onPress: buttons[0].onPress,
                })}
              </ButtonSegment>
              <ButtonSegment style={darkModeButtonSegment}>
                {AlertButton({
                  text: buttons[1].text,
                  onPress: buttons[1].onPress,
                  hasRoundedBottomLeft: true,
                  hasRoundedBottomRight: true,
                })}
              </ButtonSegment>
            </ButtonContainer>
          );
        } else {
          cancelButtonIndex >= 0 && moveButton(cancelButtonIndex, 0);
          return (
            <ButtonSegment style={darkModeButtonSegment}>
              {AlertButton({
                text: buttons[0].text,
                onPress: buttons[0].onPress,
                style: buttons[0].style,
                hasRoundedBottomLeft: true,
                width: '50%',
              })}
              <Divider style={darkModeDivider} />
              {AlertButton({
                text: buttons[1].text,
                onPress: buttons[1].onPress,
                style: buttons[1].style,
                isEnabled: buttons[1].isEnabled,
                hasRoundedBottomRight: true,
                width: '50%',
              })}
            </ButtonSegment>
          );
        }
      } else if (buttons.length === 3) {
        cancelButtonIndex >= 0 && moveButton(cancelButtonIndex, 2);
        return (
          <ButtonContainer>
            <ButtonSegment style={darkModeButtonSegment}>
              {AlertButton({
                text: buttons[0].text,
                onPress: buttons[0].onPress,
              })}
            </ButtonSegment>
            <ButtonSegment style={darkModeButtonSegment}>
              {AlertButton({
                text: buttons[1].text,
                onPress: buttons[1].onPress,
              })}
            </ButtonSegment>
            <ButtonSegment style={darkModeButtonSegment}>
              {AlertButton({
                text: buttons[2].text,
                onPress: buttons[2].onPress,
                hasRoundedBottomLeft: true,
                hasRoundedBottomRight: true,
              })}
            </ButtonSegment>
          </ButtonContainer>
        );
      }
    } else {
      return (
        <ButtonSegment style={darkModeButtonSegment}>
          {AlertButton({
            text: 'OK',
            hasRoundedBottomLeft: true,
            hasRoundedBottomRight: true,
          })}
        </ButtonSegment>
      );
    }
  };

  return (
    <Container style={darkModeContainer}>
      <TopContainer>
        <AlertTitleText style={darkModeText}>{title}</AlertTitleText>
        {message && (
          <AlertMessageText style={darkModeText}>{message}</AlertMessageText>
        )}
        {onChangeText && (
          <InputContainer>
            {error && <AlertErrorText>{errorMsg}</AlertErrorText>}
            <Input
              style={darkModeInput}
              keyboardAppearance={keyboardAppearance}
              keyboardType={keyboardType}
              value={value}
              placeholder={placeholder}
              placeholderTextColor={
                isDarkMode
                  ? appColors.gray
                  : appColors.alertTextInputPlaceholder
              }
              maxLength={maxLength}
              onChangeText={onChangeText}
            />
          </InputContainer>
        )}
      </TopContainer>
      {!overflowHeight && ButtonHeightCheck()}
      {buttonLayout()}
    </Container>
  );
};

const Container = styled(View)({
  position: 'absolute',
  flexDirection: 'column',
  borderRadius: BORDER_RADIUS,
  width: 260,
  alignItems: 'center',
  justifyContent: 'center',
});

const AlertTitleText = styled(Text)({
  fontFamily: 'System',
  textAlign: 'center',
  fontWeight: '600',
  fontSize: 17,
});

const AlertMessageText = styled(Text)({
  fontFamily: 'System',
  textAlign: 'center',
  fontSize: 13,
  marginTop: 5,
});

const AlertButtonText = styled(Text)({
  fontFamily: 'System',
  textAlign: 'center',
  fontSize: 17,
  color: appColors.alertButtonText,
});

const AlertErrorText = styled(Text)({
  fontFamily: 'System',
  textAlign: 'center',
  fontSize: 15,
  color: appColors.errorColor,
  marginBottom: 6,
});

const InputContainer = styled(View)({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 18,
});

const Input = styled(TextInput)({
  width: '100%',
  height: 28,
  borderWidth: 1,
  borderRadius: 5,
  paddingLeft: 3,
});

const TopContainer = styled(View)({
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 20,
});

const ButtonContainer = styled(View)({
  width: '100%',
});

const ButtonSegment = styled(View)({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderTopWidth: 1,
  borderTopColor: appColors.alertBorder,
});

const Button = styled(TouchableHighlight)({
  padding: 12,
  alignItems: 'center',
  justifyContent: 'center',
});

const Divider = styled(View)({
  position: 'absolute',
  height: '100%',
  width: 1,
  zIndex: 9,
});

import GraphemeSplitter from 'grapheme-splitter';

const splitter = new GraphemeSplitter();

export const getLetter = (name: string | null, index: number) => {
  if (!name) return null;
  const letterArray = splitter.splitGraphemes(name);
  const letter = letterArray[index];
  return letter ? letter.toLocaleUpperCase() : null;
};

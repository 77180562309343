export type PolyFilledWindow = Window & {
  fullscreenElement?: () => null | Document;
  requestFullscreen?: () => void;
  exitFullscreen?: () => void;
};

export const supportsFullscreen = () => {
  const polyFilledWindow = window as PolyFilledWindow;
  return !!polyFilledWindow.requestFullscreen;
};

export const getfullscreenElement = () => {
  const polyFilledWindow = window as PolyFilledWindow;
  return polyFilledWindow.fullscreenElement;
};

export const requestFullscreen = () => {
  const polyFilledWindow = window as PolyFilledWindow;
  if (polyFilledWindow.requestFullscreen) polyFilledWindow.requestFullscreen();
};

export const exitFullscreen = () => {
  const polyFilledWindow = window as PolyFilledWindow;
  if (polyFilledWindow.exitFullscreen) polyFilledWindow.exitFullscreen();
};

import React from 'react';
import {useSelector} from 'react-redux';

import {ConnectablePeripheral} from '../ble2/v2/BleManager/BleManagerBase';
import {
  currentDeviceIdSelector,
  currentDeviceNameSelector,
  currentDeviceSerialNumberSelector,
} from '../redux/bleSlice';

export const useConnectablePeripheral = () => {
  const id = useSelector(currentDeviceIdSelector);
  const name = useSelector(currentDeviceNameSelector);
  const serialNumber = useSelector(currentDeviceSerialNumberSelector);

  return React.useMemo<ConnectablePeripheral | undefined>(() => {
    if (!id) return;
    return {id, name, serialNumber};
  }, [id, name, serialNumber]);
};

import React from 'react';
import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import {searchNotFound} from '../../../assets/images';
import {Strings, appColors} from '../../../constants';

export interface Props {
  style?: StyleProp<ViewStyle>;
}

export const NoResults = ({style}: Props) => (
  <View style={[styles.container, style]}>
    <Image source={searchNotFound} style={styles.image} />

    <Text style={styles.title}>{Strings.NO_RESULTS}</Text>

    <Text style={styles.subtitle}>{Strings.NO_RESULTS_TRY_AGAIN}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 50,
    height: 50,
    marginTop: 28,
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 24,
    fontWeight: '700',
    color: appColors.white,
    marginHorizontal: 20,
    marginTop: 16,
    textTransform: 'uppercase',
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: appColors.white,
    opacity: 0.5,
    marginHorizontal: 20,
    marginTop: 16,
  },
});

import useSWR, {BareFetcher, SWRConfiguration, SWRResponse} from 'swr';
import useSWRImmutable from 'swr/immutable';

interface UseRemoteDataOptions {
  key: string;
  skip?: boolean;
}

export const useRemoteData = <O extends UseRemoteDataOptions, T>(
  {key, skip, ...o}: O,
  callback: (o: Omit<O, keyof UseRemoteDataOptions>) => Promise<T>,
  config?: SWRConfiguration<T, Error, BareFetcher<T>>,
): SWRResponse<T, Error> => {
  return useSWR<T>(!skip ? {key, ...o} : null, callback, {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: true,
    ...config,
  });
};

export const useImmutableRemoteData = <O extends UseRemoteDataOptions, T>(
  {key, skip, ...o}: O,
  callback: (o: Omit<O, keyof UseRemoteDataOptions>) => Promise<T>,
  config?: SWRConfiguration<T, Error, BareFetcher<T>>,
): SWRResponse<T, Error> => {
  return useSWRImmutable<T>(!skip ? {key, ...o} : null, callback, {
    keepPreviousData: true,
    ...config,
  });
};

import React from 'react';
import {useAsync} from 'react-use';

import {contentAccess} from '../../../../lib/api';
import {TypeDevice} from '../../../../lib/api/content-access/types';

export const useDeviceSelection = () => {
  const {value, loading, error} = useAsync(
    () => contentAccess.getDevices(),
    [],
  );

  const [selected, select] = React.useState<TypeDevice>();

  const devices = React.useMemo(() => value?.items ?? [], [value?.items]);

  React.useEffect(() => {
    if (devices.length > 0 && !selected) select(devices[0]);
  }, [devices]);

  return React.useMemo(
    () => ({
      loading,
      error,
      selected,
      devices,
      select,
    }),
    [devices, loading, error, selected, select],
  );
};

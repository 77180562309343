import {ImageSourcePropType} from 'react-native';

type ColoredBackground = {
  color: string;
};

type ColoredGradientBackground = {
  color: string;
  gradient: string[];
};

type GradientBackground = {gradient: string[]};

type ImageBackground = {image: ImageSourcePropType};

type ColoredImageBackground = {
  color: string;
  image: ImageSourcePropType;
};

export type Background =
  | ColoredBackground
  | ColoredGradientBackground
  | GradientBackground
  | ImageBackground
  | ColoredImageBackground;

export const isColoredBackground = (
  background: Background,
): background is ColoredBackground => !!(background as ColoredBackground).color;

export const isColoredGradientBackground = (
  background: Background,
): background is ColoredGradientBackground =>
  !!(
    (background as ColoredGradientBackground).color &&
    (background as ColoredGradientBackground).gradient
  );

export const isGradientBackground = (
  background: Background,
): background is GradientBackground =>
  !!(background as GradientBackground).gradient;

export const isImageBackground = (
  background: Background,
): background is ImageBackground => !!(background as ImageBackground).image;

export const isColoredImageBackground = (
  background: Background,
): background is ColoredImageBackground =>
  !!(
    (background as ColoredImageBackground).image &&
    (background as ColoredImageBackground).color
  );

import React from 'react';

interface Options {
  formatter: (value: number) => string;
}

export const useBoostValue = ({formatter}: Options) => {
  const [boost, setBoost] = React.useState<{value?: string; times: number}>({
    times: 0,
  });

  React.useEffect(() => {
    const tid = setTimeout(() => {
      setBoost(previous => ({...previous, value: undefined}));
    }, 1500);

    return () => clearTimeout(tid);
  }, [boost]);

  const show = React.useCallback(
    (value: number) => {
      setBoost(previous => ({
        value: formatter(value),
        times: previous.times + 1,
      }));
    },
    [formatter],
  );

  return React.useMemo(
    () => ({value: boost.value, times: boost.times, show}),
    [boost, show],
  );
};

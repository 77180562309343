import {Text} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';

export const HeadingText = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 32,
  letterSpacing: 0.32,
  textTransform: 'uppercase',
  color: appColors.white,
});

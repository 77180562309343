import React from 'react';
import {Animated, Text, TouchableOpacity, View} from 'react-native';

import {appColors} from '../constants';
import {ProfileSliderSwitchTitleStyle as switchTextStyle} from '../constants/Styles';
import styled from '../lib/styled';

const SWITCH_WIDTH = 160;
const BORDER_RADIUS = 8;

export enum TextSwitchHandlePosition {
  LEFT = 0,
  RIGHT = 1,
}

export type TextSwitchProps = {
  initialHandlePosition: TextSwitchHandlePosition;
  leftText: string;
  rightText: string;
  onToggle: (handlePosition: TextSwitchHandlePosition) => void;
  disabledPosition?: TextSwitchHandlePosition;
  onDisabledPress?: () => void;
};

export const TextSwitch = (props: TextSwitchProps) => {
  const {
    initialHandlePosition,
    leftText,
    rightText,
    onToggle,
    disabledPosition,
    onDisabledPress,
  } = props;
  const [handlePosition, setHandlePosition] = React.useState(
    initialHandlePosition,
  );
  const slidingAnimation = React.useRef(
    new Animated.Value(handlePosition),
  ).current;
  const isHandleLeft = handlePosition === TextSwitchHandlePosition.LEFT;

  React.useEffect(() => {
    setHandlePosition(initialHandlePosition);
  }, [initialHandlePosition]);

  React.useEffect(() => {
    const id = slidingAnimation.addListener(value =>
      setHandlePosition(value.value),
    );
    return () => slidingAnimation.removeListener(id);
  }, []);

  const handleTouch = (position: TextSwitchHandlePosition) => {
    setHandlePosition(position);
    setTimeout(() => {
      onToggle(
        isHandleLeft
          ? TextSwitchHandlePosition.RIGHT
          : TextSwitchHandlePosition.LEFT,
      );
    }, 100);
  };

  return (
    <SwitchContainer style={{backgroundColor: appColors.gray19}}>
      <SwitchHandle
        style={{
          marginLeft: handlePosition * (SWITCH_WIDTH / 2),
          backgroundColor: appColors.white,
        }}>
        <SwitchText style={{color: appColors.black, fontFamily: 'Roboto-Bold'}}>
          {isHandleLeft ? leftText : rightText}
        </SwitchText>
      </SwitchHandle>
      <TouchableOpacity
        onPress={() =>
          disabledPosition === TextSwitchHandlePosition.LEFT
            ? onDisabledPress && onDisabledPress()
            : handleTouch(TextSwitchHandlePosition.LEFT)
        }>
        <SwitchText style={{color: appColors.white}}>{leftText}</SwitchText>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          disabledPosition === TextSwitchHandlePosition.RIGHT
            ? onDisabledPress && onDisabledPress()
            : handleTouch(TextSwitchHandlePosition.RIGHT)
        }>
        <SwitchText style={{color: appColors.white}}>{rightText}</SwitchText>
      </TouchableOpacity>
    </SwitchContainer>
  );
};

const SwitchContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: SWITCH_WIDTH,
  height: 34,
  borderRadius: BORDER_RADIUS,
});

const SwitchHandle = styled(View)({
  position: 'absolute',
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  width: SWITCH_WIDTH / 2,
  height: '100%',
  borderRadius: BORDER_RADIUS,
});

const SwitchText = styled(Text)({
  fontFamily: switchTextStyle.fontFamily,
  fontSize: switchTextStyle.fontSize,
  fontWeight: switchTextStyle.fontWeight,
  letterSpacing: switchTextStyle.letterSpacing,
  textAlign: 'center',
  width: SWITCH_WIDTH / 2,
  lineHeight: 19,
  includeFontPadding: false,
});

import {
  LanternCustom0,
  LanternCustom1,
  LanternExclusive0,
  MiddlewareValue,
  Profile0,
  ProfileCustom0,
  ProfileCustom1,
  ProfileExclusive0,
  ScratchpadField,
} from '../lib/types';

export const ScratchpadIdField: ScratchpadField<{scratchpadId: number}> = {
  key: 'scratchpadId',
  type: MiddlewareValue.U_INT_8,
};

// total number of bytes including version: 1 + 16 = 18
const lanternExclusive0: ScratchpadField<Omit<LanternExclusive0, 'version'>>[] =
  [{key: 'moodUlid', type: MiddlewareValue.ULID}];

// total number of bytes including version: 18 + 32 + 4 + 1 + 4 + 18 = 77
const lanternCustom0: ScratchpadField<Omit<LanternCustom0, 'version'>>[] = [
  ...lanternExclusive0,
  {key: 'moodName', type: MiddlewareValue.STRING, length: 32},
  {key: 'moodDateModified', type: MiddlewareValue.U_INT_32},
  {key: 'moodType', type: MiddlewareValue.U_INT_8},
  {key: 'tempo', type: MiddlewareValue.FLOAT},
  {key: 'colors', type: MiddlewareValue.COLORS, length: 18},
];

// total number of bytes including version: 77 + 16 = 93
const lanternCustom1: ScratchpadField<Omit<LanternCustom1, 'version'>>[] = [
  ...lanternCustom0,
  {key: 'originalMoodUlid', type: MiddlewareValue.ULID},
];

// total number of bytes including version: 1 + 16 + 4 = 21
const profile0: ScratchpadField<Omit<Profile0, 'version'>>[] = [
  {key: 'heatProfileUlid', type: MiddlewareValue.ULID},
  {key: 'heatProfileDateModified', type: MiddlewareValue.U_INT_32},
];

// total number of bytes including version: 21 + 18 - 1 = 38
// (subtract 1 because both have version included in previous total)
const profileExclusive0: ScratchpadField<ProfileExclusive0>[] = [
  ...profile0,
  ...lanternExclusive0,
];

// total number of bytes including version: 21 + 77 - 1 = 97
const profileCustom0: ScratchpadField<ProfileCustom0>[] = [
  ...profile0,
  ...lanternCustom0,
];

// total number of bytes including version: 21 + 93 - 1 = 113
const profileCustom1: ScratchpadField<ProfileCustom1>[] = [
  ...profile0,
  ...lanternCustom1,
];

export const ScratchpadFieldVersionMap = {
  0: lanternExclusive0,
  1: lanternCustom0,
  2: profile0,
  3: profileExclusive0,
  4: profileCustom0,
  16: lanternCustom1,
  19: profileCustom1,
} as const;

export const ScratchpadVersions = {
  LanternExclusiveNewest: 0,
  LanternCustomNewest: 16,
  ProfileNewest: 2,
  ProfileExclusiveNewest: 3,
  ProfileCustomNewest: 19,
  LanternExclusive0: 0,
  LanternCustom0: 1,
  LanternCustom1: 16,
  Profile0: 2,
  ProfileExclusive0: 3,
  ProfileCustom0: 4,
  ProfileCustom1: 19,
} as const;

import React from 'react';
import {useSelector} from 'react-redux';

import {
  getMoodLightSelector,
  lanternMoodLightSelector,
} from '../redux/moodLightSlice';
import {activeProfilesSelector} from '../redux/profilesSlice';
import {MoodLight, Profile, isTHeatProfileMoodLight} from '../types';

// Returns an array of the current distinct peak mood lights
export const useGetUpdatedPeakMoodLights = () => {
  const getMoodLight = useSelector(getMoodLightSelector);
  const lanternMoodLight = useSelector(lanternMoodLightSelector);
  const actives = useSelector(activeProfilesSelector);

  return React.useCallback(
    (
      props:
        | {actives: Profile[]}
        | {lanternMoodLight: MoodLight | undefined}
        | {actives: Profile[]; lanternMoodLight: MoodLight | undefined},
    ) => {
      const peakMoodLightIds = new Set<string>();

      const lantern =
        'lanternMoodLight' in props ? props.lanternMoodLight : lanternMoodLight;
      const profiles = 'actives' in props ? props.actives : actives;

      profiles.forEach(active => {
        if (isTHeatProfileMoodLight(active)) {
          peakMoodLightIds.add(active.moodLightId);
        }
      });
      lantern && peakMoodLightIds.add(lantern.id);

      const newPeakMoodLights: MoodLight[] = [];
      peakMoodLightIds.forEach(id => {
        const moodLight = getMoodLight(id);
        moodLight && newPeakMoodLights.push(moodLight);
      });

      return newPeakMoodLights;
    },
    [actives, getMoodLight, lanternMoodLight],
  );
};

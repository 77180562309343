import {Device} from '../types';
import {getProductName} from './getProductName';

export const isPeach = (device: Device) => {
  if (device.product?.type === 'peach') return true;

  // TODO: remove this once users updated to the latest version
  return ['Pearl', 'Onyx', 'Desert', 'Flourish', 'Storm'].includes(
    getProductName(device),
  );
};

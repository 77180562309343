import {CompositeScreenProps} from '@react-navigation/native';
import {StackScreenProps, createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Navigators, Screens} from '../../constants';
import {
  SharedHeatProfileScreen,
  Props as SharedHeatProfileScreenProps,
} from '../../screens/main/SharedHeatProfileScreen';
import {HomeEmulatedDrawerStackScreenProps} from './HomeDrawerNavigator';
import {defaultNavigationOptions} from './common';

export type SharedHeatProfileStackParamList = {
  [Screens.SharedHeatProfile]: SharedHeatProfileScreenProps;
};

export type SharedHeatProfileStackScreenProps<
  T extends keyof SharedHeatProfileStackParamList,
> = CompositeScreenProps<
  StackScreenProps<SharedHeatProfileStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<typeof Navigators.SharedHeatProfile>
>;

const SharedHeatProfileStack =
  createStackNavigator<SharedHeatProfileStackParamList>();

export const SharedHeatProfileStackNavigator = () => {
  return (
    <SharedHeatProfileStack.Navigator
      initialRouteName={Screens.SharedHeatProfile}
      screenOptions={{...defaultNavigationOptions, headerShown: false}}>
      <SharedHeatProfileStack.Screen
        name={Screens.SharedHeatProfile}
        component={SharedHeatProfileScreen}
      />
    </SharedHeatProfileStack.Navigator>
  );
};

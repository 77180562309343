import {
  LinkingOptions,
  NavigationContainerRefWithCurrent,
} from '@react-navigation/native';

import {RootStackParamList} from '../navigation/navigators/RootStackNavigator';

let _navigator:
  | NavigationContainerRefWithCurrent<RootStackParamList>
  | undefined;

export const linking: LinkingOptions<any> = {
  // We have to disable linking, because on web getInitialUrl and subscribe are not called at all.
  // This would prevent deeplinking. We will implement our own deeplinking in PushService and SettingsService.
  enabled: false,
  prefixes: ['puffco-connect://', 'com.puffco://', 'https://www.puffco.app'],
};

function setTopLevelNavigator(
  navigatorRef: NavigationContainerRefWithCurrent<RootStackParamList>,
) {
  _navigator = navigatorRef;
}

const getCurrentRoute = () => {
  return _navigator?.current?.getCurrentRoute();
};

const getCurrentRouteName = () => {
  const route = getCurrentRoute();

  return route?.name ?? '';
};

export default {
  setTopLevelNavigator,
  getCurrentRoute,
  getCurrentRouteName,
  instance: () => _navigator?.current ?? undefined,
};

import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';

import styled from '../lib/styled';
import {useSafeArea} from '../lib/useSafeArea';
import {BlurView} from '../shims/BlurView';

export const BlurredBackgroundSafeArea = ({
  children,
  style,
  ...rest
}: React.PropsWithChildren<ViewProps>) => {
  const {top, bottom} = useSafeArea();

  return (
    <Container>
      <BlurView
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          flex: 1,
        }}
      />
      <Container
        style={{
          paddingTop: top,
          paddingBottom: bottom,
          ...StyleSheet.flatten(style),
        }}
        {...rest}>
        {children}
      </Container>
    </Container>
  );
};

const Container = styled(View)({
  flex: 1,
});

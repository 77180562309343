import React from 'react';
import {
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native';

import {logoClose} from '../assets/images';
import {Constants} from '../constants';
import styled from '../lib/styled';
import {Image} from '../shims/ImageWithFilter';
import {AppText} from './AppText';

const DEFAULT_LOGO_SIZE = 50;

export type StartButtonProps = TouchableOpacityProps & {
  iconColor: string;
  innerContainerDiameter: number;
  logoSize?: number;
  startButtonStyle?: TextStyle;
};

export const StartButton = ({
  iconColor,
  innerContainerDiameter,
  logoSize,
  disabled,
  style,
  startButtonStyle,
  ...rest
}: StartButtonProps) => (
  <ButtonContainer
    disabled={disabled}
    style={{
      borderRadius: innerContainerDiameter,
      opacity: disabled ? Constants.INACTIVE_OPACITY : Constants.ACTIVE_OPACITY,
      ...StyleSheet.flatten(style),
      display: 'flex',
    }}
    {...rest}>
    {/* Invisible text to serve as padding to center the icon */}
    <ButtonText></ButtonText>
    <View style={{paddingVertical: 6}}>
      <Image
        source={logoClose}
        style={{
          height: logoSize ?? DEFAULT_LOGO_SIZE,
          resizeMode: 'contain',
          tintColor: iconColor,
        }}
      />
    </View>
    <ButtonText style={startButtonStyle}>START</ButtonText>
  </ButtonContainer>
);

const ButtonContainer = styled(TouchableOpacity)({
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonText = styled(AppText)({
  fontSize: 14,
  fontFamily: 'Roboto-Regular',
  height: 17,
});

import {Dimensions} from 'react-native';

import {Constants} from '../../constants';

const {
  SCREEN_HEIGHT: {GALAXY_S8, IPHONE_SE_FIRST_GEN, PX_785, IPHONE_12_PRO},
  WINDOW_HEIGHT: {NOTE_20_ULTRA},
} = Constants;

// should compare window height
// now that we can toggle fullscreen
export const useWindowHeight = () => {
  const {height: windowHeight} = Constants.IS_WEB
    ? {height: window.innerHeight}
    : Dimensions.get('window');
  const isVerySmall = windowHeight <= IPHONE_SE_FIRST_GEN;
  const isSmall = windowHeight <= GALAXY_S8;
  const isMedium = windowHeight <= PX_785;
  const isLarge = windowHeight <= NOTE_20_ULTRA;
  const isVeryLarge = windowHeight <= IPHONE_12_PRO;

  return {windowHeight, isVerySmall, isSmall, isMedium, isLarge, isVeryLarge};
};

export const useWindowWidth = () => {
  const {width: windowWidth} = Constants.IS_WEB
    ? {width: window.innerWidth}
    : Dimensions.get('window');

  return {windowWidth};
};

import {useNavigation} from '@react-navigation/native';
import React from 'react';

import {Navigators, Screens} from '../../constants';
import {DeviceEducationStackParamList} from '../../navigation/navigators/DeviceEducationStack';
import {DeviceEducationNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';

type Navigation = DeviceEducationNavigatorScreenProps<
  keyof DeviceEducationStackParamList
>['navigation'];

export const useContactSupport = () => {
  const {navigate} = useNavigation<Navigation>();

  const contactSupport = React.useCallback(
    () => navigate(Navigators.Warranty, {screen: Screens.WarrantyClaim}),
    [navigate],
  );

  return contactSupport;
};

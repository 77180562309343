import {BottomTabBarProps} from '@react-navigation/bottom-tabs';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';

import {AppText} from '../components';
import {Constants, Screens, appColors} from '../constants';
import {GuideType} from '../lib/api';
import styled from '../lib/styled';
import {useSafeArea} from '../lib/useSafeArea';
import {useTheme} from '../lib/useTheme';
import {useGuide} from '../screens';
import {guardianTheme} from '../themes';

type Props = {
  peak: boolean;
  setPeak: React.Dispatch<React.SetStateAction<boolean>>;
  hideTopBorder?: boolean;
};

export const StyledTabBarInner = (props: Props) => {
  const navigation = useNavigation<BottomTabBarProps['navigation']>();
  const currentTheme = useTheme();
  const insets = useSafeArea();

  // Could not execute this logic after navigating (ex: in Heat Profile List, Heat Profile Select)
  // as the navigation would light up the device before opening the QuickStartGuide (apparently we don't want that)
  const guide = useGuide({type: GuideType.NEW_USER});

  const {activeColor, inactiveColor, height} = currentTheme.tabBarTheme;
  const isGuardian = currentTheme === guardianTheme;
  const {hideTopBorder = false, peak, setPeak} = props;

  const calculateMarginTop = () => {
    if (isGuardian) {
      if (Constants.IS_WEB && insets.bottom > 0) {
        return 30;
      } else {
        return 15;
      }
    } else {
      return 0;
    }
  };

  return (
    <TabContainer style={{marginTop: calculateMarginTop()}}>
      <TabBarBorder
        style={{
          backgroundColor: hideTopBorder
            ? appColors.invisible
            : appColors.baseBackgroundAccent,
        }}
      />
      <ButtonContainer style={{height}}>
        <TabButton
          onPress={() => {
            if (!peak) {
              setPeak(!peak);
              navigation.navigate(Screens.Home);
            }
          }}>
          <TabTitle
            style={{
              color: peak ? activeColor : inactiveColor,
              fontFamily: !peak ? 'Roboto-Regular' : 'Roboto-Bold',
            }}>
            MY PEAK
          </TabTitle>
        </TabButton>
        <Line />
        <TabButton
          onPress={() => {
            if (!peak) return;

            setPeak(!peak);

            // Can't handle this logic after navigating, as the heat profile activates before the guide opens
            // we don't want that
            if (!guide.shouldDisplay) {
              navigation.navigate(Screens.HeatProfileList);
              return;
            }

            guide.display({
              onExit: () => navigation.navigate(Screens.HeatProfileList),
            });
          }}>
          <TabTitle
            style={{
              color: peak ? inactiveColor : activeColor,
              fontFamily: peak ? 'Roboto-Regular' : 'Roboto-Bold',
            }}>
            HEAT PROFILES
          </TabTitle>
        </TabButton>
      </ButtonContainer>
    </TabContainer>
  );
};

const TabButton = styled(TouchableOpacity)({
  flex: 1,
});

const Line = styled(View)({
  width: 1,
  height: 25,
  backgroundColor: appColors.baseBackgroundAccent,
});

const TabContainer = styled(View)({
  flexDirection: 'column',
});

const TabBarBorder = styled(View)({
  height: 1,
  width: '95%',
  alignSelf: 'center',
});

const ButtonContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});

const TabTitle = styled(AppText)({
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  textAlign: 'center',
  color: appColors.bottomNavText,
  letterSpacing: 0.75,
  fontWeight: '400',
});

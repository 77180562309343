import {useHeaderHeight} from '@react-navigation/elements';
import {useIsFocused} from '@react-navigation/native';
import React from 'react';

import {Constants} from '../../constants';

/**
 * On web useHeaderHeight might return 0 briefly when the screen becomes focused.
 * To fix this we are going to return the cached (previous) height value, whenever
 * the screen has just been focused and the height value is 0.
 */
export function useAppHeaderHeight() {
  const isFocused = useIsFocused();
  const previousIsFocusedRef = React.useRef(isFocused);

  const height = useHeaderHeight();
  const heightRef = React.useRef<number>(height);

  return React.useMemo(() => {
    const getHeight = () => {
      // Android is not affected, we can return the height as is
      if (!Constants.IS_WEB) return height;

      // If screen is not focused, return the cached height to prevent layout calculations
      if (!isFocused) return heightRef.current;

      // If the screen just has been focused and the header height is incorrectly set to 0, return the cached height
      if (!previousIsFocusedRef.current && isFocused && height === 0)
        return heightRef.current;

      // Cache the height
      heightRef.current = height;

      return height;
    };

    const value = getHeight();

    // Cache the isFocused value
    previousIsFocusedRef.current =
      isFocused !== previousIsFocusedRef.current
        ? isFocused
        : previousIsFocusedRef.current;

    return value;
  }, [isFocused, height]);
}

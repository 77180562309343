import React from 'react';
import {LayoutChangeEvent, LayoutRectangle} from 'react-native';

export const LayoutExtractor = React.memo(function LayoutExtractor({
  children,
}: {
  children: (
    layout: LayoutRectangle | undefined,
    getLayout: (e: LayoutChangeEvent) => void,
  ) => React.ReactNode;
}) {
  const [layout, setLayout] = React.useState<LayoutRectangle>();

  const getLayout = React.useCallback((e: LayoutChangeEvent) => {
    setLayout(e?.nativeEvent?.layout);
  }, []);

  return <>{children(layout, getLayout)}</>;
});

import * as FileSystem from 'expo-file-system';

import {appLog} from './Logger';

export const makeImgToCacheUri = (localFilename: string) => {
  const localUri = `${FileSystem.cacheDirectory}asset_${localFilename}`;
  return localUri;
};

const write = async (uri: string, data: string) => {
  await FileSystem.writeAsStringAsync(uri, data, {
    encoding: FileSystem.EncodingType.Base64,
  });
};

// this workaround copies base64 assets to a known path files so we can use a regular
// `file://...` uri instead as require by expo-gl shaders

export const copyImgToCacheAsync = async (
  asset: {base64: string},
  localFilename: string,
) => {
  const localUri = makeImgToCacheUri(localFilename);
  const fileInfo = await FileSystem.getInfoAsync(localUri, {size: false});

  if (!fileInfo.exists) {
    try {
      write(localUri, asset.base64);
    } catch (error) {
      appLog.error('File write error', {error});
    }
  }
  return localUri;
};

import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import * as icons from '../../assets/images';
import {
  ImgBackground,
  SegmentedProgress,
  StyledIconProps,
} from '../../components';
import {Navigators, Screens, appColors} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {userSelector} from '../../lib/redux/userSlice';
import {addAnswer, resetAnswers} from '../../lib/redux/warrantySlice';
import styled from '../../lib/styled';
import {WarrantyNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {RedirectionParameter} from '../../navigation/navigators/params';
import {SafeAreaView} from '../../shims/SafeAreaView';

interface ScreenProps
  extends WarrantyNavigatorScreenProps<typeof Screens.WarrantyClaim> {}

const toWarrantyClaimRedirect = new RedirectionParameter(
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.Warranty,
        params: {
          screen: Screens.WarrantyClaim,
        },
      },
    },
  },
);

export const WarrantyClaimScreen: React.FC<ScreenProps> = ({navigation}) => {
  const dispatch = useDispatch();
  useAdaptiveSafeArea();

  const userInfo = useSelector(userSelector);

  const initialData = [
    {id: 1, question: 'A physical break', selected: false},
    {id: 2, question: 'Not charging or turning on', selected: false},
    {id: 3, question: 'Chamber connection error', selected: false},
    {id: 4, question: 'Vapor Production', selected: false},
    {id: 5, question: 'Mobile Application', selected: false},
    {id: 6, question: 'Other', selected: false},
  ];
  const [data, setData] = React.useState(initialData);
  const SelectQuestion = (id: number) => {
    const newTest = initialData.slice();
    newTest[id - 1].selected = true;
    setData(newTest);
    const result = newTest.filter(item => item.selected === true);
    dispatch(addAnswer({questionOne: result[0].question}));

    if (id === 2) {
      navigation.navigate(Screens.WarrantyClaimScreenTwo);
    } else {
      if (userInfo) {
        dispatch(
          addAnswer({
            userInfo: {
              name: `${userInfo?.firstName} ${userInfo?.lastName}`,
              email: userInfo?.email,
            },
          }),
        );
        navigation.navigate(Screens.WarrantyClaimScreenMessage);
      } else {
        navigation.navigate(Screens.WarrantyClaimScreenUserInfo, {
          headerLeft: toWarrantyClaimRedirect.encode(),
        });
      }
    }
  };

  React.useEffect(() => {
    dispatch(resetAnswers());
  }, []);

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <QuestionContainer>
          <QuestionText>What seems to be the problem?</QuestionText>
        </QuestionContainer>

        <SegmentedProgress progress={1} />

        <AnswersContainer>
          {data.map(({id, question, selected}) => {
            const questionRowValue = question;
            const selectedValue = selected;
            const idValue = id;
            return (
              <AnswerItemContainer
                key={id}
                onPress={() => {
                  SelectQuestion(idValue);
                }}>
                {selectedValue ? (
                  <CircleIcon name={'whiteCircleFilled'} />
                ) : (
                  <CircleIcon name={'whiteCircle'} />
                )}
                <AnswerText>{questionRowValue}</AnswerText>
              </AnswerItemContainer>
            );
          })}
        </AnswersContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const QuestionContainer = styled(View)({
  height: 110,
  marginLeft: 20,
  marginRight: 68,
  marginTop: 64,
});

const QuestionText = styled(Text)({
  color: appColors.white,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 30,
  lineHeight: 48,
  fontWeight: '400',
});

const AnswersContainer = styled(View)({
  alignContent: 'space-between',
  flexDirection: 'column',
  height: 160,
  marginHorizontal: 50,
  marginTop: 92,
});

const AnswerItemContainer = styled(TouchableOpacity)({
  flex: 1,
  flexDirection: 'row',
  height: 18,
});

const AnswerText = styled(Text)({
  alignSelf: 'center',
  color: appColors.white,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
});

const CircleIcon = (props: StyledIconProps) => {
  const {name, onPress, disabled, size, style} = props;
  if (!name) {
    return null;
  }
  return (
    <TouchableOpacity
      style={[
        {
          alignSelf: 'center',
          borderRadius: 10,
          height: 18,
          marginRight: 12,
        },
        style,
      ]}
      onPress={onPress}
      disabled={disabled || !onPress}>
      <Image
        source={icons[name]}
        style={{
          width: size || 18,
          height: size || 18,
          resizeMode: 'contain',
        }}
      />
    </TouchableOpacity>
  );
};

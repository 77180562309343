import React from 'react';
import {Helmet} from 'react-helmet';
import {StatusBar as NativeStatusbar} from 'react-native';

import {ScreenNames} from '../constants';
import {useTheme} from '../lib/useTheme';

export interface Props {
  activeRouteName?: ScreenNames;
}

export const StatusBar: React.FC<Props> = ({activeRouteName}) => {
  const {statusBarTheme} = useTheme();

  const {barStyle, backgroundColor} = activeRouteName
    ? statusBarTheme[`${activeRouteName}StatusBar`] || statusBarTheme.themed
    : statusBarTheme.themed;

  return (
    <>
      <Helmet>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={
            barStyle === 'dark-content' ? 'black-translucent' : 'default'
          }
        />
      </Helmet>

      <NativeStatusbar {...{barStyle, backgroundColor}} />
    </>
  );
};

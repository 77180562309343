import {
  PreTHeatProfile,
  ProfileVersion,
  THeatProfileMoodLight,
  THeatProfileNoMoodLight,
} from 'puffco-api-axios-client';

export type Profile = ProfilePreT | ProfileT;

export type ProfilePreT = PreTHeatProfile;
export type ProfileT = THeatProfileMoodLight | THeatProfileNoMoodLight;

export const isPreTHeatProfile = (
  profile: Profile,
): profile is PreTHeatProfile => profile.version === ProfileVersion.PreT;

export const isTHeatProfile = (profile: Profile): profile is ProfileT =>
  profile.version === ProfileVersion.T;

export const isTHeatProfileMoodLight = (
  profile: Profile,
): profile is THeatProfileMoodLight =>
  profile.version === ProfileVersion.T &&
  (profile as THeatProfileMoodLight).isMoodLight;

export const isTHeatProfileNoMoodLight = (
  profile: Profile,
): profile is THeatProfileNoMoodLight =>
  profile.version === ProfileVersion.T &&
  !(profile as THeatProfileNoMoodLight).isMoodLight;

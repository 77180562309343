import React from 'react';
import {Animated, View} from 'react-native';

import {LayoutExtractor} from '../../../../../components';
import {useAnim} from '../../../../../lib/hooks';
import {XAndY} from '../util/getDabXAndYAxis';

const Bar = React.memo(function Bar({
  index,
  height,
  animate,
  isValue,
  color,
  animationSpeed,
  width,
  val,
  max,
}: {
  index: number;
  height: number;
  animate?: boolean;
  isValue?: boolean;
  color?: string;
  animationSpeed?: number;
  width: number;
  val: number;
  max: number;
}) {
  const [value, startAnim, isAnimDone] = useAnim();
  const [rValue, startRAnim] = useAnim();

  const timeToDismiss = index >= val ? max - (index - val) - val : index;

  //Calculation to match the animation speed set
  const speedToReduce =
    (val - 5 < 0 ? -(val - 5 - 0) : val + 5 > max ? val + 5 - max : 0) * 0.1 +
    1.4;

  const halfSpeed = (animationSpeed ?? 100) / speedToReduce;

  React.useEffect(() => {
    if (animate) {
      setTimeout(() => {
        startAnim(width, halfSpeed, false);
      }, index * halfSpeed);
    }
  }, [index, animate, halfSpeed]);

  React.useEffect(() => {
    if (isAnimDone) {
      setTimeout(
        () => {
          startRAnim(1, halfSpeed, false);
          // Calculated time for a fade out effect
        },
        (max - index) * (halfSpeed ?? 100) + (halfSpeed ?? 100) * timeToDismiss,
      );
    }
  }, [isAnimDone, halfSpeed]);

  return (
    <Animated.View style={{flexDirection: 'column', zIndex: 999}}>
      <Animated.View
        style={{
          width: value,
          height,
          opacity: !animate
            ? 0
            : isValue || !isAnimDone
              ? 1
              : rValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0],
                }),
          borderTopLeftRadius: 100,
          borderTopRightRadius: 100,
          backgroundColor: color ?? 'purple',
        }}
      />
    </Animated.View>
  );
});

export const AnimatedDuration = React.memo(function AnimatedDuration({
  xAndY,
  value,
  barColor,
  gap,
  animationSpeed,
  isValue,
  animateAll,
}: {
  xAndY: XAndY<number>[];
  value: number;
  barColor?: string;
  gap?: number;
  animationSpeed?: number;
  isValue?: (v: number) => boolean;
  animateAll?: boolean;
}) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        zIndex: 1,
        height: '100%',
        width: '100%',
      }}>
      {xAndY.map((v, i) => (
        <LayoutExtractor key={i}>
          {(l, set) => {
            return (
              <View
                style={{
                  flex: 1,
                  position: 'relative',
                }}
                onLayout={set}>
                <View
                  style={{
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                    zIndex: -1,
                    bottom: -2,
                    padding: 2,
                    paddingHorizontal: gap ?? 2,
                    flexDirection: 'column',
                  }}>
                  <View style={{flex: 1}} />
                  <View
                    style={{
                      height: l?.height ? l.height / 2 : '50%',
                      backgroundColor: '#424242',
                    }}
                  />
                </View>
                {!!l && (
                  <View
                    style={{
                      height: '100%',
                      width: '100%',
                      zIndex: 99,
                      bottom: 0,
                      paddingHorizontal: gap ?? 2,
                      flexDirection: 'column',
                    }}>
                    <View style={{flex: 1}} />
                    <Bar
                      width={l.width - (gap ?? 2) * 2}
                      index={i}
                      isValue={isValue?.(i) ?? !!v.y}
                      animate={animateAll || i <= value + 5}
                      height={l.height / 2 + v.y}
                      color={barColor}
                      animationSpeed={(animationSpeed ?? 1000) / xAndY.length}
                      max={xAndY.length - 1}
                      val={value}
                    />
                  </View>
                )}
              </View>
            );
          }}
        </LayoutExtractor>
      ))}
    </View>
  );
});

import React from 'react';
import {useSelector} from 'react-redux';

import {analytics} from '../../src/services/analytics';
import {appSettingsSelector} from '../redux/appSettingsSlice';

export const useAnalyticsDataCollection = () => {
  const {agreeAgreement} = useSelector(appSettingsSelector);

  React.useEffect(() => {
    const agreed = agreeAgreement.PP && agreeAgreement.TC;

    analytics.setEnabled(agreed);
  }, [agreeAgreement.PP, agreeAgreement.TC]);
};

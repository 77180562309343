import {sharePress} from '../../shims/share';
import {Share} from '../types';
import {useAsyncFn} from './useAsyncFn';

export const useShare = () => {
  const [{loading}, share] = useAsyncFn(async (data: Share) => {
    await sharePress(data);
    // TOOD: why do we return true all the time?
    return true;
  });

  return {isSharing: loading, share};
};

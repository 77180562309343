export enum MoodType {
  NO_ANIMATION,
  DISCO,
  FADE,
  SPIN,
  SPLIT_GRADIENT,
  VERTICAL_SLIDESHOW,
  TORNADO,
  BREATHING,
  CIRCLING_SLOW,
  LAVA_LAMP,
  CONFETTI,
}

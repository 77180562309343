import React from 'react';
import {
  Image,
  ImageSourcePropType,
  ScrollView,
  StyleProp,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import {StyledButton, StyledIcon} from '../../components';
import {Constants, Strings, appColors} from '../../constants';
import styled from '../../lib/styled';
import {useSafeArea} from '../../lib/useSafeArea';

export type ImageAndContentContainerProps = {
  onBack?: () => void;

  style?: StyleProp<ViewStyle>;
  imageContainerStyle?: StyleProp<ViewStyle>;
  bodyContainerStyle?: StyleProp<ViewStyle>;
  footerContainerStyle?: StyleProp<ViewStyle>;

  image: ImageSourcePropType;
  // set height for view with dots for iamge carousel
  onImgLayout?: (height: number) => void;

  subHeader?: {
    content: string;
    style?: StyleProp<TextStyle>;
  };

  header: {
    content: string;
    style?: StyleProp<TextStyle>;
  };
  body?: {
    content: string;
    style?: StyleProp<TextStyle>;
  };

  children?: React.ReactNode;
  bottom?: {
    content: React.ReactNode;
    style?: StyleProp<ViewStyle>;
  };

  primaryButton?: {
    title?: string;
    onClick: () => void;
  };

  secondaryButton?: {
    title?: string;
    onClick: () => void;
  };

  isNotPath?: boolean;
};

export const ImageAndContentContainer = ({
  onBack,
  bodyContainerStyle,
  imageContainerStyle,
  footerContainerStyle,
  image,
  onImgLayout,
  style,
  subHeader,
  header,
  body,
  children,
  bottom,
  primaryButton,
  secondaryButton,
  isNotPath,
}: ImageAndContentContainerProps) => {
  const {top, bottom: bottomArea} = useSafeArea();
  return (
    <ViewContainer
      style={[{paddingBottom: bottomArea}, style]}
      isNothPath={isNotPath}>
      <ImageContainer
        style={imageContainerStyle}
        onLayout={event => {
          const {height} = event.nativeEvent.layout;
          onImgLayout && onImgLayout(height);
        }}>
        <ImageDisplay source={image} />
        {!!onBack && (
          <TopLeftContainer style={{top: Constants.IS_WEB ? 14 + top : 20}}>
            <StyledIcon
              name="chevronLeft"
              color={appColors.black}
              size={17}
              onPress={onBack}
            />
          </TopLeftContainer>
        )}
      </ImageContainer>
      <BodyContainer style={bodyContainerStyle}>
        {!!subHeader && (
          <SubHeader style={subHeader.style}>{subHeader.content}</SubHeader>
        )}
        <Header style={header.style}>{header.content}</Header>
        {!!body && <Body style={body.style}>{body.content}</Body>}
        {children}
      </BodyContainer>
      <FooterContainer style={footerContainerStyle}>
        {!!primaryButton && (
          <StyledButton
            title={primaryButton.title ?? Strings.DEFAULT_BTN}
            onPress={primaryButton.onClick}
            style={{
              backgroundColor: appColors.buttonActiveBackground,
              width: '100%',
            }}
            textStyle={{
              color: appColors.buttonActiveText,
            }}
          />
        )}
        {!!secondaryButton && (
          <StyledButton
            title={secondaryButton.title ?? Strings.MAYBE_LATER}
            onPress={secondaryButton.onClick}
            style={{
              marginTop: primaryButton ? 10 : 0,
              backgroundColor: appColors.white,
              borderWidth: 1,
              width: '100%',
              borderColor: appColors.lightMediumGray,
            }}
          />
        )}
        {!!bottom && (
          <BottomChildContainer
            style={[
              {marginTop: !!primaryButton || !!secondaryButton ? 10 : 0},
              bottom.style,
            ]}>
            {bottom.content}
          </BottomChildContainer>
        )}
      </FooterContainer>
    </ViewContainer>
  );
};

const ViewContainer = ({
  children,
  isNothPath,
  style,
}: {
  children: React.ReactNode;
  isNothPath?: boolean;
  style?: StyleProp<ViewStyle>;
}) => {
  return isNothPath ? (
    <NotPathContainer style={style}>{children}</NotPathContainer>
  ) : (
    <Container style={style}>{children}</Container>
  );
};

const NotPathContainer = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}) => {
  const isKeyboardOpen = useDetectKeyboardOpen();

  /**
   *  Whenever the keyboard is opened, it is automatically
   *  scrolled down at the bottom of the screen (only in SAFARI and some of it might contain black screen).
   *  The keyboard needs to show up before you can scroll the view manually.
   *  To fix this, I used the https://www.npmjs.com/package/use-detect-keyboard-open library to detect
   *  if the keyboard is open or not.
   *  This code will automatically scroll up whenever the keyboard is closed
   */
  React.useEffect(() => {
    if (!isKeyboardOpen) window.scrollTo(0, 0);
  }, [isKeyboardOpen]);
  return (
    <ScrollView
      style={{
        backgroundColor: appColors.white,
      }}
      contentContainerStyle={[
        {
          position: 'relative',
          backgroundColor: appColors.white,
          display: 'flex',
          flexDirection: 'column',
          height: window.innerHeight,
          width: '100%',
        },
        style,
      ]}>
      {children}
    </ScrollView>
  );
};

const Container = styled(View)({
  backgroundColor: appColors.white,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const ImageDisplay = styled(Image)({
  width: '100%',
  height: '100%',
});

const TopLeftContainer = styled(View)({
  position: 'absolute',
  width: '100%',
  zIndex: 1,
  paddingLeft: 12,
  flexDirection: 'row',
  justifyContent: 'flex-start',
});

const ImageContainer = styled(View)({
  flex: 1,
  position: 'relative',
});

const BodyContainer = styled(View)({
  height: 'auto',
  padding: 24,
  paddingBottom: 12,
});

const FooterContainer = styled(View)({
  height: 'auto',
  padding: 24,
  paddingTop: 12,
  display: 'flex',
  flexDirection: 'column',
});

const SubHeader = styled(Text)({
  textAlign: 'center',
  color: appColors.black50,
  fontFamily: 'Roboto-Medium',
  fontWeight: '500',
  fontSize: 16,
  textTransform: 'uppercase',
  marginBottom: 15,
  lineHeight: 18.75,
});

const Header = styled(Text)({
  textAlign: 'center',
  color: appColors.black,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  fontSize: 32,
  textTransform: 'uppercase',
  marginBottom: 24,
  lineHeight: 40,
});

const Body = styled(Text)({
  textAlign: 'center',
  color: appColors.black,
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  fontWeight: '400',
  marginHorizontal: 1,
  lineHeight: 26,
});

const BottomChildContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

import {DrawerActions, useNavigation} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useSelector} from 'react-redux';

import {appColors} from '../constants';
import {Connection} from '../contexts/useConnection';
import {useReferrals} from '../lib/hooks/useReferrals';
import {isOtaAvailableSelector} from '../lib/redux/bleSlice';
import {useTheme} from '../lib/useTheme';
import {Badge} from '../shims/Badge';
import {StyledIcon} from './StyledIcon';

interface Props {
  themed?: boolean;
}

export const HamburgerMenuButton: React.FC<Props> = ({themed}) => {
  const navigation = useNavigation();

  const {peak} = Connection.useContainer();
  const isOtaAvailable = useSelector(isOtaAvailableSelector);
  const referrals = useReferrals();

  const {navMenuIconColor} = useTheme();

  const connected = !!peak;

  const otaCount = connected && isOtaAvailable ? 1 : 0;
  const totalCount = referrals.unused.length + otaCount;

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}>
        <StyledIcon
          name="menuTriple"
          iconStyle={{tintColor: themed ? navMenuIconColor : appColors.white}}
          size={26}
        />

        {totalCount > 0 && (
          <Badge style={styles.badge}>
            <Text numberOfLines={1} style={styles.count}>
              {totalCount}
            </Text>
          </Badge>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {marginLeft: 6},
  badge: {
    zIndex: 10,
    position: 'absolute',
    bottom: -2,
    right: 0,
  },
  count: {
    color: appColors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    textAlign: 'center',
  },
});

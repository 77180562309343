import {Buffer} from 'buffer';
import isEqual from 'lodash/isEqual';
import {ulid} from 'ulid';

import {Constants, appColors} from '../constants';
import {encodeTrusted} from '../lib/utilityFunctions/ulidCoding';
import {convertRgbArrayToHex} from './convertRgbArrayToHex';
import {MiddlewareValue} from './types';

export const convertReadValue = (value: string, type: MiddlewareValue) => {
  const readValueBase64 = value;
  const readValueBuffer = Buffer.from(readValueBase64, 'base64');

  return convertBufferValue(readValueBuffer, type);
};

export const convertBufferValue = (buffer: Buffer, type: MiddlewareValue) => {
  switch (type) {
    case MiddlewareValue.STRING:
      return buffer.toString();

    case MiddlewareValue.FLOAT:
      return buffer.readFloatLE(0);

    case MiddlewareValue.U_INT_8:
      return buffer.readUInt8(0);

    case MiddlewareValue.U_INT_16:
      return buffer.readUInt16LE(0);

    case MiddlewareValue.U_INT_32:
      return buffer.readUInt32LE(0);

    case MiddlewareValue.INT_8:
      return buffer.readInt8(0);

    case MiddlewareValue.INT_32:
      return buffer.readInt32LE(0);

    case MiddlewareValue.BUFFER:
      return buffer;

    case MiddlewareValue.ULID: {
      const ulidNumArray = [...buffer];
      return isEqual(ulidNumArray, Constants.INITIAL_UUID)
        ? ulid()
        : encodeTrusted(ulidNumArray);
    }

    case MiddlewareValue.COLORS: {
      const colorArray: string[] = [];
      for (let i = 0; i < buffer.length; i += 3) {
        const colorHex = convertRgbArrayToHex([...buffer.slice(i, i + 3)]);

        // Lantern color can not be black
        colorHex && colorHex !== appColors.black && colorArray.push(colorHex);
      }
      return colorArray;
    }
  }
};

export const convertReadValueFromArrayBuffer = (
  value: ArrayBuffer,
  type: MiddlewareValue,
) => convertReadValue(arrayBufferToBase64(value), type);

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

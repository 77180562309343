import {crc32} from './crc32';

/* CRC is located in the last 4 bytes of the file. Perform CRC32 with all but the last
 * 4 bytes and compare the CRC32 value with the numerical value contained in the last
 * 4 bytes of the file.
 */
export const isOtaValid = (fileBuffer: Buffer) => {
  if (fileBuffer.length < 4) {
    return false;
  }
  const crcStartingIndex = fileBuffer.length - 4;
  const file = fileBuffer.slice(0, crcStartingIndex);
  const expectedCrc = fileBuffer.slice(crcStartingIndex);

  // crc32 returns a signed 32-bit integer.
  return crc32(file) === expectedCrc.readInt32LE(0);
};

import {MoodLightCategory} from 'puffco-api-axios-client';

import {
  CustomMoodLight0,
  CustomMoodLight1,
  CustomMoodLight2,
} from './CustomMoodLight';
import {ExclusiveMoodLight} from './ExclusiveMoodLight';

export type MoodLight0 = CustomMoodLight0 | ExclusiveMoodLight;
export type MoodLight1 = CustomMoodLight1 | ExclusiveMoodLight;
export type MoodLight2 = CustomMoodLight2 | ExclusiveMoodLight;

export type MoodLightVersions = [MoodLight0, MoodLight1, MoodLight2];

export type MoodLight = MoodLight2;

export const isCustomMoodLight = (
  moodlight: MoodLight,
): moodlight is CustomMoodLight2 =>
  moodlight.category === MoodLightCategory.Custom;

export const isExclusiveMoodLight = (
  moodlight: MoodLight,
): moodlight is ExclusiveMoodLight =>
  moodlight.category === MoodLightCategory.Exclusive;

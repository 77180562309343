import {RouteProp, useRoute} from '@react-navigation/native';
import React from 'react';
import AppleLogin from 'react-apple-login';
import {ViewStyle} from 'react-native';

import {appleLogo} from '../../assets/images';
import {StyledButton} from '../../components';
import {Environment, Strings, appColors} from '../../constants';
import {RedirectOptions} from '../../navigation/navigators/params';
import {createSocialAuthState, getSocialAuthCallback} from '../../src/util/url';

interface Props {
  title?: string;
  disabled?: boolean;
  screen: 'register' | 'login';
  buttonStyle?: ViewStyle;
  onSubmit(code: string): Promise<void>;
}

export const AppleSignIn: React.FC<Props> = ({
  title,
  disabled,
  screen,
  buttonStyle,
}) => {
  const route =
    useRoute<RouteProp<{[x: string]: {redirect?: RedirectOptions}}>>();

  const redirect = route.params?.redirect;

  const [clicked, setClicked] = React.useState<boolean>(false);

  //Set clicked state back to false after 3 seconds
  React.useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, 3000);
    }
  }, [clicked, setClicked]);

  return (
    <AppleLogin
      clientId={Environment.apple.clientId}
      redirectURI={getSocialAuthCallback('apple')}
      scope="email name"
      responseMode="form_post"
      responseType="code"
      state={createSocialAuthState({state: screen, redirect})}
      render={props => {
        return (
          <StyledButton
            title={title ?? Strings.APPLE_SIGN_IN}
            style={{
              backgroundColor: appColors.white,
              width: '100%',
              borderWidth: 1,
              borderColor: appColors.fieldDivider,
              ...buttonStyle,
            }}
            onPress={() => {
              setClicked(true);
              props.onClick();
            }}
            disabled={clicked || props.disabled || disabled}
            textStyle={{
              color: appColors.black,
              fontFamily: 'Roboto-Bold',
            }}
            buttonIcon={appleLogo}
          />
        );
      }}
    />
  );
};

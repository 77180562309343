import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Image, ImageSourcePropType, Text, View} from 'react-native';

import {Modal} from '../components';
import {Screens, Strings, appColors} from '../constants';
import styled from '../lib/styled';
import {HomeTabNavigatorScreenProps} from '../navigation/navigators/HomeTabNavigator';
import {StyledButton} from './StyledButton';

export type ChamberMetric = {
  type: string;
  value: string;
};

export type ChamberCardData = {
  name: string;
  source: ImageSourcePropType;
  metrics?:
    | [ChamberMetric]
    | [ChamberMetric, ChamberMetric]
    | [ChamberMetric, ChamberMetric, ChamberMetric];
};

export type ChamberCardProps = {
  data: ChamberCardData;
  isChamberTypeFirmware: boolean;
};

type Navigation = HomeTabNavigatorScreenProps<
  typeof Screens.Home
>['navigation'];

export const ChamberCard = ({
  data: {name, source, metrics},
  isChamberTypeFirmware,
}: ChamberCardProps) => {
  const navigation = useNavigation<Navigation>();

  return (
    <Window>
      <TitleContainer>
        <TitleText>{name}</TitleText>
        <SubtitleText>Peak Pro</SubtitleText>
      </TitleContainer>
      <Image source={source} />
      <BottomContainer>
        <MetricsContainer>
          {metrics &&
            metrics.map((metric, index) => (
              <React.Fragment key={`${metric.type}-${metric.value}`}>
                <MetricSection>
                  <MetricValue>{metric.value}</MetricValue>
                  <MetricType>{metric.type}</MetricType>
                </MetricSection>
                {index < metrics.length - 1 && <VerticalLine />}
              </React.Fragment>
            ))}
        </MetricsContainer>
        <UpdateContainer>
          {!isChamberTypeFirmware && (
            <UpdateText>{`PLEASE UPDATE YOUR DEVICE TO USE THE ${name}`}</UpdateText>
          )}
        </UpdateContainer>
        <StyledButton
          theme={{
            buttonColor: appColors.black,
            buttonTextColor: appColors.white,
          }}
          {...(isChamberTypeFirmware
            ? {title: Strings.DONE, onPress: Modal.close}
            : {
                title: 'UPDATE',
                onPress: () => {
                  // First clear nav stack, then navigate to firmware info screen
                  navigation.popToTop();
                  navigation.navigate(Screens.FirmwareInfo);
                  Modal.closeAll();
                },
              })}
        />
      </BottomContainer>
    </Window>
  );
};

const Window = styled(View)({
  display: 'flex',
  width: 308,
  height: 528,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: appColors.white,
  paddingTop: 26.5,
  paddingBottom: 22,
  paddingHorizontal: 20,
  borderRadius: 39,
});

const TitleContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const TitleText = styled(Text)({
  fontSize: 30,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  letterSpacing: 0.6,
  textTransform: 'uppercase',
  includeFontPadding: false,
  color: appColors.black,
});

const SubtitleText = styled(Text)({
  fontSize: 12,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  letterSpacing: 0.35,
  marginTop: -4,
  textTransform: 'uppercase',
  color: appColors.black,
});

const BottomContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const MetricsContainer = styled(View)({
  width: '100%',
  height: 45,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
});

const VerticalLine = styled(View)({
  width: 1,
  height: '100%',
  backgroundColor: appColors.black,
});

const MetricSection = styled(View)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
});

const MetricValue = styled(Text)({
  fontSize: 30,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  letterSpacing: 0.6,
  includeFontPadding: false,
  color: appColors.black,
});

const MetricType = styled(Text)({
  fontSize: 12,
  fontFamily: 'BigShouldersDisplay-Medium',
  fontWeight: '400',
  letterSpacing: 0.6,
  includeFontPadding: false,
  textTransform: 'capitalize',
  marginBottom: 2,
  color: appColors.black,
});

const UpdateContainer = styled(View)({
  display: 'flex',
  width: '100%',
  height: 41,
  flexDirection: 'column',
  alignItems: 'center',
});

const UpdateText = styled(Text)({
  marginTop: 17.5,
  marginBottom: 7.5,
  fontSize: 12,
  fontFamily: 'BigShouldersDisplay-Medium',
  fontWeight: '400',
  color: appColors.red,
  letterSpacing: 0.6,
  includeFontPadding: false,
  textTransform: 'uppercase',
});

import React from 'react';
import {Image, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {trophy} from '../assets/images';
import {Strings} from '../constants';
import styled from '../lib/styled';
import {Title} from './Title';

export type Rank = 1 | 2 | 3;

interface Props {
  rank: Rank;
  withIcon?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const isPossibleRank = (value: unknown): value is Rank =>
  typeof value === 'number' && value >= 1 && value <= 3;

const suffixes = ['ST', 'ND', 'RD'];

export const RankIndicator: React.FC<Props> = ({rank, withIcon, style}) => {
  return (
    <View style={[styles.container, style]}>
      {withIcon && <Image source={trophy} style={styles.icon} />}

      <View style={styles.labelContainer}>
        <RankLabel>{rank}</RankLabel>

        <RankLabel style={styles.superscript}>{suffixes[rank - 1]}</RankLabel>

        <RankLabel>&nbsp;{Strings.PLACE}</RankLabel>
      </View>
    </View>
  );
};

const RankLabel = styled(Title)({
  fontFamily: 'Roboto-Bold',
  textTransform: 'uppercase',
  letterSpacing: 0.8,
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  labelContainer: {flexDirection: 'row', alignItems: 'flex-start'},
  superscript: {
    fontSize: 12,
    textAlignVertical: 'top',
  },
  icon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
});

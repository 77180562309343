import {useIsFocused} from '@react-navigation/native';
import omit from 'lodash/omit';
import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsync} from 'react-use';
import {ulid} from 'ulid';

import {
  AppText,
  CloseButton,
  HeatProfileScreenLayeredBackground,
  ProfileTemperature,
  StartButton,
  StyledButton,
  StyledIcon,
  calculateInnerContainerDiameter,
  useSpinner,
} from '../../components';
import {Dabber} from '../../components/Dabber';
import {VaporModeText} from '../../components/VaporModeText';
import {
  Alerts,
  Constants,
  DabbingReminders,
  ErrorMessages,
  Screens,
  Strings,
  appColors,
  ProfileDurationTitleStyle as durationStyle,
} from '../../constants';
import {Connection} from '../../contexts/useConnection';
import {
  UpdateHeatProfileDto,
  useUpdateHeatProfiles,
} from '../../lib/api/HeatProfile.hooks';
import {useGetMoodLights} from '../../lib/api/MoodLight.hooks';
import {shareApi} from '../../lib/api/apis';
import {useTemperature} from '../../lib/convertTemperature';
import {getTimeFormat} from '../../lib/getTimeFormat';
import {
  useAppDispatch,
  useAppHeaderHeight,
  useChamberType,
  useGetVaporSetting,
  useLantern,
  useUpdateDabbingReduxValues,
} from '../../lib/hooks';
import {maxProfileGuard} from '../../lib/profileFunctions';
import {appSettingsSelector} from '../../lib/redux/appSettingsSlice';
import {
  currentDeviceSWRevisionSelector,
  currentDeviceSelector,
  currentDeviceStateSelector,
} from '../../lib/redux/bleSlice';
import {
  getMoodLightSelector,
  upsertAccountMoodLights,
} from '../../lib/redux/moodLightSlice';
import {
  addArchiveProfileFront,
  archiveProfilesSelector,
  tempProfileSelector,
} from '../../lib/redux/profilesSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {
  MoodLight,
  OperatingState,
  Profile,
  Vapor,
  isCustomMoodLight,
  isCustomMoodLightSnapshot,
  isTHeatProfile,
  isTHeatProfileMoodLight,
  isTHeatProfileNoMoodLight,
  isTHeatProfileSnapshot,
} from '../../lib/types';
import {useSafeArea} from '../../lib/useSafeArea';
import {useTheme} from '../../lib/useTheme';
import {verifyGuard} from '../../lib/userFunctions';
import {
  getProfileAnimatedHaloWHSize,
  getProfileLogoSize,
  getProfileTempFontSize,
  meetsMinimumFirmware,
} from '../../lib/utilityFunctions';
import {Temperature} from '../../lib/utils/temperature';
import {SharedHeatProfileStackScreenProps} from '../../navigation/navigators/SharedHeatProfileStackNavigator';
import {toShareProfile} from '../../navigation/navigators/util';
import {Alert} from '../../shims/alert';
import {analytics} from '../../src/services/analytics';
import {
  convertChamberType,
  convertProduct,
  convertVaporMode,
} from '../../src/services/analytics/converters';

const SCREEN_HEIGHT = Dimensions.get('window').height;

export interface Props {
  shareId: string;
  save?: boolean;
}

interface ScreenProps
  extends SharedHeatProfileStackScreenProps<typeof Screens.SharedHeatProfile> {}

export const SharedHeatProfileScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const currentTheme = useTheme();
  const {
    primaryColor,
    navMenuIconColor,
    navMenuTitleStyle,
    styledButtonTheme,
    alternateSpinnerColor,
    heatProfileSelectScreenTheme: {durationColor},
    dabbingScreenTheme: {textColor, fadedTextColor, iconColor},
  } = currentTheme;

  const insets = useSafeArea();
  const tempProfile = useSelector(tempProfileSelector);
  const getMoodLight = useSelector(getMoodLightSelector);

  const {shareId, save} = route.params ?? {};

  const {loading, value} = useAsync(async () => {
    if (!shareId) throw new Error('Share missing.');

    const snapshot = await shareApi
      .getShareSnapshot({shareId})
      .then(r => r.data.heatProfileSnapshot);

    if (!snapshot) return;

    const modified = new Date().getTime();

    const moodlight: MoodLight | undefined =
      isTHeatProfileSnapshot(snapshot) && snapshot.isMoodLight
        ? snapshot.moodLight && isCustomMoodLightSnapshot(snapshot.moodLight)
          ? {
              ...snapshot.moodLight,
              id: ulid(),
              modified,
            }
          : snapshot.moodLight?.id
            ? getMoodLight(snapshot.moodLight.id)
            : undefined
        : undefined;

    const profile: Profile = {
      ...omit(snapshot, 'moodLight'),
      id: ulid(),
      modified,
      order: 0,
      isMoodLight: !!moodlight?.id,
      moodLightId: moodlight?.id,
      color: !moodlight ? snapshot.color : appColors.defaultColor,
    };

    return {moodlight, profile};
  }, [shareId]);

  const profile = React.useMemo(
    () => value?.profile ?? tempProfile,
    [value, tempProfile],
  );

  const moodlight = value?.moodlight;

  const dispatch = useAppDispatch();
  const updateDabbingReduxValues = useUpdateDabbingReduxValues();
  const HEADER_HEIGHT = useAppHeaderHeight() || 56;

  const user = useSelector(userSelector);
  const settings = useSelector(appSettingsSelector);
  const deviceState = useSelector(currentDeviceStateSelector);
  const deviceSWRevision = useSelector(currentDeviceSWRevisionSelector);
  const archives: Profile[] = useSelector(archiveProfilesSelector);

  const {peak} = Connection.useContainer();
  const connected = !!peak;
  const device = useSelector(currentDeviceSelector);

  const [isIdle, setIsIdle] = React.useState(true);
  const [deviceActive, setDeviceActive] = React.useState(false);
  const [resetDab, setResetDab] = React.useState(false);

  const [backgroundHeight, setBackgroundHeight] = React.useState(SCREEN_HEIGHT);

  const contentTopPadding = Constants.IS_WEB
    ? insets.top + HEADER_HEIGHT / 2
    : HEADER_HEIGHT;
  const contentBottomPadding = backgroundHeight * 0.03;

  const profileBaseTemp = useTemperature(
    profile?.temperature ?? Constants.TEMPERATURE_MIN_FAHRENHEIT,
    profile?.units ?? TemperatureUnit.Fahrenheit,
  );

  const {innerHaloDiameterSize, logoSize, tempFontSize} = React.useMemo(() => {
    const animHaloSize = getProfileAnimatedHaloWHSize(backgroundHeight);
    const innerHaloDiameterSize = calculateInnerContainerDiameter(animHaloSize);
    const logoSize = getProfileLogoSize(backgroundHeight);
    const tempFontSize = getProfileTempFontSize(backgroundHeight);
    return {innerHaloDiameterSize, logoSize, tempFontSize};
  }, [backgroundHeight]);

  const {isVaporEnabled, isMeetAG, isChamberDisconnected, isXLChamber} =
    useChamberType();

  const isFocused = useIsFocused();
  const [{loading: updateLoading}, updateApiArchives] = useUpdateHeatProfiles();
  const [, getMoodLights] = useGetMoodLights();

  const update = React.useCallback(
    async (profiles: UpdateHeatProfileDto[]) => {
      try {
        await updateApiArchives(profiles);

        if (!moodlight || !profile) return;

        if (profile)
          dispatch(
            addArchiveProfileFront({
              ...profile,
              isMoodLight: !!moodlight,
              moodLightId: moodlight?.id,
              color: isTHeatProfileNoMoodLight(profile)
                ? profile.color
                : appColors.defaultColor,
            }),
          );

        const moodlights = await getMoodLights();

        dispatch(upsertAccountMoodLights(moodlights));

        Alert.alert(Alerts.SUCCESS, Alerts.CONFIRM_ARCHIVE_SAVE_BODY);

        navigation.goBack();
      } catch {
        Alert.alert('Error', ErrorMessages.NETWORK_ERROR);
      }
    },
    [updateApiArchives, getMoodLights, navigation, profile, moodlight],
  );

  useLantern(
    !profile || isTHeatProfileMoodLight(profile) ? moodlight : profile.color,
  );

  React.useEffect(() => {
    if (!save) return;

    navigation.setParams({save: false});

    saveProfileAndMood();
  }, [save, profile, route, navigation]);

  React.useEffect(() => {
    if (
      !deviceActive &&
      deviceState &&
      [
        OperatingState.HEAT_CYCLE_PREHEAT,
        OperatingState.HEAT_CYCLE_ACTIVE,
        OperatingState.HEAT_CYCLE_FADE,
      ].includes(deviceState) &&
      !isIdle
    ) {
      //Sometimes the device state is initially 'IDLE'. This would cause the
      //screen to immediately close so deviceActive is set as a flag to bypass
      //this occasional initial IDLE state.
      setDeviceActive(true);
    }

    // Intended to execute when dab ended MANUALLY or with BACK button
    if (deviceActive && deviceState === OperatingState.IDLE) {
      setResetDab(true);
      setDeviceActive(false);
      setIsIdle(true);
    }
  }, [deviceState]);

  const saveProfileAndMood = React.useCallback(() => {
    if (!profile) return;

    maxProfileGuard(archives?.length, () => {
      if (!user) {
        navigation.goBack();

        return Alert.alert(
          Alerts.ACCOUNT_NOT_LOGGED_IN,
          Alerts.FEATURE_ACCESS_MESSAGE,
          [
            {
              text: 'Sign In',
              onPress: () => {
                if (!profile) return;

                navigation.navigate(Screens.Login, {
                  redirect: toShareProfile({shareId, save}).encode(),
                });
              },
            },
            {text: 'Cancel', style: 'cancel'},
          ],
        );
      }

      verifyGuard(
        user,
        () => {
          const archive: UpdateHeatProfileDto = {
            ...profile,
            ...(moodlight &&
              isCustomMoodLight(moodlight) && {
                moodLight: {...moodlight, userId: user.id},
              }),
            userId: user.id,
          };

          if (!moodlight) return update([archive]);

          // Add profile to archives and adjust profile order
          return update(
            [archive]
              .concat(archives.map(p => ({...p, userId: user.id})))
              .map((p, order) => ({...p, order})),
          );
        },
        navigation.navigate,
      );
    });
  }, [profile, moodlight, archives, user, navigation]);

  const startDabbing = async () => {
    if (
      !meetsMinimumFirmware(
        deviceSWRevision,
        Constants.MINIMUM_FIRMWARE_VERSION.TEMP_PROFILE,
      )
    ) {
      return Alert.alert(Alerts.UPDATE_FIRMWARE, Alerts.UPDATE_FIRMWARE_BODY);
    }

    if (!connected) {
      return Alert.alert(
        Alerts.SHARED_DAB_NOT_CONNECTED_TITLE,
        Alerts.SHARED_DAB_NOT_CONNECTED_BODY,
      );
    }

    if (isChamberDisconnected) {
      return Alert.alert(
        Alerts.CHAMBER_DISCONNECTED_TITLE,
        Alerts.CHAMBER_DISCONNECTED_MESSAGE,
        [{text: Strings.I_UNDERSTAND}],
      );
    }

    if (!isMeetAG && isXLChamber) {
      return Alert.alert(
        Alerts.UPDATE_FIRMWARE,
        Alerts.XL_CHAMBER_UPDATE_FIRMWARE_MESSAGE,
        [
          {
            text: Strings.CANCEL,
            onPress: () => navigation.navigate(Screens.FirmwareInfo),
          },
        ],
      );
    }

    if (!profile) {
      return Alert.alert(Alerts.DABBING_ERROR_TITLE, Alerts.DABBING_ERROR_BODY);
    }

    updateDabbingReduxValues(profile);

    await peak?.writeTempHeatProfile(profile, moodlight);
    await peak?.startDabbing(Constants.TEMP_HEAT_PROFILE_INDEX).then(() =>
      analytics.trackEvent('heat cycle start', {
        profile_id: profile.id,
        product: convertProduct(peak.product),
        ...(device?.chamberType && {
          chamber_type: convertChamberType(device.chamberType),
        }),
        ...(isTHeatProfile(profile) && {
          vapor_mode: convertVaporMode(
            profile.vaporSetting as Vapor | undefined,
          ),
        }),
        ...(isTHeatProfileMoodLight(profile) && {
          mood_light_id: profile.moodLightId,
        }),
        duration: profile.duration,
        temperature: profile.temperature,
      }),
    );

    setIsIdle(false);
  };

  const vapor = useGetVaporSetting(profile);

  useSpinner({
    isVisible: (loading || updateLoading) && isFocused,
    text: loading ? Strings.LOADING_DATA : Strings.SAVING,
    color: iconColor ?? alternateSpinnerColor,
  });

  const idleFooter = () => {
    return (
      <BottomContainer>
        <ProfileTemperature
          style={{paddingLeft: 12, paddingBottom: 8}}
          temperature={Temperature.convert(
            profile?.temperature ?? Constants.TEMPERATURE_MIN_FAHRENHEIT,
            {
              from: profile?.units ?? TemperatureUnit.Fahrenheit,
              to: settings.tempPreference,
            },
          )}
          temperatureUnit={settings.tempPreference[0]}
          textStyle={{
            fontSize: tempFontSize,
            color: textColor ?? primaryColor,
          }}
        />
        <Time
          style={{
            color: fadedTextColor ?? durationColor,
            paddingBottom: isVaporEnabled
              ? 8
              : Math.min(backgroundHeight * 0.043, 35),
          }}>
          {getTimeFormat(profile?.duration ?? 0, {
            padMinutes: true,
            padSeconds: true,
          })}
        </Time>
        {isVaporEnabled && (
          <VaporModeContainer
            style={{marginBottom: Math.min(backgroundHeight * 0.043, 35)}}>
            <VaporModeText {...{vapor}} />
          </VaporModeContainer>
        )}
        <SaveButtonContainer style={{height: 92 - contentBottomPadding}}>
          <StyledButton
            title="SAVE"
            theme={styledButtonTheme}
            onPress={saveProfileAndMood}
          />
        </SaveButtonContainer>
      </BottomContainer>
    );
  };

  if (loading) return null;

  return (
    <ContentContainer
      style={{
        paddingTop: contentTopPadding,
        paddingBottom: contentBottomPadding,
      }}
      onLayout={e => {
        setBackgroundHeight(e.nativeEvent.layout.height);
      }}>
      <HeatProfileScreenLayeredBackground
        style={{
          alignSelf: 'center',
          borderRadius: 20,
        }}
        theme={currentTheme}
        {...(profile && isTHeatProfileMoodLight(profile) && moodlight
          ? {moodLight: moodlight}
          : {
              backgroundColor:
                !profile || isTHeatProfileMoodLight(profile)
                  ? appColors.defaultColor
                  : profile.color,
            })}>
        <NavHeader style={{height: HEADER_HEIGHT}}>
          {!isIdle ? (
            <StyledIcon
              onPress={() => {
                peak?.stopDabbing();
              }}
              name="chevronLeft"
              disabled={isIdle}
              iconStyle={{
                tintColor: iconColor ?? navMenuIconColor,
              }}
            />
          ) : (
            <View style={{width: 40, height: 40}} />
          )}

          <Text
            style={{
              ...navMenuTitleStyle,
              ...(textColor ? {color: textColor} : {}),
            }}>
            NEW PROFILE
          </Text>

          <CloseButton
            onPress={() => {
              if (isIdle) {
                navigation.pop();
              } else {
                Alert.alert(Alerts.END_SESSION_TITLE, Alerts.END_SESSION_BODY, [
                  {text: 'Cancel'},
                  {
                    text: 'OK',
                    onPress: () => {
                      peak?.stopDabbing();
                      setResetDab(true);
                      setTimeout(() => {
                        navigation.goBack();
                      }, 600);
                    },
                  },
                ]);
              }
            }}
            iconStyle={{tintColor: iconColor ?? navMenuIconColor}}
          />
        </NavHeader>

        {profile && (
          <Dabber
            isVaporEnabled={isVaporEnabled}
            moodLight={moodlight}
            profile={profile}
            profileBaseDuration={profile.duration}
            profileBaseTemp={profileBaseTemp}
            isResumingDab={false}
            isIdle={isIdle}
            resetDab={resetDab}
            onResetDabCompletion={() => {
              return setResetDab(false);
            }}
            idleInnerComponent={() => (
              <StartButton
                logoSize={logoSize}
                innerContainerDiameter={innerHaloDiameterSize}
                iconColor={iconColor ?? primaryColor}
                startButtonStyle={{color: textColor ?? primaryColor}}
                onPress={startDabbing}
                disabled={loading || updateLoading}
              />
            )}
            idleFooter={idleFooter}
            dabMessagesTitle={'REMINDER'}
            dabMessages={DabbingReminders}
            haloPositionShift={-1 * contentBottomPadding}
            tipTextMarginBottom={10}
            headerTopPadding={0}
            titleTopPadding={
              HEADER_HEIGHT +
              (backgroundHeight - contentTopPadding - contentBottomPadding) *
                Constants.PROFILE_TITLE_TOP_PADDING_BG_RATIO
            }
          />
        )}
      </HeatProfileScreenLayeredBackground>
    </ContentContainer>
  );
};

const ContentContainer = styled(View)({
  position: 'absolute',
  backgroundColor: 'transparent',
  alignSelf: 'center',
  width: '90%',
  height: '100%',
  overflow: 'hidden',
  alignContent: 'flex-end',
  justifyContent: 'flex-end',
  flex: 1,
});

const NavHeader = styled(View)({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingHorizontal: 10,
  position: 'absolute',
  zIndex: 1,
});

const Time = styled(AppText)({
  ...durationStyle,
});

const BottomContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 0.45,
  width: '100%',
});

const SaveButtonContainer = styled(View)({
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

const VaporModeContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: 10,
  paddingVertical: 4,
  backgroundColor: appColors.black,
  borderRadius: 100,
});

import type {Asset, Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeStyledAssetFields {
  title: EntryFields.Symbol;
  asset: Asset;
  style?: EntryFields.Object;
}

export type TypeStyledAsset = Entry<TypeStyledAssetFields>;

export function isTypeStyledAsset(
  entry: WithContentTypeLink,
): entry is TypeStyledAsset {
  return entry?.sys?.contentType?.sys?.id === 'styledAsset';
}

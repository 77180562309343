import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {Store} from '../types';

const warrantySlice = createSlice({
  name: 'warranty',
  initialState: {current: null, warrantyAnswers: {}},
  reducers: {
    addAnswer(state, action: PayloadAction<Record<string, any>>) {
      return {
        ...state,
        warrantyAnswers: {...state.warrantyAnswers, ...action.payload},
      };
    },
    resetAnswers(state) {
      return {...state, warrantyAnswers: {}};
    },
  },
});

export const warrantySelector = (state: Store) => state.warranty;

export const {addAnswer, resetAnswers} = warrantySlice.actions;

export const warrantyReducer = warrantySlice.reducer;

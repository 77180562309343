import React from 'react';
import {
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import {SpringConfig} from 'react-native-reanimated/lib/typescript/animation/springUtils';

import {
  CloseButton,
  SwipeDirection,
  SwipeableArea,
} from '../../../../components';
import {StyledButton} from '../../../../components/StyledButton';
import {Constants, Screens, Strings, appColors} from '../../../../constants';
import {resolveAssetUrl} from '../../../../lib/api';
import {TypeGuideStep} from '../../../../lib/api/content-access/types';
import {useAdaptiveSafeArea} from '../../../../lib/hooks/useAdaptiveSafeArea';
import styled from '../../../../lib/styled';
import {GuideNavigatorScreenProps} from '../../../../navigation/navigators/HomeDrawerNavigator';
import {LinearGradient} from '../../../../shims/LinearGradient';

const {width: screenWidth, height: screenHeight} = Dimensions.get('window');
const tooShort = screenHeight < 740;

const springConfig: SpringConfig = {
  stiffness: 400,
  damping: 12,
  mass: 0.25,
};

export interface GuideStepScreenProps {
  stepNumber?: number;
  step: TypeGuideStep;
  onNext: (currentIndex: number) => void;
  onCompletion: () => void;
  totalSteps: number;
  title: string;
  onDismiss: () => void;
}

interface ScreenProps
  extends GuideNavigatorScreenProps<typeof Screens.GuideStep> {}

export const GuideStepScreen: React.FC<ScreenProps> = ({route, navigation}) => {
  const currentStep = route.params.stepNumber ?? 1;
  const step = route.params.step ?? {};
  const onNext = route.params.onNext;
  const onCompletion = route.params.onCompletion;
  const totalSteps = route.params.totalSteps;
  const isLast = currentStep === totalSteps;
  const title = route.params.title;
  const onDismiss = route.params.onDismiss;

  const bounceValue = useSharedValue(0);

  useAdaptiveSafeArea();

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: title ?? Strings.DEVICE_EDUCATION.GUIDESCREEN.DEFAULT_TITLE,
      headerRight: () => (
        <CloseButton
          onPress={onDismiss}
          iconStyle={{tintColor: appColors.black}}
        />
      ),
    });
  }, [navigation, title, onDismiss]);

  const image = step.fields.image;

  const bounceAnimation = (
    direction: SwipeDirection.LEFT | SwipeDirection.RIGHT,
  ) => {
    const toValue = direction === SwipeDirection.LEFT ? -30 : 30;

    bounceValue.value = withSpring(toValue, springConfig, () => {
      bounceValue.value = withSpring(0, springConfig);
    });
  };

  const handleSwipeLeft = () => {
    if (!isLast) return onNext(currentStep);

    bounceAnimation(SwipeDirection.LEFT); // Bounce when swiping forward from the last step
  };

  const handleSwipeRight = () => {
    if (currentStep > 1) return navigation.goBack();

    bounceAnimation(SwipeDirection.RIGHT); // Bounce when swiping back from the first step
  };

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{translateX: bounceValue.value}],
  }));

  return (
    <Container>
      <Animated.View style={[styles.animatedView, animatedStyle]}>
        <SwipeableArea onLeft={handleSwipeLeft} onRight={handleSwipeRight}>
          <View style={styles.content}>
            <ScrollView showsVerticalScrollIndicator={false}>
              <GuideCarouselTitle>
                {step.fields.title.toUpperCase()}
              </GuideCarouselTitle>

              {image && (
                <GuideCarouselImage source={{uri: resolveAssetUrl(image)}} />
              )}

              <TextContainer>
                {!isLast && (
                  <StepCounter>
                    {Strings.STEP.toUpperCase()} {currentStep}&nbsp;/&nbsp;
                    {totalSteps - 1}
                  </StepCounter>
                )}
                <CurrentStep>{step.fields.description}</CurrentStep>
              </TextContainer>
            </ScrollView>

            {Constants.IS_NATIVE_ANDROID && <FadeEffect />}
          </View>
        </SwipeableArea>

        <ButtonContainer>
          {currentStep === totalSteps && (
            <Button
              title={
                Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.INSTRUCTIONS_MANUAL
              }
              onPress={onCompletion}
              textStyle={{
                color: getButtonTextColor({style: 'secondary'}),
                fontWeight: '700',
                fontSize: 16,
                textTransform: 'uppercase',
              }}
              style={{
                backgroundColor: getButtonBackgroundColor({style: 'secondary'}),
                borderColor: appColors.lightMediumGray,
                borderWidth: 1,
                marginBottom: 8,
              }}
            />
          )}

          <Button
            title={
              currentStep === totalSteps
                ? Strings.CLOSE.toUpperCase()
                : currentStep === totalSteps - 1
                  ? Strings.CONTINUE.toUpperCase()
                  : Strings.NEXT.toUpperCase()
            }
            onPress={() => {
              onNext(currentStep);
            }}
            textStyle={{
              color: getButtonTextColor({style: 'primary'}),
              fontSize: 16,
            }}
            style={{
              backgroundColor: getButtonBackgroundColor({style: 'primary'}),
            }}
          />
        </ButtonContainer>
      </Animated.View>
    </Container>
  );
};

const FadeEffect = () => (
  <LinearGradient
    colors={['transparent', 'rgba(255, 255, 255, 0.8)']}
    style={styles.fadeEffect}
  />
);

interface ButtonColorProps {
  style?: 'primary' | 'secondary';
}

const getButtonBackgroundColor = ({style}: ButtonColorProps) => {
  switch (style) {
    case 'primary':
      return appColors.black;
    case 'secondary':
      return appColors.white;
  }
};

const getButtonTextColor = ({style}: ButtonColorProps) => {
  switch (style) {
    case 'primary':
      return appColors.white;
    case 'secondary':
      return appColors.black;
  }
};

const styles = StyleSheet.create({
  animatedView: {
    flex: 1,
  },
  fadeEffect: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 10,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: 0,
  },
});

const Container = styled(View)({
  flex: 1,
  backgroundColor: appColors.white,
});

const GuideCarouselTitle = styled(Text)({
  color: appColors.black,
  fontSize: 24,
  textAlign: 'center',
  fontFamily: 'Roboto-Regular',
  fontWeight: '700',
  marginTop: tooShort ? 20 : 36,
  marginBottom: tooShort ? 16 : 24,
  paddingHorizontal: 24,
});

const GuideCarouselImage = styled(Image)({
  width: screenWidth,
  aspectRatio: 1,
  minHeight: 260,
  resizeMode: 'contain',
  alignSelf: 'center',
});

const TextContainer = styled(View)({
  flex: 1,
  marginTop: tooShort ? 12 : 28,
  paddingHorizontal: 24,
});

const StepCounter = styled(Text)({
  color: appColors.black,
  fontSize: 16,
  fontFamily: 'Roboto-Regular',
  fontWeight: '700',
});

const CurrentStep = styled(Text)({
  color: appColors.textOnSurfaceLight,
  fontSize: tooShort ? 14 : 16,
  textAlign: 'left',
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  marginTop: 8,
  lineHeight: 24,
});

const ButtonContainer = styled(View)({
  paddingHorizontal: 24,
  marginBottom: 24,
  shadowColor: appColors.white,
  shadowOffset: {
    width: 0,
    height: -8,
  },
  shadowOpacity: 0.9,
  shadowRadius: 10,
});

const Button = styled(StyledButton)({
  width: '100%',
});

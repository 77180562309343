// TODO: remove file when we have real data
import BREATHING from '../led3/breathing.json';
import CIRCLING_SLOW from '../led3/circling_slow.json';
import CONFETTI from '../led3/confetti.json';
import DISCO from '../led3/disco.json';
import FADE from '../led3/fade.json';
import LAVA_LAMP from '../led3/lava_lamp.json';
import LED2 from '../led3/led2.json';
import LED2_NO_ANIMATION from '../led3/led2_no_animation.json';
import NO_ANIMATION from '../led3/no_animation.json';
import PARTY_MODE from '../led3/party_mode.json';
import SPIN from '../led3/spin.json';
import SPLIT_GRADIENT from '../led3/split_gradient.json';
import VERTICAL_SLIDESHOW from '../led3/vertical_slideshow.json';
import {Led2Config, Led3Config, MoodType} from '../types';

export const LED3Data = {
  [MoodType.NO_ANIMATION]: NO_ANIMATION as Led3Config,
  [MoodType.DISCO]: DISCO as Led3Config,
  [MoodType.FADE]: FADE as Led3Config,
  [MoodType.SPIN]: SPIN as Led3Config,
  [MoodType.SPLIT_GRADIENT]: SPLIT_GRADIENT as Led3Config,
  [MoodType.VERTICAL_SLIDESHOW]: VERTICAL_SLIDESHOW as Led3Config,
  [MoodType.BREATHING]: BREATHING as Led3Config,
  [MoodType.CIRCLING_SLOW]: CIRCLING_SLOW as Led3Config,
  [MoodType.LAVA_LAMP]: LAVA_LAMP as Led3Config,
  [MoodType.CONFETTI]: CONFETTI as Led3Config,
};

export const LED3PartyModeData = PARTY_MODE as Led3Config;

export const LED2Data = LED2 as Led2Config;

export const LED2NoAnimationData = LED2_NO_ANIMATION as Led2Config;

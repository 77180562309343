let lock: WakeLockSentinel | undefined;

export const activateScreenAwake = async () => {
  if (!window.navigator?.wakeLock?.request) return;

  lock = await window.navigator.wakeLock
    .request('screen')
    .catch(() => undefined);
};

export const deactivateScreenAwake = async () => {
  if (!lock?.release) return;
  lock.release();
};

import {Formik, FormikHelpers} from 'formik';
import {MarketOptIn} from 'puffco-api-axios-client';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from 'react-native';
import {useAsync} from 'react-use';
import * as Yup from 'yup';

import {
  CheckBoxContent,
  ImgBackground,
  StyledButton,
  StyledCheckBox,
  StyledField,
  displayRightIconMessage,
  getRightIconName,
} from '../../components';
import {
  Constants,
  ErrorMessages,
  Messages,
  Screens,
  appColors,
} from '../../constants';
import {ChangeMarketOptInDTO, updateUser} from '../../lib/api';
import {geolocationApi, marketOptInAccess} from '../../lib/api/apis';
import {useAppHeaderHeight} from '../../lib/hooks';
import styled from '../../lib/styled';
import {
  formatDate,
  formatPhoneNumber,
  phoneRegExp,
} from '../../lib/utilityFunctions';
import {MarketOptInNavigatorScreenProps} from '../../navigation/navigators/MainStackNavigator';
import {analytics} from '../../src/services/analytics';
import {LogoHeader, LogoTitle, TermsAndPrivacy} from '../components';
import {isDisplayZoomed} from '../lib/isDisplayZoomed';
import {checkAgeTest} from './lib/checkAge';

interface MarketOptInValues {
  birthday: string;
  phoneNumber: string;
  email: string;
  agreeEmail: boolean;
  agreePhone: boolean;
}

const {IPHONE_8} = Constants.SCREEN_HEIGHT;
const {height} = Dimensions.get('window');
const isSmall = height <= IPHONE_8;

interface Props
  extends MarketOptInNavigatorScreenProps<typeof Screens.MarketOptInExist> {}

export const MarketOptInExistScreen = ({navigation}: Props) => {
  const HEADER_HEIGHT = useAppHeaderHeight();
  const {value} = useAsync(() => geolocationApi.getCountry(), []);

  const isFromUS = value?.data.ipcountry === 'US';

  const handleSubmit = async (
    values: MarketOptInValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    // Append 1 to indicate US code, remove extra characters for Voyage API to accept format
    const phoneNumber = '1' + values.phoneNumber.replace(/[\s()-]/, '');

    const updateData: ChangeMarketOptInDTO = {};

    // Assign Opt-In
    if (values.email) {
      updateData.marketOptIn = MarketOptIn.Email;
      await marketOptInAccess
        .subscribeToEmail({email: values.email})
        .then(() => {
          analytics.trackEvent('optin', {content_type: 'email'});
        });
    }
    if (values.phoneNumber) {
      updateData.marketOptIn = MarketOptIn.Phone;
      await marketOptInAccess.subscribeToSms({phoneNumber}).then(() => {
        analytics.trackEvent('optin', {content_type: 'sms'});
      });
    }
    if (values.email && values.phoneNumber) {
      updateData.marketOptIn = MarketOptIn.All;
    }

    try {
      await updateUser(updateData, ErrorMessages.INVALID_NUMBER);

      navigation.navigate(Screens.MarketOptInConfirmed, {
        userData: {email: values.email, phoneNumber: values.phoneNumber},
      });
    } catch {
      // TODO better error handling
    }

    actions.setSubmitting(false);
  };

  const MarketOptInSchema = Yup.object().shape(
    {
      birthday: checkAgeTest.when(
        'phoneNumber',
        (phoneNumber: string, schema: Yup.StringSchema) => {
          return phoneNumber
            ? schema.required(ErrorMessages.REQUIRED_BIRTHDAY)
            : schema.notRequired();
        },
      ),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, ErrorMessages.INVALID_PHONE_NUMBER)
        .when('agreePhone', (agreePhone: boolean, schema: Yup.StringSchema) => {
          return agreePhone
            ? schema.required(ErrorMessages.REQUIRED_PHONE_NUMBER)
            : schema.notRequired();
        }),
      agreePhone: Yup.boolean().when(
        'phoneNumber',
        (phoneNumber: string, schema: Yup.BooleanSchema) => {
          return phoneNumber ? schema.oneOf([true]) : schema.notRequired();
        },
      ),
      email: Yup.string()
        .trim()
        .email(ErrorMessages.INVALID_EMAIL)
        .when('agreeEmail', (agreeEmail: boolean, schema: Yup.StringSchema) => {
          return agreeEmail
            ? schema.required(ErrorMessages.REQUIRED_EMAIL)
            : schema.notRequired();
        }),
      agreeEmail: Yup.boolean().when(
        'email',
        (email: string, schema: Yup.BooleanSchema) => {
          return email ? schema.oneOf([true]) : schema.notRequired();
        },
      ),
    },
    [
      ['email', 'agreeEmail'],
      ['phoneNumber', 'agreePhone'],
    ],
  );

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <ScrollView
          style={{flex: 1}}
          contentContainerStyle={{
            height: Constants.IS_WEB ? 1300 : undefined,
          }}>
          <InnerContainer style={{paddingTop: HEADER_HEIGHT}}>
            <Formik
              initialValues={{
                birthday: '',
                phoneNumber: '',
                email: '',
                agreeEmail: false,
                agreePhone: false,
              }}
              onSubmit={handleSubmit}
              validateOnMount
              validationSchema={MarketOptInSchema}>
              {({
                handleBlur,
                handleChange,
                setFieldValue,
                isSubmitting,
                isValid,
                submitForm,
                touched,
                values,
                errors,
              }) => (
                <FormContainer>
                  <SpinnerContainer>
                    {isSubmitting && (
                      <ActivityIndicator size="large" color={appColors.black} />
                    )}
                  </SpinnerContainer>

                  <LogoHeader style={{marginTop: 20}}>
                    <LogoTitle>
                      {Messages.MARKET_OPT_IN_SIGN_IN_PROMPT}
                    </LogoTitle>
                  </LogoHeader>

                  <FieldsContainer>
                    <FieldLabel>BIRTHDAY</FieldLabel>
                    <StyledField
                      name={'mail'}
                      borderColor={appColors.lightGray}
                      height={35}
                      placeholder={'MM / DD / YYYY'}
                      onChangeText={handleChange('birthday')}
                      onBlur={handleBlur('birthday')}
                      value={formatDate(values.birthday)}
                      keyboardType="number-pad"
                      leftIconColor={appColors.mediumGray}
                      rightIcon={getRightIconName(
                        values.birthday,
                        errors.birthday,
                        values.phoneNumber.length > 0 || touched.birthday,
                      )}
                      rightIconColor={
                        errors.birthday ? appColors.red : appColors.green
                      }
                      rightIconMessage={displayRightIconMessage(
                        values.birthday,
                        errors.birthday,
                        values.phoneNumber.length > 0 || touched.birthday,
                      )}
                    />
                    <BottomContainer style={{marginVertical: 2}}>
                      <GrayText>Must be 21 or older</GrayText>
                    </BottomContainer>
                    {isFromUS && (
                      <>
                        <FieldLabel>MOBILE NUMBER</FieldLabel>
                        <StyledField
                          name={'phone'}
                          borderColor={appColors.lightGray}
                          height={35}
                          placeholder={'(   )   -'}
                          onChangeText={handleChange('phoneNumber')}
                          onBlur={handleBlur('phoneNumber')}
                          value={formatPhoneNumber(values.phoneNumber)}
                          keyboardType="number-pad"
                          leftIconColor={appColors.mediumGray}
                          rightIcon={getRightIconName(
                            values.phoneNumber,
                            errors.phoneNumber,
                            touched.phoneNumber,
                          )}
                          rightIconColor={
                            errors.phoneNumber ? appColors.red : appColors.green
                          }
                          rightIconMessage={displayRightIconMessage(
                            values.phoneNumber,
                            errors.phoneNumber,
                            touched.phoneNumber,
                          )}
                        />
                        <BottomContainer style={{marginVertical: 2}}>
                          <GrayText>Optional</GrayText>
                        </BottomContainer>
                      </>
                    )}
                    <FieldLabel>EMAIL</FieldLabel>
                    <StyledField
                      name={'mail'}
                      borderColor={appColors.lightGray}
                      height={35}
                      onChangeText={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      keyboardType="email-address"
                      leftIconColor={appColors.mediumGray}
                      rightIcon={getRightIconName(
                        values.email,
                        errors.email,
                        touched.email,
                      )}
                      rightIconColor={
                        errors.email ? appColors.red : appColors.green
                      }
                      rightIconMessage={displayRightIconMessage(
                        values.email,
                        errors.email,
                        touched.email,
                      )}
                    />
                    <BottomContainer style={{marginVertical: 2}}>
                      <GrayText>Optional</GrayText>
                    </BottomContainer>
                  </FieldsContainer>

                  <AgreeContainer
                    style={{
                      marginBottom: isFromUS ? -10 : 30,
                    }}>
                    <StyledCheckBox
                      checked={values.agreeEmail}
                      onChange={value => setFieldValue('agreeEmail', value)}>
                      <CheckBoxContent
                        style={{
                          fontSize: 10,
                          letterSpacing: 0.25,
                          lineHeight: 15,
                        }}>
                        {Messages.MARKET_OPT_IN_AGREE_EMAIL}
                      </CheckBoxContent>
                    </StyledCheckBox>

                    {isFromUS && (
                      <StyledCheckBox
                        checked={values.agreePhone}
                        onChange={value => setFieldValue('agreePhone', value)}>
                        <CheckBoxContent
                          style={{
                            fontSize: 10,
                            letterSpacing: 0.25,
                            lineHeight: 15,
                          }}>
                          {Messages.MARKET_OPT_IN_AGREE_PHONE}
                        </CheckBoxContent>
                      </StyledCheckBox>
                    )}
                  </AgreeContainer>
                  <ButtonContainer>
                    <StyledButton
                      title="SIGN ME UP"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        !(values.agreePhone || values.agreeEmail)
                      }
                      onPress={submitForm}
                      style={{
                        backgroundColor: appColors.black,
                        width: '100%',
                      }}
                      textStyle={{color: appColors.white}}
                    />
                  </ButtonContainer>
                  <AgreeContainer>
                    <Text
                      style={{
                        fontSize: 10,
                        letterSpacing: 0.25,
                        lineHeight: 15,
                        color: appColors.gray,
                        fontFamily: 'Roboto-Regular',
                        fontWeight: '400',
                      }}>
                      {Messages.MARKET_OPT_IN_AGREE}
                    </Text>
                  </AgreeContainer>
                  <LinkContainer>
                    <TermsAndPrivacy />
                  </LinkContainer>
                </FormContainer>
              )}
            </Formik>
          </InnerContainer>
        </ScrollView>
      </SafeAreaView>
    </ImgBackground>
  );
};

const InnerContainer = styled(View)({
  width: '100%',
  flex: 1,
  paddingTop: 10,
  backgroundColor: appColors.white,
});

const FormContainer = styled(View)({
  marginHorizontal: isDisplayZoomed() ? 40 : 50,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const LinkContainer = styled(View)({
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 10,
});

const FieldsContainer = styled(View)({
  width: '100%',
  marginTop: 10,
});

const FieldLabel = styled(Text)({
  color: appColors.black,
  fontFamily: 'Roboto-Bold',
  fontSize: 15,
  fontWeight: '100',
  letterSpacing: 0.75,
  marginTop: 10,
});

const AgreeContainer = styled(View)({
  marginVertical: 20,
});

const ButtonContainer = styled(View)({
  justifyContent: 'flex-end',
  marginTop: isSmall ? 20 : 38,
  width: '100%',
});

const BottomContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const SpinnerContainer = styled(View)({
  position: 'absolute',
  top: '55%',
});

const GrayText = styled(Text)({
  color: appColors.gray,
  fontSize: 12,
  fontFamily: 'Roboto-Regular',
  letterSpacing: 0.37,
  fontWeight: '400',
});

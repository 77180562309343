import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';
import {
  Gesture,
  GestureDetector,
  GestureUpdateEvent,
  PanGestureChangeEventPayload,
  PanGestureHandlerEventPayload,
} from 'react-native-gesture-handler';
import {
  SharedValue,
  clamp,
  runOnJS,
  useSharedValue,
} from 'react-native-reanimated';

interface Props extends ViewProps {
  value: SharedValue<number>;
  disabled?: boolean;
  gestureStyle?: ViewProps['style'];
  onEvent(
    event: GestureUpdateEvent<
      PanGestureHandlerEventPayload & PanGestureChangeEventPayload
    >,
  ): number;
  onChange?(value: number): void;
  onBegin?(value: number): void;
  onEnd?(value: number): void;
}

const clampValue = (value: number) => {
  'worklet';
  return clamp(value, 0, 1);
};

export const useSyncedSharedValue = (
  value: number,
  getValue: (value: number) => number,
  deps: React.DependencyList,
) => {
  const shared = useSharedValue(value);

  React.useEffect(() => {
    shared.value = getValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return shared;
};

export const Slider: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  disabled,
  style,
  onEvent,
  onBegin,
  onChange,
  onEnd,
  ...props
}) => {
  const gesture = Gesture.Pan()
    .onChange(event => {
      if (disabled) return;
      value.value = clampValue(onEvent(event));
      if (!onChange) return;
      runOnJS(onChange)(value.value);
    })
    .onBegin(() => {
      if (!onBegin || disabled) return;
      runOnJS(onBegin)(clampValue(value.value));
    })
    .onEnd(() => {
      if (!onEnd || disabled) return;
      runOnJS(onEnd)(clampValue(value.value));
    });

  return (
    <GestureDetector {...{gesture}}>
      <View {...props} style={[styles.gesture, style]} />
    </GestureDetector>
  );
};

const styles = StyleSheet.create({
  gesture: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
});

import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';

import {Screens, Strings, appColors} from '../../constants';
import {UserManualScreen} from '../../screens';
import {
  GuideScreen,
  GuideStepScreen,
} from '../../screens/Education/screens/GuideScreen';
import {GuideScreenProps} from '../../screens/Education/screens/GuideScreen/GuideScreen';
import {GuideStepScreenProps} from '../../screens/Education/screens/GuideScreen/GuideStepScreen';
import {UserManualScreenProps} from '../../screens/support/UserManualScreen';
import {defaultNavigationOptions, withBackButton} from './common';

export type GuideStackParamList = {
  [Screens.Guide]: GuideScreenProps;
  [Screens.GuideStep]: GuideStepScreenProps;
  [Screens.UserManual]: UserManualScreenProps;
};

const GuideStackNavigator = createStackNavigator<GuideStackParamList>();

export const GuideStack = () => {
  return (
    <GuideStackNavigator.Navigator
      initialRouteName={Screens.Guide}
      screenOptions={() => ({
        ...defaultNavigationOptions,
        headerStyle: {
          elevation: 0,
          shadowColor: 'transparent',
          borderBottomWidth: 0,
        },
        headerTitleContainerStyle: {
          flexGrow: 1,
          alignItems: 'center',
        },
        headerTitleStyle: {
          fontFamily: 'Roboto-Regular',
          fontSize: 16,
          fontWeight: '700',
          color: appColors.black,
          textTransform: 'uppercase',
        },
        headerLeft: () => null,
        headerTitle: '',
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      })}>
      <GuideStackNavigator.Screen
        name={Screens.Guide}
        component={GuideScreen}
      />

      <GuideStackNavigator.Screen
        name={Screens.GuideStep}
        component={GuideStepScreen}
        options={{
          ...withBackButton({iconStyle: {tintColor: appColors.black}}),
          headerTransparent: false,
        }}
      />

      <GuideStackNavigator.Screen
        name={Screens.UserManual}
        component={UserManualScreen}
        options={{
          headerTitle: Strings.USER_MANUAL,
          headerTransparent: false,
          cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
          ...withBackButton({iconStyle: {tintColor: appColors.black}}),
        }}
      />
    </GuideStackNavigator.Navigator>
  );
};

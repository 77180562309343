import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';

import {StyledIcon} from '../../../components';
import {Constants, Messages, Strings, appColors} from '../../../constants';
import styled from '../../../lib/styled';
import {CheckboxUI, Led3Value, MoodLightUI, SliderUI} from '../../../lib/types';
import {Checkbox} from './Checkbox';
import {Slider} from './Slider';

const {DYNAMIC_INHALE_TITLE, DYNAMIC_INHALE_MSG} = Messages;
const {TEMPO, COMPLEXITY, RANDOMNESS, ANIMATION} = Strings;
const {MOOD_TYPE_AND_VALUE} = Constants;

const keyTitleMap: {[x: string]: string} = {
  tempoFrac: TEMPO,
  compFrac: COMPLEXITY,
  randFrac: RANDOMNESS,
  dynamicInhale: DYNAMIC_INHALE_TITLE,
};

export const ControlPanelComponent = ({
  moodLightValues,
  setMoodLightValues,
  navigateTo,
  animation,
  uiList,
}: {
  uiList: MoodLightUI[];
  moodLightValues: Led3Value[];
  setMoodLightValues: (v: Led3Value[]) => void;
  animation?: number;
  navigateTo?: () => void;
}) => {
  const handleSetValue = React.useCallback(
    (key: string, val: number) => {
      const newValue = Number(Number(val).toFixed(2));
      setMoodLightValues(
        moodLightValues.map(item =>
          item.key === key
            ? {
                ...item,
                value: newValue,
              }
            : item,
        ),
      );
    },
    [moodLightValues],
  );

  return (
    <>
      <ControlPanelButtonContainer onPress={() => navigateTo?.()}>
        <ButtonTitle numberOfLines={1}>{ANIMATION}</ButtonTitle>
        <ButtonEnd>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Text
              style={{
                color: appColors.white50,
                fontWeight: '400',
                fontFamily: 'Roboto-Regular',
              }}>
              {Object.keys(MOOD_TYPE_AND_VALUE).find(
                v =>
                  MOOD_TYPE_AND_VALUE[v as keyof typeof MOOD_TYPE_AND_VALUE] ===
                  animation,
              )}
            </Text>
            <StyledIcon name={'chevronRight'} size={16} />
          </View>
        </ButtonEnd>
      </ControlPanelButtonContainer>
      {/* TODO test with real data */}
      {uiList.map((ui, i) => {
        if (!(ui.key in keyTitleMap || ui.label)) return null;
        if (ui.type === 'slider')
          return (
            <Slider
              key={i}
              containerWidth="100%"
              containerMarginLeft={0}
              paddingHorizontal={7}
              title={(keyTitleMap[ui.key] || ui.label || '').toUpperCase()}
              ui={ui as SliderUI}
              value={
                (moodLightValues.find(val => val.key === ui.key)
                  ?.value as number) ?? 0
              }
              setValue={val => handleSetValue(ui.key, val)}
              trackColor={appColors.white20}
              delay={100}
            />
          );
        else if (ui.type === 'checkbox')
          return (
            <React.Fragment key={i}>
              <Checkbox
                title={keyTitleMap[ui.key] || ui.label || ''}
                ui={ui as CheckboxUI}
                value={
                  moodLightValues.find(val => val.key === ui.key)?.value as
                    | 0
                    | 1
                }
                setValue={val => handleSetValue(ui.key, val)}
              />
              {ui.key === 'dynamicInhale' && (
                <Text
                  style={{
                    fontSize: 11,
                    color: appColors.white50,
                    letterSpacing: 0.03,
                    lineHeight: 18,
                    fontWeight: '400',
                    fontFamily: 'Roboto-Regular',
                    width: '100%',
                  }}>
                  {DYNAMIC_INHALE_MSG}
                </Text>
              )}
            </React.Fragment>
          );
        else return null;
      })}
    </>
  );
};

const ControlPanelButtonContainer = styled(TouchableOpacity)({
  height: 56,
  paddingLeft: 24,
  paddingRight: 18,
  backgroundColor: appColors.black30,
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 22,
  borderRadius: 8,
  alignItems: 'center',
});

const ButtonTitle = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 16,
  color: appColors.white,
  flex: 1,
});

const ButtonEnd = styled(View)({
  flex: 1,
  alignItems: 'flex-end',
});

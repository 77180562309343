import {BottomTabNavigationOptions} from '@react-navigation/bottom-tabs';
import {DrawerNavigationOptions} from '@react-navigation/drawer';
import {
  StackCardInterpolationProps,
  StackNavigationOptions,
  TransitionPresets,
} from '@react-navigation/stack';
import React from 'react';
import {Animated, Easing, Platform} from 'react-native';

import {BackButton} from '../../components';
import {HamburgerMenuButton} from '../../components/HamburgerMenuButton';
import {appColors} from '../../constants';

export const defaultNavigationOptions: StackNavigationOptions &
  DrawerNavigationOptions &
  BottomTabNavigationOptions = {
  animationEnabled: true,
  headerTransparent: true,
  headerTintColor: appColors.white,
  headerLeftContainerStyle: {
    marginLeft: 8,
  },
  headerTitleAlign: 'center',
  headerTitleStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  headerRightContainerStyle: {
    marginRight: 8,
  },
  gestureEnabled: false,
  cardOverlayEnabled: true,
};

export const modalNavigationOptions = {
  ...TransitionPresets.ModalTransition,
  cardOverlayEnabled: true,
  gestureEnabled: true,
  headerTintColor: appColors.white,
  headerTransparent: true,
  headerTitle: () => null,
  cardStyle: {
    backgroundColor: Platform.OS !== 'android' ? '#00000033' : '#000000E6',
  },
};

export const defaultNoHeaderScreenNavigation: Partial<StackNavigationOptions> =
  {headerShown: false};

export const withBackButton = (
  props: Omit<
    React.ComponentProps<typeof BackButton>,
    'disabled' | 'onPress'
  > = {},
): Partial<StackNavigationOptions> => ({
  headerLeft: ({disabled, onPress}) => {
    return <BackButton {...props} {...{disabled, onPress}} />;
  },
});

export const withHamburgerMenu = ({
  themed,
}: {themed?: boolean} = {}): Partial<StackNavigationOptions> => ({
  headerLeft: () => <HamburgerMenuButton {...{themed}} />,
});

// const CrossFadeTransition = (index: number, position: AnimatedValue) => {
//   const inputRange = [index - 1, index, index + 1];
//   const opacity = position.interpolate({
//     inputRange,
//     outputRange: [0, 1, 1],
//   });

//   return {
//     opacity,
//   };
// };

const forFade = ({current}: StackCardInterpolationProps) => ({
  cardStyle: {
    opacity: current.progress,
    duration: 5000,
    easing: Easing.bezier(0.2833, 0.99, 0.31833, 0.99),
    timing: Animated.timing,
  },
});

export const fadeNavigationOptions: StackNavigationOptions = {
  cardStyleInterpolator: forFade,
  // transitionConfig: {
  //   screenInterpolator: (sceneProps: {
  //     position: number;
  //     scene: {index: AnimatedValue};
  //   }) => {
  //     const {position, scene} = sceneProps;
  //     const {index} = scene;
  //     return CrossFadeTransition(position, index);
  //   },
  // },
  transitionSpec: {
    open: {
      animation: 'timing',
      config: {
        duration: 1000,
        easing: Easing.bezier(0.2833, 0.99, 0.31833, 0.99),
      },
    },
    close: {
      animation: 'timing',
      config: {
        duration: 1000,
        easing: Easing.bezier(0.2833, 0.99, 0.31833, 0.99),
      },
    },
  },
};

export const fadeStackOptions = {
  ...defaultNavigationOptions,
  ...fadeNavigationOptions,
  gestureEnabled: false,
};

export class DisposableCallback<P> {
  private callback?: (arg: P) => void;

  register(callback: (arg: P) => void) {
    this.callback = callback;

    return () => (this.callback = undefined);
  }

  invoke(arg: P) {
    this.callback?.(arg);
  }
}

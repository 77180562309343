import {useNavigation} from '@react-navigation/native';
import React from 'react';

import {Alerts, Navigators, Screens, Strings} from '../../constants';
import {useChamberType} from '../../lib/hooks';
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigation/navigators/RootStackNavigator';
import {Alert} from '../../shims/alert';

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const useCanDab = () => {
  const navigation = useNavigation<Navigation>();
  const {isMeetAG, isChamberDisconnected, isXLChamber} = useChamberType();

  const verifyCanDab = React.useCallback(
    (cb: () => void) => {
      if (isChamberDisconnected) {
        Alert.alert(
          Alerts.CHAMBER_DISCONNECTED_TITLE,
          Alerts.CHAMBER_DISCONNECTED_MESSAGE,
          [{text: Strings.I_UNDERSTAND}],
        );

        return;
      }

      if (!isMeetAG && isXLChamber) {
        Alert.alert(
          Alerts.UPDATE_FIRMWARE,
          Alerts.XL_CHAMBER_UPDATE_FIRMWARE_MESSAGE,
          [
            {
              text: Strings.CONTINUE,
              onPress: () =>
                navigation.navigate(Navigators.MainNavigator, {
                  screen: Navigators.HomeDrawerNavigator,
                  params: {
                    screen: Navigators.HomeEmulatedDrawer,
                    params: {
                      screen: Screens.FirmwareInfo,
                    },
                  },
                }),
            },
          ],
        );

        return;
      }

      cb();
    },
    [isChamberDisconnected, isMeetAG, isXLChamber, navigation],
  );

  return React.useMemo(() => ({verifyCanDab}), [verifyCanDab]);
};

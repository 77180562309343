// Remove non number characters
export const regex = (value: string) => value.replace(/\D/g, '');

export const formatDate = (value: string) => {
  if (!value) return '';
  let formattedDate = '';
  const {length} = value;
  const year = () => `${regex(value).slice(4, 8)}`;
  const month = () => `${regex(value).slice(0, 2)}`;
  const date = () => `${regex(value).slice(2, 4)}`;

  if (length <= 2) {
    formattedDate = `${regex(value)}`;
  } else if (length === 4) {
    // handle backspace
    formattedDate = `${regex(value).slice(0, 2)}`;
  } else if (length <= 7) {
    formattedDate = `${month()} / ${date()}`;
  } else if (length === 9) {
    // handle backspace
    formattedDate = `${month()} / ${date().slice(0, 2)}`;
  } else {
    formattedDate = `${month()} / ${date()} / ${year()}`;
  }
  return formattedDate;
};

export const phoneRegExp =
  /^[+]?[(]?[\d]{3}[)]?[\s]?[\d]{3}?[\s][-][\s]?[\d]{4}$/;

export const formatPhoneNumber = (value: string) => {
  if (!value) return '';
  let formattedPhoneNumber = '';
  const {length} = value;
  const areaCode = () => `${regex(value).slice(0, 3)}`;
  const secondThree = () => `${regex(value).slice(3, 6)}`;
  const remain = `${regex(value).slice(6)}`;
  if (length <= 3) {
    formattedPhoneNumber = regex(value);
  } else if (length === 5) {
    // Handle backspaces
    formattedPhoneNumber = `${areaCode().slice(0, 2)}`;
  } else if (length <= 9) {
    formattedPhoneNumber = `(${areaCode()}) ${secondThree()}`;
  } else if (length === 11) {
    // Handle backspaces
    formattedPhoneNumber = `(${areaCode()}) ${secondThree().slice(0, 2)}`;
  } else {
    formattedPhoneNumber = `(${areaCode()}) ${secondThree()} - ${remain}`;
  }
  return formattedPhoneNumber;
};

import {BasePermissionService} from '../BasePermissionService';
import {PermissionError} from '../PermissionError';
import {IBluetoothService} from './IBluetoothService';

export abstract class BaseBluetoothService
  extends BasePermissionService
  implements Pick<IBluetoothService, 'granted' | 'request'>
{
  protected abstract enabled(): Promise<boolean>;
  protected abstract enable(): Promise<void>;
  protected abstract requestPermissions(): Promise<void>;

  abstract granted(): Promise<boolean>;

  async request(): Promise<void> {
    const check = async (callback?: () => Promise<void>) => {
      const granted = await this.granted();

      if (granted) {
        await this.enabled();

        await this.waitForActive({
          onRequest: this.enable.bind(this),
          onCheck: async () => {
            const enabled = await this.enabled();

            if (enabled) return;

            throw new Error(PermissionError.BluetoothDisabled);
          },
        });
      }

      await callback?.();
    };

    await check(async () => {
      await this.requestPermissions();

      await check();
    });
  }
}

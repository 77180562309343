import {convertHexToRgbArray} from '../convertHexToRgbArray';
import {convertRgbArrayToHex} from '../convertRgbArrayToHex';

type mixColorProps = {
  colorA: string;
  colorB: string;
  percentage?: number; // Percentage mix from colorA to colorB
};

/* These functions only accept hex color strings */

export const mixColor = ({colorA, colorB, percentage = 0.5}: mixColorProps) => {
  const [aR, aG, aB] = convertHexToRgbArray(colorA);
  const [bR, bG, bB] = convertHexToRgbArray(colorB);
  const rgbArr = [
    Math.round((bR - aR) * percentage + aR),
    Math.round((bG - aG) * percentage + aG),
    Math.round((bB - aB) * percentage + aB),
  ];
  return convertRgbArrayToHex(rgbArr);
};

import {Text} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';

export const Title = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  color: appColors.white,
});

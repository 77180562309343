export const getTimeFormat = (
  time = 0,
  {
    padMinutes = true,
    padSeconds = true,
  }: {padMinutes: boolean; padSeconds: boolean},
) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const minStr = padMinutes ? `${minutes}`.padStart(2, '0') : minutes;
  const secStr = padSeconds ? `${seconds}`.padStart(2, '0') : seconds;
  return `${minStr}:${secStr}`;
};

import React from 'react';

import {useTheme} from '../lib/useTheme';
import {Image} from '../shims/ImageWithFilter';

export type LogoProps = {
  /*
   * The overall size of the icon, default is 60 (login screen size) with vertical margins
   */
  size?: number;
  color?: string;
};

export const Logo = (props: LogoProps) => {
  const {logoIcon} = useTheme();
  return (
    <Image
      source={logoIcon}
      style={{
        width: props.size || 60,
        height: props.size || 60,
        resizeMode: 'contain',
        tintColor: props.color,
      }}
    />
  );
};

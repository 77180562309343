import React from 'react';

import {Alert, useSpinner} from '../../components';
import {useShare} from '../../lib/hooks';
import {useAsyncFn} from '../../lib/hooks/useAsyncFn';
import {Share} from '../../lib/types';
import {useTheme} from '../../lib/useTheme';

interface Props {
  load: () => Promise<Share | undefined>;
  onShareEnd: () => Promise<void>;
  icon: React.ComponentType<{onPress: () => void}>;
  alertTitle: string;
  alertMessage: string;
  loadingMessage: string;
}

export const ShareHandler = ({
  load,
  onShareEnd,
  icon: Component,
  alertTitle,
  alertMessage,
  loadingMessage,
}: React.PropsWithChildren<Props>) => {
  const {share} = useShare();
  const theme = useTheme();
  const {alternateSpinnerColor} = theme;

  const [{loading}, trigger] = useAsyncFn(async () => {
    await load().then(value => {
      if (!value) return;

      Alert.alert(alertTitle, alertMessage, [
        {
          style: 'cancel',
          text: 'Cancel',
        },
        {
          onPress: () => {
            share(value)
              .then(onShareEnd)
              .catch(() => void 0);
          },
          text: 'Proceed',
        },
      ]);
    });
  }, []);

  useSpinner({
    isVisible: loading,
    ...(loading && {
      text: loadingMessage,
      color: alternateSpinnerColor,
    }),
  });

  return <Component onPress={trigger} />;
};

import React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import {appColors} from '../constants';
import {addOpacityToColorHex} from '../lib/utilityFunctions';
import {StyledIcon, StyledIconProps} from './StyledIcon';

export type MoodLightIconProps = Omit<StyledIconProps, 'name'>;

export const MoodLightIcon = ({
  style = {},
  iconStyle = {},
  ...rest
}: MoodLightIconProps) => (
  <StyledIcon
    name={'logoInverted'}
    style={[defaultStyle, style]}
    iconStyle={{width: '50%', height: '50%', ...StyleSheet.flatten(iconStyle)}}
    {...rest}
  />
);

const defaultStyle: ViewStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: addOpacityToColorHex(appColors.white, 0.3),
  borderRadius: 50,
  height: 50,
  width: 50,
  padding: 0,
};

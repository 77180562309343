import React from 'react';
import {
  Animated,
  StyleSheet,
  SwitchProps,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {Path, Svg} from 'react-native-svg';

import {appColors} from '../constants';
import styled from '../lib/styled';
import {addOpacityToColorHex} from '../lib/utilityFunctions';

const BORDER_RADIUS = 15;
const SWITCH_HEIGHT = BORDER_RADIUS * 2;
const SWITCH_WIDTH = 50;
const RIGHT_ARC_X_VALUE = SWITCH_WIDTH - BORDER_RADIUS;

enum HandlePosition {
  LEFT = 0,
  RIGHT = 1,
}

export type CustomSwitchProps = {
  leftBackgroundColor?: string;
  rightBackgroundColor?: string;
} & SwitchProps;

export const CustomSwitch = ({
  onValueChange,
  style = {},
  value = false,
  leftBackgroundColor = addOpacityToColorHex(appColors.white, 0.65),
  rightBackgroundColor = addOpacityToColorHex(appColors.black, 0.2),
  ...rest
}: CustomSwitchProps) => {
  const flattenedStyle = StyleSheet.flatten(style);
  const [handlePosition, setHandlePosition] = React.useState<number>(
    value ? HandlePosition.RIGHT : HandlePosition.LEFT,
  );
  const slidingAnimation = React.useRef(
    new Animated.Value(handlePosition),
  ).current;
  const handleLeftOffset = (SWITCH_WIDTH - BORDER_RADIUS * 2) * handlePosition;
  const handleCenterPosition = BORDER_RADIUS + handleLeftOffset;

  const startSlidingAnimation = React.useCallback(
    (value: boolean) => {
      Animated.timing(slidingAnimation, {
        toValue: value ? HandlePosition.RIGHT : HandlePosition.LEFT,
        duration: 200,
        useNativeDriver: false,
      }).start();
    },
    [slidingAnimation],
  );

  React.useEffect(() => {
    startSlidingAnimation(value);
  }, [startSlidingAnimation, value]);

  React.useEffect(() => {
    const id = slidingAnimation.addListener(value =>
      setHandlePosition(value.value),
    );
    return () => slidingAnimation.removeListener(id);
  }, [slidingAnimation]);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (
          handlePosition === HandlePosition.LEFT ||
          handlePosition === HandlePosition.RIGHT
        ) {
          startSlidingAnimation(value);
          onValueChange && onValueChange(!value);
        }
      }}>
      <SwitchContainer
        {...rest}
        style={{
          ...flattenedStyle,
          backgroundColor: appColors.inActiveButtonSlider,
        }}>
        <Svg height={'100%'} width={'100%'}>
          <Path
            d={`
              M 0 ${BORDER_RADIUS}
              A ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 1 ${BORDER_RADIUS} 0
              L ${handleCenterPosition} 0
              L ${handleCenterPosition} ${SWITCH_HEIGHT}
              L ${BORDER_RADIUS} ${SWITCH_HEIGHT}
              A ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 1 0 ${BORDER_RADIUS}
            `}
            fill={leftBackgroundColor}
          />
          <Path
            d={`
              M ${SWITCH_WIDTH} ${BORDER_RADIUS}
              A ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${RIGHT_ARC_X_VALUE} 0
              L ${handleCenterPosition} 0
              L ${handleCenterPosition} ${SWITCH_HEIGHT}
              L ${RIGHT_ARC_X_VALUE} ${SWITCH_HEIGHT}
              A ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${SWITCH_WIDTH} ${BORDER_RADIUS}
            `}
            fill={rightBackgroundColor}
          />
        </Svg>
        <SwitchHandle style={{marginLeft: handleLeftOffset}} />
      </SwitchContainer>
    </TouchableWithoutFeedback>
  );
};

const SwitchContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: SWITCH_WIDTH,
  height: SWITCH_HEIGHT,
  borderRadius: BORDER_RADIUS,
});

const SwitchHandle = styled(View)({
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: BORDER_RADIUS * 2,
  height: '100%',
  backgroundColor: appColors.white,
  borderRadius: BORDER_RADIUS,
  elevation: 4,
  shadowColor: appColors.black,
  shadowOffset: {width: 0, height: 2},
  shadowOpacity: 0.5,
  shadowRadius: 4,
});

import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {TemperatureUnit} from 'puffco-api-axios-client';

import {AppSettingsStore, Store} from '../types';

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    tempPreference: TemperatureUnit.Fahrenheit,
    enablePeakAnimation: false,
    agreeAgreement: {
      TC: false,
      PP: false,
    },
    batteryPreservationOn: false,
  } as AppSettingsStore,
  reducers: {
    updateAppSettings(state, action: PayloadAction<Partial<AppSettingsStore>>) {
      return {...state, ...action.payload};
    },
  },
});

export const appSettingsSelector = (state: Store) => state.appSettings;

export const {updateAppSettings} = appSettingsSlice.actions;

export const appSettingsReducer = appSettingsSlice.reducer;

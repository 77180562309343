import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {ColorValue, RegisteredStyle, TextStyle} from 'react-native';

import {Messages, Screens, appColors} from '../../constants';
import {MoodLight} from '../../lib/types';
import {useTheme} from '../../lib/useTheme';
import {
  AssignToAHeatProfileScreen,
  ControlCenterBoostScreen,
  ControlCenterScreen,
  EpilepsyWarningScreen,
  LanternModeScreen,
  MoodLightLibraryScreen,
  SessionReadyScreen,
} from '../../screens';
import {defaultNavigationOptions, withBackButton} from './common';
import {WithRedirect} from './params';

export type ControlCenterStackParamList = {
  [Screens.AssignToAHeatProfile]: {
    titleStyle?: TextStyle;
    iconColor?: string;
    headerRight?: React.ReactElement;
    moodLight: MoodLight;
    isPeakMoodLight?: boolean;
  };

  [Screens.ControlCenter]: undefined;
  [Screens.ControlCenterBoost]:
    | {
        titleStyle?: TextStyle;
        iconColor?: string;
      }
    | undefined;
  [Screens.MoodLightLibrary]: undefined;
  [Screens.LanternMode]:
    | {
        titleStyle: RegisteredStyle<TextStyle>;
        iconColor: ColorValue;
      }
    | undefined;
  [Screens.SessionReady]: undefined;
  [Screens.EpilepsyWarning]: WithRedirect;
};

const ControlCenterStack = createStackNavigator<ControlCenterStackParamList>();

export const ControlCenterStackNavigator = () => {
  const {navMenuTitleStyle} = useTheme();

  return (
    <ControlCenterStack.Navigator
      initialRouteName={Screens.ControlCenter}
      screenOptions={{
        ...defaultNavigationOptions,
        animationEnabled: true,
        gestureEnabled: false,
        headerTitle: '',
        headerLeft: () => null,
        presentation: 'transparentModal',
        cardOverlayEnabled: false,
      }}>
      <ControlCenterStack.Screen
        name={Screens.AssignToAHeatProfile}
        component={AssignToAHeatProfileScreen}
      />

      <ControlCenterStack.Screen
        name={Screens.ControlCenter}
        component={ControlCenterScreen}
      />

      <ControlCenterStack.Screen
        name={Screens.ControlCenterBoost}
        component={ControlCenterBoostScreen}
      />

      <ControlCenterStack.Screen
        name={Screens.MoodLightLibrary}
        component={MoodLightLibraryScreen}
        options={{
          headerTitle: Messages.MOOD_LIGHTS_LIBARY_TITLE,
          headerTitleStyle: {
            ...navMenuTitleStyle,
            color: appColors.white,
            fontWeight: '400',
          },
          ...withBackButton({color: appColors.white}),
        }}
      />

      <ControlCenterStack.Screen
        name={Screens.LanternMode}
        component={LanternModeScreen}
      />

      <ControlCenterStack.Screen
        name={Screens.SessionReady}
        component={SessionReadyScreen}
      />

      <ControlCenterStack.Screen
        name={Screens.EpilepsyWarning}
        component={EpilepsyWarningScreen}
        options={{headerShown: false}}
      />
    </ControlCenterStack.Navigator>
  );
};

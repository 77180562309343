import React, {PropsWithChildren} from 'react';
import {
  PanGestureHandler,
  PanGestureHandlerProps,
} from 'react-native-gesture-handler';

import {SwipeHandlerOptions, useSwipe} from '../lib/hooks';

interface Props
  extends SwipeHandlerOptions,
    Pick<PanGestureHandlerProps, 'minDist'> {}

export enum SwipeDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'UP',
  DOWN = 'DOWN',
}

export const SwipeableArea: React.FC<PropsWithChildren<Props>> = ({
  children,
  minDist = 25,
  ...rest
}) => {
  const onEnded = useSwipe({...rest});

  return (
    <PanGestureHandler {...{minDist, onEnded}} shouldCancelWhenOutside>
      {children}
    </PanGestureHandler>
  );
};

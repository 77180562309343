import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Screens} from '../../constants';
import {DevicesListScreen} from '../../screens';
import {fadeStackOptions} from './common';

export type DevicesListStackParamList = {
  [Screens.DevicesList]: undefined;
};

const DevicesListStackNavigator =
  createStackNavigator<DevicesListStackParamList>();

export const DevicesListStack = () => {
  return (
    <DevicesListStackNavigator.Navigator
      initialRouteName={Screens.DevicesList}
      screenOptions={() => ({...fadeStackOptions, headerLeft: () => null})}>
      <DevicesListStackNavigator.Screen
        name={Screens.DevicesList}
        component={DevicesListScreen}
      />
    </DevicesListStackNavigator.Navigator>
  );
};

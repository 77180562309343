import React from 'react';
import {View} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';

export type SegmentedProgressProps = {
  /**
   * Filled progress color
   */
  colorFilled?: string;
  /**
   * color of unfilled progress
   */
  colorBackground?: string;
  /**
   * number of segments, default to 4
   */
  segments?: number;
  /**
   * number of segments that are filled, default to 0
   */
  progress?: number;
};

export const SegmentedProgress = (props: SegmentedProgressProps) => {
  const {colorFilled, colorBackground} = props;
  let {segments, progress} = props;

  const progresses = [];
  if (progress === undefined) progress = 0;
  if (segments === undefined) segments = 4;
  if (progress > segments) progress = segments;

  for (let i = 0; i < progress; i++) {
    progresses.push(
      <LineProgress
        key={i}
        style={{backgroundColor: colorFilled || appColors.lightMediumGray}}
      />,
    );
  }
  for (let i = progress; i < segments; i++) {
    progresses.push(
      <LineProgress
        key={i}
        style={{
          backgroundColor: colorBackground || appColors.lightMediumGray30,
        }}
      />,
    );
  }
  return <LineProgressContainer>{progresses}</LineProgressContainer>;
};

const LineProgressContainer = styled(View)({
  flexDirection: 'row',
  height: 2,
  paddingHorizontal: 20,
  width: '100%',
});

const LineProgress = styled(View)({
  flex: 1,
  height: 2,
  marginRight: 5,
});

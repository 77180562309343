import React from 'react';
import {Text, View} from 'react-native';

import {Constants, appColors} from '../../../../../constants';
import {getTimeFormat} from '../../../../../lib/getTimeFormat';
import styled from '../../../../../lib/styled';
import {AnimatedDuration} from './AnimatedDuration';

const TOTAL = 21;

const getData = (index: number) => [
  ...Array(TOTAL)
    .fill(null)
    .map((_, i) => ({
      x: i,
      y:
        index - 5 <= i && i <= index + 5
          ? i === index
            ? 16
            : i - 1 === index || i + 1 === index
              ? 5
              : 0
          : 0,
    })),
];

const {DURATION_MAX, DURATION_MIN} = Constants;
interface DurationGraphProps {
  durationSummaryAvg?: number;
}

export const DurationGraph: React.FC<DurationGraphProps> = React.memo(
  function DurationGraph({durationSummaryAvg = 0}) {
    let percentage = 0;

    if (durationSummaryAvg < DURATION_MIN) percentage = 0;
    else if (durationSummaryAvg > DURATION_MAX) percentage = 100;
    else
      percentage =
        ((durationSummaryAvg - DURATION_MIN) * 100) /
        (DURATION_MAX - DURATION_MIN);

    const percentageToBar = Math.floor((percentage / 100) * (TOTAL - 1));

    return (
      <View
        style={{
          width: '100%',
          flex: 0.7,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <AnimatedDuration
          xAndY={getData(percentageToBar)}
          value={percentageToBar}
          animationSpeed={1000}
          barColor={appColors.medianPurple}
          isValue={v => percentageToBar - 5 <= v && v <= percentageToBar + 5}
          gap={2}
          animateAll={true}
        />
        <RowContainer>
          <AxisText>
            {getTimeFormat(DURATION_MIN, {padMinutes: false, padSeconds: true})}
          </AxisText>
          <AxisText>
            {getTimeFormat(DURATION_MAX, {padMinutes: false, padSeconds: true})}
          </AxisText>
        </RowContainer>
      </View>
    );
  },
);

const RowContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: 2,
});

const AxisText = styled(Text)({
  color: appColors.mediumGray50,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
  lineHeight: 16,
  textTransform: 'uppercase',
  letterSpacing: 0.05,
});

import React from 'react';
import {ViewStyle} from 'react-native';

import {Constants} from '../../constants';

export const useCardScaleStyle = (cardScaleFactor: number) => {
  return React.useMemo(() => {
    const cardScaleStyle: Pick<ViewStyle, 'width' | 'height' | 'transform'> = {
      width: Constants.CARD_WIDTH,
      height: Constants.CARD_HEIGHT,
      transform: [{scale: cardScaleFactor}],
    };

    return cardScaleStyle;
  }, [cardScaleFactor]);
};

import {BLOCKS, Block, Inline} from '@contentful/rich-text-types';
import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import {ParamListBase} from '@react-navigation/routers';
import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import {Description} from '../../../components';
import {BackToTopButton} from '../../../components/BackToTopButton';
import {Constants, appColors} from '../../../constants';
import {resolveAssetUrl} from '../../../lib/api';
import {useAdaptiveSafeArea} from '../../../lib/hooks/useAdaptiveSafeArea';
import {useLoadPage} from '../../../lib/hooks/useLoadPages';
import {LoadingContainer} from '../../Education/components/LoadingContainer';
import {FixedFooter} from '../FixedFooter';
import {ErrorContainer} from './ErrorContainer';
import {HeroImage} from './HeroImage';
import {
  RichTextRenderer,
  isEmptyParagraph,
  styles as richTextStyles,
} from './RichTextRenderer';

interface ScreenProps extends MaterialTopTabScreenProps<ParamListBase> {}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
      if (isEmptyParagraph(node)) return <View style={styles.emptyParagraph} />;

      return <Description style={richTextStyles.text}>{children}</Description>;
    },
  },
};

export const Page: React.FC<ScreenProps> = ({route}) => {
  const screenName = route.name;

  const {page, loading} = useLoadPage({screenName});

  useAdaptiveSafeArea();

  const scrollViewRef = React.useRef<ScrollView>(null);

  if (loading) return <LoadingContainer />;

  if (!page) return <ErrorContainer />;

  const fields = page.fields;

  return (
    <View style={styles.layout}>
      <ScrollView
        ref={scrollViewRef}
        showsVerticalScrollIndicator={false}
        style={styles.scroller}>
        {fields.heroImage && (
          <HeroImage
            source={{
              uri: resolveAssetUrl(fields.heroImage),
            }}
          />
        )}

        <View
          style={[
            styles.content,
            {paddingBottom: fields.fixedFooter ? 124 : 48},
          ]}>
          <RichTextRenderer content={fields?.content} customOptions={options} />

          {!!fields.backToTopButton && (
            <BackToTopButton
              onPress={() =>
                scrollViewRef.current?.scrollTo({y: 0, animated: true})
              }
            />
          )}
        </View>
      </ScrollView>

      {!!fields.fixedFooter && (
        <FixedFooter>
          <RichTextRenderer content={fields.fixedFooter} />
        </FixedFooter>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    paddingTop: 16,
    flex: 1,
    flexBasis: 0,
    backgroundColor: appColors.black,
  },
  scroller: {
    flex: 1,
  },
  content: {
    marginTop: 48,
    paddingHorizontal: Constants.PAGE_HORIZONTAL_PADDING,
  },
  emptyParagraph: {
    height: 16,
  },
});

import React from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  View,
  ViewProps,
} from 'react-native';

import {Constants} from '../../../constants';

const screenHeight = Constants.DIMENSIONS.HEIGHT;

interface Props extends ViewProps {
  source: ImageSourcePropType;
}

export const ImageHeader = ({style, source, ...rest}: Props) => (
  <View style={[styles.imageWrapper, style]} {...rest}>
    <Image resizeMode="cover" style={styles.image} {...{source}} />
  </View>
);

const styles = StyleSheet.create({
  imageWrapper: {
    width: '100%',
    aspectRatio: screenHeight < Constants.SCREEN_HEIGHT.PX_785 ? 1.8 : 1.6,
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

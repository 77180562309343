import {TextStyle, ViewStyle} from 'react-native';

import {StyledIconProps} from '../components';

export const ProfileTitleStyle: TextStyle = {
  fontSize: 26,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  textAlign: 'center',
  letterSpacing: 0.38,
  lineHeight: 25,
};

export const ProfileSliderSwitchTitleStyle: TextStyle = {
  fontSize: 14,
  fontFamily: 'Roboto-Regular',
  letterSpacing: 0.35,
  fontWeight: '400',
};

export const ProfileDurationTitleStyle = {
  fontSize: 12,
  fontFamily: 'Roboto-Regular',
};

export const NavTextButtonTitleStyle: TextStyle = {
  fontFamily: 'BigShouldersDisplay-Medium',
  fontWeight: '400',
  fontSize: 16,
  letterSpacing: 0.6,
};

export const fillStyle: Pick<
  ViewStyle,
  'display' | 'flex' | 'width' | 'height'
> = {
  display: 'flex',
  flex: 1,
  width: '100%',
  height: '100%',
};

export const centerStyle: Pick<
  ViewStyle,
  'display' | 'alignItems' | 'justifyContent'
> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const centeredColumnStyle: Pick<
  ViewStyle,
  'display' | 'flexDirection' | 'alignItems' | 'justifyContent'
> = {
  ...centerStyle,
  flexDirection: 'column',
};

export const centeredRowStyle: Pick<
  ViewStyle,
  'display' | 'flexDirection' | 'alignItems' | 'justifyContent'
> = {
  ...centerStyle,
  flexDirection: 'row',
};

export const checkMarkButtonStyle: Pick<StyledIconProps, 'name' | 'size'> = {
  name: 'check',
  size: 24,
};

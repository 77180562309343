import React from 'react';

export type HeatProfileCardRingProps = {
  colors: string[];
  strokeWidth?: number;
  webInnerRingDivisor?: number;
  width?: number;
  height?: number;
  offsetBottom?: number;
  offsetRight?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const HeatProfileCardRing = ({
  colors,
  width = 200,
  height = 200,
  offsetBottom = 0,
  offsetRight = 0,
  webInnerRingDivisor = 2.03,
  children,
  style,
}: HeatProfileCardRingProps) => {
  return (
    <div
      style={{
        ...style,
        width,
        height,
        borderRadius: width / 2,
        position: 'absolute',
        zIndex: 0,
        justifyContent: 'center',
        alignItems: 'center',
        bottom: offsetBottom,
        right: offsetRight,
        background:
          colors.length === 1
            ? `${colors[0]}`
            : `conic-gradient(from 180deg, #F4F4F4 10%, ${colors.reduce(
                (prev, curr, currIndex) => {
                  const percentage = (index: number) =>
                    index * (20 / (colors.length - 1));
                  return `${prev}, ${curr} ${35 + percentage(currIndex)}%`;
                },
              )}, #F4F4F4 90%)`,
        WebkitMask: `radial-gradient(transparent ${
          width / webInnerRingDivisor
        }px,#000 ${width / webInnerRingDivisor}px)`,
        mask: `radial-gradient(transparent ${
          width / webInnerRingDivisor
        }px,#000 ${width / webInnerRingDivisor}px)`,
        flex: 1,
      }}>
      {children}
    </div>
  );
};

import {ProductName} from 'pikaparam';
import {ImageSourcePropType} from 'react-native';

import * as images from '../assets/images';
import {
  ChamberType,
  DeviceModelType,
  Dictionary,
  LightingPattern,
  LumaAnimation,
  MiddlewareValue,
} from '../lib/types';

/* To define a default value, define a get function in
 * '../lib/utilityFunctions/getDictionaryValue.ts'.
 */

export const DICTIONARIES = {
  CHAMBER_LOG_VALUES_DICTIONARY: {
    [ChamberType.Performance]: {
      minValue: 0x0002,
      maxValue: 0x1979,
    },
    [ChamberType.XL]: {
      minValue: 0x197a,
      maxValue: 0x32f3,
    },
  } as Dictionary<ChamberType, {minValue: number; maxValue: number}>,
  DEVICE_MODEL_TYPE: {
    OG: DeviceModelType.OG,
    Opal: DeviceModelType.LIGHTNING,
    Indiglow: DeviceModelType.LIGHTNING,
    Guardian: DeviceModelType.LIGHTNING,
    Onyx: DeviceModelType.LIGHTNING,
    Pearl: DeviceModelType.LIGHTNING,
    Desert: DeviceModelType.LIGHTNING,
    Flourish: DeviceModelType.LIGHTNING,
    Storm: DeviceModelType.LIGHTNING,
  } as Dictionary<ProductName, DeviceModelType>,
  IMAGE_SOURCE_DICTIONARY: images as unknown as Dictionary<
    string,
    ImageSourcePropType
  >,
  LIGHTING_PATTERN_DICTIONARY: {
    [LumaAnimation.STEADY]: LightingPattern.STEADY,
    [LumaAnimation.BREATHING]: LightingPattern.BREATHING,
    [LumaAnimation.CIRCLING_SLOW]: LightingPattern.CIRCLING,
  } as Dictionary<LumaAnimation, LightingPattern>,
  LUMA_ANIMATION_DICTIONARY: {
    [LightingPattern.STEADY]: LumaAnimation.STEADY,
    [LightingPattern.BREATHING]: LumaAnimation.BREATHING,
    [LightingPattern.CIRCLING]: LumaAnimation.CIRCLING_SLOW,
  } as Dictionary<LightingPattern, LumaAnimation>,
  MIDDLEWARE_VALUE_BYTE_SIZE: {
    [MiddlewareValue.FLOAT]: 4,
    [MiddlewareValue.U_INT_8]: 1,
    [MiddlewareValue.U_INT_16]: 2,
    [MiddlewareValue.U_INT_32]: 4,
    [MiddlewareValue.INT_8]: 1,
    [MiddlewareValue.INT_32]: 4,
    [MiddlewareValue.ULID]: 16,
  } as Dictionary<MiddlewareValue, number>,
  /* 
    Product Codes and Model Codes are mixed together.
    For explanation see: https://www.notion.so/halcyonmobile/Peak-Product-Model-Types-e42daaa64d54485da3fb8036e49c4a38
  */
  PUFFCO_PRODUCTS: {
    '0': 'OG',
    '1': 'Opal',
    '2': 'Indiglow',
    '21': 'OG',
    '22': 'Opal',
    '25': 'Indiglow',
    '26': 'Guardian',
    '4': 'Guardian',
    '4294967295': 'OG',
    '12': 'Pearl',
    '13': 'Onyx',
    '51': 'OG',
    '71': 'Onyx',
    '72': 'Pearl',
    '15': 'Desert',
    '74': 'Desert',
    '17': 'Flourish',
    '75': 'Flourish',
    '19': 'Storm',
    '78': 'Storm',
    '79': 'Onyx',
    '80': 'Pearl',
  } as Dictionary<string, ProductName>,
};

export const POSSIBLE_XL_CHAMBER_KITS = ['12', '13', '71', '72', '79', '80'];
export const XL_CHAMBER_KITS = ['19', '78'];

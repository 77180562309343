import React from 'react';
import {
  Animated,
  Dimensions,
  Image,
  ImageSourcePropType,
  PanResponder,
  View,
  ViewStyle,
} from 'react-native';
import {useUpdateEffect} from 'react-use';

import {appColors} from '../constants';
import styled from '../lib/styled';

const thumbSize = 18;
const verticalOffset = 15;

export interface SliderProps {
  value: number;
  thumbTintColor: string;
  maximumTrackTintColor?: string;
  minimumTrackTintColor?: string;
  maximumValue: number;
  minimumValue: number;
  onValueChange(value: number): void;
  onSlidingComplete(percent: number): void;
  onAutoSlide?: (v: number) => number;
  trackImage?: ImageSourcePropType;
  containerWidth?: ViewStyle['width'];
  containerMarginLeft?: ViewStyle['marginLeft'];
  step?: number;
  nTickers?: number;
  enabled?: boolean;
  paddingHorizontal?: number;
}

export const Slider = (props: SliderProps) => {
  const pan = React.useRef(
    new Animated.ValueXY({x: 0, y: verticalOffset - thumbSize / 2}),
  );
  const {
    thumbTintColor,
    maximumTrackTintColor = appColors.gray,
    minimumTrackTintColor = appColors.white,
    minimumValue,
    maximumValue,
    onValueChange,
    trackImage,
    containerWidth,
    containerMarginLeft,
    step = 0.01,
    onSlidingComplete,
    value,
    nTickers,
    enabled = true,
    onAutoSlide,
    paddingHorizontal,
  } = props;

  const [componentWidth, setComponentWidth] = React.useState(0);
  const {width} = Dimensions.get('window');
  const leftOffset = (width - componentWidth + thumbSize) / 2;
  const [sliderPercent, setSliderPercent] = React.useState(0); //percentage of slider between 0 and 1

  const isUnderBounds = (coordinate: number) => {
    return coordinate - leftOffset - thumbSize / 2 < 0;
  };

  const isOverBounds = (coordinate: number) => {
    return coordinate - leftOffset - thumbSize / 2 > componentWidth;
  };

  const provideValue = (coordinate: number) => {
    const distance = maximumValue - minimumValue;
    const value =
      ((coordinate - leftOffset) / componentWidth) * distance + minimumValue;

    if (step === 1) {
      return Math.floor(value);
    } else {
      if (value > 1) {
        return 1;
      }
      return value;
    }
  };

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponderCapture: () => true,
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponderCapture: () => true,
    onPanResponderGrant: () => true,
    onPanResponderMove: (_, gestureState) => {
      if (enabled) {
        let gestureValue = gestureState.moveX;
        if (isUnderBounds(gestureState.moveX)) {
          gestureValue = leftOffset + thumbSize / 2;
        } else if (isOverBounds(gestureState.moveX)) {
          gestureValue = leftOffset + thumbSize / 2 + componentWidth;
        }
        const currentVal = (gestureValue - leftOffset) / componentWidth;
        setSliderPercent(
          step !== 1 && currentVal > 1
            ? 1
            : (gestureValue - leftOffset - thumbSize / 2) / componentWidth,
        );
        onValueChange(provideValue(gestureValue - thumbSize / 2));
        pan.current.setOffset({
          y: 0,
          x: gestureValue - leftOffset - thumbSize + (paddingHorizontal ?? 0),
        });
      }
    },
    onMoveShouldSetPanResponder: () => true,
    onPanResponderRelease: () => {
      onSlidingComplete(
        step === 1
          ? Math.round(
              sliderPercent * (maximumValue - minimumValue) + minimumValue,
            )
          : sliderPercent,
      );

      const p = onAutoSlide?.(
        step === 1
          ? Math.round(
              sliderPercent * (maximumValue - minimumValue) + minimumValue,
            )
          : sliderPercent,
      );

      if (p !== undefined) {
        const val = p * 0.01 * componentWidth - thumbSize / 2;
        pan.current.setOffset({
          y: 0,
          x: val + (paddingHorizontal ?? 0),
        });
        setSliderPercent(p * 0.01);
      }
    },
  });

  const panHandlers = (panResponder && panResponder.panHandlers) || {};

  useUpdateEffect(() => {
    if (componentWidth <= 0) return;

    const boundedValue =
      value < minimumValue
        ? minimumValue
        : value > maximumValue
          ? maximumValue
          : value;

    const percentage =
      (boundedValue - minimumValue) / (maximumValue - minimumValue);

    const startingLocation = Math.floor(
      percentage * componentWidth - thumbSize / 2,
    );

    pan.current.setOffset({
      y: 0,
      x: (paddingHorizontal ?? 0) + startingLocation,
    });

    setSliderPercent(percentage);
  }, [value, componentWidth, minimumValue, maximumValue, paddingHorizontal]);

  return (
    <SliderContainer
      {...panHandlers}
      style={{
        marginLeft: containerMarginLeft || 0,
        width: containerWidth || '85%',
        paddingHorizontal,
      }}
      onLayout={event => {
        const {width: widthTemp} = event.nativeEvent.layout;

        const width = paddingHorizontal
          ? widthTemp - paddingHorizontal * 2
          : widthTemp;

        setComponentWidth(width);
      }}>
      <Animated.View
        style={{
          ...pan.current.getLayout(),
          elevation: 3,
          shadowColor: appColors.black50,
          shadowOffset: {height: 3, width: 0},
          shadowRadius: 8,
          width: thumbSize,
          height: thumbSize,
          borderRadius: 45,
          backgroundColor: !enabled
            ? appColors.darkGreyMedium33
            : thumbTintColor,
          position: 'absolute',
          zIndex: 10,
        }}
      />
      {trackImage ? (
        <Image source={trackImage} style={{width: '100%', height: 2}} />
      ) : (
        <SliderTrackContainer
          style={{
            width: (paddingHorizontal ?? 0) * 2 + componentWidth,
          }}>
          {nTickers && (
            <TickerContainer>
              {Array.from({length: nTickers}).map((_, index) => (
                <Ticker key={index} />
              ))}
            </TickerContainer>
          )}

          <SliderTrackLeft
            style={{
              width: `${sliderPercent * 100}%`,
              backgroundColor: !enabled
                ? appColors.darkGreyMedium33
                : minimumTrackTintColor,
            }}
          />

          <SliderTrackRight
            style={{
              width: `${(1 - sliderPercent) * 100}%`,
              backgroundColor: !enabled
                ? appColors.darkGreyMedium33
                : maximumTrackTintColor,
            }}
          />
        </SliderTrackContainer>
      )}
    </SliderContainer>
  );
};

const SliderContainer = styled(View)({
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  height: verticalOffset * 2,
});

const SliderTrackContainer = styled(View)({
  height: 2,
  width: '100%',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
});

const TickerContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  position: 'absolute',
});

const Ticker = styled(View)({
  height: 7,
  width: 2,
  backgroundColor: appColors.white50,
});

const SliderTrackLeft = styled(View)({
  height: 2,
});

const SliderTrackRight = styled(View)({
  height: 2,
});

export interface AsyncWrapperOptions<T> {
  onSuccess?(data: T): void;
  onError?(error: Error): void;
}

export const withAsyncWrapper = <A extends any[], T>(
  callback: (...args: A) => Promise<T>,
  {onSuccess, onError}: AsyncWrapperOptions<T>,
) => {
  return async (...args: A) => {
    try {
      const data = await callback(...args);
      onSuccess?.(data);
      return data;
    } catch (error) {
      onError?.(error as Error);
      throw error;
    }
  };
};

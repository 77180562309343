import React from 'react';
import {Animated} from 'react-native';
import {Easing} from 'react-native-reanimated';

export function useAnim(): [
  Animated.Value,
  (maxValue: number, duration?: number, native?: boolean) => void,
  boolean,
  boolean,
  () => void,
] {
  const anim = React.useRef(new Animated.Value(0)).current;
  const [isAnimationDone, setIsAnimationDone] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const startAnim = (maxValue: number, duration?: number, native?: boolean) => {
    anim.removeAllListeners();

    anim.addListener(({value}) => {
      if (value === maxValue) setIsAnimationDone(true);
      else {
        setIsLoading(true);
        setIsAnimationDone(false);
      }
    });

    Animated.timing(anim, {
      toValue: maxValue,
      useNativeDriver: native ?? false,
      easing: Easing.linear,
      duration: duration ?? 50,
    }).start();
  };

  const end = () => {
    anim.setValue(0);
    anim.removeAllListeners();
    setIsAnimationDone(false);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (isAnimationDone) setIsLoading(false);
  }, [isAnimationDone]);

  return [anim, startAnim, isAnimationDone, isLoading, end];
}

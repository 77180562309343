import type {Asset, Entry, EntryFields} from 'contentful';

import type {TypeDeviceDocumentFields} from './TypeDeviceDocument';
import type {TypeGuideFields} from './TypeGuide';
import type {TypeTagFields} from './TypeTag';
import type {TypeVideoFields} from './TypeVideo';
import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeDeviceFields {
  name: EntryFields.Symbol;
  order?: EntryFields.Integer;
  description: EntryFields.RichText;
  thumbnailImage: Asset;
  backgroundImage: Asset;
  videos?: Entry<TypeVideoFields>[];
  documents?: Entry<TypeDeviceDocumentFields>[];
  guides?: Entry<TypeGuideFields>[];
  tags?: Entry<TypeTagFields>[];
  manual?: Asset;
}

export type TypeDevice = Entry<TypeDeviceFields>;

export function isTypeDevice(entry: WithContentTypeLink): entry is TypeDevice {
  return entry?.sys?.contentType?.sys?.id === 'device';
}

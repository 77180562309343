import React from 'react';
import {
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import {IconName, StyledIcon} from '.';
import styled from '../lib/styled';
import {useTheme} from '../lib/useTheme';

export enum BottomSheetButtonTitle {
  EDIT = 'EDIT',
  SHARE = 'SHARE',
  DELETE = 'DELETE',
  DELETE_ACCOUNT = 'DELETE ACCOUNT',
  DUPLICATE = 'DUPLICATE',
  ASSIGN = 'ASSIGN',
  DOWNLOAD = 'DOWNLOAD',
  LOGOUT = 'LOG OUT',
  VERIFY_EMAIL = 'VERIFY EMAIL',
}

export type BottomSheetButtonProps = {
  title: BottomSheetButtonTitle;
  iconName: IconName;
  size?: number;
  iconViewStyle?: ViewStyle;
} & ViewProps &
  Pick<TouchableOpacityProps, 'onPress'>;

export const BottomSheetButton: React.FC<BottomSheetButtonProps> = ({
  title,
  iconName,
  size,
  iconViewStyle,
  onPress,
  ...rest
}) => {
  const {iconColor, buttonTextColor} = useTheme().bottomSheetTheme;
  return (
    <TouchableOpacity onPress={onPress}>
      <BottomSheetButtonContainer {...rest}>
        <Title style={{color: buttonTextColor}}>{title}</Title>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: '100%',
          }}>
          <StyledIcon
            name={iconName}
            style={{
              padding: 0,
              width: size,
              height: size,
              ...iconViewStyle,
            }}
            iconStyle={{width: size, height: size, tintColor: iconColor}}
          />
        </View>
      </BottomSheetButtonContainer>
    </TouchableOpacity>
  );
};

const BottomSheetButtonContainer = styled(View)({
  height: 49,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingHorizontal: 30,
});

const Title = styled(Text)({
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  letterSpacing: 0.75,
  fontWeight: '400',
});

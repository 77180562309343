import {ConnectedDevice as PikaparamDevice} from 'pikaparam';

import {ConnectablePeripheral} from '../ble2/v2/BleManager/BleManagerBase';
import {ConnectedDevice} from '../ble2/v2/ConnectedDevice';
import {OtaDevice} from '../ble2/v2/OtaDevice';
import {IPeakDevice} from '../ble2/v2/PeakDevice/IPeakDevice';
import {Device} from '../types';

export type AnyDevice =
  | ConnectedDevice<ConnectablePeripheral>
  | ConnectedDevice<PikaparamDevice>
  | IPeakDevice
  | OtaDevice
  | Device;

const isConnectableDevice = (
  device: AnyDevice,
): device is ConnectedDevice<ConnectablePeripheral> => 'device' in device;

const isPikaparamDevice = (
  device: AnyDevice,
): device is ConnectedDevice<PikaparamDevice> =>
  isConnectableDevice(device) && 'writeCharWithResponse' in device.device;

const isPeakDevice = (device: AnyDevice): device is IPeakDevice =>
  'firmwareType' in device;

export const serializeDevice = (device: AnyDevice) => {
  if (isPeakDevice(device)) return serializePeak(device);
  if (device instanceof OtaDevice) return serializeOtaDevice(device);
  if (isPikaparamDevice(device)) return serializePikaparamDevice(device);
  if (isConnectableDevice(device)) return serializeConnectableDevice(device);
  return serializeReduxDevice(device);
};

const serializeConnectableDevice = ({
  device,
  metadata,
}: ConnectedDevice<ConnectablePeripheral>) => ({
  peripheralId: device.id,
  name: device.name,
  serialNumber: device.serialNumber,
  rssi: metadata.rssi,
});

const serializePikaparamDevice = ({
  device,
  metadata,
}: ConnectedDevice<PikaparamDevice>) => ({
  peripheralId: device.id,
  name: device.name,
  state: device.state,
  rssi: metadata.rssi,
});

const serializePeak = (device: IPeakDevice) => ({
  peripheralId: device.peripheralId,
  name: device.name,
  serialNumber: device.serialNumber,
  model: device.modelNumber,
  firmware: device.softwareRevision,
  type: device.firmwareType,
  githash: device.gitHash,
  product: device.product,
  connected: device.connected,
  rssi: device.metadata?.rssi,
});

const serializeOtaDevice = (device: OtaDevice) => ({
  peripheralId: device.peripheralId,
  deviceState: device.deviceState,
  flashState: device.flashState,
  serialNumber: device.serialNumber,
  rssi: device.metadata?.rssi,
});

const serializeReduxDevice = (device: Device) => ({
  peripheralId: device.id,
  name: device.name,
  serialNumber: device.serialNumberString,
  model: device.modelNumberString,
  firmware: device.softwareRevisionString,
  githash: device.gitHashString,
});

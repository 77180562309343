import React from 'react';
import {View} from 'react-native';

import {appColors} from '../constants';
import {MultiEmitter} from '../lib/MultiEmitter';
import styled from '../lib/styled';
import {EmitterEvent} from '../lib/types';
import {BackHandler} from '../shims/BackHandler';

export type ModalProps = {
  element: JSX.Element;
  onClose?: () => void;
};

export const modalEmitter = new MultiEmitter<ModalProps>();

export const Modal = {
  display: (element: ModalProps) => modalEmitter.emit(element),
  close: () => modalEmitter.emit(EmitterEvent.CLOSE),
  closeAll: () => modalEmitter.emit(EmitterEvent.CLOSE_ALL),
};

export const modalBackPress = () => {
  Modal.close();
  return true;
};

const ModalContext = React.createContext({
  modalQueueLength: 0,
});

type ModalProviderProps = {
  children: React.ReactNode;
};
export const ModalProvider = ({children}: ModalProviderProps) => {
  const [modalQueue, setModalQueue] = React.useState<ModalProps[]>([]);

  React.useEffect(() => {
    const modalEventHandler = (modal: ModalProps | EmitterEvent) => {
      setModalQueue(modalQueue => {
        switch (modal) {
          case EmitterEvent.CLOSE: {
            if (modalQueue.length > 0) {
              const {onClose} = modalQueue[0];
              onClose && onClose();
              if (modalQueue.length === 1) {
                BackHandler.removeEventListener(
                  'hardwareBackPress',
                  modalBackPress,
                );
              }
              return modalQueue.slice(1);
            } else {
              return [];
            }
          }
          case EmitterEvent.CLOSE_ALL: {
            modalQueue.forEach(modal => {
              const {onClose} = modal;
              onClose && onClose();
            });
            modalQueue.length > 0 &&
              BackHandler.removeEventListener(
                'hardwareBackPress',
                modalBackPress,
              );
            return [];
          }
          default: {
            modalQueue.length === 0 &&
              BackHandler.addEventListener('hardwareBackPress', modalBackPress);
            return [...modalQueue, modal];
          }
        }
      });
    };
    modalEmitter.subscribe(modalEventHandler);

    return () => modalEmitter.unsubscribe(modalEventHandler);
  }, []);

  return (
    <ModalContext.Provider value={{modalQueueLength: modalQueue.length}}>
      {children}
      {modalQueue.length > 0 ? (
        <Container>
          <ScreenBlocker onTouchEnd={Modal.close} />
          {modalQueue[0].element}
        </Container>
      ) : null}
    </ModalContext.Provider>
  );
};

const Container = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const ScreenBlocker = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: appColors.alertDisabledBackground,
});

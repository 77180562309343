import React from 'react';
import {StyleSheet, Text, TextProps, View} from 'react-native';

import {appColors} from '../../constants';
import styled from '../../lib/styled';

export const SliderContainer = styled(View)({
  gap: 8,
});

export const SliderValues = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  zIndex: -1,
});

export const SliderTick = styled(View)({
  marginTop: -8,
  marginBottom: -7,
  width: 2,
  height: 7,
  marginHorizontal: 'auto',
  backgroundColor: appColors.white20,
  zIndex: -1,
});

interface SliderValueProps extends TextProps {
  highlighted?: boolean;
}

export const SliderValue: React.FC<SliderValueProps> = ({
  highlighted,
  ...props
}) => (
  <Text
    {...props}
    style={[styles.value, highlighted ? styles.highlighted : undefined]}
  />
);

const styles = StyleSheet.create({
  value: {
    color: appColors.white50,
  },
  highlighted: {
    color: appColors.white,
  },
});

import React from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';

import {StyledIcon} from '../../components';
import {Constants, Screens, Strings, appColors} from '../../constants';
import {useBackPress} from '../../lib/hooks';
import {useHasLed3Api} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import styled from '../../lib/styled';
import {MoodType} from '../../lib/types';
import {MoodLightControllerNavigatorScreenProps} from '../../navigation/navigators/MainStackNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';

const {MOOD_TYPES, ADVANCED_MOOD_TYPES, MOOD_TYPE_AND_VALUE} = Constants;

interface Props
  extends MoodLightControllerNavigatorScreenProps<
    typeof Screens.MoodLightAnimation
  > {}

export const MoodLightAnimationScreen = ({route, navigation}: Props) => {
  useAdaptiveSafeArea();

  const {initialAnimationValue, onAnimationValueChange} = route.params ?? {};

  const [animation, setAnimation] = React.useState<MoodType>(
    initialAnimationValue,
  );

  const hasLed3Api = useHasLed3Api();

  React.useEffect(() => {
    setAnimation(initialAnimationValue);
  }, [initialAnimationValue]);

  React.useEffect(() => {
    onAnimationValueChange(animation);
  }, [onAnimationValueChange, animation]);

  const onBackPress = React.useCallback(() => {
    navigation.goBack();

    return true;
  }, [navigation]);

  useBackPress(onBackPress);

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'black'}}>
      <ScrollView
        style={{flex: 1, marginTop: 64, marginBottom: 14}}
        contentContainerStyle={{
          height: Constants.IS_WEB ? 0 : undefined,
        }}>
        <SectionHeaderContainer>
          <SectionHeaderText>{Strings.BASIC}</SectionHeaderText>
        </SectionHeaderContainer>

        <SectionContainer>
          {MOOD_TYPES.map((name, i) => {
            const moodValue =
              MOOD_TYPE_AND_VALUE[name as keyof typeof MOOD_TYPE_AND_VALUE];
            return (
              <SectionItemTouchable
                key={moodValue}
                onPress={() => {
                  setAnimation(moodValue);
                }}
                style={
                  i === MOOD_TYPES.length - 1
                    ? {borderBottomWidth: 0}
                    : undefined
                }>
                <SectionHeader>{name}</SectionHeader>
                {((!!animation && Number(animation) === moodValue) ||
                  (!animation && moodValue === 0)) && (
                  <FlexRowContainer>
                    <StyledIcon
                      name={'check'}
                      color={appColors.readyGreen}
                      size={16}
                      style={{alignSelf: 'center'}}
                    />
                  </FlexRowContainer>
                )}
              </SectionItemTouchable>
            );
          })}
        </SectionContainer>

        <SectionHeaderContainer style={{marginTop: 35}}>
          <SectionHeaderText>{Strings.ADVANCED}</SectionHeaderText>
        </SectionHeaderContainer>

        <SectionContainer>
          {ADVANCED_MOOD_TYPES.map((name, i) => {
            const moodValue =
              MOOD_TYPE_AND_VALUE[name as keyof typeof MOOD_TYPE_AND_VALUE];
            return (
              <SectionItemTouchable
                key={moodValue}
                onPress={() => hasLed3Api && setAnimation(moodValue)}
                style={
                  i === ADVANCED_MOOD_TYPES.length - 1
                    ? {borderBottomWidth: 0}
                    : undefined
                }
                disabled={!hasLed3Api}>
                <SectionHeader
                  style={!hasLed3Api && {color: appColors.white50}}>
                  {name}
                </SectionHeader>
                {((!!animation && Number(animation) === moodValue) ||
                  (!animation && moodValue === 0)) && (
                  <FlexRowContainer>
                    <StyledIcon
                      name={'check'}
                      color={appColors.readyGreen}
                      size={16}
                      style={{alignSelf: 'center'}}
                    />
                  </FlexRowContainer>
                )}
              </SectionItemTouchable>
            );
          })}
        </SectionContainer>
      </ScrollView>
    </SafeAreaView>
  );
};

const SectionHeaderText = styled(Text)({
  color: appColors.white,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '500',
  marginLeft: 24,
  lineHeight: 16,
  textTransform: 'uppercase',
  letterSpacing: 0.02,
});

const SectionHeaderContainer = styled(View)({
  marginBottom: 8,
});

const SectionContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 8,
  backgroundColor: appColors.animationSettingsContainer,
  marginHorizontal: 24,
  borderRadius: 8,
});

const SectionItemTouchable = styled(TouchableOpacity)({
  borderBottomWidth: 1,
  borderColor: appColors.white10,
  flexDirection: 'row',
  height: 51,
  justifyContent: 'space-between',
  paddingLeft: 16,
});

const SectionHeader = styled(Text)({
  alignSelf: 'center',
  color: appColors.baseText,
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  lineHeight: 16,
  textAlign: 'left',
  fontWeight: '400',
});

const FlexRowContainer = styled(View)({
  flexDirection: 'row',
  paddingRight: 8,
});

import {NavigationProp, useNavigation} from '@react-navigation/core';
import React from 'react';

import {Strings} from '../../../../constants';
import {GalleryType, StrictTypeGallery} from '../../../../lib/api';
import {TypePage} from '../../../../lib/api/content-access/types';
import {SupportItemSection} from '../SupportItemSection';
import {Carousel} from './Carousel';
import {ContestGallery} from './ContestGallery';
import {DefaultGallery} from './DefaultGallery';

interface Props {
  gallery: StrictTypeGallery;
}

interface Navigation extends NavigationProp<Record<string, undefined>> {}

export const Gallery = ({gallery}: Props) => {
  const navigation = useNavigation<Navigation>();

  const items = gallery.fields.elements;

  if (!items) return null;

  if (gallery.fields.type === GalleryType.CAROUSEL)
    return <Carousel {...{items}} title={gallery.fields.displayName} />;

  if (gallery.fields.type === GalleryType.DEFAULT)
    return <DefaultGallery {...{gallery}} />;

  if (gallery.fields.type === GalleryType.SUPPORT_ITEM) {
    const pages = gallery.fields.elements as TypePage[];

    return (
      <SupportItemSection
        title={gallery.fields.displayName ?? Strings.ADDITIONAL_INFORMATION}
        items={[
          ...(pages ?? []).map(page => {
            return {
              title: page.fields.title,
              onPress: () => {
                navigation.navigate(page.fields.screenName);
              },
            };
          }),
        ]}
      />
    );
  }

  if (gallery.fields.type === GalleryType.CONTEST)
    return <ContestGallery {...{gallery}} />;

  return null;
};

export enum PermissionError {
  LocationDisabled = 'Location is disabled.',
  LocationCanceled = 'Location request is canceled.',
  LocationDismissed = 'Location request is dismissed.',
  LocationRequiresAction = 'Location request requires action.',
  BluetoothDisabled = 'Bluetooth is disabled.',
  BluetoothDenied = 'Bluetooth request is denied.',
  BluetoothCanceled = 'Bluetooth request is canceled.',
  BluetoothDismissed = 'Bluetooth request is dismissed.',
  BluetoothRequiresAction = 'Bluetooth request requires action.',
  PushDenied = 'Push request is denied.',
}

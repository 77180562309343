import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import * as Yup from 'yup';

import {
  StyledField,
  displayRightIconMessage,
  getRightIconName,
} from '../../components';
import {Constants, ErrorMessages, Screens, appColors} from '../../constants';
import {updateUser} from '../../lib/api/User.hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import styled from '../../lib/styled';
import {AccountNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Alert} from '../../shims/alert';

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(ErrorMessages.REQUIRED_PASSWORD),
  newPassword: Yup.string()
    .required(ErrorMessages.REQUIRED_PASSWORD)
    .matches(
      Constants.VALID_PASSWORD_REGEX,
      ErrorMessages.PASSWORD_FORMAT_ERROR,
    )
    .min(8, ErrorMessages.PASSWORD_FORMAT_ERROR),
});

interface ChangPasswordValues {
  currentPassword: string;
  newPassword: string;
}

interface Props
  extends AccountNavigatorScreenProps<typeof Screens.ChangePassword> {}

export const ChangePasswordScreen = ({navigation}: Props) => {
  useAdaptiveSafeArea();

  const handleSave = async (
    values: ChangPasswordValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);

    await updateUser(
      {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      },
      ErrorMessages.INVALID_PASSWORD_CREDENTIALS,
    );

    Alert.alert('Success', 'Your account password has been changed!');

    navigation.goBack();

    actions.setSubmitting(false);
  };

  return (
    <Container>
      <Formik
        initialValues={{currentPassword: '', newPassword: ''}}
        onSubmit={handleSave}
        validationSchema={ChangePasswordSchema}>
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          submitForm,
          values,
          errors,
          touched,
        }) => (
          <View style={{alignItems: 'center'}}>
            <ChangePasswordContainer>
              <FieldLabel>CURRENT PASSWORD</FieldLabel>
              <StyledField
                name={'lock'}
                borderColor={appColors.mediumGray}
                height={35}
                leftIconColor={appColors.mediumGray}
                onChangeText={handleChange('currentPassword')}
                onBlur={handleBlur('currentPassword')}
                password={true}
                value={values.currentPassword}
                textColor={appColors.white}
              />
              <FieldLabel style={{marginTop: 20}}>NEW PASSWORD</FieldLabel>
              <StyledField
                name={'lock'}
                borderColor={appColors.mediumGray}
                height={35}
                leftIconColor={appColors.mediumGray}
                onChangeText={handleChange('newPassword')}
                onBlur={handleBlur('newPassword')}
                password={true}
                value={values.newPassword}
                rightIcon={getRightIconName(
                  values.newPassword,
                  errors.newPassword,
                  touched.newPassword,
                )}
                rightIconColor={
                  errors.newPassword ? appColors.red : appColors.green
                }
                rightIconMessage={displayRightIconMessage(
                  values.newPassword,
                  errors.newPassword,
                  touched.newPassword,
                )}
                textColor={appColors.white}
              />
            </ChangePasswordContainer>
            <ButtonContainer>
              <Button disabled={!isValid || isSubmitting} onPress={submitForm}>
                <ButtonText>SAVE</ButtonText>
              </Button>
            </ButtonContainer>
          </View>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled(SafeAreaView)({
  alignItems: 'center',
  backgroundColor: '#333333',
  flex: 1,
  justifyContent: 'center',
});

const ChangePasswordContainer = styled(View)({
  width: 250,
});

const FieldLabel = styled(Text)({
  color: appColors.white,
  fontFamily: 'Roboto-Bold',
  fontSize: 16,
  fontWeight: '400',
});

const ButtonContainer = styled(View)({
  marginTop: 20,
});

const Button = styled(TouchableOpacity)({
  backgroundColor: appColors.mediumGray,
  borderRadius: 20,
  paddingHorizontal: 30,
  paddingVertical: 8,
});

const ButtonText = styled(Text)({
  color: appColors.white,
  fontFamily: 'Roboto-Medium',
  fontSize: 12,
  fontWeight: '400',
});

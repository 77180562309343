import {IPeakProduct} from '../../../lib/ble2/v2/PeakDevice/IPeakDevice';
import {ChamberType, Vapor} from '../../../lib/types';

const chambers = {
  [ChamberType.Classic]: 'og',
  [ChamberType.Performance]: '3d',
  [ChamberType.XL]: 'xl',
} as const;

export const convertChamberType = (chamberType: ChamberType) => {
  if (chamberType === ChamberType.None) return;

  return chambers[chamberType];
};

const vapors = {
  [Vapor.Standard]: 'standard',
  [Vapor.High]: 'high',
  [Vapor.Max]: 'max',
  [Vapor.Xl]: 'xl',
} as const;

export const convertVaporMode = (vaporMode?: Vapor) => {
  if (vaporMode === undefined) return;

  return vapors[vaporMode];
};

export const convertProduct = (
  product: Pick<IPeakProduct, 'type' | 'name'>,
) => {
  return `${product.type},${product.name}`;
};

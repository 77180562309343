export const base64ToFile = (
  base64: string,
  mime: string,
  filename: string,
) => {
  return new Promise<File>(resolve => {
    const bstr = atob(base64);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    resolve(
      new File([u8arr], filename, {
        type: mime,
      }),
    );
  });
};

import {Profile, Vapor, isPreTHeatProfile} from '../types';
import {useChamberType} from './useChamberType';

export const useGetVaporSetting = (profile?: Profile) => {
  const {isXLChamber} = useChamberType();

  if (!profile || isPreTHeatProfile(profile)) return Vapor.Standard;

  const {vaporSetting} = profile;
  if (!vaporSetting) return Vapor.Standard;

  if (vaporSetting === 1.5 && isXLChamber) return Vapor.Xl;
  if (vaporSetting === 1.5 && !isXLChamber) return Vapor.Max;
  if (vaporSetting >= 1.0) return Vapor.Max;
  if (vaporSetting >= 0.5) return Vapor.High;
  return Vapor.Standard;
};

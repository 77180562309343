import {TimeFilterPeriod} from 'puffco-api-axios-client';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';

import {appColors} from '../../../../../constants';
import styled from '../../../../../lib/styled';

interface TimeFilterRowProps {
  timeFilter: TimeFilterPeriod;
  safeAreaBottom?: number;
  setTimeFilter?: (val: TimeFilterPeriod) => void;
}

const timeFilterList: TimeFilterPeriod[] = Object.values(TimeFilterPeriod);

export const TimeFilterRow: React.FC<TimeFilterRowProps> = React.memo(
  function TimeFilterRow({timeFilter, safeAreaBottom, setTimeFilter}) {
    return (
      <TimeFilterContainerWrapper
        style={{
          height: safeAreaBottom ? safeAreaBottom + 70 : 70,
          paddingBottom: safeAreaBottom ?? 0,
        }}>
        <TimeFilterContainer>
          {timeFilterList.map(item => (
            <TimeFilter
              key={`timeFilter-${item}`}
              style={{
                backgroundColor: item === timeFilter ? 'black' : 'transparent',
              }}
              onPress={() => setTimeFilter?.(item as TimeFilterPeriod)}>
              {item === timeFilter ? (
                <SelectedTimeFilterText>
                  {item.toUpperCase()}
                </SelectedTimeFilterText>
              ) : (
                <TimeFilterText>{item.toUpperCase()}</TimeFilterText>
              )}
            </TimeFilter>
          ))}
        </TimeFilterContainer>
      </TimeFilterContainerWrapper>
    );
  },
);

const TimeFilterContainerWrapper = styled(View)({
  backgroundColor: '#1C1C1C',
  justifyContent: 'center',
});

const TimeFilterContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignContent: 'center',
});

const TimeFilter = styled(TouchableOpacity)({
  height: 46,
  width: 60,
  backgroundColor: 'black',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
});

const TimeFilterText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 14,
  color: appColors.baseText,
});

const SelectedTimeFilterText = styled(Text)({
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 14,
  color: appColors.baseText,
});

import {
  PreTHeatProfileSnapshot,
  ProfileVersion,
  THeatProfileSnapshot,
} from 'puffco-api-axios-client';

export type ProfileSnapshot = PreTHeatProfileSnapshot | THeatProfileSnapshot;

export const isPreTHeatProfileSnapshot = (
  profile: ProfileSnapshot,
): profile is PreTHeatProfileSnapshot =>
  profile.version === ProfileVersion.PreT;

export const isTHeatProfileSnapshot = (
  profile: ProfileSnapshot,
): profile is THeatProfileSnapshot => profile.version === ProfileVersion.T;

import React from 'react';

import {bluetoothStartPairing} from '../../assets/images';
import {
  Constants,
  Messages,
  Screens,
  Strings,
  interpolate,
} from '../../constants';
import {useWindowHeight} from '../../lib/hooks';
import {MainNavigatorScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {ImageAndContentContainer} from '../components';

interface Props
  extends MainNavigatorScreenProps<typeof Screens.BluetoothStartPairing> {}

export const BluetoothStartPairingScreen = ({navigation, route}: Props) => {
  const {isSmall} = useWindowHeight();

  const redirect = route.params?.redirect;

  return (
    <ImageAndContentContainer
      image={bluetoothStartPairing}
      header={{content: Messages.START_PAIRING_TITLE}}
      body={{
        content: interpolate(Messages.START_PAIRING_MSG, {
          appName: Constants.IS_WEB
            ? Strings.PATH_BROWSER
            : Strings.PUFFCO_CONNECT,
        }),
        style: {textAlign: 'left'},
      }}
      imageContainerStyle={{maxHeight: isSmall ? undefined : 502}}
      bodyContainerStyle={{flex: isSmall ? undefined : 0.5}}
      primaryButton={{
        title: Strings.START_PAIRING,
        onClick: () => {
          navigation.navigate(Screens.PairInstructions, {redirect});
        },
      }}
    />
  );
};

import React from 'react';
import {ColorValue, Text, TouchableOpacity, ViewStyle} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';
import {StyledIcon} from './StyledIcon';

interface StyledCheckBoxProps {
  checked: boolean;
  onChange(value: boolean): void;
  disabled?: boolean;
  checkboxColor?: ColorValue;
  style?: ViewStyle;
}

export const StyledCheckBox: React.FC<
  React.PropsWithChildren<StyledCheckBoxProps>
> = ({checked, disabled, style, checkboxColor, children, onChange}) => (
  <Container
    {...{style, disabled}}
    activeOpacity={0.6}
    onPress={() => onChange?.(!checked)}>
    <CheckIcon
      name="check"
      style={{
        borderColor: checked
          ? appColors.lightMediumGray
          : appColors.transparent,
        borderWidth: checked ? 0 : 1,
        backgroundColor: checked
          ? checkboxColor || appColors.readyGreen
          : appColors.white,
      }}
      color={!checked ? 'transparent' : appColors.white}
      size={12}
    />

    {children}
  </Container>
);

const Container = styled(TouchableOpacity)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  width: '100%',
  minHeight: 28,
  gap: 8,
});

const CheckIcon = styled(StyledIcon)({
  padding: 0,
  height: 20,
  width: 20,
  borderRadius: 4,
  borderWidth: 1,
});

export const CheckBoxContent = styled(Text)({
  color: appColors.gray,
  lineHeight: 17,
  flex: 1,
  fontSize: 12,
  fontFamily: 'Roboto-Regular',
  letterSpacing: 0.37,
  fontWeight: '400',
});

class BackgroundTimer {
  setTimeout(action: () => void, time: number) {
    const timerId = setTimeout(() => action(), time);
    return timerId;
  }

  clearTimeout(timerId) {
    clearTimeout(timerId);
  }
}

export const BackgroundTimerTask = new BackgroundTimer();

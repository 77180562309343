import React from 'react';
import {ImageSourcePropType, View} from 'react-native';

import styled from '../lib/styled';
import {useTheme} from '../lib/useTheme';
import {Image} from '../shims/ImageWithFilter';

export type LanternTypeButtonProps = {
  onIconSource?: ImageSourcePropType;
  isActive: boolean;
  iconWidth?: number;
  iconHeight?: number;
  onPress: () => void;
};

export const LanternTypeButton = (props: LanternTypeButtonProps) => {
  const {
    activeBackground,
    inactiveBackground,
    activeForeground,
    inactiveForeground,
  } = useTheme().lanternTypeButtonTheme;

  const {onIconSource, isActive, onPress, iconWidth, iconHeight} = props;
  return (
    <Container
      style={{
        backgroundColor: isActive ? activeBackground : inactiveBackground,
      }}
      onTouchEnd={() => {
        onPress();
      }}>
      {onIconSource && (
        <Image
          source={onIconSource}
          style={{
            width: iconWidth,
            height: iconHeight,
            alignSelf: 'center',
            tintColor: isActive ? activeForeground : inactiveForeground,
          }}
        />
      )}
    </Container>
  );
};

const Container = styled(View)({
  display: 'flex',
  height: 60,
  width: 60,
  borderRadius: 30,
  justifyContent: 'center',
});

import React from 'react';
import {View} from 'react-native';

import {SupportCard} from '../../components';
import {ImgBackground} from '../../components/ImgBackground';
import {Screens} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {navigateToWebsite} from '../../lib/navigateToWebsite';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.HowToVideos
>;

export const HowToVideosScreen = (_props: ScreenProps) => {
  useAdaptiveSafeArea();

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <MainContainer>
          <SupportCard
            text={'HOW-TO-USE'}
            onPress={() => navigateToWebsite('pages/support')}
          />
          <SupportCard
            text={'HOW-TO-CLEAN'}
            onPress={() => navigateToWebsite('pages/support')}
          />
          <SupportCard
            text={'HOW-TO-PAIR'}
            onPress={() => navigateToWebsite('pages/support')}
          />
          <Spacer />
        </MainContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const MainContainer = styled(View)({
  flex: 1,
  paddingHorizontal: 17,
  flexDirection: 'column',
  marginVertical: 50,
  justifyContent: 'flex-start',
});

const Spacer = styled(View)({
  flex: 1,
});

import {VideoProps as ExpoVidProps} from 'expo-av';
import omit from 'lodash/omit';
import React from 'react';
import {ViewProps} from 'react-native';

export type VideoProps = Omit<ExpoVidProps, keyof ViewProps> &
  Omit<VideoHTMLAttributes<HTMLVideoElement>, 'src' | 'autoPlay' | 'loop'> &
  ClassAttributes<HTMLVideoElement>;

export const Video = (props: VideoProps) => {
  const omitProps = omit(props, ['useNativeControls']); // TODO: Omit all unnecessary ExpoVidProps
  const {source, resizeMode, shouldPlay, isLooping, style, ...rest} = omitProps;

  return (
    <video
      {...(typeof source !== 'number' && {src: source.uri})}
      playsInline={true}
      autoPlay={shouldPlay}
      loop={isLooping}
      style={{
        ...style,
        ...(resizeMode === 'cover' && {objectFit: 'cover'}),
      }}
      {...rest}
    />
  );
};

import React from 'react';
import {useSelector} from 'react-redux';

import {Constants, ErrorMessages} from '../constants';
import {Connection} from '../contexts/useConnection';
import {checkStringBytes} from '../lib/checkStringBytes';
import {getIsPup} from '../lib/getIsPup';
import {useAppDispatch} from '../lib/hooks';
import {currentDeviceSelector} from '../lib/redux/bleSlice';
import {bleWriteDeviceName} from '../lib/redux/thunk';
import {AlertPrompt} from './AlertPrompt';
import {Modal} from './Modal';

export const PeakNameAlertPrompt = () => {
  const device = useSelector(currentDeviceSelector);
  const dispatch = useAppDispatch();

  const {peak} = Connection.useContainer();
  const connected = !!peak;

  const isPup = getIsPup(connected);

  const isValidName = (name: string) => {
    const trimmedName = name?.trim() || '';
    return (
      trimmedName?.length > 0 && checkStringBytes(trimmedName, 'device', isPup)
    );
  };

  const [peakName, setPeakName] = React.useState(device?.name || '');
  const [nameError, setNameError] = React.useState(false);

  const saveNewPeakName = () => {
    if (!nameError && peakName !== '') {
      Modal.close();
      if (peakName) dispatch(bleWriteDeviceName({name: peakName}));
    } else if (peakName === '') {
      setNameError(true);
    }
  };

  const errorMessage = () => {
    if (!connected) {
      return ErrorMessages.DEVICE_NAME_DISCONNECTED;
    } else if (nameError) {
      const trimmedName = peakName?.trim() || '';
      return trimmedName.length === 0
        ? ErrorMessages.PROFILE_NAME_EMPTY
        : ErrorMessages.PROFILE_NAME_LONG;
    }
    return undefined;
  };

  return (
    <AlertPrompt
      visible={true}
      title={'Change Peak Name'}
      description={'Enter new name'}
      btn1Label={'Cancel'}
      onPress1={() => {
        Modal.close();
        setNameError(false);
        setPeakName(device?.name || '');
      }}
      btn2Label={'OK'}
      onPress2={() => {
        if (connected && isValidName(peakName)) {
          setPeakName(name => name?.trim() || '');
          saveNewPeakName();
        } else {
          setNameError(true);
        }
      }}
      value={peakName}
      placeholder={'Peak Name'}
      maxLength={
        isPup
          ? Constants.PEAK_NAME_PUP_MAX_LENGTH
          : Constants.PEAK_NAME_MAX_LENGTH
      }
      error={!connected || nameError}
      errorMsg={errorMessage()}
      onChangeText={value => {
        setPeakName(value);
        connected && isValidName(value) && setNameError(false);
      }}
    />
  );
};

import React from 'react';
import {LayoutChangeEvent, LayoutRectangle, View} from 'react-native';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import {CarouselProps} from './Carousel';

export const Carousel = (props: CarouselProps) => {
  const {renderItem, data, loop, onSnapToItem, currentParentIndex, height} =
    props;
  const ref = React.useRef(null);
  const [layout, setLayout] = React.useState<LayoutRectangle>();
  const [localInd, setLocalInd] = React.useState(currentParentIndex);

  React.useEffect(() => {
    if (currentParentIndex !== localInd) {
      ref && ref.current.slickGoTo && ref.current.slickGoTo(currentParentIndex);
      setLocalInd(currentParentIndex);
    }
  }, [currentParentIndex]);

  return (
    <View
      style={{width: '100%', height: height}}
      onLayout={(event: LayoutChangeEvent) =>
        setLayout(event.nativeEvent.layout)
      }>
      <Slider
        ref={ref}
        initialSlide={currentParentIndex}
        infinite={loop}
        afterChange={index => {
          setLocalInd(index);
          onSnapToItem(index);
        }}>
        {data.map(item =>
          renderItem({
            ...item,
            height: height,
            width: layout?.width,
          }),
        )}
      </Slider>
    </View>
  );
};

import {Constants} from '../../constants';

const {UNIT_CONVERSION} = Constants;
const {HOURS_TO_MINUTES, MINUTES_TO_SECONDS, SECONDS_TO_MILLISECONDS} =
  UNIT_CONVERSION;

// Unit Conversion
export const addToTimeInSeconds = (
  timeInSeconds: number,
  hours?: number,
  minutes?: number,
  seconds?: number,
) =>
  timeInSeconds +
  (hours ?? 0) * HOURS_TO_MINUTES * MINUTES_TO_SECONDS +
  (minutes ?? 0) * MINUTES_TO_SECONDS +
  (seconds ?? 0);

export const getSecondsFromDate = (date: Date | string) =>
  getSecondsFromMilliseconds(new Date(date).getTime());

export const getSecondsFromMilliseconds = (milliseconds: number) =>
  Math.round(milliseconds / SECONDS_TO_MILLISECONDS);

export const convertDegreesToRadians = (degrees: number) =>
  degrees * (Math.PI / 180);

export const convertRadiansToDegrees = (radians: number) =>
  radians * (180 / Math.PI);

// Coordinate system conversion
export const convertCartesianToPolar = (x: number, y: number) => ({
  radius: Math.hypot(x, y),
  angle: Math.atan2(y, x), // In radians
});

export const convertPolarToCartesian = (radius: number, angle: number) => ({
  x: radius * Math.cos(angle),
  y: radius * Math.sin(angle),
});

import React from 'react';
import {Dimensions, View} from 'react-native';

import {backgroundWelcome} from '../../assets/images';
import {ImgBackground, StyledButton, StyledIcon} from '../../components';
import {Messages, Screens, Strings, appColors} from '../../constants';
import styled from '../../lib/styled';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {requestFullscreen} from '../../shims/Fullscreen';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Info, Title} from './components';

const {WELCOME_SUBTITLE, WELCOME_TITLE} = Messages;
const {GET_STARTED} = Strings;
const {black, buttonActiveBackground, buttonActiveText} = appColors;

interface ScreenProps extends RootStackScreenProps<typeof Screens.Welcome> {}

export const WelcomeScreen: React.FC<ScreenProps> = ({navigation}) => {
  const {height} = Dimensions.get('window');

  return (
    <ImgBackground
      imageStyle={{
        height: `${Math.max(100, (2.35 - 0.0017 * height) * 100)}%`,
      }}
      source={backgroundWelcome}>
      <Container>
        <TextContainer>
          <StyledIcon
            name={'logoClose'}
            color={black}
            size={30}
            style={{paddingVertical: 40}}
          />
          <WelcomeSubtitle>{WELCOME_SUBTITLE}</WelcomeSubtitle>
          <WelcomeTitle>{WELCOME_TITLE}</WelcomeTitle>
        </TextContainer>
        <ButtonContainer>
          <StyledButton
            title={GET_STARTED}
            onPress={() => {
              requestFullscreen();
              navigation.navigate(Screens.LegalIntro);
            }}
            style={{
              backgroundColor: buttonActiveBackground,
              width: '100%',
            }}
            textStyle={{
              color: buttonActiveText,
            }}
          />
        </ButtonContainer>
      </Container>
    </ImgBackground>
  );
};

const Container = styled(SafeAreaView)({
  flex: 1,
  alignItems: 'center',
});

const TextContainer = styled(View)({
  alignItems: 'center',
  flex: 1,
});

const WelcomeTitle = styled(Title)({
  color: black,
  fontSize: 50,
  lineHeight: 52,
  textAlign: 'center',
  marginTop: 0,
  fontWeight: '400',
});

const WelcomeSubtitle = styled(Info)({
  color: black,
  fontSize: 14,
  fontFamily: 'Roboto-Medium',
  letterSpacing: 2,
  lineHeight: 24,
  textAlign: 'center',
  alignSelf: 'center',
  paddingBottom: 5,
  opacity: 0.5,
  fontWeight: '400',
});

const ButtonContainer = styled(View)({
  width: '100%',
  padding: 24,
});

import {useNavigation} from '@react-navigation/core';
import React from 'react';
import {useSelector} from 'react-redux';

import {Screens} from '../../constants';
import {userIdSelector} from '../../lib/redux/userSlice';
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigation/navigators/RootStackNavigator';
import {
  getNavigationRoute,
  routeToOptions,
} from '../../navigation/navigators/util';
import {LoadingContainer} from '../../screens/Education/components/LoadingContainer';

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const withUserGuard = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const ComponentWithHOC: React.FC<P> = (props: P) => {
    const navigation = useNavigation<Navigation>();
    const loggedIn = !!useSelector(userIdSelector);

    React.useEffect(() => {
      if (loggedIn) return;

      const redirect = routeToOptions(getNavigationRoute(navigation));

      navigation.replace(Screens.Login, {redirect});
    }, [loggedIn]);

    if (!loggedIn) return <LoadingContainer />;

    return <Component {...props} />;
  };

  ComponentWithHOC.displayName = `withUserGuard(${Component.displayName ?? ''})`;

  return ComponentWithHOC;
};

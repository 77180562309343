import React from 'react';
import {useSelector} from 'react-redux';
import {useDebounce} from 'use-debounce';

import {
  CloseButton,
  HeatProfileScreenLayeredBackground,
  Modal,
} from '../../components';
import {Dabber} from '../../components/Dabber';
import {
  Alerts,
  Constants,
  DabbingTips,
  Screens,
  XLDabbingTips,
} from '../../constants';
import {useUseMoodLight} from '../../lib/api';
import {useTemperature} from '../../lib/convertTemperature';
import {useAppDispatch, useBackPress, useChamberType} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useLantern} from '../../lib/hooks/useLantern';
import {
  connectedPeakSelector,
  currentDeviceSWRevisionSelector,
  currentDeviceStateSelector,
} from '../../lib/redux/bleSlice';
import {getMoodLightSelector} from '../../lib/redux/moodLightSlice';
import {
  currentProfileSelector,
  tempProfileSelector,
  updateTempProfile,
} from '../../lib/redux/profilesSlice';
import {bleReadTempHeatProfile} from '../../lib/redux/thunk';
import {
  OperatingState,
  Profile,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useTheme} from '../../lib/useTheme';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {Alert} from '../../shims/alert';

export interface Props {
  archiveProfile?: Profile;
  isResumingDab?: boolean;
}

interface ScreenProps
  extends HomeEmulatedDrawerStackScreenProps<typeof Screens.Dabbing> {}

export const DabbingScreen: React.FC<ScreenProps> = ({navigation, route}) => {
  useDisconnectGuard();
  const theme = useTheme();
  const {
    navMenuIconColor,
    dabbingScreenTheme: {iconColor},
  } = theme;
  const dispatch = useAppDispatch();
  useAdaptiveSafeArea();

  const {isVaporEnabled, isXLChamber} = useChamberType();

  const peak = useSelector(connectedPeakSelector);
  const deviceState = useSelector(currentDeviceStateSelector);
  const deviceSWRevision = useSelector(currentDeviceSWRevisionSelector);
  const currentProfile = useSelector(currentProfileSelector); // for default values
  const tempProfile = useSelector(tempProfileSelector);
  const getMoodLight = useSelector(getMoodLightSelector); // Requires a minimum of firmware T

  const {archiveProfile, isResumingDab} = route.params ?? {};

  const hasGracePeriod = meetsMinimumFirmware(
    deviceSWRevision,
    Constants.MINIMUM_FIRMWARE_VERSION.TEMP_PROFILE,
  );

  const profile: Profile =
    archiveProfile ??
    (hasGracePeriod && tempProfile ? tempProfile : currentProfile);

  const source = hasGracePeriod ? tempProfile ?? profile : profile;

  const profileBaseTemp = useTemperature(source.temperature, source.units);

  const profileBaseDuration =
    hasGracePeriod && tempProfile ? tempProfile.duration : profile.duration;
  const [deviceActive, setDeviceActive] = React.useState(false);
  const [resetDab, setResetDab] = React.useState(false);

  const moodLightId = isTHeatProfileMoodLight(profile) && profile.moodLightId;
  const moodLight = moodLightId && getMoodLight(moodLightId);
  const profileColor = isTHeatProfileMoodLight(profile)
    ? '#FFFFFF'
    : profile.color;

  // flag to determine if session was started from the peak device
  // true: if session currently on active state
  // false: if session currently on preheat, fade, or started through app
  // set to false after initial set up of variables

  useLantern(
    isTHeatProfileMoodLight(profile) && moodLight ? moodLight : profileColor,
  );

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <CloseButton
          onPress={() => {
            Alert.alert(Alerts.END_SESSION_TITLE, Alerts.END_SESSION_BODY, [
              {text: 'Cancel'},
              {
                text: 'OK',
                onPress: () => {
                  peak?.stopDabbing();
                },
              },
            ]);
          }}
          iconStyle={{tintColor: iconColor ?? navMenuIconColor}}
        />
      ),
    });
  }, [peak, navigation, iconColor, navMenuIconColor]);

  React.useEffect(() => {
    Modal.closeAll();
    hasGracePeriod && dispatch(bleReadTempHeatProfile());
    // Save archive profile name
    archiveProfile && updateTempProfile(archiveProfile);
  }, []);

  const [debouncedDeviceState] = useDebounce(deviceState, 200);

  React.useEffect(() => {
    if (
      !deviceActive &&
      (deviceState === OperatingState.HEAT_CYCLE_PREHEAT ||
        deviceState === OperatingState.HEAT_CYCLE_ACTIVE ||
        deviceState === OperatingState.HEAT_CYCLE_FADE)
    ) {
      //Sometimes the device state is initially 'IDLE'. This would cause the
      //screen to immediately close so deviceActive is set as a flag to bypass
      //this occasional initial IDLE state.
      setDeviceActive(true);
    }
  }, [deviceState]);

  React.useEffect(() => {
    // Intended to executes when dab ended MANUALLY or with CLOSE button
    if (deviceActive && debouncedDeviceState === OperatingState.IDLE) {
      setResetDab(true);
      navigation.goBack();
    }
  }, [debouncedDeviceState]);

  useBackPress(
    React.useCallback(() => {
      peak?.stopDabbing();
      return true;
    }, [peak]),
  );

  const [coreImageLayout, setCoreImageLayout] = React.useState<
    {height: number; y: number} | undefined
  >(undefined);
  const onCoreImageLayout = (e: {height: number; y: number}) => {
    // the core/gem is centered but y is incorrect due to animation?
    setCoreImageLayout({height: e.height, y: 0});
  };

  const [, dab] = useUseMoodLight();

  React.useEffect(() => {
    if (!moodLightId) return;
    dab(moodLightId);
  }, [moodLightId]);

  return (
    <HeatProfileScreenLayeredBackground
      theme={theme}
      backgroundScale={
        theme.heatProfileScreenBackgroundTheme
          .backgroundImageHeightToGemHeightRatioAnimated
      }
      coreImageLayout={coreImageLayout}
      {...(isTHeatProfileMoodLight(profile) && moodLight
        ? {moodLight}
        : {backgroundColor: profileColor})}>
      {profile?.name && (
        <Dabber
          moodLight={moodLight ? moodLight : undefined}
          profile={profile}
          profileBaseDuration={profileBaseDuration}
          profileBaseTemp={profileBaseTemp}
          isResumingDab={!!isResumingDab}
          resetDab={resetDab}
          onResetDabCompletion={() => {
            return setResetDab(false);
          }}
          dabMessagesTitle="TIP"
          dabMessages={isXLChamber ? XLDabbingTips : DabbingTips}
          onCoreImageLayout={onCoreImageLayout}
          isVaporEnabled={isVaporEnabled}
        />
      )}
    </HeatProfileScreenLayeredBackground>
  );
};

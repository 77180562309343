import React from 'react';
import {View} from 'react-native';

import {
  backgroundGlitter,
  backgroundOpal,
  heatProfileCoreCircleMask,
  le01Gem,
  peakOpal,
  peakOpalDarkScreen,
  peakOpalLightsBaseFarLeft,
  peakOpalLightsBaseFarRight,
  peakOpalLightsBaseMidLeft,
  peakOpalLightsBaseMidRight,
  peakOpalLightsBaseRingFarLeft,
  peakOpalLightsBaseRingFarRight,
  peakOpalLightsBaseRingMidLeft,
  peakOpalLightsBaseRingMidRight,
  peakOpalLightsGlassFarLeft,
  peakOpalLightsGlassFarRight,
  peakOpalLightsGlassMidLeft,
  peakOpalLightsGlassMidRight,
} from '../assets/images';
import {Constants, fillStyle} from '../constants';
import {appColors} from '../constants/Colors';
// Import from exact file to prevent cycle
import {getTransparentGradientColors} from '../lib/getTransparentGradientColors';
import {
  AssignToAHeatProfileScreenTheme,
  BottomSheetTheme,
  ConnectScreenTheme,
  ControlCenterTheme,
  CustomSwitchTheme,
  DevicesListScreenTheme,
  HeatProfileCardTheme,
  HeatProfileCoreImageTheme,
  HeatProfileListScreenTheme,
  HeatProfileScreenBackgroundTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LanternTypeButtonTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  MoodLightCardTheme,
  MoodTypeButtonTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  SaveToDeviceScreenTheme,
  StatusBarTheme,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from '../lib/types';
import {addOpacityToColorHex} from '../lib/utilityFunctions/addOpacityToColorHex';
// Import from exact file to prevent cycle
import {
  DarkStatusBarTheme,
  LightStatusBarTheme,
  defaultTheme,
} from './DefaultTheme';

// General

const navMenuTitleStyle: NavMenuTitleStyle = {
  ...defaultTheme.navMenuTitleStyle,
  color: appColors.black,
};

// Components

const bottomSheetTheme: BottomSheetTheme = {
  ...defaultTheme.bottomSheetTheme,
  backgroundColor: appColors.lightestGrey,
  buttonBackgroundColor: appColors.white,
  buttonTextColor: appColors.black,
  titleColor: appColors.black,
  subtitleColor: appColors.black50,
  iconColor: appColors.black,
  dividerColor: appColors.black10,
  IconBaseBackground: () => (
    <View
      style={{
        ...fillStyle,
        backgroundColor: appColors.opalWhite,
      }}
    />
  ),
};

const customSwitchTheme: CustomSwitchTheme = {
  ...defaultTheme.customSwitchTheme,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  primaryFontColor: appColors.black,
  durationFontColor: appColors.white,
  specularColorStart: appColors.white50,
  specularColorEnd: appColors.white50,
  iconColor: appColors.black,
  iosShadowAlpha: 75,
  gradientShadeFunction: getTransparentGradientColors,
  overLayGradientColors: [
    addOpacityToColorHex(appColors.white, 0.8),
    addOpacityToColorHex(appColors.white, 0.5),
    addOpacityToColorHex(appColors.white, 0),
  ],
};

const heatProfileCoreImageTheme: HeatProfileCoreImageTheme = {
  baseImageSource: le01Gem,
  maskImageSource: heatProfileCoreCircleMask,
  whiteColorShadePercentage: -21,
};

const heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme = {
  gradientShadeFunction: getTransparentGradientColors,
  background: backgroundGlitter,
};

const lanternTypeButtonTheme: LanternTypeButtonTheme = {
  ...defaultTheme.lanternTypeButtonTheme,
};

const moodLightCardTheme: MoodLightCardTheme = {
  ...defaultTheme.moodLightCardTheme,
};

const moodTypeButtonTheme: MoodTypeButtonTheme = {
  ...defaultTheme.moodTypeButtonTheme,
  textColor: appColors.black,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: peakOpal,
  lightLayers: {
    glass: {
      farLeft: peakOpalLightsGlassFarLeft,
      farRight: peakOpalLightsGlassFarRight,
      midLeft: peakOpalLightsGlassMidLeft,
      midRight: peakOpalLightsGlassMidRight,
    },
    base: {
      farLeft: peakOpalLightsBaseFarLeft,
      farRight: peakOpalLightsBaseFarRight,
      midLeft: peakOpalLightsBaseMidLeft,
      midRight: peakOpalLightsBaseMidRight,
    },
    ring: {
      farLeft: peakOpalLightsBaseRingFarLeft,
      farRight: peakOpalLightsBaseRingFarRight,
      midLeft: peakOpalLightsBaseRingMidLeft,
      midRight: peakOpalLightsBaseRingMidRight,
    },
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: peakOpalDarkScreen,
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: appColors.black,
  buttonTextColor: appColors.white,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: appColors.black,
  backgroundColor: appColors.opalWhite,
};

// Screens

const assignToAHeatProfileScreenTheme: AssignToAHeatProfileScreenTheme = {
  selectedBorderColor: appColors.black,
  background: {color: appColors.lightestGrey},
};

const connectScreenTheme: ConnectScreenTheme = {
  background: {image: backgroundOpal},
  peakNameBackgroundColor: appColors.white,
};

const controlCenterTheme: ControlCenterTheme = {
  ...defaultTheme.controlCenterTheme,
  infoText: appColors.black,
  fatSliderBGColor: appColors.black50,
  blurBackgroundColor: appColors.transluscentLightGray,
  background: {color: appColors.lightestGrey},
  boostScreenLabel: appColors.black,
};

const dabbingScreenTheme = {
  ...defaultTheme.dabbingScreenTheme,
  dabbingScreenActiveText: appColors.black,
  dabbingButtonBackground: appColors.black,
  dabbingButtonBackgroundPressed: appColors.bone,
  dabbingButtonForeground: appColors.bone,
  dabbingButtonForegroundPressed: appColors.black,
};

const devicesListScreenTheme: DevicesListScreenTheme = {
  ...defaultTheme.devicesListScreenTheme,
  cardColor: appColors.iOSGray,
  cardText: appColors.black,
  listTitleText: appColors.black,
  listDivider: '#979797',
  trashCanIconColor: appColors.black,
  blurBackgroundColor: appColors.transluscentLightGray,
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {
    gradient: ['#F8F8F8', '#EDEDED'],
  },
  hoverBackgroundColor: appColors.gray,
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  ...defaultTheme.heatProfileSelectScreenTheme,
  startTextStyle: {color: appColors.black},
  backgroundLineColor: appColors.lightGray,
  primaryColor: appColors.black,
  durationColor: appColors.black50,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,
  // General
  background: {
    image: backgroundOpal,
  },

  // Status info
  batteryBarBackgroundColor: appColors.mediumGray79,
  batteryDisconnectedColor: addOpacityToColorHex(appColors.black, 0.2),
  preserveIndicator: appColors.black,
  statusTextStealthColor: appColors.black,
  statusTitleTextColor: appColors.black,
  stealthIconColor: appColors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: addOpacityToColorHex(appColors.white, 0.8), location: 0},
    {color: appColors.invisible, location: 1},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(appColors.black, 0.2),
  metricTextColor: appColors.black,
  metricTitleTextColor: appColors.black50,

  // Limited edition info
  limitedEditionText: {
    leftBorderGradientArray: [appColors.black10, appColors.black10],
    name: {color: appColors.black30, value: 'Opal'},
    numberString: {color: appColors.black10, value: '01'},
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {image: backgroundOpal},
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl: 'https://puffco-prod.s3-us-west-2.amazonaws.com/welcome/opal.mp4',
};

const saveToDeviceScreenTheme: SaveToDeviceScreenTheme = {
  background: heatProfileListScreenTheme.background,
  infoLabelColor: appColors.black50,
};

const statusBarTheme: StatusBarTheme = {
  ...defaultTheme.statusBarTheme,
  themed: Constants.IS_NATIVE_ANDROID
    ? DarkStatusBarTheme
    : LightStatusBarTheme,
};

export const opalTheme: Theme = {
  ...defaultTheme,

  // General
  fadedTextColor: appColors.black50,
  logoTint: appColors.black,
  navMenuIconColor: appColors.black,
  navMenuTitleStyle,
  primaryTextColor: appColors.black,
  primaryColor: appColors.black,
  spinnerColor: appColors.black,

  // Components
  bottomSheetTheme,
  customSwitchTheme,
  heatProfileCardTheme,
  heatProfileCoreImageTheme,
  heatProfileScreenBackgroundTheme,
  lanternTypeButtonTheme,
  moodLightCardTheme,
  moodTypeButtonTheme,
  peakImageTheme,
  sectionDividerColor: '#040404',
  sectionTitleTextColor: appColors.black,
  statusDisplayTextColor: appColors.black,
  styledButtonTheme,
  statusBarTheme,
  tabBarTheme,

  // Screens
  assignToAHeatProfileScreenTheme,
  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  devicesListScreenTheme,
  heatProfileListScreenTheme,
  heatProfileSelectScreenTheme,
  homeScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  saveToDeviceScreenTheme,
};

import React from 'react';
import {Text, View, ViewProps} from 'react-native';

import {Logo} from '../../components';
import {appColors} from '../../constants';
import styled from '../../lib/styled';

interface Props extends ViewProps {}

export const LogoHeader: React.FC<React.PropsWithChildren<Props>> = ({
  style,
  children,
}) => (
  <Container {...{style}}>
    <Logo color={appColors.black} size={39} />
    {children}
  </Container>
);

const Container = styled(View)({
  alignItems: 'center',
});

export const LogoTitle = styled(Text)({
  color: appColors.black,
  fontSize: 32,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  marginTop: 24,
  textAlign: 'center',
  textTransform: 'uppercase',
});

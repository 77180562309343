import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import {revisionStringToNumber} from 'pikaparam';

import {bleManager} from '../ble2/v2/BleManager';
import {IPeakDevice} from '../ble2/v2/PeakDevice/IPeakDevice';
import {
  AppLanternSetting,
  BleStore,
  Device,
  DeviceAppSettings,
  LatestOta,
  Store,
} from '../types';

const bleSlice = createSlice({
  name: 'ble',
  initialState: {
    devices: [],
  } as BleStore,
  reducers: {
    setConnectedDeviceId(state, action: PayloadAction<string | undefined>) {
      const deviceId = action.payload;

      state.connectedDevice = deviceId
        ? {id: deviceId, connectedAt: Date.now()}
        : undefined;

      if (!deviceId) return;

      state.currentDeviceId = deviceId;

      const index = state.devices.findIndex(d => d.id === deviceId);

      if (index >= 0) {
        state.devices[index] = {
          ...state.devices[index],
          firmwareUpdate: undefined,
        };
      }

      // Reorder devices so the most recently connected device will be the first one in the list
      state.devices = [
        ...state.devices.filter(d => d.id === deviceId),
        ...state.devices.filter(d => d.id !== deviceId),
      ];
    },
    updateDeviceInfo(state, action: PayloadAction<Device>) {
      const index = state.devices.findIndex(d => d.id === action.payload.id);

      if (index < 0) {
        state.devices.push(action.payload);
        return;
      }

      state.devices[index] = {
        ...state.devices[index],
        ...action.payload,
        settings: {
          ...state.devices[index].settings,
          ...action.payload.settings,
        },
        ...(action.payload.userLanternPreference && {
          userLanternPreference: {
            ...state.devices[index].userLanternPreference,
            ...action.payload.userLanternPreference,
          },
        }),
        appSettings: {
          ...state.devices[index].appSettings,
          ...action.payload.appSettings,
        },
      };
    },
    updateDeviceAppSettings(
      state,
      action: PayloadAction<Device & DeviceAppSettings>,
    ) {
      const ind = state.devices.findIndex(d => d.id === action.payload.id);
      if (ind !== -1) {
        state.devices[ind] = {
          ...state.devices[ind],
          appSettings: {
            ...state.devices[ind].appSettings,
            ...omit(action.payload, 'id'),
            cleaningReminderLastUpdated:
              action.payload.cleaningReminderLastUpdated ?? Date.now(),
          },
        };
      }
    },
    updateDeviceSettings(
      state,
      action: PayloadAction<
        Device & {syncUserLanternPreference?: boolean; moodLightId?: string}
      >,
    ) {
      const index = state.devices.findIndex(d => d.id === action.payload.id);

      if (index < 0) return;

      const syncUserLanternPreference =
        action.payload.syncUserLanternPreference ?? false;
      const moodLightId = action.payload.moodLightId ?? undefined;

      const settings = {
        ...state.devices[index].settings,
        ...omit(
          action.payload.settings,
          'syncUserLanternPreference',
          'moodLightId',
        ),
      };

      state.devices[index].settings = {
        ...settings,
      };

      if (syncUserLanternPreference) {
        const lanternPreference: AppLanternSetting = {
          lanternMode: settings?.lanternMode,
          lanternColor: settings?.lanternColor,
          lanternPattern: settings?.lanternPattern,
          partyMode: settings?.partyMode,
          moodLightId: moodLightId,
          modified: new Date().getTime(),
        };

        state.devices[index] = {
          ...state.devices[index],
          userLanternPreference: lanternPreference,
        };
      }
    },
    clearUserLanternPreference(
      state,
      action: PayloadAction<{deviceId: string}>,
    ) {
      const index = state.devices.findIndex(
        d => d.id === action.payload.deviceId,
      );

      if (index < 0) return;

      state.devices[index] = {
        ...state.devices[index],
        userLanternPreference: undefined,
      };
    },
    addDeviceLogs(
      state,
      action: PayloadAction<Required<Pick<Device, 'id' | 'logs'>>>,
    ) {
      const index = state.devices.findIndex(d => d.id === action.payload.id);

      if (index < 0) return;

      const logs = state.devices[index].logs ?? [];
      const indices = new Set(logs.map(l => l.offset));

      state.devices[index] = {
        ...state.devices[index],
        logs: logs.concat(
          action.payload.logs.filter(l => !indices.has(l.offset)),
        ),
      };
    },
    removeDeviceLogs(
      state,
      action: PayloadAction<Required<Pick<Device, 'id' | 'logs'>>>,
    ) {
      const index = state.devices.findIndex(d => d.id === action.payload.id);

      if (index < 0) return;

      const logs = state.devices[index].logs ?? [];
      const indices = new Set(action.payload.logs.map(l => l.offset));

      state.devices[index] = {
        ...state.devices[index],
        logs: logs.filter(l => !indices.has(l.offset)),
      };
    },
    removeBleDevice(state, action: PayloadAction<string>) {
      state.devices = state.devices.filter(d => d.id !== action.payload);

      if (action.payload !== state.currentDeviceId) return;

      state.currentDeviceId = state.devices[0]?.id;
    },
    setOta(state, action: PayloadAction<LatestOta | undefined>) {
      state.ota = action.payload;
    },
    initiateFirmwareUpdate(
      state,
      action: PayloadAction<Pick<Device, 'id' | 'firmwareUpdate'>>,
    ) {
      const index = state.devices.findIndex(d => d.id === action.payload.id);

      if (index < 0) return;

      state.devices[index] = {
        ...state.devices[index],
        firmwareUpdate: action.payload.firmwareUpdate,
      };
    },
    finishFirmwareUpdate(state) {
      state.devices = state.devices.map(
        ({firmwareUpdate, ...device}) => device,
      );
    },
  },
});

export const currentDeviceIdSelector = (state: Store) =>
  state.ble.currentDeviceId;

export const currentDeviceNameSelector = (state: Store) =>
  currentDeviceSelector(state)?.name;

export const currentDeviceSerialNumberSelector = (state: Store) =>
  currentDeviceSelector(state)?.serialNumberString;

export const currentDeviceFirmwareUpdateSelector = (state: Store) => {
  const device = currentDeviceSelector(state);

  return device?.firmwareUpdate;
};

export const currentDeviceFirmwarePendingSelector = (state: Store) => {
  const device = currentDeviceSelector(state);

  if (!device?.softwareRevisionString) return false;

  const firmwareUpdate = currentDeviceFirmwareUpdateSelector(state);

  return (
    !!firmwareUpdate &&
    revisionStringToNumber(firmwareUpdate.toVersion) >
      revisionStringToNumber(device.softwareRevisionString)
  );
};

export const currentDeviceSelector = (state: Store) => {
  const id = currentDeviceIdSelector(state);

  if (!id) return;

  return state.ble.devices.find(device => device.id === id);
};

export const connectedPeakSelector = (
  state: Store,
): IPeakDevice | undefined => {
  if (
    state.ble.connectedDevice?.id !== bleManager.peak?.peripheralId ||
    currentDeviceIdSelector(state) !== bleManager.peak?.peripheralId
  )
    return;

  return bleManager.peak;
};

export const devicesSelector = (state: Store) => state.ble.devices;

export const currentDeviceSettingsSelector = (state: Store) =>
  currentDeviceSelector(state)?.settings;

export const userLanternPreferenceSelector = (state: Store) =>
  currentDeviceSelector(state)?.userLanternPreference;

export const currentDeviceStateSelector = (state: Store) =>
  currentDeviceSelector(state)?.state;

export const currentDeviceLogsSelector = (state: Store) =>
  currentDeviceSelector(state)?.logs;

export const currentDeviceSWRevisionSelector = (state: Store) =>
  currentDeviceSelector(state)?.softwareRevisionString ?? '';

export const isOtaAvailableSelector = (state: Store) => {
  const device = currentDeviceSelector(state);

  if (!device?.softwareRevisionString || !state.ble.ota?.version) return false;

  return (
    revisionStringToNumber(state.ble.ota.version) >
    revisionStringToNumber(device.softwareRevisionString)
  );
};

export const {
  setConnectedDeviceId,
  updateDeviceInfo,
  updateDeviceAppSettings,
  updateDeviceSettings,
  addDeviceLogs,
  removeDeviceLogs,
  removeBleDevice,
  setOta,
  clearUserLanternPreference,
  initiateFirmwareUpdate,
  finishFirmwareUpdate,
} = bleSlice.actions;

export const bleReducer = bleSlice.reducer;

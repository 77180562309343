import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import {Strings} from '../constants';
import {CustomMoodLight, MoodLight} from './types';

export const isMoodLightNewer = (
  current: CustomMoodLight,
  compared: CustomMoodLight,
) => {
  return current.modified > compared.modified;
};

export const findMatchingMoodLight = (
  moodLight: MoodLight,
  list: MoodLight[],
) => {
  const props = ['name', 'category', 'colors', 'tempo', 'type', 'led3Meta'];
  const moodLightFiltered = pick(moodLight, [...props]);
  return list.find(currentMoodLight =>
    isEqual(moodLightFiltered, pick(currentMoodLight, [...props])),
  );
};

export const getTempoString = (tempo: CustomMoodLight['tempo']): string =>
  `${Strings.TEMPO}: ${Math.round(tempo * 100)}%`;

import {useIsFocused} from '@react-navigation/native';
import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  Text,
  View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {backgroundRegister} from '../../assets/images';
import {
  ImgBackground,
  StyledButton,
  StyledField,
  displayRightIconMessage,
  getRightIconName,
} from '../../components';
import {
  Alerts,
  Constants,
  ErrorMessages,
  Messages,
  Screens,
  appColors,
  interpolate,
} from '../../constants';
import {updateUser} from '../../lib/api';
import {userApi} from '../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {setUser, userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {Alert} from '../../shims/alert';
import {LogoHeader, LogoTitle, TermsAndPrivacy} from '../components';
import {isDisplayZoomed} from '../lib/isDisplayZoomed';

interface EmailVerifyValues {
  email: string;
}

const {IPHONE_8} = Constants.SCREEN_HEIGHT;
const {height} = Dimensions.get('window');
const isSmall = height <= IPHONE_8;

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<typeof Screens.EmailVerify> {}

export const EmailVerifyScreen = ({navigation}: Props) => {
  useAdaptiveSafeArea();

  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const EmailVerifySchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(ErrorMessages.REQUIRED_EMAIL)
      .email(ErrorMessages.INVALID_EMAIL)
      .test({
        name: 'checkEmail',
        test: async function (value) {
          if (!value || !value.length || value === user?.email) return true;

          const {data} = await userApi.validateFields({
            userValidateFieldsDto: {email: value},
          });

          if (data.email === 'ok') return true;

          const message =
            data.email === 'taken'
              ? 'Email already in use'
              : 'Invalid email address';

          return this.createError({message, path: 'email'});
        },
      }),
  });

  const handleSendVerification = async () => {
    try {
      await userApi.requestEmailVerification({id: 'me'});

      Alert.alert(
        Alerts.REQUEST_EMAIL_VERIFICATION,
        interpolate(Alerts.REQUEST_EMAIL_VERIFICATION_SUCCESS, {
          email: user?.email,
        }),
      );

      navigation.goBack();
    } catch (error: any) {
      Alert.alert(Alerts.REQUEST_EMAIL_VERIFICATION_ERROR, error.message);
    }
  };

  // Update user email
  const updateEmail = async (values: EmailVerifyValues) => {
    const updateData: {email?: string} = {};

    if (!!values.email?.length && values.email !== user?.email) {
      updateData.email = values.email.trim();
    }

    await updateUser(updateData);

    handleSendVerification();
  };

  const handleSubmit = async (
    values: EmailVerifyValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    // check if the user changed their email
    if (values.email === user?.email) {
      await handleSendVerification();
      actions.setSubmitting(false);
    } else {
      updateEmail(values);
    }
  };

  // Update user when the user navigates away
  React.useEffect(() => {
    if (isFocused) return;

    userApi.getUserById({id: 'me'}).then(r => dispatch(setUser(r.data)));
  }, [isFocused]);

  return (
    <BackgroundImage source={backgroundRegister}>
      <Container behavior={Platform.OS === 'ios' ? 'padding' : 'padding'}>
        <InnerContainer>
          <Formik
            initialValues={{
              email: user?.email || '',
            }}
            onSubmit={handleSubmit}
            validationSchema={EmailVerifySchema}>
            {({
              handleBlur,
              handleChange,
              isSubmitting,
              isValid,
              submitForm,
              touched,
              values,
              errors,
            }) => (
              <FormContainer>
                <SpinnerContainer>
                  {isSubmitting && (
                    <ActivityIndicator size="large" color={appColors.black} />
                  )}
                </SpinnerContainer>

                <LogoHeader style={{marginTop: 40}}>
                  <LogoTitle>{Messages.EMAIL_NOT_VERIFIED_PROMPT}</LogoTitle>
                </LogoHeader>

                <DescContainer>
                  <DescText>{Messages.EMAIL_NOT_VERIFIED_DESC}</DescText>
                </DescContainer>

                <FieldsContainer>
                  <FieldLabel>EMAIL</FieldLabel>
                  <StyledField
                    name={'mail'}
                    borderColor={appColors.lightGray}
                    height={35}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    keyboardType="email-address"
                    leftIconColor={appColors.mediumGray}
                    rightIcon={getRightIconName(
                      values.email,
                      errors.email,
                      touched.email,
                    )}
                    rightIconColor={
                      errors.email ? appColors.red : appColors.green
                    }
                    rightIconMessage={displayRightIconMessage(
                      values.email,
                      errors.email,
                      touched.email,
                    )}
                  />
                  <BottomContainer style={{marginVertical: 2}}>
                    <GrayText>Edit the text above to make changes</GrayText>
                  </BottomContainer>
                </FieldsContainer>

                <ButtonContainer>
                  <StyledButton
                    title="Confirm Email"
                    disabled={!isValid || isSubmitting}
                    onPress={submitForm}
                    style={{
                      backgroundColor: appColors.black,
                      width: '100%',
                    }}
                    textStyle={{color: appColors.white}}
                  />
                </ButtonContainer>
                <LinkContainer>
                  <TermsAndPrivacy />
                </LinkContainer>
              </FormContainer>
            )}
          </Formik>
        </InnerContainer>
      </Container>
    </BackgroundImage>
  );
};

const BackgroundImage = styled(ImgBackground)({
  flex: 1,
});

const InnerContainer = styled(View)({
  width: '100%',
  height: height * (isDisplayZoomed() ? 0.8 : 0.7),
  backgroundColor: appColors.white,
  borderTopRightRadius: 45,
  borderTopLeftRadius: 45,
});

const FormContainer = styled(View)({
  marginHorizontal: 50,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const Container = styled(KeyboardAvoidingView)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flex: 1,
});

const DescContainer = styled(View)({
  textAlign: 'center',
  marginTop: 10,
  marginBottom: 25,
});

const LinkContainer = styled(View)({
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 10,
});

const FieldsContainer = styled(View)({
  width: '100%',
  marginTop: 10,
});

const FieldLabel = styled(Text)({
  color: appColors.black,
  fontFamily: 'Roboto-Bold',
  fontSize: 15,
  fontWeight: '100',
  letterSpacing: 0.75,
  marginTop: 10,
});

const ButtonContainer = styled(View)({
  justifyContent: 'flex-end',
  marginTop: isSmall ? 20 : 38,
  width: '100%',
});

const BottomContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const SpinnerContainer = styled(View)({
  position: 'absolute',
  top: '55%',
});

const GrayText = styled(Text)({
  color: appColors.gray,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
});

const DescText = styled(GrayText)({
  textAlign: 'center',
});

import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';

import {
  StyledField,
  displayRightIconMessage,
  getRightIconName,
} from '../../components';
import {ErrorMessages, Screens, appColors} from '../../constants';
import {updateUser} from '../../lib/api';
import {userApi} from '../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {AccountNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Alert} from '../../shims/alert';

interface ChangeEmailValues {
  email: string;
}

interface Props
  extends AccountNavigatorScreenProps<typeof Screens.ChangeEmail> {}

export const ChangeEmailScreen = ({route, navigation}: Props) => {
  const user = useSelector(userSelector);

  useAdaptiveSafeArea();

  const setAccountFieldValue = route.params?.setAccountFieldValue;

  const ChangeEmailSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(ErrorMessages.REQUIRED_EMAIL)
      .email(ErrorMessages.INVALID_EMAIL)
      .test({
        name: 'checkEmail',
        test: async function (value) {
          if (
            value &&
            value.includes('@') &&
            value.includes('.') &&
            value !== user?.email
          ) {
            const {data} = await userApi.validateFields({
              userValidateFieldsDto: {email: value},
            });

            if (data.email === 'ok') return true;

            const message =
              data.email === 'taken'
                ? 'Email already in use'
                : 'Invalid email address';

            return this.createError({message, path: 'email'});
          }

          return true;
        },
      }),
  });

  const handleSave = async (
    values: ChangeEmailValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    const updateData: {
      email?: string;
    } = {};
    if (values.email && values.email !== '' && values.email !== user?.email) {
      updateData.email = values.email.trim();
    }
    const {email} = await updateUser(updateData);

    if (email !== user?.email) {
      setAccountFieldValue?.('email', email);

      Alert.alert('Success', 'Your email has been changed!');
    }

    navigation.goBack();

    actions.setSubmitting(false);
  };

  return (
    <Container>
      <Formik
        initialValues={{email: user ? user.email : ''}}
        onSubmit={handleSave}
        validationSchema={ChangeEmailSchema}>
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          submitForm,
          values,
          errors,
          touched,
        }) => (
          <View style={{alignItems: 'center'}}>
            <ChangeContainer>
              <FieldLabel>EMAIL</FieldLabel>
              <StyledField
                name={'mail'}
                borderColor={appColors.mediumGray}
                height={35}
                onChangeText={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                keyboardType="email-address"
                leftIconColor={appColors.mediumGray}
                rightIcon={getRightIconName(
                  values.email,
                  errors.email,
                  user?.email !== values.email,
                )}
                rightIconColor={errors.email ? appColors.red : appColors.green}
                rightIconMessage={displayRightIconMessage(
                  values.email,
                  errors.email,
                  touched.email,
                )}
                textColor={appColors.white}
              />
            </ChangeContainer>
            <ButtonContainer>
              <Button disabled={!isValid || isSubmitting} onPress={submitForm}>
                <ButtonText>SAVE</ButtonText>
              </Button>
            </ButtonContainer>
          </View>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled(SafeAreaView)({
  alignItems: 'center',
  backgroundColor: '#333333',
  flex: 1,
  justifyContent: 'center',
});

const ChangeContainer = styled(View)({
  width: 250,
});

const FieldLabel = styled(Text)({
  color: appColors.white,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 16,
});

const ButtonContainer = styled(View)({
  marginTop: 20,
});

const Button = styled(TouchableOpacity)({
  backgroundColor: appColors.mediumGray,
  borderRadius: 20,
  paddingHorizontal: 30,
  paddingVertical: 8,
});

const ButtonText = styled(Text)({
  color: appColors.white,
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
  fontSize: 12,
});

import {Environment} from '../../constants/Environment';
import {RedirectOptions} from '../../navigation/navigators/params';
import {getUrlFromOptions} from '../../navigation/navigators/util';

export const getAppUrl = () => {
  return window?.location?.origin ?? Environment.hostUrl;
};

export const getSocialAuthCallback = (type: 'apple' | 'google') => {
  return `${getAppUrl()}/auth/${type}/callback`;
};

const delimiter = '___';

export const createSocialAuthState = ({
  state,
  redirect,
}: {
  state: 'login' | 'register';
  redirect?: RedirectOptions;
}) => {
  if (!redirect) return state;

  return `${state}${delimiter}${getUrlFromOptions(redirect)}`;
};

export const getSocialAuthState = async (value?: string) => {
  if (!value) return {state: 'login'};

  const [state, redirect] = value.split(delimiter) ?? [];

  if (!redirect) return {state};

  return {state, redirect};
};

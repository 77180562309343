import {EntryFields} from 'contentful';
import React, {ComponentType} from 'react';
import {StyleProp, TextProps, TextStyle, View, ViewStyle} from 'react-native';

import {isString} from '../../../lib/util/types';
import {RichTextRenderer} from './RichTextRenderer';

export type PossibleText = EntryFields.RichText | string;

interface Props {
  text: PossibleText;
  as: ComponentType<TextProps>;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export const RichTextString: React.FC<Props> = ({
  text,
  as,
  style,
  textStyle,
}) => (
  <View {...{style}}>
    {isString(text) ? (
      React.createElement(as, {style: textStyle}, text)
    ) : (
      <RichTextRenderer content={text} />
    )}
  </View>
);

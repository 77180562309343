import Clipboard from '@react-native-clipboard/clipboard';
import React from 'react';

export const useCopy = () => {
  const [copied, setCopied] = React.useState<boolean>(false);

  const onCopy = ({text}: {text: string}) => {
    Clipboard.setString(text);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return {onCopy, copied};
};

import {Constants} from '../../constants';

export const getBoundedValue = (value: number, min: number, max: number) =>
  value < min ? min : value > max ? max : value;

export const getProfileLogoSize = (height: number) =>
  getBoundedValue(height * Constants.PROFILE_START_BTN_LOGO_BG_RATIO, 38, 60);

export const getProfileTempFontSize = (height: number) =>
  getBoundedValue(height * Constants.PROFILE_TEMP_FONT_BG_RATIO, 44, 65);

export const getProfileGlowHaloWHSize = (height: number) =>
  getBoundedValue(height, 670, 900) * Constants.PROFILE_GLOW_HALO_BG_RATIO;

export const getProfileAnimatedHaloWHSize = (height: number) =>
  getBoundedValue(height, 670, 900) * Constants.PROFILE_ANIMATED_HALO_BG_RATIO;

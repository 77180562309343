import React from 'react';
import {StyleSheet} from 'react-native';

export type ConicGradientViewProps = {
  colors: string[];
  startAngle?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const ConicGradientView = ({
  children,
  colors,
  startAngle = 0,
  style = {},
  ...rest
}: React.PropsWithChildren<ConicGradientViewProps>) => {
  const flattenedStyle = StyleSheet.flatten(style);

  return (
    <div
      style={{
        ...flattenedStyle,
        background: `conic-gradient(from ${startAngle}deg, ${colors.join(
          ', ',
        )}, ${colors[0]})`,
      }}
      {...rest}>
      {children}
    </div>
  );
};

import range from 'lodash/range';
import {pchip, piecewiseCubic} from 'slatec-pchip';

export type Triple = [number, number, number];

function maxChromaLuv(Li: number, Hi: number): number {
  const L = Math.min(Math.max(Li, 0), 100);
  const Hrad = (Hi * Math.PI) / 180;
  if (L === 0) {
    return 0;
  }

  const sinH = Math.sin(Hrad);
  const cosH = Math.cos(Hrad);
  const sub1 = Math.pow(L + 16, 3) / 1560896;
  const sub2 = sub1 > 216 / 24389 ? sub1 : (L * 27) / 24389;

  const rbot = [
    -1.836216146601413e6, -3.205832374331522e6, 1.055040285844013e7,
  ];
  const lbot = [-1.472964691512916e7, 4.250821495452787e6, 1.283157104404885e6];

  let Cout = Number.MAX_VALUE;

  for (let i = 0; i < 3; ++i) {
    const top = 11700000 * sub2;
    const bot = (rbot[i] * sinH + lbot[i] * cosH) * sub2;

    const C = [
      (L * top) / bot,
      (L * (top - 11700000)) / (bot + 1921696 * sinH),
    ];
    Cout = Math.min(Cout, ...C.filter(v => v > 0)); // any C > 0 is fed into minimum value calc
  }

  return Math.min(Cout, 175.2);
}

const gamma = (v: number) =>
  v <= 0.0031306684425005883 ? v * 12.92 : 1.055 * Math.pow(v, 1 / 2.4) - 0.055;
const invGamma = (v: number) =>
  v <= 0.0404482362771076 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
const f = (Y: number) =>
  Y < 216 / 24389 ? ((24389 / 27) * Y + 16) / 116 : Math.cbrt(Y);
const invF = (fY: number) =>
  fY < 6 / 29 ? ((116 * fY - 16) * 27) / 24389 : Math.pow(fY, 3);
const refU = 0.197839824821408;
const refV = 0.46833630293241;

export function rgbToLch(rgb: Triple): Triple {
  const [R, G, B] = rgb.map(invGamma);
  const X =
    R * 0.412456439089691 + G * 0.357576077643907 + B * 0.180437483266397;
  const Y =
    R * 0.212672851405621 + G * 0.715152155287816 + B * 0.072174993306558;
  const Z = R * 0.019333895582328 + G * 0.1191920258813 + B * 0.950304078536368;
  const D = X + 15 * Y + 3 * Z;
  const Dd = D !== 0 ? D : 1;

  const L = 116 * f(Y) - 16;
  const U = 13 * L * ((4 * X) / Dd - refU);
  const V = 13 * L * ((9 * Y) / Dd - refV);

  const H = ((Math.atan2(V, U) * 180) / Math.PI + 360) % 360;
  const C = Math.sqrt(U * U + V * V);
  const Cclamp = Math.min(Math.max(C, 0), maxChromaLuv(L, H));

  return [L, Cclamp, H];
}

export function lchToRgb(lch: Triple): Triple {
  const L = lch[0];
  if (L <= 0) {
    return [0, 0, 0];
  }
  const H = lch[2];
  const C = Math.min(Math.max(lch[1], 0), maxChromaLuv(L, H));
  const Y = invF((L + 16) / 116);
  const U = (Math.cos((H * Math.PI) / 180) * C) / (13 * L) + refU;
  const V = (Math.sin((H * Math.PI) / 180) * C) / (13 * L) + refV;
  const X = -(9 * Y * U) / ((U - 4) * V - U * V);
  const Z = (9 * Y - 15 * V * Y - V * X) / (3 * V);
  const RGB = [
    X * 3.240454162114103 + Y * -1.537138512797715 + Z * -0.49853140955601,
    X * -0.96926603050518 + Y * 1.876010845446694 + Z * 0.041556017530349,
    X * 0.055643430959114 + Y * -0.20402591351675 + Z * 1.057225188223179,
  ];
  return RGB.map(gamma).map(v => Math.min(Math.max(v, 0), 1)) as Triple;
}

export function htmlColorToTriple(c: string): Triple {
  const l = c.toLowerCase().replace('#', '');
  if (!l.match(/[0-9a-f]{6}/)) {
    throw new Error(`"${c}" does not look like an HTML color`);
  }
  return [l.slice(0, 2), l.slice(2, 4), l.slice(4, 6)].map(
    h => parseInt(h, 16) / 255,
  ) as Triple;
}

export function tripleToHtmlColor(t: Triple) {
  const scaled = t.map(v => Math.round(v * 255));
  return '#' + scaled.map(v => ('0' + v.toString(16)).slice(-2)).join('');
}

export interface ColorTimePoint {
  rgb: Triple;
  t: number;
}

export interface LchycleArgsBase {
  nOut: number;
  luma?: number;
  zeroDeriv?: boolean;
  luv?: boolean;
}

export type LchycleEqualArgs = LchycleArgsBase & {
  colors: Triple[] | string[];
  steady?: number;
  phase?: number;
};
export type LchycleTimeArgs = LchycleArgsBase & {points: ColorTimePoint[]};

// Cubic interpolate between a provided sequence of RGB colors, in Lch space, forming a closed loop.
// Exactly one of `points` and `colors` should be provided.
// `points` is an array of points to interpolate between, with time values in units of output points.
// Therefore, a point with t === 1 will appear as the second value of the output.
// `colors` is an array of colors to interpolate between, evenly spaced. The first color input will appear
// as the first color of the output.
export function lchycle(o: LchycleEqualArgs | LchycleTimeArgs): Triple[] {
  let points: ColorTimePoint[];
  let zeroDeriv: boolean;
  if ('points' in o) {
    points = o.points;
    zeroDeriv = o.zeroDeriv ?? false;
  } else if ('steady' in o && o.steady !== undefined && o.steady > 0) {
    const colors = (typeof o.colors[0] === 'string'
      ? (o.colors as string[]).map(htmlColorToTriple)
      : o.colors) as unknown as Triple[];
    points = range(colors.length * 2).map(i => {
      const iTriple = Math.floor(i / 2);
      const inputTime = o.nOut / colors.length;
      const steadyFrac = Math.min(o.steady!, 1 - 10 * Number.EPSILON);
      const delta = i % 2 ? steadyFrac / 2 : -steadyFrac / 2;
      return {
        rgb: colors[Math.floor(i / 2)],
        t: (iTriple + delta) * inputTime + o.nOut * (o.phase || 0),
      };
    });
    zeroDeriv = o.zeroDeriv ?? true;
  } else {
    const colors = (typeof o.colors[0] === 'string'
      ? (o.colors as string[]).map(htmlColorToTriple)
      : o.colors) as unknown as Triple[];
    points = range(colors.length).map(i => {
      const inputTime = o.nOut / colors.length;
      return {
        rgb: colors[i],
        t: i * inputTime + o.nOut * (o.phase || 0),
      };
    });
    zeroDeriv = o.zeroDeriv ?? false;
  }
  points.sort((a, b) => a.t - b.t);
  const rgbIn = points.map(p => p.rgb);
  const tIn = points.map(p => p.t);
  const lchIn = rgbIn.map(rgbToLch);
  const interpCycle = o.luv
    ? lchIn.map(([L, C, H]) => [
        L,
        C * Math.cos((H * Math.PI) / 180),
        C * Math.sin((H * Math.PI) / 180),
      ])
    : lchIn;
  const interpLoop = [...interpCycle, ...interpCycle, ...interpCycle];
  if (!o.luv) {
    // don't take the long way around the axis at the stitch points
    for (let i = 1; i < interpLoop.length; ++i) {
      const prevH = interpLoop[i - 1][2];
      const curH = interpLoop[i][2];
      interpLoop[i] = [
        interpLoop[i][0],
        interpLoop[i][1],
        curH - 360 * Math.round((curH - prevH) / 360),
      ];
    }
  }
  const tInterp = [
    ...tIn,
    ...tIn.map(v => v + o.nOut),
    ...tIn.map(v => v + o.nOut * 2),
  ];
  const tOut = range(o.nOut).map(i => o.nOut + i);
  const interpOut = range(o.nOut).map(() => [0, 0, 0]);
  for (let j = 0; j < 3; ++j) {
    const componentInterp = interpLoop.map(v => v[j]);
    const zeros = interpLoop.map(() => 0);
    const componentOut = zeroDeriv
      ? piecewiseCubic(tInterp, componentInterp, zeros, tOut)
      : pchip(tInterp, componentInterp, tOut);
    for (const [i, v] of Object.entries(componentOut)) {
      interpOut[Number.parseInt(i)][j] = v;
    }
  }
  const lchOut: Triple[] = o.luv
    ? interpOut.map(([L, U, V]) => [
        L,
        Math.sqrt(U * U + V * V),
        ((Math.atan2(V, U) * 180) / Math.PI + 360) % 360,
      ])
    : interpOut.map(([L, C, H]) => [L, C, H % 360]);
  return lchOut.map(lchToRgb);
}

import React from 'react';
import {useSelector} from 'react-redux';
import {ulid} from 'ulid';

import {UpdateHeatProfileDto} from '../api/HeatProfile.hooks';
import {
  getAccountMoodLightSelector,
  getPeakMoodLightSelector,
} from '../redux/moodLightSlice';
import {
  CustomMoodLight,
  Dictionary,
  Profile,
  isCustomMoodLight,
  isTHeatProfileMoodLight,
} from '../types';
import {useGetUpdatedPeakMoodLights} from './useGetUpdatedPeakMoodLights';

/* Used to get updated account/peak mood lights after swapping profiles as well as
 * archive profiles with updated mood light references and UpdateHeatProfileDtos.
 */
export const useGetSwappedProfileValues = () => {
  const getAccountMoodLight = useSelector(getAccountMoodLightSelector);
  const getPeakMoodLight = useSelector(getPeakMoodLightSelector);
  const getUpdatedPeakMoodLights = useGetUpdatedPeakMoodLights();

  return React.useCallback(
    ({
      swappedActives,
      swappedArchives,
      userId,
    }: {
      swappedActives: Profile[];
      swappedArchives: Profile[];
      userId: number | undefined;
    }) => {
      const updateHeatProfileDtos: UpdateHeatProfileDto[] = [];
      const newAccountMoodLights: CustomMoodLight[] = [];
      const moodLightMapping: Dictionary<string, CustomMoodLight> = {};
      const updatedArchives = swappedArchives.map(archive => {
        if (isTHeatProfileMoodLight(archive)) {
          const peakMoodLight = getPeakMoodLight(archive.moodLightId);
          if (
            peakMoodLight &&
            isCustomMoodLight(peakMoodLight) &&
            !getAccountMoodLight(archive.moodLightId)
          ) {
            // Ensure that heat profiles using the same mood light will have the same mood light id
            let newMoodLight = moodLightMapping[archive.moodLightId];
            if (!newMoodLight) {
              newMoodLight = {...peakMoodLight, id: ulid()};
              moodLightMapping[archive.moodLightId] = newMoodLight;
            }

            const newArchive = {...archive, moodLightId: newMoodLight.id};
            newAccountMoodLights.push(newMoodLight);
            userId !== undefined &&
              updateHeatProfileDtos.push({
                ...newArchive,
                userId,
                moodLight: {...newMoodLight, userId},
              });
            return newArchive;
          }
        }
        userId !== undefined &&
          updateHeatProfileDtos.push({...archive, userId});
        return archive;
      });

      return {
        newAccountMoodLights,
        newPeakMoodLights: getUpdatedPeakMoodLights({actives: swappedActives}),
        updatedArchives,
        updateHeatProfileDtos,
      };
    },
    [getAccountMoodLight, getPeakMoodLight, getUpdatedPeakMoodLights, ulid],
  );
};

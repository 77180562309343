import {AgreementType} from 'puffco-api-axios-client';
import React from 'react';
import {useAsync} from 'react-use';

import {ImgBackground} from '../../components';
import {Screens, appColors} from '../../constants';
import {agreementApi} from '../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {WebView} from '../../shims/WebView';

export interface Props {}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.PrivacyPolicy> {}

export const PrivacyPolicyScreen: React.FC<ScreenProps> = () => {
  useAdaptiveSafeArea();

  const {value: agreementData} = useAsync(
    () => agreementApi.getLatestAgreement({type: AgreementType.Pp}),
    [],
  );

  return (
    <ImgBackground>
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: appColors.baseBackground,
        }}>
        <WebView
          source={{
            html: agreementData?.data.content ?? '',
          }}
          scalesPageToFit={false}
        />
      </SafeAreaView>
    </ImgBackground>
  );
};

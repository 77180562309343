import React from 'react';
import {StyleProp, Text, TextStyle, View} from 'react-native';

import {
  appColors,
  ProfileSliderSwitchTitleStyle as labelsStyle,
} from '../constants';
import {getTimeFormat} from '../lib/getTimeFormat';
import styled from '../lib/styled';
import {useThrottle} from '../lib/useThrottle';
import {Slider, SliderProps} from './Slider';
import {IconName, StyledIcon} from './StyledIcon';

export type StyledSliderProps = {
  /*
   * Name of the slider
   */
  title?: string;
  /**
   * Name of the styled icon to be used to the left of the slider
   */
  name?: IconName;
  /**
   * Name of the styled icon on the right side
   */
  nameRight?: IconName;
  /**
   * This is in the form '[abc]#[xyz]' where # will be replaced with the slider value
   * '+#%' yields +43% on the top right corner
   * using 'time' will convert seconds to readable format
   */
  messageFormat?: string | 'time';
  /**
   * The faint message printed to the top right of the slider indicating slider value
   * supercedes messageFormat
   */
  message?: string;
  /**
   * Color of the icon
   */
  color?: string;
  fontSize?: number;
  fontFamily?: string;
  fontLetterSpacing?: number;
  fontColor?: string;
  bottomFontColor?: string;
  iconSize?: number;

  paddingBottom?: number;
  noHeader?: boolean;
  containerMarginLeft?: number | string;
  numberFormat?: (val: number) => string;
  tickers?: string[];
  enabled?: boolean;
  titleMarginBottom?: number;
  titleIcon?: IconName;
  hideTickers?: boolean;
  paddingHorizontal?: number;
} & SliderProps;

export const StyledSlider = (props: StyledSliderProps) => {
  const {
    color,
    onSlidingComplete,
    onValueChange,
    name,
    nameRight,
    message,
    messageFormat,
    title,
    step,
    maximumTrackTintColor,
    minimumTrackTintColor,
    maximumValue,
    minimumValue,
    thumbTintColor,
    value,
    fontColor,
    bottomFontColor,
    fontSize,
    fontFamily,
    fontLetterSpacing,
    iconSize,
    trackImage,
    containerWidth,
    containerMarginLeft,
    noHeader,
    paddingBottom,
    numberFormat,
    tickers,
    nTickers,
    enabled = true,
    titleMarginBottom,
    onAutoSlide,
    titleIcon,
    hideTickers,
    paddingHorizontal,
  } = props;
  const throttledValueChange = useThrottle(onValueChange, 100);
  let leftMessage = (messageFormat && messageFormat.split('#')[0]) || '';
  let rightMessage = (messageFormat && messageFormat.split('#')[1]) || '';
  let valueMessage = value?.toString();
  const textStyle: StyleProp<TextStyle> = {
    color: fontColor ?? appColors.white,
    fontSize: fontSize ?? labelsStyle.fontSize,
    fontFamily: fontFamily ?? labelsStyle.fontFamily,
    letterSpacing: fontLetterSpacing ?? labelsStyle.letterSpacing,
  };
  if (messageFormat === 'time') {
    leftMessage = '+';
    rightMessage = '';
    valueMessage = getTimeFormat(value || 0, {
      padMinutes: false,
      padSeconds: true,
    });
  }

  const displayTickers = hideTickers
    ? undefined
    : tickers
      ? tickers.length > 3
        ? [tickers[0], tickers[tickers?.length - 1]]
        : tickers
      : [
          numberFormat ? numberFormat(minimumValue) : minimumValue,
          numberFormat ? numberFormat(maximumValue) : maximumValue,
        ];

  return (
    <View
      style={{
        paddingBottom: paddingBottom ?? 0,
        width: '100%',
      }}>
      {!noHeader && (
        <HorizontalContainer style={{marginBottom: titleMarginBottom || 0}}>
          <TitleContainer>
            {titleIcon && (
              <StyledIcon
                name={titleIcon}
                style={{width: 15, height: 15, marginRight: 6}}
                color={!enabled ? '#606060' : thumbTintColor}
              />
            )}

            <SliderText style={textStyle}>{title || ''}</SliderText>
          </TitleContainer>

          <SliderText style={textStyle}>
            {message ||
              (messageFormat && leftMessage + valueMessage + rightMessage) ||
              ''}
          </SliderText>
        </HorizontalContainer>
      )}
      <LowerContainer>
        {name && (
          <IconContainer>
            <StyledIcon
              size={iconSize}
              name={name || 'brightness'}
              color={color || appColors.white}
              containerSize={25}
              style={{
                padding: 0,
                marginLeft: -2,
                alignItems: 'center',
              }}
            />
          </IconContainer>
        )}
        <Slider
          step={step}
          paddingHorizontal={paddingHorizontal}
          value={value}
          maximumValue={maximumValue || 100}
          minimumValue={minimumValue || 0}
          thumbTintColor={thumbTintColor ?? appColors.white}
          maximumTrackTintColor={maximumTrackTintColor}
          minimumTrackTintColor={minimumTrackTintColor}
          containerWidth={containerWidth}
          containerMarginLeft={containerMarginLeft}
          trackImage={trackImage}
          onValueChange={(value: number) => throttledValueChange(value)}
          onSlidingComplete={(value: number) => onSlidingComplete(value)}
          onAutoSlide={onAutoSlide}
          nTickers={nTickers}
          enabled={enabled}
        />

        {nameRight && <StyledIcon name={nameRight || 'brightness'} />}
      </LowerContainer>
      <HorizontalContainer style={{alignItems: 'flex-start'}}>
        {displayTickers?.map((ticker, i) => (
          <MinMaxContainer
            key={i}
            style={{
              alignItems:
                i === 0
                  ? 'flex-start'
                  : i === displayTickers.length - 1
                    ? 'flex-end'
                    : 'center',
            }}>
            <MinMaxText style={{...textStyle, color: bottomFontColor}}>
              {ticker}
            </MinMaxText>
          </MinMaxContainer>
        ))}
      </HorizontalContainer>
    </View>
  );
};

const HorizontalContainer = styled(View)({
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
});

const TitleContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});

const IconContainer = styled(View)({
  width: 40,
});

const LowerContainer = styled(HorizontalContainer)({
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const SliderText = styled(Text)({
  ...labelsStyle,
});
const MinMaxContainer = styled(View)({
  width: 100,
});

const MinMaxText = styled(Text)({
  ...labelsStyle,
});

import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  Block,
  Document,
  INLINES,
  Inline,
  MARKS,
} from '@contentful/rich-text-types';
import {EntryFields, RichTextContent} from 'contentful';
import React from 'react';
import {Image, StyleSheet, Text, TextStyle, View} from 'react-native';

import {Description, HeadingText, Title} from '../../../components';
import {Constants, appColors} from '../../../constants';
import {isStrictTypeGallery, resolveAssetUrl} from '../../../lib/api';
import {
  isTypeComponent,
  isTypeLinkItem,
  isTypeStyledAsset,
  isTypeVideo,
} from '../../../lib/api/content-access/types';
import {
  addOpacityToColorHex,
  isImageStyle,
} from '../../../lib/utilityFunctions';
import {VideoItem} from '../../Education/components/DeviceVideoCarousel/VideoItem';
import {Hyperlink} from '../../Education/components/Hyperlink';
import {ScaledImage} from '../../Education/components/ScaledImage';
import {Component} from './Component';
import {Gallery} from './Gallery';
import {LinkItem} from './LinkItem';

export const isEmptyParagraph = (
  node: RichTextContent | Inline | Block,
): boolean => {
  return (
    node.nodeType === BLOCKS.PARAGRAPH &&
    !!node.content &&
    node.content.length === 1 &&
    node.content[0].nodeType === 'text' &&
    node.content[0].value === ''
  );
};

const defaultOptions: Options = {
  renderNode: {
    [BLOCKS.DOCUMENT]: (_node: Block | Inline, children: React.ReactNode) => {
      return typeof children === 'string' ? (
        <Description style={styles.text}>{children}</Description>
      ) : (
        <View style={styles.documentView}>{children}</View>
      );
    },
    [BLOCKS.UL_LIST]: (_node: Block | Inline, children: React.ReactNode) => {
      return <View style={styles.ulList}>{children}</View>;
    },
    [BLOCKS.LIST_ITEM]: (_node: Block | Inline, children: React.ReactNode) => {
      return (
        <View style={styles.listItemContainer}>
          <ListMarker />
          <Text>{children}</Text>
        </View>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
      if (isEmptyParagraph(node)) return <View style={styles.emptyParagraph} />;

      return <Description style={styles.text}>{children}</Description>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
      const entry = node.data.target;

      if (isTypeVideo(entry))
        return (
          <VideoItem
            thumbnail={resolveAssetUrl(entry.fields.thumbnailImage)}
            title={entry.fields.title}
            link={entry.fields.link}
          />
        );

      if (isTypeComponent(entry)) return <Component component={entry} />;

      if (isStrictTypeGallery(entry)) return <Gallery gallery={entry} />;

      if (isTypeLinkItem(entry)) return <LinkItem item={entry} />;

      if (isTypeStyledAsset(entry)) {
        if (!isImageStyle(entry.fields.style) && !!entry.fields.style)
          return null;

        return (
          <Image
            style={entry.fields.style ?? styles.image}
            source={{uri: resolveAssetUrl(entry.fields.asset)}}
          />
        );
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      const uri = resolveAssetUrl(node.data.target);

      return <ScaledImage height={30} uri={uri} />;
    },
    [BLOCKS.HEADING_4]: (_node: Block | Inline, children: React.ReactNode) => {
      return <Text style={styles.heading4}>{children}</Text>;
    },
    [BLOCKS.HEADING_3]: (_node: Block | Inline, children: React.ReactNode) => {
      return <Title>{children}</Title>;
    },
    [BLOCKS.HEADING_2]: (_node: Block | Inline, children: React.ReactNode) => {
      return <Text style={styles.heading2}>{children}</Text>;
    },
    [BLOCKS.HEADING_1]: (_node: Block | Inline, children: React.ReactNode) => {
      return <HeadingText style={styles.heading1}>{children}</HeadingText>;
    },
    // Use the quote block to center blocks
    [BLOCKS.QUOTE]: (_node: Block | Inline, children: React.ReactNode) => {
      return <View style={styles.center}>{children}</View>;
    },
    [INLINES.HYPERLINK]: (node: Block | Inline, children: React.ReactNode) => {
      const {uri} = node.data;

      return (
        <Hyperlink appearance="primary" {...{uri}}>
          {children}
        </Hyperlink>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) => {
      const entry = node.data.target;

      if (isTypeVideo(entry))
        return (
          <VideoItem
            thumbnail={resolveAssetUrl(entry.fields.thumbnailImage)}
            title={entry.fields.title}
            link={entry.fields.link}
          />
        );

      if (isTypeComponent(entry)) return <Component component={entry} />;

      if (isStrictTypeGallery(entry)) return <Gallery gallery={entry} />;

      if (isTypeLinkItem(entry)) return <LinkItem item={entry} />;

      if (isTypeStyledAsset(entry)) {
        if (!isImageStyle(entry.fields.style) && !!entry.fields.style)
          return null;

        return (
          <Image
            style={entry.fields.style ?? styles.image}
            source={{uri: resolveAssetUrl(entry.fields.asset)}}
          />
        );
      }
    },
  },

  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => (
      <Text style={styles.bold}>{text}</Text>
    ),
    [MARKS.ITALIC]: (text: React.ReactNode) => (
      <Text style={styles.italic}>{text}</Text>
    ),
    [MARKS.UNDERLINE]: (text: React.ReactNode) => (
      <Text style={styles.underline}>{text}</Text>
    ),
    [MARKS.SUPERSCRIPT]: (text: React.ReactNode) => (
      <View style={styles.superscriptCenter}>
        <Text style={styles.heading4}>{text}</Text>
      </View>
    ),
  },
};

interface RichTextRendererProps {
  content: EntryFields.RichText;
  customOptions?: Options;
}

export const RichTextRenderer: React.FC<RichTextRendererProps> = ({
  content,
  customOptions,
}) => {
  const renderNode = {
    ...defaultOptions.renderNode,
    ...customOptions?.renderNode,
  };

  const renderMark = {
    ...defaultOptions.renderMark,
    ...customOptions?.renderMark,
  };

  const options = {renderNode, renderMark};

  const document = {
    ...content,
    content: content.content.filter((node, index) => {
      const isLast = index === content.content.length - 1;
      return !!renderNode[node.nodeType] && !(isLast && isEmptyParagraph(node));
    }),
  };

  return (
    content && (
      <View>{documentToReactComponents(document as Document, options)}</View>
    )
  );
};

export const ListMarker = ({style}: {style?: TextStyle}) => (
  <Text style={[styles.bulletPoint, style]}>•</Text>
);

export const styles = StyleSheet.create({
  text: {
    alignSelf: 'flex-start',
    lineHeight: 26,
  },
  documentView: {
    display: 'flex',
    flexDirection: 'column',
  },
  ulList: {
    paddingLeft: 8,
    paddingTop: 12,
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  bulletPoint: {
    marginRight: 12,
    color: appColors.textOnSurface,
    fontSize: 16,
    lineHeight: 22,
  },
  emptyParagraph: {
    height: 12,
  },
  center: {
    alignSelf: 'center',
  },
  superscriptCenter: {
    width: Constants.DIMENSIONS.WIDTH - 2 * Constants.PAGE_HORIZONTAL_PADDING,
    alignItems: 'center',
  },
  heading4: {
    color: addOpacityToColorHex(appColors.white, 0.5),
    fontSize: 14,
  },
  heading2: {
    color: appColors.white,
    fontSize: 18,
  },
  heading1: {
    letterSpacing: 0.64,
  },
  image: {
    width: '100%',
    resizeMode: 'contain',
    aspectRatio: 1.78,
    borderRadius: 10,
  },
  bold: {
    fontWeight: '700',
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  countdown: {
    marginBottom: 48,
  },
});

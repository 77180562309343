import React from 'react';

import {Screens} from '../../constants';
import {MoodLightControllerNavigatorScreenProps} from '../../navigation/navigators/MainStackNavigator';
import {analytics} from '../../src/services/analytics';
import {MoodLightController} from './MoodLightControllerScreen';

export type Props = {
  id: string;
  isFromEditProfile?: boolean;
};

type ScreenProps = MoodLightControllerNavigatorScreenProps<
  typeof Screens.MoodLightEdit
>;

export const MoodLightEditScreen = ({route}: ScreenProps) => {
  const onSave = React.useCallback(() => {
    analytics.trackEvent('mood light edit', {});
  }, []);

  return <MoodLightController {...{onSave}} {...(route.params ?? {})} />;
};

import React from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {copy} from '../assets/images';
import {Constants, Strings, appColors} from '../constants';
import {useCopy} from '../lib/hooks';

interface Props {
  text: string;
}

export const CopyBox = ({text}: Props) => {
  const {onCopy, copied} = useCopy();

  return (
    <View
      style={[
        styles.container,
        copied
          ? {
              borderColor: appColors.transparentLight,
            }
          : {
              backgroundColor: appColors.transparentLighter,
              borderColor: appColors.invisible,
            },
      ]}>
      <Text style={styles.text}>{copied ? Strings.COPIED : text}</Text>

      <TouchableOpacity onPress={() => onCopy({text})}>
        <Image style={styles.icon} source={copy} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding:
      Constants.DIMENSIONS.HEIGHT < Constants.SCREEN_HEIGHT.PX_785 ? 12 : 20,
    borderRadius: 8,
    width: '100%',
    borderWidth: 1,
  },
  text: {
    marginRight: 10,
    textTransform: 'uppercase',
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: appColors.black,
  },
  icon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
});

import React from 'react';
import {Image, Text, TouchableOpacity} from 'react-native';
import {StyleProp} from 'react-native';
import {TextStyle} from 'react-native';

import {learnMoreIcon} from '../../../assets/images';
import {appColors} from '../../../constants';
import {useLink} from '../../../lib/hooks/useLink';
import styled from '../../../lib/styled';

interface Props {
  uri: string;
  style?: StyleProp<TextStyle>;
  appearance?: 'primary' | 'default';
}

export const Hyperlink = ({
  uri,
  children,
  style,
  appearance = 'default',
}: React.PropsWithChildren<Props>) => {
  const handleLink = useLink();

  return (
    <>
      <LinkText onPress={() => handleLink(uri)} {...{style}}>
        {children}
      </LinkText>

      <HyperLinkIconContainer onPress={() => handleLink(uri)}>
        {appearance === 'primary' && (
          <ExtenralLinkIcon source={learnMoreIcon} />
        )}
      </HyperLinkIconContainer>
    </>
  );
};

const HyperLinkIconContainer = styled(TouchableOpacity)({
  flexDirection: 'row',
  alignItems: 'center',
});

const ExtenralLinkIcon = styled(Image)({
  width: 10,
  height: 11,
  marginStart: 4,
  marginTop: 3,
});

export const LinkText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  fontWeight: '400',
  textDecorationLine: 'underline',
  color: appColors.azureBlue,
});

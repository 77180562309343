import {AppState, AppStateStatus} from 'react-native';

const waitUntilAppState = (appState: AppStateStatus, timeout?: number) => {
  return new Promise<void>((resolve, reject) => {
    const timeoutId = timeout
      ? setTimeout(() => {
          subscription.remove();
          reject(new Error(`App didn't come to foreground in ${timeout}ms.`));
        }, timeout)
      : undefined;

    const subscription = AppState.addEventListener('change', state => {
      if (state !== appState) return;

      if (timeoutId) clearTimeout(timeoutId);
      subscription.remove();

      return resolve();
    });
  });
};

export const waitUntilForeground = (timeout?: number) =>
  waitUntilAppState('active', timeout);

import React from 'react';
import {Dimensions} from 'react-native';

type DimensionsChangeListener = Parameters<
  typeof Dimensions.addEventListener
>[1];

export const useDimensions = () => {
  const [dimensions, setDimensions] = React.useState<
    Parameters<DimensionsChangeListener>[0]
  >({window: Dimensions.get('window'), screen: Dimensions.get('screen')});

  React.useEffect(() => {
    const listener: DimensionsChangeListener = event =>
      setDimensions({...event});
    const subscription = Dimensions.addEventListener('change', listener);

    return () => {
      subscription.remove();
    };
  }, []);

  return dimensions;
};

import {ReferralDto} from 'puffco-api-axios-client';
import React from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';

import {copy} from '../../../assets/images';
import {Constants, Strings, appColors} from '../../../constants';
import {useCopy} from '../../../lib/hooks';
import styled from '../../../lib/styled';

interface Props extends ViewProps, ReferralDto {}

export const ReferralRewardCard = ({code, isUsed, style}: Props) => {
  const {onCopy, copied} = useCopy();

  return (
    <View style={[styles.container, style]}>
      <View style={styles.codeContainer}>
        <Circle
          style={{
            backgroundColor: isUsed ? appColors.red : appColors.readyGreen,
          }}
        />

        <Text
          style={[
            styles.code,
            isUsed && {opacity: 0.5, textDecorationLine: 'line-through'},
          ]}>
          {code}
        </Text>
      </View>

      {!isUsed && (
        <TouchableOpacity
          style={[
            styles.copyBox,
            {backgroundColor: copied ? appColors.black : appColors.white},
            copied
              ? {
                  borderColor: appColors.textOnSurface,
                  backgroundColor: appColors.black,
                }
              : {
                  borderColor: appColors.invisible,
                  backgroundColor: appColors.white,
                },
          ]}
          onPress={() => onCopy({text: code})}>
          <Image
            source={copy}
            style={[styles.icon, copied && {tintColor: appColors.white}]}
          />

          <Text
            style={[
              styles.copyText,
              {color: copied ? appColors.white : appColors.black},
            ]}>
            {copied ? Strings.COPIED : Strings.COPY}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};
const Circle = styled(View)({
  height: 9,
  width: 9,
  borderRadius: 50,
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    paddingVertical: 24,
    borderColor: appColors.transluscentLightGray,
  },
  codeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  code: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: appColors.white,
    maxWidth: Constants.DIMENSIONS.WIDTH * 0.56,
  },
  icon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  copyBox: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    paddingVertical: 8,
    paddingHorizontal: Constants.DIMENSIONS.WIDTH < 380 ? 12 : 16,
    borderRadius: 50,
    marginLeft: 6,
    borderWidth: 1,
  },
  copyText: {
    fontFamily: 'Roboto-Bold',
    textTransform: 'uppercase',
    fontSize: Constants.DIMENSIONS.WIDTH < 380 ? 14 : 16,
    marginLeft: 6,
  },
});

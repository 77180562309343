import {useNavigation} from '@react-navigation/native';
import {Linking} from 'react-native';

const isExternalLink = (link: string) =>
  link.startsWith('http://') || link.startsWith('https://');

const isMailTo = (link: string) => link.startsWith('mailto:');

export const useLink = () => {
  const {navigate} = useNavigation<any>();

  return (link: string) =>
    isExternalLink(link) || isMailTo(link)
      ? Linking.openURL(link)
      : navigate(link);
};

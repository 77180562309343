import React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {Title} from '../../../components';
import styled from '../../../lib/styled';
import {PossibleText, RichTextString} from './RichTextString';

interface Props {
  title?: PossibleText;
  style?: StyleProp<ViewStyle>;
}

export const Section = ({
  title,
  style,
  children,
}: React.PropsWithChildren<Props>) => (
  <View style={[styles.container, style]}>
    {!!title && (
      <RichTextString text={title} as={SectionTitle} style={styles.title} />
    )}

    {children}
  </View>
);

const SectionTitle = styled(Title)({
  fontSize: 14,
  textTransform: 'uppercase',
  opacity: 0.5,
});

const styles = StyleSheet.create({
  container: {
    marginBottom: 48,
  },
  title: {
    marginBottom: 16,
  },
});

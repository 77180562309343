import {Formik, FormikHelpers, FormikProps} from 'formik';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {
  AppText,
  BottomSheetButtonProps,
  BottomSheetButtonTitle,
  BottomSheetComponent,
  BottomSheetDetail,
  StyledField,
  StyledIcon,
  displayRightIconMessage,
  getRightIconName,
} from '../../../components';
import {
  Alerts,
  Constants,
  ErrorMessages,
  Screens,
  Strings,
  appColors,
  interpolate,
} from '../../../constants';
import {Auth} from '../../../contexts/auth';
import {updateUser} from '../../../lib/api/User.hooks';
import {userApi} from '../../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../../lib/hooks/useAdaptiveSafeArea';
import {updateAppFlags} from '../../../lib/redux/appFlagsSlice';
import {userSelector} from '../../../lib/redux/userSlice';
import styled from '../../../lib/styled';
import {AccountNavigatorScreenProps} from '../../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../../shims/SafeAreaView';
import {Alert} from '../../../shims/alert';
import {pushService} from '../../../src/services/push';
import {isDisplayZoomed} from '../../lib/isDisplayZoomed';
import {getLetter} from './lib/getLetter';

interface UpdateUserValues {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const {GALAXY_S8, IPHONE_8} = Constants.SCREEN_HEIGHT;
const {height} = Dimensions.get('window');
const isSmall = height <= IPHONE_8 || height <= GALAXY_S8;

interface Props extends AccountNavigatorScreenProps<typeof Screens.Account> {}

export const AccountScreen = ({navigation}: Props) => {
  const dispatch = useDispatch();
  const {logout} = Auth.useContainer();
  useAdaptiveSafeArea();
  const user = useSelector(userSelector);
  const formRef = React.useRef<FormikProps<UpdateUserValues>>(null);
  const [editing, setEditing] = React.useState(false);

  const UpdateUserSchema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .required(ErrorMessages.REQUIRED_USERNAME)
      .min(5, ErrorMessages.REQUIRE_USERNAME_MIN_LENGTH)
      .max(18, ErrorMessages.REQUIRE_USERNAME_MAX_LENGTH)
      .test({
        name: 'checkUsername',
        test: async function (value) {
          if (!value || !value.length || value === user?.username) return true;

          const {data} = await userApi.validateFields({
            userValidateFieldsDto: {username: value},
          });

          if (data.username === 'ok') return true;

          const message =
            data.username === 'taken'
              ? 'Username is taken'
              : data.username === 'offensive'
                ? 'Offensive usernames are prohibited'
                : 'Username is invalid';

          return this.createError({message, path: 'username'});
        },
      }),
    password: Yup.string()
      .required(ErrorMessages.REQUIRED_PASSWORD)
      .matches(
        Constants.VALID_PASSWORD_REGEX,
        ErrorMessages.PASSWORD_FORMAT_ERROR,
      )
      .min(8, ErrorMessages.PASSWORD_FORMAT_ERROR),
  });

  React.useEffect(() => {
    if (editing) {
      navigation.setOptions({
        headerRight: () => (
          <StyledIcon name="check" onPress={onSaveButton} size={26} />
        ),
      });

      return () => {
        formRef.current?.resetForm();
        setEditing(false);
      };
    } else {
      navigation.setOptions({
        headerRight: () => (
          <StyledIcon
            name="more"
            iconStyle={{transform: [{rotate: '90deg'}]}}
            onPress={onMoreButton}
            size={26}
          />
        ),
      });
    }
  }, [editing, navigation]);

  const onDeleteButton = () => {
    Alert.alert(
      Alerts.CONFIRM_DELETE_ACCOUNT_TITLE,
      Alerts.CONFIRM_DELETE_ACCOUNT_BODY,
      [
        {
          text: Strings.CANCEL,
          style: 'cancel',
        },
        {
          text: Strings.PROCEED,
          onPress: async () => {
            pushService.deleteToken().catch(() => void 0);
            await userApi.deleteUserById({id: 'me'});
            await logout();
          },
        },
      ],
    );
  };

  const onMoreButton = () => {
    const buttonPropArr: BottomSheetButtonProps[] = [
      {
        title: BottomSheetButtonTitle.LOGOUT,
        iconName: 'logout',
        size: 16,
        iconViewStyle: {marginLeft: 5},
        onPress: () => logout(),
      },
      {
        title: BottomSheetButtonTitle.DELETE_ACCOUNT,
        iconName: 'delete',
        size: 24,
        onPress: () => onDeleteButton(),
      },
    ];
    if (user && !user.verified) {
      buttonPropArr.unshift({
        title: BottomSheetButtonTitle.VERIFY_EMAIL,
        iconName: 'mail',
        size: 18,
        onPress: async () => {
          try {
            await userApi.requestEmailVerification({id: 'me'});

            Alert.alert(
              Alerts.REQUEST_EMAIL_VERIFICATION,
              interpolate(Alerts.REQUEST_EMAIL_VERIFICATION_SUCCESS, {
                email: user.email,
              }),
            );
          } catch (error: any) {
            Alert.alert(Alerts.REQUEST_EMAIL_VERIFICATION_ERROR, error.message);
          }
        },
      });
    }
    BottomSheetComponent.display(
      <BottomSheetDetail
        header={{
          icon: (
            // TODO: If profile is found, render that. Otherwise, render default icon.
            <StyledIcon
              name={'emptyAccount'}
              color={appColors.white}
              size={26}></StyledIcon>
          ),
          title: user?.username ?? '',
          subtitle: user?.email ?? '',
          viewStyle: {backgroundColor: '#353535'},
        }}
        buttonPropArr={buttonPropArr}
      />,
    );
  };

  const onSaveButton = async () => {
    if (formRef.current?.errors) {
      if (!formRef.current.errors.email && !formRef.current.errors.username) {
        const updateData: {
          email?: string;
          username?: string;
          firstName?: string;
          lastName?: string;
        } = {};
        const {values} = formRef.current;
        if (
          values.username &&
          values.username !== '' &&
          values.username !== user?.username
        ) {
          updateData.username = values.username.trim();
          dispatch(updateAppFlags({hasSeenNewUsername: true}));
        }
        if (
          values.firstName &&
          values.firstName !== '' &&
          values.firstName !== user?.firstName
        ) {
          updateData.firstName = values.firstName.trim();
        }
        if (
          values.lastName &&
          values.lastName !== '' &&
          values.lastName !== user?.lastName
        ) {
          updateData.lastName = values.lastName.trim();
        }

        await updateUser(updateData).then(() => navigation.goBack());
      }
    }
  };

  const handleSubmit = async (
    values: UpdateUserValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    const email = values.email?.trim();
    const username = values.username?.trim();

    await updateUser({username, email});

    navigation.goBack();

    actions.setSubmitting(false);
  };

  const userFirst =
    getLetter(user?.firstName ?? '', 0) ||
    getLetter(user?.username ?? '', 0) ||
    'P';

  const userSecond =
    getLetter(user?.lastName ?? '', 0) ||
    getLetter(user?.username ?? '', 1) ||
    'U';

  return (
    <Container>
      <UserInfoContainer>
        <Initials>
          <InitialText>{userFirst}</InitialText>
          <InitialText>{userSecond}</InitialText>
        </Initials>
      </UserInfoContainer>
      <ScrollView style={{marginBottom: 10}}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{flex: 1}}>
          <Formik
            initialValues={{
              username: user ? user.username : '',
              firstName: user ? user.firstName : '',
              lastName: user ? user.lastName : '',
              email: user ? user.email : '',
              password: '',
            }}
            innerRef={formRef}
            onSubmit={handleSubmit}
            validationSchema={UpdateUserSchema}>
            {({
              handleBlur,
              handleChange,
              isSubmitting,
              touched,
              values,
              errors,
              setFieldValue,
            }) => (
              <FormContainer>
                <SpinnerContainer>
                  {isSubmitting && (
                    <ActivityIndicator size="large" color={appColors.black} />
                  )}
                </SpinnerContainer>
                <FieldsContainer>
                  <TitleText>ACCOUNT INFORMATION</TitleText>
                  <FieldLabel>USERNAME</FieldLabel>
                  <StyledField
                    name={'emptyAccount'}
                    borderColor={appColors.mediumGray}
                    height={35}
                    onChangeText={handleChange('username')}
                    onBlur={() => {
                      handleBlur('username');
                      setEditing(true);
                    }}
                    value={values.username}
                    leftIconColor={appColors.mediumGray}
                    rightIcon={getRightIconName(
                      values.username,
                      errors.username,
                      user?.username !== values.username,
                    )}
                    rightIconColor={
                      errors.username ? appColors.red : appColors.green
                    }
                    rightIconMessage={displayRightIconMessage(
                      values.username,
                      errors.username,
                      touched.username,
                    )}
                    textColor={appColors.white}
                  />
                  <FieldLabel>FIRST NAME</FieldLabel>
                  <StyledField
                    name={'emptyAccount'}
                    borderColor={appColors.mediumGray}
                    height={35}
                    onChangeText={handleChange('firstName')}
                    onBlur={() => {
                      handleBlur('firstName');
                      setEditing(true);
                    }}
                    value={values.firstName}
                    leftIconColor={appColors.mediumGray}
                    textColor={appColors.white}
                  />
                  <FieldLabel>LAST NAME</FieldLabel>
                  <StyledField
                    name={'emptyAccount'}
                    borderColor={appColors.mediumGray}
                    height={35}
                    onChangeText={handleChange('lastName')}
                    onBlur={() => {
                      handleBlur('lastName');
                      setEditing(true);
                    }}
                    value={values.lastName}
                    leftIconColor={appColors.mediumGray}
                    textColor={appColors.white}
                  />
                  <FieldLabel>EMAIL</FieldLabel>
                  <StyledField
                    name={'mail'}
                    borderColor={appColors.mediumGray}
                    height={35}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    keyboardType="email-address"
                    leftIconColor={appColors.mediumGray}
                    editable={false}
                    textColor={appColors.mediumGray}
                  />
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate(Screens.ChangeEmail, {
                        setAccountFieldValue: setFieldValue,
                      })
                    }>
                    <GrayLink>Update Email</GrayLink>
                  </TouchableOpacity>
                  <FieldLabel>PASSWORD</FieldLabel>
                  <StyledField
                    name={'lock'}
                    borderColor={appColors.mediumGray}
                    height={35}
                    onChangeText={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                    value={'password'}
                    password={true}
                    editable={false}
                    leftIconColor={appColors.mediumGray}
                    textColor={appColors.mediumGray}
                  />
                  <TouchableOpacity
                    onPress={() => navigation.navigate(Screens.ChangePassword)}>
                    <GrayLink>Reset Password</GrayLink>
                  </TouchableOpacity>
                </FieldsContainer>
              </FormContainer>
            )}
          </Formik>
        </KeyboardAvoidingView>
      </ScrollView>
    </Container>
  );
};

const Container = styled(SafeAreaView)({
  flex: 1,
  backgroundColor: appColors.black,
});

const SpinnerContainer = styled(View)({
  position: 'absolute',
  top: '55%',
});

export const ButtonText = styled(Text)({
  textAlign: 'center',
  color: appColors.white,
  fontFamily: 'Roboto-Medium',
  fontSize: 12,
  fontWeight: '400',
});

//MARK:- User Info
const UserInfoContainer = styled(View)({
  alignItems: 'center',
  marginTop: isSmall ? 55 : 75,
  width: '100%',
  justifyContent: 'center',
});

const Initials = styled(View)({
  width: isDisplayZoomed() ? 80 : 120,
  height: isDisplayZoomed() ? 80 : 120,
  borderRadius: 72.5,
  backgroundColor: appColors.darkGray,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
});

const InitialText = styled(AppText)({
  fontSize: isDisplayZoomed() ? 40 : 65,
  fontFamily: 'Roboto-Bold',
  color: appColors.gray,
  paddingTop: 0,
});

const TitleText = styled(Text)({
  color: '#999',
  fontFamily: 'Roboto-Bold',
  fontSize: 12,
  fontWeight: '400',
});

const FormContainer = styled(View)({
  flex: 1,
  marginHorizontal: 50,
});

const FieldsContainer = styled(View)({
  flex: 1,
  marginTop: isSmall ? (isDisplayZoomed() ? 10 : 20) : 35,
});

const FieldLabel = styled(Text)({
  color: appColors.white,
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  letterSpacing: 0.5,
  marginTop: isSmall ? 15 : 25,
  fontWeight: '400',
});

const GrayLink = styled(Text)({
  color: appColors.white50,
  fontFamily: 'Roboto-Regular',
  marginTop: 2,
  textDecorationLine: 'underline',
  fontWeight: '400',
});

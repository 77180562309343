import {otaApi} from '../api/apis';
import {useAppDispatch} from '../hooks';
import {setOta} from '../redux/bleSlice';
import {useAsyncFn} from './useAsyncFn';

export const useGetLatestOtaFile = () => {
  const dispatch = useAppDispatch();

  return useAsyncFn(async (serialNumber: string) => {
    try {
      const ota = await otaApi.getLatestOta({serialNumber}).then(r => r.data);
      dispatch(setOta({version: ota.version}));
      return ota;
    } catch (error) {
      dispatch(setOta());
      throw error;
    }
  }, []);
};

import React from 'react';
import {Animated, Pressable, Text, View, ViewStyle} from 'react-native';

import {Constants, appColors, fillStyle} from '../constants';
import {useDimensions} from '../lib/hooks';
import styled from '../lib/styled';
import {useSafeArea} from '../lib/useSafeArea';
import {AppText} from './AppText';
import {StyledIcon} from './StyledIcon';

export type BottomSheetScrollable = {
  addButtonAction: () => void;
  innerComponent: () => JSX.Element;
  titleSubheader?: string;
  titleHeader: string;
  onOpenChange?: (open: boolean) => void;
  onMinimizedPress?: (isEnabled: boolean) => void;
  isOpen: boolean;
  isEnabled?: boolean;
  headerOnlyVisibleHeight?: number;
  screenHeight: number;
};

export const BottomSheetScrollable = ({
  addButtonAction,
  innerComponent,
  onOpenChange,
  onMinimizedPress,
  isOpen,
  isEnabled = true,
  titleHeader,
  titleSubheader,
  headerOnlyVisibleHeight,
  screenHeight,
}: BottomSheetScrollable) => {
  const {
    window: {width},
  } = useDimensions();
  const backgroundColor = appColors.black;
  const primaryColor = appColors.white;
  const [showSheet, setShowSheet] = React.useState(false);
  const insets = useSafeArea();
  const headerOnlyVisiblePosition =
    screenHeight -
    (headerOnlyVisibleHeight ?? Constants.BOTTOM_SHEET_HEADER_HEIGHT);

  const pan = React.useRef(new Animated.ValueXY()).current;

  const openSheet = React.useCallback(() => {
    pan.flattenOffset();
    Animated.spring(pan, {
      toValue: {x: 0, y: 0},
      useNativeDriver: false,
    }).start();
    onOpenChange && onOpenChange(true);
  }, [onOpenChange, pan]);

  const closeSheet = React.useCallback(() => {
    pan.flattenOffset();
    Animated.spring(pan, {
      toValue: {x: 0, y: headerOnlyVisiblePosition},
      useNativeDriver: false,
    }).start();
    onOpenChange && onOpenChange(false);
  }, [headerOnlyVisiblePosition, onOpenChange, pan]);

  React.useEffect(() => {
    pan.setValue({
      x: 0,
      y: isOpen ? 0 : headerOnlyVisiblePosition,
    });
    setShowSheet(true);
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      openSheet();
    } else {
      closeSheet();
    }
  }, [closeSheet, isOpen, openSheet]);

  return showSheet ? (
    <Animated.View
      style={{
        ...animatedViewStyle,
        backgroundColor,
        top: pan.y,
        width,
        paddingTop: isOpen && Constants.IS_WEB ? insets.top : 0,
        paddingBottom: isOpen && Constants.IS_WEB ? insets.bottom : 0,
      }}>
      <Header
        onPress={() => {
          onMinimizedPress && !isOpen && onMinimizedPress(isEnabled);
          isEnabled && !isOpen && openSheet();
        }}>
        <DraggableContainer>
          <HeaderTitleText style={{color: primaryColor}}>
            {titleHeader}
          </HeaderTitleText>
          {!isOpen && (
            <HeaderSubtitleText style={{color: primaryColor}}>
              {titleSubheader}
            </HeaderSubtitleText>
          )}
        </DraggableContainer>
        <StyledIcon
          onPress={() => {
            onMinimizedPress && !isOpen && onMinimizedPress(isEnabled);
            isEnabled && (isOpen ? closeSheet() : openSheet());
          }}
          name={isOpen ? 'chevronDown' : 'chevronUp'}
          iconStyle={{tintColor: primaryColor}}
          style={{position: 'absolute', left: 5}}
        />
        {isOpen && (
          <StyledIcon
            onPress={() => isEnabled && addButtonAction()}
            name={'add'}
            iconStyle={{tintColor: primaryColor}}
            style={{position: 'absolute', right: 5}}
          />
        )}
      </Header>
      {innerComponent()}
    </Animated.View>
  ) : (
    <View />
  );
};

const animatedViewStyle: ViewStyle = {
  left: 0,
  bottom: 0,
  position: 'absolute',
  borderTopRightRadius: 10,
  borderTopLeftRadius: 10,
};

const HeaderTitleText = styled(AppText)({
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  textTransform: 'uppercase',
});

const HeaderSubtitleText = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  fontSize: 12,
  letterSpacing: 0.6,
  opacity: 0.5,
  height: 15,
  textTransform: 'uppercase',
});

const Header = styled(Pressable)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: Constants.BOTTOM_SHEET_HEADER_HEIGHT,
  borderTopRightRadius: 10,
  borderTopLeftRadius: 10,
  alignItems: 'center',
});

const DraggableContainer = styled(View)({
  ...fillStyle,
  justifyContent: 'center',
  alignItems: 'center',
  color: appColors.invisible, // Needed to make whole view draggable on Android
});

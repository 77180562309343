import React from 'react';
import {Text} from 'react-native';

import {backgroundForgotPassword} from '../../assets/images';
import {Messages, Screens} from '../../constants';
import {appColors} from '../../constants';
import {useWindowHeight} from '../../lib/hooks';
import styled from '../../lib/styled';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {ImageAndContentContainer, TermsAndPrivacy} from '../components';

const {
  PASSWORD_RESET_SENT_TITLE,
  PASSWORD_RESET_INSTRUCTIONS,
  PASSWORD_RESET_SENT_MSG,
} = Messages;

export interface Props {
  email?: string;
}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.PasswordResetSent> {}

export const PasswordResetSentScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const {isVeryLarge} = useWindowHeight();

  const email = route?.params?.email ?? '';

  const onButtonClick = () => {
    navigation.pop(2);
  };

  return (
    <ImageAndContentContainer
      image={backgroundForgotPassword}
      header={{content: PASSWORD_RESET_SENT_TITLE}}
      body={{content: PASSWORD_RESET_SENT_MSG}}
      imageContainerStyle={{maxHeight: isVeryLarge ? undefined : 461}}
      bodyContainerStyle={{flex: isVeryLarge ? undefined : 0.5}}
      primaryButton={{onClick: onButtonClick}}
      bottom={{content: <TermsAndPrivacy />, style: {marginTop: 24}}}>
      <EmailText numberOfLines={2}>{email}</EmailText>
      <InstructionText>{PASSWORD_RESET_INSTRUCTIONS} </InstructionText>
    </ImageAndContentContainer>
  );
};

const EmailText = styled(Text)({
  textAlign: 'center',
  color: appColors.black,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 24,
  lineHeight: 27,
  marginVertical: 12,
  marginHorizontal: 24,
});

const InstructionText = styled(Text)({
  textAlign: 'center',
  color: appColors.black,
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  fontWeight: '400',
  marginHorizontal: 8,
  lineHeight: 27,
});

import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {appColors} from '../../../constants';

interface Props {
  title: string;
}

export const Section = ({title, children}: React.PropsWithChildren<Props>) => (
  <View style={styles.container}>
    <Text style={styles.title}>{title}</Text>

    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    marginBottom: 48,
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: '400',
    color: appColors.white,
    opacity: 0.5,
    marginHorizontal: 20,
    marginBottom: 16,
  },
});

import {Constants} from '../../constants';
import {Storage} from '../Storage';

export const getAccessToken = async (): Promise<string | null> =>
  Storage.getItem(Constants.ACCESS_TOKEN_STORAGE_KEY);

export const getRefreshToken = async (): Promise<string | null> =>
  Storage.getItem(Constants.REFRESH_TOKEN_STORAGE_KEY);

export const setTokens = async ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken?: string;
}) => {
  await Promise.all([
    Storage.setItem(Constants.ACCESS_TOKEN_STORAGE_KEY, accessToken),
    ...(refreshToken
      ? [Storage.setItem(Constants.REFRESH_TOKEN_STORAGE_KEY, refreshToken)]
      : []),
  ]);
};

export const clearTokens = async () => {
  await Promise.all([
    Storage.removeItem(Constants.ACCESS_TOKEN_STORAGE_KEY),
    Storage.removeItem(Constants.REFRESH_TOKEN_STORAGE_KEY),
  ]);
};

export enum LumaAnimation {
  PRESERVE = 0, // Preserves previous/default animation
  STEADY = 1,
  BREATHING = 5, // Nominal cycle time of 4.0 seconds
  RISING = 6, // Nominal cycle time of 3.0 seconds
  CIRCLING = 7, // Nominal cycle time of 4.0 seconds
  CIRCLING_SLOW = 21, // Requires minimum of firmware U. Matches LED API 1 Pattern
  USER_0 = 240,
  USER_1 = 241,
  USER_2 = 242,
  USER_3 = 243,
  USER_0_1 = 244, // User 0 + User 1
  USER_2_3 = 245, // User 2 + User 3
  USER_ALL = 246, // User 0 through User 3
}

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';

// import * as Sentry from '@sentry/react';

export interface HttpPromise<
  C extends HttpRequestConfig = HttpRequestConfig,
  T = any,
> extends Promise<HttpResponse<C, T>> {}

export interface HttpClientConfig {
  sentry?: {disabled?: boolean; ignoreStatus?: number[]};
}

export type CreateHttpDefaults<C extends HttpClientConfig = HttpClientConfig> =
  CreateAxiosDefaults & C;

interface HttpInstance<C extends HttpClientConfig = HttpClientConfig>
  extends AxiosInstance {
  (config: HttpRequestConfig<C>): HttpPromise<C, any>;
  (config: InternalHttpRequestConfig<C>): HttpPromise<C, any>;
  (url: string, config?: HttpRequestConfig<C>): HttpPromise<C, any>;

  defaults: AxiosInstance['defaults'] & C;
}

type InternalHttpRequestConfig<
  C extends HttpClientConfig = HttpClientConfig,
  D = any,
> = InternalAxiosRequestConfig<D> & C;
export type HttpRequestConfig<
  C extends HttpClientConfig = HttpClientConfig,
  D = any,
> = AxiosRequestConfig<D> & C;

export interface HttpError<
  C extends HttpClientConfig = HttpClientConfig,
  T = unknown,
  D = any,
> extends AxiosError<T, D> {
  config?: InternalHttpRequestConfig<C, D>;
  response?: HttpResponse<C, T, D>;
}

export interface HttpResponse<
  C extends HttpClientConfig = HttpClientConfig,
  T = any,
  D = any,
> extends AxiosResponse<T, D> {
  config: InternalHttpRequestConfig<C, D>;
}

export const isHttpError = <
  C extends HttpClientConfig = HttpClientConfig,
  T = {message: string},
  D = any,
>(
  error: unknown,
): error is HttpError<C, T> => isAxiosError<T, D>(error);

export class HttpClient<C extends HttpClientConfig = HttpClientConfig> {
  protected client: HttpInstance<C>;

  constructor(config?: CreateHttpDefaults<C>) {
    this.client = axios.create(config) as HttpInstance<C>;

    this.client.interceptors.request.use(undefined, this.report);
    this.client.interceptors.response.use(undefined, this.report);
  }

  get instance() {
    return this.client;
  }

  public get<T, P = any>(
    url: string,
    params?: P,
    config?: AxiosRequestConfig<never>,
  ) {
    return this.client
      .get<T, HttpResponse<C>>(url, {params, ...config})
      .then(this.unwrap);
  }

  public post<T, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ) {
    return this.client
      .post<T, HttpResponse<C>>(url, data, config)
      .then(this.unwrap);
  }

  public put<T, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ) {
    return this.client
      .put<T, HttpResponse<C>>(url, data, config)
      .then(this.unwrap);
  }

  public patch<T, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ) {
    return this.client
      .patch<T, HttpResponse<C>>(url, data, config)
      .then(this.unwrap);
  }

  public delete<T, D = any>(url: string, config?: AxiosRequestConfig<D>) {
    return this.client.delete<T>(url, config);
  }

  protected unwrap = <T>(response: HttpResponse<C, T>) => response.data;

  protected getReportOptions(error: HttpError<C, any>) {
    return error.isAxiosError
      ? {
          config: {
            headers: error.config?.headers,
            data: error.config?.data,
            params: error.config?.params,
          },
          response: error.response ? error.response.data : {},
        }
      : {};
  }

  private report = (error: HttpError) => {
    const shouldReport =
      this.shouldReport(this.client.defaults.sentry, error.response) &&
      this.shouldReport(error.config?.sentry, error.response);

    if (!shouldReport) throw error;

    // Sentry.withScope((scope) => {
    //   scope.setExtras(this.getReportOptions(error));
    //   Sentry.captureException(error, scope);
    // });

    throw error;
  };

  private shouldReport(
    sentry: HttpClientConfig['sentry'],
    response?: HttpResponse<any>,
  ) {
    if (!sentry) return true;

    if (sentry.disabled || !response) return false;

    if (!sentry.ignoreStatus) return true;

    return !sentry.ignoreStatus.includes(response.status);
  }
}

import throttle from 'lodash/throttle';
import React from 'react';

export function useThrottle<T>(fn: T, wait: number): T {
  const throttledFn = React.useMemo(() => {
    return throttle(fn as any, wait);
  }, [fn, wait]);
  React.useEffect(() => {
    return () => {
      throttledFn.cancel();
    };
  }, [throttledFn]);
  return throttledFn as T;
}

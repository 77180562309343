import React from 'react';
import {ScrollView, ScrollViewProps, StyleSheet, View} from 'react-native';

import {appColors} from '../../../constants';

export const ScrollLayout = ({children, style, ...rest}: ScrollViewProps) => (
  <View style={styles.container}>
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={[styles.layout, style]}
      {...rest}>
      {children}
    </ScrollView>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis: 0,
  },
  layout: {
    flex: 1,
    backgroundColor: appColors.black,
    paddingTop: 16,
  },
});

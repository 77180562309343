import {DeviceModelType} from '../lib/types';

export const MOOD_ARRAY_OFFSETS = {
  allZeros: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  noAnimationDictionary: {
    OG: [
      [],
      [
        0, 0, 0, 0, 0, 0, 65536, 0, 0, 65536, 0, 0, 65536, 65536, 0, 0, 65536,
        65536, 65536, 0,
      ],
      [
        0, 0, 0, 0, 0, 0, 65536, 0, 0, 65536, 4096, 4096, 65536, 65536, 4096,
        4096, 65536, 65536, 65536, 4096,
      ],
      [
        0, 0, 0, 0, 0, 0, 65536, 8192, 8192, 65536, 4096, 4096, 65536, 65536,
        4096, 4096, 65536, 65536, 65536, 4096,
      ],
      [
        0, 0, 0, 0, 0, 0, 65536, 8192, 12288, 65536, 4096, 4096, 65536, 65536,
        4096, 4096, 65536, 65536, 65536, 4096,
      ],
      [
        0, 0, 16384, 16384, 16384, 0, 65536, 8192, 12288, 65536, 4096, 4096,
        65536, 65536, 4096, 4096, 65536, 65536, 65536, 4096,
      ],
      [
        0, 0, 16384, 16384, 16384, 0, 65536, 8192, 12288, 65536, 4096, 4096,
        65536, 65536, 20480, 20480, 65536, 65536, 65536, 4096,
      ],
    ],
    Lightning: [
      [],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 65536, 65536, 65536, 0],
      [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4096, 4096, 0, 0, 4096, 4096, 65536,
        65536, 65536, 4096,
      ],
      [
        0, 0, 0, 0, 0, 0, 0, 8192, 8192, 0, 4096, 4096, 0, 0, 4096, 4096, 65536,
        65536, 65536, 4096,
      ],
      [
        0, 0, 0, 0, 0, 0, 0, 8192, 12288, 0, 4096, 4096, 0, 0, 4096, 4096,
        65536, 65536, 65536, 4096,
      ],
      [
        0, 0, 0, 0, 0, 0, 0, 8192, 12288, 0, 4096, 4096, 16384, 16384, 4096,
        4096, 65536, 65536, 65536, 4096,
      ],
      [
        0, 0, 0, 0, 0, 0, 20480, 8192, 12288, 20480, 4096, 4096, 16384, 16384,
        4096, 4096, 65536, 65536, 65536, 4096,
      ],
    ],
  } as Record<DeviceModelType, number[][]>,
  disco: [
    15360, 18773, 1707, 5120, 8533, 11947, 15360, 10240, 10240, 5120, 2844,
    1138, 853, 19627, 19342, 17636, 0, 0, 0, 0,
  ],
  splitGradient: [
    [],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [
      0, 0, 0, 0, 0, 0, 7680, 25600, 15360, 7680, 12800, 12800, 17920, 17920,
      12800, 12800, 15360, 15360, 15360, 15360,
    ],
    [
      0, 0, 0, 0, 0, 0, 7680, 46080, 15360, 7680, 33280, 33280, 38400, 38400,
      33280, 33280, 15360, 15360, 15360, 15360,
    ],
    [
      0, 0, 0, 0, 0, 0, 7680, 46080, 15360, 7680, 33280, 33280, 38400, 38400,
      33280, 33280, 15360, 15360, 15360, 15360,
    ],
    [
      0, 0, 0, 0, 0, 0, 7680, 66560, 15360, 7680, 53760, 53760, 58880, 58880,
      53760, 53760, 15360, 15360, 15360, 15360,
    ],
    [
      0, 0, 0, 0, 0, 0, 7680, 66560, 15360, 7680, 53760, 53760, 58880, 58880,
      53760, 53760, 15360, 15360, 15360, 15360,
    ],
  ] as number[][],
  verticalSlideShow: [
    20480, 20480, 20480, 20480, 20480, 20480, 15930, 9100, 11835, 15930, 0, 0,
    6825, 6825, 0, 0, 20480, 20480, 20480, 20480,
  ],
};

import {revisionStringToNumber} from 'pikaparam';

export const meetsMinimumFirmware = (
  currentFirmware?: string,
  minimumFirmware?: string,
) => {
  if (!currentFirmware || !minimumFirmware) return false;

  return (
    revisionStringToNumber(currentFirmware) >=
    revisionStringToNumber(minimumFirmware)
  );
};

type Options = {minApi?: string; maxApi?: string};

export const meetsRequirement = (fw: string, {minApi, maxApi}: Options) => {
  if (!maxApi && !minApi)
    throw new Error('Missing requirement minApi or maxApi option');

  if (!maxApi && minApi)
    return revisionStringToNumber(fw) >= revisionStringToNumber(minApi);

  if (!minApi && maxApi)
    return revisionStringToNumber(fw) <= revisionStringToNumber(maxApi);

  if (minApi && maxApi)
    return (
      revisionStringToNumber(fw) >= revisionStringToNumber(minApi) &&
      revisionStringToNumber(fw) <= revisionStringToNumber(maxApi)
    );
};

import {MoodAnimationJson} from '../types';

export const convertAnimArrayToNumArray = (
  moodAnimationArray: MoodAnimationJson[],
) =>
  moodAnimationArray
    .map(moodAnimationJson => {
      moodAnimationJson.luma.unshift(moodAnimationJson.time);
      return moodAnimationJson.luma;
    })
    .reduce((prev, curr) => [...prev, ...curr]);

import {
  NavigationState,
  NavigatorScreenParams,
  getActionFromState,
  getStateFromPath,
} from '@react-navigation/native';
import {Linking} from 'react-native';

import {
  Constants,
  Environment,
  ErrorMessages,
  Navigators,
  Screens,
} from '../../../constants';
import {appLog} from '../../../lib/Logger';
import {linking} from '../../../lib/NavigationService';
import {isHttpError, shareApi} from '../../../lib/api/apis';
import {RootStackParamList} from '../../../navigation/navigators/RootStackNavigator';
import {RedirectOptions} from '../../../navigation/navigators/params';
import {
  getOptionsFromAction,
  optionsToRoute,
} from '../../../navigation/navigators/util';
import {Alert} from '../../../shims/alert';
import {getSocialAuthState} from '../../util/url';

// NavigationAction is not exported from the library
export type NavigationAction<
  P = RootStackParamList,
  S extends NavigationState = NavigationState,
> = {
  type: 'NAVIGATE';
  payload: {
    name: string;
    params?: NavigatorScreenParams<P, S>;
    path?: string;
  };
};

const optionsToNavigationPayload = (
  ...args: Parameters<typeof optionsToRoute>
) => optionsToRoute(...args) as NavigationAction['payload'];

export abstract class BaseSettingsService {
  abstract openUrlInBrowser(url: string): Promise<void>;

  private normalizeUrl(url: string) {
    if (url.startsWith('/')) return url;

    const parsed = new URL(url);

    // https://domain.com/Stack/Screen?param=value -> Stack/Screen?param=value
    if (['http:', 'https:'].includes(parsed.protocol))
      return parsed.pathname + parsed.search;

    // puffco-connect://Stack/Screen?param=value -> Stack/Screen?param=value
    return url.replace(`${parsed.protocol}//`, '');
  }

  protected async getNavigationActionFromUrl(url: string) {
    const state = getStateFromPath(url);

    if (!state) return;

    const action = await this.normalizeAction(
      getActionFromState(state) as NavigationAction,
    );

    if (!action) return;

    return await this.normalizeParams(action);
  }

  protected async getOptionsFromUrl(url?: string) {
    if (!url) return;

    const action = await this.getNavigationActionFromUrl(url);

    if (!action) return;

    return getOptionsFromAction(action);
  }

  protected async normalizeParams(action: NavigationAction) {
    if (!action.payload.params) return action;

    const parsers = Object.entries({
      redirect: async (value: unknown) => {
        if (Array.isArray(value)) return value as RedirectOptions;
        if (typeof value !== 'string') return;
        return this.getOptionsFromUrl(value);
      },
    });

    const traverse = async (params: Record<string, any>) => {
      await Promise.all(
        parsers.map(async ([key, parser]) => {
          if (!params[key]) return;

          params[key] = await parser(params[key]);
        }),
      );

      if (!params.params) return;

      await traverse(params.params);
    };

    await traverse(action.payload.params);

    return action;
  }

  protected async normalizeAction(
    action?: NavigationAction,
  ): Promise<NavigationAction | undefined> {
    if (!action || action.type !== 'NAVIGATE') return action;

    switch (action.payload.name) {
      case 'login': {
        const payload: any = action.payload;

        return {
          type: action.type,
          payload: optionsToNavigationPayload([
            Screens.Login,
            {
              email: payload.params?.email,
              redirect: await this.getOptionsFromUrl(payload.params?.redirect),
            },
          ]),
        };
      }
      case 'reset-password': {
        const payload: any = action.payload;

        action.payload = optionsToNavigationPayload([
          Screens.PasswordReset,
          {token: payload.params?.token},
        ]);

        return action;
      }
      case 'confirm-email': {
        const payload: any = action.payload;

        return {
          type: action.type,
          payload: optionsToNavigationPayload([
            Screens.EmailConfirmed,
            {token: payload.params?.token},
          ]),
        };
      }
      case 'auth': {
        const payload: any = action.payload;
        const type: 'google' | 'apple' = payload.params?.screen;

        if (!['google', 'apple'].includes(type)) return;

        if (payload.params?.params?.screen !== 'callback') return;

        const {state, redirect} = await getSocialAuthState(
          payload.params?.params?.params?.state,
        );

        const code = payload.params?.params?.params?.code;

        return {
          type: action.type,
          payload: optionsToNavigationPayload([
            state === 'login' ? Screens.Login : Screens.Register,
            {
              redirect: await this.getOptionsFromUrl(redirect),
              googleCode: type === 'google' ? code : undefined,
              appleCode: type === 'apple' ? code : undefined,
            },
          ]),
        };
      }
      case 'share': {
        const shareId = action.payload.params?.screen;

        if (!shareId) return;

        const data = await shareApi
          .getShareSnapshot({shareId})
          .then(r => r.data)
          .then(({moodLightSnapshot, heatProfileSnapshot}) => ({
            hasMoodlight: !!moodLightSnapshot,
            hasProfile: !!heatProfileSnapshot,
          }))
          .catch(error => {
            if (!isHttpError(error)) return Alert.alert(error.message);

            return Alert.alert(
              error.status === 404
                ? ErrorMessages.SHARE_NOT_FOUND
                : error.response?.data?.message ?? 'Unknown error',
            );
          });

        if (!data) return;

        if (data.hasMoodlight) {
          return {
            type: action.type,
            payload: optionsToNavigationPayload([
              Navigators.MainNavigator,
              {
                screen: Navigators.HomeDrawerNavigator,
                params: {
                  screen: Navigators.HomeEmulatedDrawer,
                  params: {
                    screen: Screens.SharedMood,
                    params: {shareId},
                  },
                },
              },
            ]),
          };
        }

        if (!data.hasProfile) return;

        if (!Constants.IS_USING_APP)
          return {
            type: action.type,
            payload: optionsToNavigationPayload([
              Navigators.DownloadSharedHeatProfile,
              {
                screen: Screens.DownloadSharedHeatProfile,
                params: {shareId},
              },
            ]),
          };

        return {
          type: action.type,
          payload: optionsToNavigationPayload([
            Navigators.MainNavigator,
            {
              screen: Navigators.HomeDrawerNavigator,
              params: {
                screen: Navigators.HomeEmulatedDrawer,
                params: {
                  screen: Navigators.HomeTabNavigator,
                  params: {
                    screen: Screens.HeatProfileList,
                    params: {shareId},
                  },
                },
              },
            },
          ]),
        };
      }
    }

    return action;
  }

  async getActionFromUrl(url: string) {
    appLog.info('Get action from url.', {url});

    const supported = await this.canOpenUrl(url);

    appLog.info('Url is supported.', {supported});

    if (!supported) return;

    const deeplink = await this.isDeeplink(url);

    appLog.info('Url is deeplink.', {deeplink});

    if (!deeplink) return {type: 'external' as const, url: url};

    const action = await this.getNavigationActionFromUrl(
      this.normalizeUrl(url),
    );

    appLog.info('Action resolved from url.', {action});

    return {type: 'internal' as const, action};
  }

  protected async canOpenUrl(url: string) {
    return await Linking.canOpenURL(url);
  }

  protected async isDeeplink(url: string) {
    if (url.startsWith('/')) return true;
    if (url.startsWith(Environment.hostUrl)) return true;
    if (window?.location?.origin && url.startsWith(window.location.origin))
      return true;

    return linking.prefixes.some(p => url.startsWith(p));
  }
}

import {useNavigation} from '@react-navigation/native';
import {TransitionPresets, createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {useDispatch} from 'react-redux';

import {CloseButton} from '../../components';
import {Screens} from '../../constants';
import {resetAnswers} from '../../lib/redux/warrantySlice';
import {
  WarrantyClaimScreen,
  WarrantyClaimScreenMessage,
  WarrantyClaimScreenSuccess,
  WarrantyClaimScreenTwo,
  WarrantyClaimScreenUserInfo,
} from '../../screens';
import {WarrantyClaimUserInfoScreenProps} from '../../screens/support/WarrantyClaimScreenUserInfo';
import {WarrantyNavigatorScreenProps} from './HomeDrawerNavigator';
import {defaultNavigationOptions, withBackButton} from './common';

export type WarrantyStackParamList = {
  [Screens.WarrantyClaim]: undefined;
  [Screens.WarrantyClaimScreenTwo]: undefined;
  [Screens.WarrantyClaimScreenUserInfo]: WarrantyClaimUserInfoScreenProps;
  [Screens.WarrantyClaimScreenMessage]: undefined;
  [Screens.WarrantyClaimScreenSuccess]: undefined;
};

const WarrantyStackNavigator = createStackNavigator<WarrantyStackParamList>();

type Navigation = WarrantyNavigatorScreenProps<
  keyof WarrantyStackParamList
>['navigation'];

export const WarrantyStack = () => {
  const navigation = useNavigation<Navigation>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetAnswers());
  }, []);

  return (
    <WarrantyStackNavigator.Navigator
      initialRouteName={Screens.WarrantyClaim}
      screenOptions={() => ({
        ...defaultNavigationOptions,
        ...TransitionPresets.SlideFromRightIOS,
        headerTitle: 'WARRANTY CLAIM',
        gestureEnabled: false,
        ...withBackButton(),
        headerRight: () => <CloseButton onPress={() => navigation.pop()} />,
      })}>
      <WarrantyStackNavigator.Screen
        name={Screens.WarrantyClaim}
        component={WarrantyClaimScreen}
      />

      <WarrantyStackNavigator.Screen
        name={Screens.WarrantyClaimScreenMessage}
        component={WarrantyClaimScreenMessage}
      />

      <WarrantyStackNavigator.Screen
        name={Screens.WarrantyClaimScreenTwo}
        component={WarrantyClaimScreenTwo}
      />

      <WarrantyStackNavigator.Screen
        name={Screens.WarrantyClaimScreenUserInfo}
        component={WarrantyClaimScreenUserInfo}
      />

      <WarrantyStackNavigator.Screen
        name={Screens.WarrantyClaimScreenSuccess}
        component={WarrantyClaimScreenSuccess}
        options={{
          headerLeft: () => null,
          headerRight: () => null,
        }}
      />
    </WarrantyStackNavigator.Navigator>
  );
};

import {Buffer} from 'buffer';
import {chunk, isEqual, isNil} from 'lodash';
import {
  FaultLog1Type,
  LogIndex,
  RgbtColor,
  codecs,
  getClockAdjEpochs,
  makeRecord,
  params,
} from 'pikaparam';
import {ProfileVersion, TemperatureUnit} from 'puffco-api-axios-client';

import {
  Constants,
  DICTIONARIES,
  ScratchpadVersions,
  appColors,
} from '../../../../constants';
import {convertHexToRgbArray} from '../../../convertHexToRgbArray';
import {convertRgbArrayToHex} from '../../../convertRgbArrayToHex';
import {writeAnimNumArrayToAnimFrame} from '../../../convertWriteValue';
import {
  ChamberType,
  DeviceSettings,
  Dictionary,
  ExclusiveMoodLight,
  LightingPattern,
  LumaAnimation,
  MoodLight,
  OperatingState,
  Profile,
  ProfileNewest,
  ProfileScratchpadId,
  Scratchpad,
  TableColor,
  isCustomMoodLight,
  isPreTHeatProfile,
  isTHeatProfile,
  isTHeatProfileNoMoodLight,
} from '../../../types';
import {isDefined} from '../../../util/types';
import {
  convertMoodLightToRaw,
  convertMoodLightToTableColor,
  getDeviceModelType,
  getLightingPattern,
  getLumaAnimation,
  getSecondsFromMilliseconds,
  meetsMinimumFirmware,
  meetsRequirement,
  splitIndices,
} from '../../../utilityFunctions';
import {Temperature} from '../../../utils/temperature';
import {CHARACTERISTIC_CONFIG} from '../../characteristics';
import {PATH_CONFIG} from '../../paths';
import {
  FlatProperties,
  HeatCycleArrayIndex,
  LED_API_VERSIONS,
  NvmIndex,
  TEMP_HEAT_CYCLE_ARRAY_INDEX,
  isLed3ColorPointer,
  isNvmIndex,
  isRgbtColor,
  isTempHeatProfileIndex,
} from '../pikaparam';
import {FirmwareType, HeatProfileIndex, IPeakDevice} from './IPeakDevice';
import {PeakDeviceBase, PeakDeviceBaseOptions} from './PeakDeviceBase';

const {
  MODE_COMMAND,
  NVM_ARRAY_INDICES,
  MINIMUM_FIRMWARE_VERSION,
  LED_API_TYPE_CODE,
  TABLE_COLOR_BYTES,
  UNIT_CONVERSION,
} = Constants;

export class PeakFlatDevice
  extends PeakDeviceBase<FlatProperties>
  implements IPeakDevice
{
  constructor(o: Omit<PeakDeviceBaseOptions, 'firmwareType'>) {
    super({...o, firmwareType: FirmwareType.Flat});
  }

  protected async readChamberType(): Promise<ChamberType> {
    const supported = meetsMinimumFirmware(
      this.softwareRevision,
      Constants.MINIMUM_FIRMWARE_VERSION.CHAMBER_TYPE,
    );

    if (supported) {
      const value = await this.readCommand('heaterType').catch(
        () => ChamberType.None,
      );

      if (!Object.values(ChamberType).includes(value)) return ChamberType.None;

      return value as ChamberType;
    }

    const faultEndIndex = supported
      ? undefined
      : await this.readCommand('faultLogEnd');

    try {
      let currentTemp = await this.readCommand('heaterTemp');

      if (faultEndIndex === undefined)
        return currentTemp ? ChamberType.Classic : ChamberType.None;

      await this.writeCommand(
        'faultLogPointer',
        Math.max(faultEndIndex - 1, 0),
      );

      const faultLog = makeRecord(
        LogIndex.Fault,
        {
          index: faultEndIndex - 1,
          buffer: await this.readCommand('faultLogEntry'),
        },
        0,
        getClockAdjEpochs(LogIndex.Fault, []),
      );

      currentTemp = await this.readCommand('heaterTemp');

      if (
        faultLog.typeCode !== FaultLog1Type.HeaterTempLost ||
        !faultLog.freeze.length
      ) {
        return currentTemp ? ChamberType.Classic : ChamberType.None;
      }

      const rawCode = parseInt(faultLog.freeze[0].value);

      if (typeof rawCode !== 'number')
        return currentTemp ? ChamberType.Classic : ChamberType.None;

      return (
        [ChamberType.Performance, ChamberType.XL].find(type => {
          const logValues = DICTIONARIES.CHAMBER_LOG_VALUES_DICTIONARY[type];
          return (
            logValues &&
            rawCode >= logValues.minValue &&
            rawCode <= logValues.maxValue
          );
        }) ?? ChamberType.None
      );
    } catch {
      return ChamberType.None;
    }
  }

  async stopDabbing(): Promise<void> {
    await this.writeCommand('modeCmd', MODE_COMMAND.heatCycleAbort);
    await this.writeCommand('modeCmd', MODE_COMMAND.idle);
  }

  public async readDabbingValues(): Promise<{
    state: OperatingState;
    settings: DeviceSettings;
  }> {
    const op = await this.readCommand('operatingState');

    let elapsedTime = 0;
    let totalTime = 0;

    const currentTemp = await this.readCommand('userHeaterTemp');
    const targetTemp = await this.readCommand('userHeaterTempCommand');

    // Prevent reading preheat elapsed/total times
    if (op === OperatingState.HEAT_CYCLE_ACTIVE) {
      totalTime = await this.readCommand('stateTotalTime');
      const elapsedTimeResponse = await this.readCommand('stateElapsedTime');

      if (Number.isFinite(elapsedTimeResponse)) {
        elapsedTime = elapsedTimeResponse;
      }
    }

    const activeLedColors = await this.readCommand(
      `activeLedColorApi${this.getLedApiVersion()}`,
    );

    const activeLedArrayBuffer =
      (isLed3ColorPointer(activeLedColors)
        ? RgbtColor.fromRgb(activeLedColors)
        : activeLedColors
      ).bytes ?? [];

    const activeLedArray = Array.from(new Uint8Array(activeLedArrayBuffer));

    const settings: DeviceSettings = {
      currentTemp,
      stateElapsedTime: elapsedTime,
      stateTotalTime: totalTime,
      targetTemp,
      isDabbingDiscoMode:
        !isNil(activeLedArray) &&
        // Compare with all party mode LED API 2 values except luma animation
        activeLedArray[TABLE_COLOR_BYTES.BRIGHTNESS] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.BRIGHTNESS] &&
        activeLedArray[TABLE_COLOR_BYTES.SPEED] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.SPEED] &&
        activeLedArray[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] &&
        activeLedArray[TABLE_COLOR_BYTES.PHASE_LOCK_NUMERATOR] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.PHASE_LOCK_NUMERATOR] &&
        activeLedArray[TABLE_COLOR_BYTES.PHASE_LOCK_DENOMINATOR] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.PHASE_LOCK_DENOMINATOR] &&
        activeLedArray[TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES] ===
          Constants.PARTY_MODE.T[
            TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES
          ] &&
        activeLedArray[TABLE_COLOR_BYTES.COLOR_ARRAY_LENGTH] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.COLOR_ARRAY_LENGTH],
    };

    return {
      state: op,
      settings,
    };
  }

  async readLanternSettings(
    exclusiveMoodlights: Dictionary<string, ExclusiveMoodLight>,
  ): Promise<{
    lColor?: string | undefined;
    lPattern?: number | undefined;
    partyMode?: boolean | undefined;
    lanternMoodLight?: MoodLight | undefined;
  }> {
    let lColor: string | undefined;
    let lPattern: number | undefined;
    let partyMode: boolean | undefined;
    let lanternMoodLight: MoodLight | undefined;

    const lanternColor = await this.readCommand(
      `lanternColorApi${this.getLedApiVersion()}`,
    );

    const lanternColorArray = Array.from(new Uint8Array(lanternColor.as4Byte));

    if (
      meetsMinimumFirmware(
        this.softwareRevision,
        Constants.MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
      )
    ) {
      partyMode = isEqual(lanternColorArray, Constants.PARTY_MODE.T);
      if (partyMode) {
        lColor = appColors.defaultColor;
        lPattern = LightingPattern.STEADY;
      } else {
        if (
          lanternColorArray[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
          LED_API_TYPE_CODE.LANTERN_COLOR
        ) {
          lColor = convertRgbArrayToHex(lanternColorArray.slice(0, 4));
          lPattern = getLightingPattern(lanternColorArray[4] as LumaAnimation);
        } else {
          const lanternScratchpadBuffer =
            await this.readLanternScratchpadBuffer();
          let scratchpad: Scratchpad | undefined;
          lanternScratchpadBuffer &&
            (scratchpad = await this.readScratchpad(lanternScratchpadBuffer));
          if (scratchpad) {
            if (
              scratchpad.version ===
                ScratchpadVersions.LanternExclusiveNewest ||
              scratchpad.version === ScratchpadVersions.ProfileExclusiveNewest
            ) {
              const knownExclusive = exclusiveMoodlights[scratchpad.moodUlid];
              if (knownExclusive) {
                lanternMoodLight = knownExclusive;
              }
            } else {
              lanternMoodLight = this.getMoodLightFromScratchpad(scratchpad);
            }
          }
        }
      }
    } else if (
      meetsRequirement(this.softwareRevision, {
        maxApi: params.flat.lanternPattern.maxApi,
      })
    ) {
      lPattern = await this.readLanternPattern();
      lColor = convertRgbArrayToHex(lanternColorArray);
      partyMode = isEqual(lanternColorArray, Constants.PARTY_MODE.PRE_T);
    }

    return {
      lColor,
      lPattern,
      partyMode,
      lanternMoodLight,
    };
  }

  async readLanternPattern() {
    return this.readCommand('lanternPattern');
  }

  async setPartyMode() {
    const color = meetsMinimumFirmware(
      this.softwareRevision,
      Constants.MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
    )
      ? Constants.PARTY_MODE.T
      : Constants.PARTY_MODE.PRE_T;

    const rgbt = this.convertColorApiProperty([...color]);

    await this.writeCommand(`lanternColorApi${this.getLedApiVersion()}`, rgbt);
  }

  async writeColor(
    color: string,
    lightingPattern: LightingPattern,
    isLantern: boolean,
    index?: HeatProfileIndex,
  ): Promise<void> {
    let colorArray = convertHexToRgbArray(color);
    const hasMoodLighting = meetsMinimumFirmware(
      this.softwareRevision,
      MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
    );
    if (hasMoodLighting) {
      colorArray = [...colorArray, getLumaAnimation(lightingPattern), 0, 0, 0];
    }
    if (isLantern) {
      await this.writeCommand(
        `lanternColorApi${this.getLedApiVersion()}`,
        this.convertColorApiProperty(colorArray),
      );
    } else {
      if (index) {
        const property =
          index === -1
            ? (`tempHeatCycleColorApi${this.getLedApiVersion()}` as const)
            : (`heatCycle${index}ColorApi${this.getLedApiVersion()}` as const);

        await this.writeCommand(
          property,
          this.convertColorApiProperty(colorArray),
        );
      }
    }

    if (
      !hasMoodLighting &&
      isLantern &&
      meetsRequirement(this.softwareRevision, {
        maxApi: params.flat.lanternPattern.maxApi,
      })
    ) {
      // no mood lighting, so lumaAnimation needs separate write
      await this.writeCommand('lanternPattern', lightingPattern);
    }
  }

  async writeHeatProfile(
    profile: Profile,
    moodLight?: MoodLight | undefined,
  ): Promise<void> {
    const index = profile.order as HeatProfileIndex;
    const temperature = Temperature.convert(profile.temperature, {
      from: profile.units,
      to: TemperatureUnit.Celsius,
    });

    const base = isTempHeatProfileIndex(index)
      ? ('tempHeatCycle' as const)
      : (`heatCycle${index}` as const);

    if (!isTempHeatProfileIndex(index)) {
      await this.writeCommand(`${base}Name`, profile.name);
    }

    await this.writeCommand(`${base}Temp`, temperature);

    await this.writeCommand(`${base}Time`, profile.duration);

    if (moodLight) {
      const index = NVM_ARRAY_INDICES.HEAT_PROFILE_0 + profile.order;

      if (isNvmIndex(index))
        await this.writeMoodLight(
          CHARACTERISTIC_CONFIG.heatCycleColor.offset,
          index,
          moodLight,
          profile,
        );
    } else if (
      isPreTHeatProfile(profile) ||
      isTHeatProfileNoMoodLight(profile)
    ) {
      const index = profile.order as HeatProfileIndex;
      // Assuming LumaAnimation isn't defined
      await this.writeColor(
        profile.color,
        LightingPattern.STEADY,
        false,
        index,
      );
      if (
        meetsMinimumFirmware(
          this.softwareRevision,
          MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
        )
      ) {
        const scratchpad: ProfileNewest = {
          version: ProfileScratchpadId.PROFILE_NEWEST,
          heatProfileUlid: profile.id,
          heatProfileDateModified: getSecondsFromMilliseconds(
            isTHeatProfile(profile) ? profile.modified : new Date().getTime(),
          ),
        };

        await this.writeScratchpad(scratchpad, index);
      }
    }
  }

  async readHeatProfile(
    index: HeatProfileIndex,
  ): Promise<{profile: Profile; moodLight: MoodLight | undefined} | undefined> {
    const base = isTempHeatProfileIndex(index)
      ? ('tempHeatCycle' as const)
      : (`heatCycle${index}` as const);

    const name = !isTempHeatProfileIndex(index)
      ? await this.readCommand(`${base}Name`)
      : undefined;

    const temperature = await this.readCommand(`${base}Temp`);

    const duration = await this.readCommand(`${base}Time`);

    const colorBufferProperty =
      `${base}ColorApi${this.getLedApiVersion()}` as const;

    const colorBuffer = await this.readCommand(colorBufferProperty);

    const colorArray = [...colorBuffer.bytes];
    let id: string | null = null;
    let version: ProfileVersion;
    let moodLight: MoodLight | undefined;
    let moodLightId: string | undefined;
    let modified: number | undefined;
    let isMoodLight: boolean | undefined;
    let color: string | undefined;
    if (
      meetsMinimumFirmware(
        this.softwareRevision,
        Constants.MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
      )
    ) {
      version = ProfileVersion.T;
      isMoodLight =
        colorArray[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
        LED_API_TYPE_CODE.TABLE_COLOR;

      const heatCycleScratchpadBuffer = await this.readCommand(
        `${base}Scratchpad`,
      );

      let scratchpad: Scratchpad | undefined;
      if (!isMoodLight) {
        color = convertRgbArrayToHex(colorArray.slice(0, 4));
      }
      if (heatCycleScratchpadBuffer) {
        scratchpad = this.parseScratchpad(
          Buffer.from(heatCycleScratchpadBuffer),
        );
      }
      if (scratchpad) {
        if (
          isMoodLight &&
          scratchpad.version !== ScratchpadVersions.ProfileNewest
        ) {
          // TODO reads from Store?
          moodLight = this.getMoodLightFromScratchpad(scratchpad);
          moodLightId = scratchpad.moodUlid;
        }

        if (
          scratchpad.version === ScratchpadVersions.ProfileNewest ||
          scratchpad.version === ScratchpadVersions.ProfileExclusiveNewest ||
          scratchpad.version === ScratchpadVersions.ProfileCustomNewest
        ) {
          id = scratchpad.heatProfileUlid ?? null;
          const scratchpadDate: number | undefined =
            scratchpad.heatProfileDateModified;
          modified = (
            scratchpadDate === undefined
              ? new Date()
              : new Date(
                  scratchpadDate * UNIT_CONVERSION.SECONDS_TO_MILLISECONDS,
                )
          ).getTime();
        }
      } else {
        modified = new Date().getTime();
        if (isMoodLight) {
          color = appColors.defaultColor;
          isMoodLight = false;
        }
      }
    } else {
      version = ProfileVersion.PreT;
      color = convertRgbArrayToHex(colorArray);
    }

    if (
      (name === undefined || typeof name === 'string') &&
      typeof temperature === 'number' &&
      typeof duration === 'number'
    ) {
      return {
        profile: {
          version,
          id,
          order: index,
          ...(name !== undefined && {name}),
          temperature,
          duration: Math.floor(duration),
          units: TemperatureUnit.Celsius,
          ...(modified && {modified}),
          ...(isMoodLight && {isMoodLight}),
          ...(color && {color}),
          ...(moodLightId !== undefined && {moodLightId}),
        } as Profile,
        moodLight,
      };
    }
  }

  protected async writeTableColor(
    characteristicUuid: string,
    nvmIndex: number,
    moodLight: MoodLight,
    profile?: Profile | undefined,
    tableColor?: TableColor | undefined,
    shouldWriteScratchpad: boolean | undefined = true,
  ) {
    const {
      brightness,
      speed,
      lumaAnimation,
      phaseLockNumerator,
      phaseLockDenominator,
      arrayIndices,
      colorArrayLength,
    } = tableColor ?? convertMoodLightToTableColor(moodLight, nvmIndex);

    const getProperty = () => {
      // could be lanternColor, a heat profile color, or the temp heat profile color
      if (characteristicUuid !== PATH_CONFIG.heatCycleColor.path)
        return `lanternColorApi${this.getLedApiVersion()}` as const;

      const order: HeatProfileIndex =
        (profile?.order as HeatCycleArrayIndex) ?? TEMP_HEAT_CYCLE_ARRAY_INDEX;

      if (order >= 0)
        return `heatCycle${order}ColorApi${this.getLedApiVersion()}` as const;

      return `tempHeatCycleColorApi${this.getLedApiVersion()}` as const;
    };

    const property = getProperty();

    await this.writeCommand(
      property,
      new RgbtColor([
        brightness,
        speed,
        lumaAnimation,
        Constants.LED_API_TYPE_CODE.TABLE_COLOR,
        phaseLockNumerator,
        phaseLockDenominator,
        arrayIndices,
        colorArrayLength,
      ]),
    );

    if (shouldWriteScratchpad)
      await this.writeMoodLightScratchpad(moodLight, profile);
  }

  public async readLanternColorArrayIndex() {
    const lanternColorProperty =
      `lanternColorApi${this.getLedApiVersion()}` as const;

    const buffer = await this.readCommand(lanternColorProperty);

    const lanternColorArray = isRgbtColor(buffer)
      ? Array.from(Buffer.from(buffer.bytes))
      : Array.from(Buffer.from(buffer));

    if (
      lanternColorArray.length >
        Constants.TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES &&
      lanternColorArray[Constants.TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
        Constants.LED_API_TYPE_CODE.TABLE_COLOR
    ) {
      const {colorIndex} = splitIndices(
        lanternColorArray[
          Constants.TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES
        ],
      );

      return colorIndex;
    }
    return Constants.NVM_ARRAY_INDICES.DEFAULT_LANTERN_ARRAY_INDEX;
  }

  public async writeMoodLight(
    characteristicUuid: string,
    nvmIndex: NvmIndex<FlatProperties>,
    moodLight: MoodLight,
    profile?: Profile | undefined,
  ): Promise<void> {
    if (!this.modelNumber)
      throw new Error(
        "Couldn't write mood light because model number is missing.",
      );

    if (!this.serialNumber)
      throw new Error(
        "Couldn't write mood light because serial number is missing.",
      );

    if (isCustomMoodLight(moodLight)) {
      moodLight = {
        ...moodLight,
        tempo: Number(moodLight.tempo),
        type: Number(moodLight.type),
      };
    }
    nvmIndex = await this.alternateLanternNvmIndex(nvmIndex);
    const {tableColor, colorArray, offsetArray, animationArray} =
      convertMoodLightToRaw(
        moodLight,
        getDeviceModelType(this.product.name ?? 'OG'),
        nvmIndex,
      );

    const newColorArray = chunk(colorArray, 4)
      .map(bytes => RgbtColor.from4Byte(Buffer.from(bytes)).asRgb)
      .filter(isDefined);

    const colorProperty = `userColorArray${nvmIndex}` as const;
    await this.writeCommand(colorProperty, newColorArray);

    const offsetProperty = `userOffsetArray${nvmIndex}` as const;
    await this.writeCommand(offsetProperty, offsetArray);

    if (animationArray) {
      const animationBuffer = writeAnimNumArrayToAnimFrame(animationArray);
      // TODO is this right? should this correspond to the heat profile index?
      // looking at the flat implementation, it always seems to write to 0
      const aaProperty = `userAnimArray0`;

      await this.writeCommand(aaProperty, [animationBuffer]);
    }

    await this.writeTableColor(
      characteristicUuid,
      nvmIndex,
      moodLight,
      profile,
      tableColor,
    );
  }

  protected getLedApiVersion() {
    const version = LED_API_VERSIONS.find(a =>
      this.pikaparam.devInfo.info?.features.includes(a),
    );

    switch (version) {
      case 'led-api-1':
        return 1;
      case 'led-api-2':
        return 2;
      default:
        throw new Error(
          `Unsupported led api version '${version}' for PeakFlatDevice`,
        );
    }
  }

  protected convertColorApiProperty(array: number[]) {
    const ledApiVersion = this.getLedApiVersion();

    switch (ledApiVersion) {
      case 1:
        return codecs.rgbtApi1.decode(Buffer.from(array));
      case 2:
        return codecs.rgbtApi2.decode(Buffer.from(array));
    }
  }
}

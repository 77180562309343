import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';

import {
  AppText,
  ImgBackground,
  StyledButton,
  StyledField,
} from '../../components';
import {ErrorMessages, Screens, appColors} from '../../constants';
import {updateUser} from '../../lib/api';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {AccountNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Alert} from '../../shims/alert';

const ChangeNameSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(ErrorMessages.REQUIRED_FIRSTNAME),
  lastName: Yup.string().trim().required(ErrorMessages.REQUIRED_LASTNAME),
});

interface ChangeNameValues {
  firstName: string;
  lastName: string;
}

interface Props
  extends AccountNavigatorScreenProps<typeof Screens.ChangeName> {}

export const ChangeNameScreen = ({navigation}: Props) => {
  useAdaptiveSafeArea();
  const currentUser = useSelector(userSelector);

  const handleSave = async (
    values: ChangeNameValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);

    await updateUser({firstName: values.firstName, lastName: values.lastName});

    Alert.alert('Success', 'Your account name has been changed!');

    navigation.goBack();

    actions.setSubmitting(false);
  };

  return (
    <ImgBackground>
      <SafeAreaView
        style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <Formik
          initialValues={{
            firstName: currentUser?.firstName ?? '',
            lastName: currentUser?.lastName ?? '',
          }}
          onSubmit={handleSave}
          validationSchema={ChangeNameSchema}>
          {({
            handleBlur,
            handleChange,
            isSubmitting,
            isValid,
            submitForm,
            values,
            errors,
          }) => (
            <View>
              <ChangeNameContainer>
                <StyledField
                  name={'account'}
                  onChangeText={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  placeholder="First Name"
                  password={false}
                  value={values.firstName}
                />
                <StyledField
                  name={'account'}
                  onChangeText={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  placeholder="Last Name"
                  password={false}
                  value={values.lastName}
                />
              </ChangeNameContainer>
              <MiddleContainer>
                {values.firstName !== '' && values.lastName !== '' && (
                  <AppText
                    style={{color: appColors.errorColor, textAlign: 'center'}}>
                    {' '}
                    {errors.firstName || errors.lastName}{' '}
                  </AppText>
                )}
              </MiddleContainer>
              <ButtonContainer>
                <StyledButton
                  title="Save"
                  disabled={!isValid || isSubmitting}
                  onPress={submitForm}
                />
              </ButtonContainer>
            </View>
          )}
        </Formik>
      </SafeAreaView>
    </ImgBackground>
  );
};

const ChangeNameContainer = styled(View)({
  width: 250,
});

const ButtonContainer = styled(View)({
  width: '100%',
});

const MiddleContainer = styled(View)({
  height: 150,
  width: 250,
  justifyContent: 'center',
});

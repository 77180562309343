import React from 'react';
import {Image, StyleSheet, TextInput} from 'react-native';

import {clearSearchIcon, searchBarIcon} from '../../../assets/images';
import {appColors} from '../../../constants';
import {PressableOpacity} from '../../../shims/PressableOpacity';

interface Props {
  value: string;
  onChangeText: (query: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const SearchBar = ({value, onChangeText, onFocus, onBlur}: Props) => {
  const hasInput = value.length > 0;

  return (
    <>
      <TextInput
        {...{value, onChangeText, onFocus, onBlur}}
        placeholder="Search"
        placeholderTextColor={appColors.white50}
        selectionColor={appColors.white50}
        style={styles.container}
      />

      <PressableOpacity
        onPress={() => {
          if (hasInput) onChangeText('');
        }}
        style={styles.iconContainer}>
        <Image
          source={hasInput ? clearSearchIcon : searchBarIcon}
          style={styles.icon}
        />
      </PressableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    margin: 20,
    paddingStart: 20,
    paddingEnd: 50,
    backgroundColor: appColors.darkGray,
    borderRadius: 30,
    color: appColors.white50,
  },
  iconContainer: {
    position: 'absolute',
    top: 26,
    right: 30,
    zIndex: 10,
    padding: 12,
  },
  icon: {
    width: 18,
    height: 18,
  },
});

import {GoogleOAuthProvider} from '@react-oauth/google';
import React from 'react';

import {Environment} from '../constants';

export const GoogleAuthProvider = ({children}: {children: React.ReactNode}) => {
  return (
    <GoogleOAuthProvider clientId={Environment.google.clientId}>
      {children}
    </GoogleOAuthProvider>
  );
};

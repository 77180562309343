import {Entry} from 'contentful';

import {
  TypeGalleryFields,
  TypePageFields,
  TypeStyledAssetFields,
  TypeVideoFields,
  WithContentTypeLink,
} from './types';

export enum GuideType {
  NEW_USER = 'NEW_USER',
  DEVICE = 'DEVICE',
  GALLERY = 'GALLERY',
}

export enum PageType {
  COMMUNITY = 'COMMUNITY',
  COMMUNITY_SECONDARY = 'COMMUNITY-SECONDARY',
}

export enum GalleryType {
  DEFAULT = 'DEFAULT',
  GRID = 'GRID',
  CONTEST = 'CONTEST',
  CAROUSEL = 'CAROUSEL',
  SUPPORT_ITEM = 'SUPPORT_ITEM',
}

export interface GalleryElement
  extends Entry<
    TypeStyledAssetFields | TypeVideoFields | TypeGalleryFields | TypePageFields
  > {}

export type StrictTypeGallery = Entry<
  Omit<TypeGalleryFields, 'elements'> & {elements?: GalleryElement[]}
>;

export function isStrictTypeGallery(
  entry: WithContentTypeLink,
): entry is StrictTypeGallery {
  return entry?.sys?.contentType?.sys?.id === 'gallery';
}

import {WebBluetoothConnectedDevice} from 'pikaparam';

import {Constants} from '../../../../constants';
import {Alert} from '../../../../shims/alert';
import {bleLog} from '../../../Logger';
import {sleep} from '../../../sleep';
import {OtaDevice} from '../OtaDevice';
import {ConnectionError} from '../types';
import {
  BleManagerBase,
  ConnectOptions,
  ConnectResult,
  ConnectablePeripheral,
  IBleManager,
  OtaConnectOptions,
} from './BleManagerBase';

const nav: Puffco.Navigator = window.navigator;

export class BleManager
  extends BleManagerBase<
    Puffco.PathBluetoothDevice,
    WebBluetoothConnectedDevice
  >
  implements IBleManager
{
  private scanner?: {stop: () => Promise<void>};

  public async connect({
    peripheral,
    ...o
  }: ConnectOptions): Promise<ConnectResult> {
    return this.initiateConnection({peripheral, ...o}, () => {
      if (!peripheral) {
        return this.scan(
          this.getFilters({
            services: this.serviceIdentifiers,
            identifiers: this.uniqueIdentifiers,
          }),
          // We will wait for 1 min because the user has to select a device
          60000,
        );
      }

      return this.scan(
        this.getFilters({
          peripheral,
          services: [],
          identifiers: [],
        }),
        5000,
      );
    });
  }

  public async otaConnect({peripheral}: OtaConnectOptions): Promise<OtaDevice> {
    const onDisconnect = async () => void 0;

    const {ota} = await this.initiateConnection(
      {peripheral, onDisconnect},
      () => {
        return this.scan(
          this.getFilters({
            peripheral,
            services: this.otaServiceIdentifiers,
            identifiers: this.uniqueIdentifiers,
          }),
          30000,
        );
      },
    );

    return ota;
  }

  public async scanForAdvertisements(
    // eslint-disable-next-line
    callback: (device: any, event: any) => void,
  ): Promise<void> {
    // const peakFilter = [{services: [Uuids.loraxService]}];
    // await nav.bluetooth?.addEventListener('advertisementreceived', event => {
    //   const {device, manufacturerData} = event.data;
    //   const md: ArrayBuffer = manufacturerData.get(Constants.COMPANY_ID);
    //   if (!md) {
    //     return;
    //   }
    //   callback(device, Buffer.from(md));
    // });
    // await this.scanner?.stop();
    // this.scanner = await this.runAfterUserInteraction(() =>
    //   nav.bluetooth?.requestLEScan({filters: peakFilter}),
    // );
  }

  public async stopScanForAdvertisements(): Promise<void> {
    this.logger.info('stop advertisement scan');
    await this.scanner?.stop();
    this.scanner = undefined;
  }

  protected async createConnectedDevice(device: Puffco.PathBluetoothDevice) {
    return await WebBluetoothConnectedDevice.create(device, this.logger, {
      timeout: 10000,
    });
  }

  protected getConnectionMetadata({adData}: Puffco.PathBluetoothDevice) {
    return {rssi: adData?.rssi};
  }

  protected async bond(device: WebBluetoothConnectedDevice) {
    bleLog.info('Bonding.', {peripheralId: device.id});

    await device.triggerBonding(0).catch(error => {
      bleLog.error('Bonding failed.', {error});
      throw new Error(ConnectionError.IOS_BONDING_ERROR);
    });
  }

  // MTU is not supported on web
  protected async requestMtu() {}

  protected async stopScan() {
    await nav.bluetooth?.stopRequest?.();
  }

  private getFilters({
    peripheral,
    services,
    identifiers,
  }: {
    peripheral?: ConnectablePeripheral;
    services: string[];
    identifiers: string[];
  }): Puffco.PathBluetoothLEScanFilter[] {
    if (!Constants.IS_USING_PATH_BROWSER)
      return [
        // The app is running in a browser and we cannot filter by peripheralId, so we use the name if possible
        ...(peripheral?.name ? [{name: peripheral.name}] : []),
        ...services.map(uuid => ({services: [uuid]})),
        ...identifiers.map(namePrefix => ({namePrefix})),
      ];

    // The {deviceId} filter should be the first item to trigger the non-interactive
    // reconnect process (non-standard). Omitting it will display the device selector.
    // Passing {name} should be the ideal behavior, but it looks after name change the
    // Pikachu unit is still available with its old name.
    // Needs validation: We don't use {deviceId} alone anymore because iOS seems to be
    // caching the device during the scan and thinks it found it, but we can never
    // connect to it because it's not there.

    return [
      ...(peripheral?.name
        ? // TODO: after the next force update in Path Browser, remove deviceId: x
          // [{name: peripheral.name}, ...peripheral.id ? [{deviceId: peripheral.id }]}]
          [{deviceId: peripheral.id ?? 'x'}, {name: peripheral.name}]
        : []),
      ...services.map(uuid => ({services: [uuid]})),
      ...identifiers.map(namePrefix => ({namePrefix})),
    ];
  }

  private async scan(
    filters: Puffco.PathBluetoothLEScanFilter[],
    timeout: number,
  ) {
    this.logger.info('Scanning devices using filters.', {filters});

    let timedOut = false;

    const device = await this.runAfterUserInteraction(async () => {
      const timeoutId = setTimeout(async () => {
        timedOut = true;
        await this.stopScan();
      }, timeout);

      return nav.bluetooth
        ?.requestDevice({filters, optionalServices: this.serviceIdentifiers})
        .catch(error => {
          if (typeof error === 'string') throw new Error(error);
          throw error;
        })
        .finally(() => clearTimeout(timeoutId));
    });

    if (!device) throw new Error(ConnectionError.DEVICE_NOT_FOUND);

    if (this.getValidDevice(device)) return device;

    if (timedOut) throw new Error(ConnectionError.DEVICE_NOT_SELECTED);

    throw new Error(ConnectionError.DEVICE_INVALID);
  }

  private getValidDevice(device: BluetoothDevice) {
    // Path Browser might return an invalid device when stopRequest has been called
    // so we will check for the existance of the device id.
    if (!device.id) return;

    return device;
  }

  private async runAfterUserInteraction<T>(callback: () => T) {
    if (Constants.IS_USING_PATH_BROWSER) return callback();

    if (nav.userActivation?.isActive) return callback();

    return new Promise<void>(resolve => {
      // Sleep is required, otherwise the alert is not displayed on web
      sleep(100).then(() => {
        Alert.alert(
          'Manual interaction required',
          'Click the button to continue',
          [{text: 'Continue', onPress: () => resolve()}],
          {onDismiss: () => resolve()},
        );
      });
    }).then(() => callback());
  }
}

export const bleManager: IBleManager = new BleManager();

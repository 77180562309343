import React from 'react';

import {Modal} from '../../../../components';
import {resolveAssetUrl} from '../../../../lib/api';
import {
  TypeGallery,
  TypeStyledAsset,
  isTypeStyledAsset,
} from '../../../../lib/api/content-access/types';
import {AssetCarouselItem} from './AssetCarouselItem';
import {GalleryViewer} from './GalleryViewer';

interface Props {
  gallery: TypeGallery;
}
export const DefaultGallery: React.FC<Props> = ({gallery}) => {
  const elements = gallery.fields.elements;

  if (!Array.isArray(elements) || elements.length === 0 || !elements[0])
    return null;

  const firstAsset = elements[0];

  if (!isTypeStyledAsset(firstAsset)) return null;

  const thumbnailUrl = gallery.fields.thumbnailImage
    ? resolveAssetUrl(gallery.fields.thumbnailImage)
    : resolveAssetUrl(firstAsset.fields.asset);

  return (
    <AssetCarouselItem
      thumbnail={thumbnailUrl}
      thumbnailStyle={firstAsset.fields.style}
      title={gallery.fields.displayName}
      onPress={() => {
        Modal.display({
          element: (
            <GalleryViewer
              assets={elements as TypeStyledAsset[]}
              onClose={() => Modal.close()}
            />
          ),
        });
      }}
    />
  );
};

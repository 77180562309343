import {Text} from 'react-native';

import {appColors} from '../../../constants';
import styled from '../../../lib/styled';

export const BodyText = styled(Text)({
  color: appColors.baseText,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  lineHeight: 15,
  marginTop: 14,
  fontWeight: '400',
});

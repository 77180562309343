import React from 'react';
import {View} from 'react-native';
import {useDispatch} from 'react-redux';

import {AppText, ImgBackground, SupportCard} from '../../components';
import {Messages, Navigators, Screens, appColors} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {resetAnswers} from '../../lib/redux/warrantySlice';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<typeof Screens.ContactUs>;

export const ContactUsScreen = ({navigation}: ScreenProps) => {
  useAdaptiveSafeArea();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetAnswers());
  }, []);

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <MainContainer>
          <SupportCard
            text={'WARRANTY CLAIM'}
            icon={'mail'}
            onPress={() =>
              navigation.navigate(Navigators.Warranty, {
                screen: Screens.WarrantyClaim,
              })
            }
          />
          <TextContainer>
            <BodyText>{Messages.SUPPORT_EXCHANGES}</BodyText>
            <BodyText>{Messages.SUPPORT_IMPERFECTION}</BodyText>
          </TextContainer>
        </MainContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const MainContainer = styled(View)({
  flex: 1,
  paddingHorizontal: 17,
  flexDirection: 'column',
  marginVertical: 50,
  justifyContent: 'flex-start',
});

const TextContainer = styled(View)({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flex: 3,
});

const BodyText = styled(AppText)({
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  marginVertical: 10,
  marginHorizontal: 20,
  textAlign: 'center',
  color: appColors.mediumGray,
});

import colorsys from 'colorsys';

import {Constants} from '../constants/Constants';
import {convertHexToHsl} from './convertHexToHsl';
import {convertHslToHex} from './convertHslToHex';

export const changeHexColorValues = (
  hex: string,
  saturation: number,
  lightness: number,
  colorCreatedWithSlider: boolean,
) => {
  const {whiteHue} = Constants.SLIDER_WHITE_VALUES;
  const {r, g, b} = colorsys.hexToRgb(hex);
  if (r === g && g === b) {
    return convertHslToHex(
      whiteHue,
      saturation,
      lightness,
      colorCreatedWithSlider,
    );
  }
  const h = convertHexToHsl(hex);
  return convertHslToHex(h, saturation, lightness, colorCreatedWithSlider);
};

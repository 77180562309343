import {TextStyle} from 'react-native';

import {
  logoClose,
  peachWhiteBackgroundHomeScreen,
  peakBaseShadow,
  peakPeachLightsGlassFarLeft,
  peakPeachLightsGlassFarRight,
  peakPeachLightsGlassMidLeft,
  peakPeachLightsGlassMidRight,
  peakPeachWhite,
  peakPeachWhiteBase,
  peakPeachWhiteChamberRegular,
  peakPeachWhiteChamberXl,
  peakPeachWhiteGlass,
  peakPeachWhiteLightsBaseFarLeft,
  peakPeachWhiteLightsBaseFarRight,
  peakPeachWhiteLightsBaseMidLeft,
  peakPeachWhiteLightsBaseMidRight,
  peakPeachWhiteLightsRingFarLeft,
  peakPeachWhiteLightsRingFarRight,
  peakPeachWhiteLightsRingMidLeft,
  peakPeachWhiteLightsRingMidRight,
} from '../assets/images';
import {Constants} from '../constants';
import {appColors} from '../constants/Colors';
// Import from exact file to prevent cycle
import {
  BottomSheetTheme,
  ConnectScreenTheme,
  ControlCenterTheme,
  DabbingScreenTheme,
  DevicesListScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HomeScreenTheme,
  MoodLightCardTheme,
  MoodTypeButtonTheme,
  NavMenuTitleStyle,
  PeakImageTheme,
  SaveToDeviceScreenTheme,
  StatusBarTheme,
  TabBarTheme,
  Theme,
} from '../lib/types';
// Import from exact file to prevent cycle
import {addOpacityToColorHex} from '../lib/utilityFunctions/addOpacityToColorHex';
import {
  DarkStatusBarTheme,
  LightStatusBarTheme,
  defaultTheme,
} from './DefaultTheme';

const {IS_NATIVE_ANDROID} = Constants;

// General
const navMenuTitleStyle: NavMenuTitleStyle = {
  ...defaultTheme.navMenuTitleStyle,
  color: appColors.black,
};

// Components
const bottomSheetTheme: BottomSheetTheme = {
  ...defaultTheme.bottomSheetTheme,
  backgroundColor: appColors.opalWhite,
  buttonBackgroundColor: appColors.white,
  buttonTextColor: appColors.black,
  iconColor: appColors.black,
  titleColor: appColors.black,
  subtitleColor: appColors.black50,
  dividerColor: appColors.black10,
  lineColor: appColors.black,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: peakPeachWhite,
  peakLayers: {
    glass: peakPeachWhiteGlass,
    base: peakPeachWhiteBase,
    chamber: {
      regular: peakPeachWhiteChamberRegular,
      xl: peakPeachWhiteChamberXl,
    },
    shadow: peakBaseShadow,
  },
  lightLayers: {
    glass: {
      farLeft: peakPeachLightsGlassFarLeft,
      farRight: peakPeachLightsGlassFarRight,
      midLeft: peakPeachLightsGlassMidLeft,
      midRight: peakPeachLightsGlassMidRight,
    },
    base: {
      farLeft: peakPeachWhiteLightsBaseFarLeft,
      farRight: peakPeachWhiteLightsBaseFarRight,
      midLeft: peakPeachWhiteLightsBaseMidLeft,
      midRight: peakPeachWhiteLightsBaseMidRight,
    },
    ring: {
      farLeft: peakPeachWhiteLightsRingFarLeft,
      farRight: peakPeachWhiteLightsRingFarRight,
      midLeft: peakPeachWhiteLightsRingMidLeft,
      midRight: peakPeachWhiteLightsRingMidRight,
    },
  },
  peakDarkScreen: peakPeachWhite,
};

const moodLightCardTheme: MoodLightCardTheme = {
  ...defaultTheme.moodLightCardTheme,
  textColor: appColors.white,
};

const moodTypeButtonTheme: MoodTypeButtonTheme = {
  ...defaultTheme.moodTypeButtonTheme,
  textColor: appColors.black,
};

// Screens
const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: appColors.black,
  backgroundColor: appColors.peakWhite,
};

const connectScreenTheme: ConnectScreenTheme = {
  ...defaultTheme.connectScreenTheme,
  background: {
    image: peachWhiteBackgroundHomeScreen,
    color: appColors.peakWhite,
  },
  peakNameBackgroundColor: appColors.darkGray,
  peakNameTextColor: appColors.white,
};

const controlCenterTheme: ControlCenterTheme = {
  ...defaultTheme.controlCenterTheme,
  infoText: appColors.black,
  fatSliderBGColor: appColors.black50,
  blurBackgroundColor: appColors.transluscentLightGray,
  background: {color: appColors.lightestGrey},
  boostScreenLabel: appColors.black,
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: appColors.white,
  dabbingScreenFadedText: appColors.mediumGray50,
  dabbingButtonBackground: appColors.bone,
  dabbingButtonBackgroundPressed: appColors.black,
  dabbingButtonForeground: appColors.black,
  dabbingButtonForegroundPressed: appColors.bone,
  iconColor: appColors.white,
  textColor: appColors.white,
  fadedTextColor: appColors.mediumGray50,
};

const devicesListScreenTheme: DevicesListScreenTheme = {
  ...defaultTheme.devicesListScreenTheme,
  cardColor: appColors.iOSGray,
  cardText: appColors.black,
  listTitleText: appColors.black,
  listDivider: '#979797',
  trashCanIconColor: appColors.black,
  blurBackgroundColor: appColors.transluscentLightGray,
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  ...defaultTheme.heatProfileListScreenTheme,
  background: {
    image: peachWhiteBackgroundHomeScreen,
    color: appColors.peakWhite,
  },
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: appColors.black,
  durationFontColor: appColors.white,
  iosShadowAlpha: 75,
  // Use default values for iOS/Web
  specularColorStart: IS_NATIVE_ANDROID
    ? appColors.invisible
    : defaultTheme.heatProfileCardTheme.specularColorStart,
  specularColorEnd: IS_NATIVE_ANDROID
    ? appColors.invisible
    : defaultTheme.heatProfileCardTheme.specularColorEnd,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: appColors.white,
  iconColor: appColors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: appColors.white,
  },
};

const saveToDeviceScreenTheme: SaveToDeviceScreenTheme = {
  background: {
    image: peachWhiteBackgroundHomeScreen,
    color: appColors.peakWhite,
  },
  infoLabelColor: appColors.black50,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,
  // General
  background: {
    image: peachWhiteBackgroundHomeScreen,
    color: appColors.peakWhite,
  },
  // Status info
  batteryBarBackgroundColor: appColors.mediumGray79,
  batteryDisconnectedColor: addOpacityToColorHex(appColors.black, 0.2),
  preserveIndicator: appColors.black,
  statusTextStealthColor: appColors.black,
  statusTitleTextColor: appColors.black,
  stealthIconColor: appColors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: appColors.white, location: 0},
    {color: addOpacityToColorHex(appColors.white, 0), location: 1},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(appColors.black, 0.2),
  metricTextColor: appColors.black,
  metricTitleTextColor: appColors.black50,
};

const temperatureBigStyle: TextStyle = {
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 65,
  color: appColors.white,
  letterSpacing: 0,
  textAlign: 'center',
  lineHeight: 41,
};

const statusBarTheme: StatusBarTheme = {
  ...defaultTheme.statusBarTheme,
  themed: Constants.IS_NATIVE_ANDROID
    ? DarkStatusBarTheme
    : LightStatusBarTheme,
};

export const whitePeachTheme: Theme = {
  ...defaultTheme,
  // General
  logoIcon: logoClose,
  logoTint: appColors.invisible,
  fadedTextColor: appColors.black50,
  navMenuIconColor: appColors.black,
  navMenuTitleStyle,
  spinnerColor: appColors.black,
  alternateSpinnerColor: appColors.white,
  primaryColor: appColors.black,
  primaryTextColor: appColors.black,

  // Components
  temperatureBigStyle,
  peakImageTheme,
  sectionDividerColor: appColors.black20,
  sectionTitleTextColor: appColors.black,
  statusDisplayTextColor: appColors.black,
  tabBarTheme,
  moodTypeButtonTheme,
  moodLightCardTheme,
  bottomSheetTheme,
  statusBarTheme,

  // Screens
  assignToAHeatProfileScreenTheme: {
    ...defaultTheme.assignToAHeatProfileScreenTheme,
    background: {color: appColors.opalWhite},
  },
  homeScreenTheme,
  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  devicesListScreenTheme,
  heatProfileListScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileCardTheme,
  saveToDeviceScreenTheme,

  dabTickTheme: {
    color: appColors.white,
  },
};

import React from 'react';
import {
  ImageBackground,
  ImageBackgroundProps,
  ImageSourcePropType,
} from 'react-native';

import {background as defaultBackground} from '../assets/images';

export type ImgBackgroundProps = React.PropsWithChildren<
  Omit<ImageBackgroundProps, 'source'> & {source?: ImageSourcePropType}
>;

export function ImgBackground({
  source = defaultBackground,
  style,
  imageStyle,
  ...rest
}: ImgBackgroundProps) {
  return (
    <ImageBackground
      {...{source, imageStyle}}
      style={[
        {
          height: '100%',
          width: '100%',
          marginHorizontal: 'auto',
          maxHeight: 1027,
          flex: 1,
        },
        style,
      ]}
      {...rest}
    />
  );
}

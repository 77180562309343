import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import {CompositeScreenProps} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';

import {Navigators, Screens, Strings, appColors} from '../../constants';
import {userSelector} from '../../lib/redux/userSlice';
import {ReferralWelcomeScreen} from '../../screens/ReferralProgram/ReferralWelcomeScreen';
import {ReferralNavigatorScreenProps} from './HomeDrawerNavigator';
import {
  ReferralProgramTab,
  ReferralTabParamList,
} from './ReferralProgramTabNavigator';
import {defaultNavigationOptions} from './common';

export type ReferralStackParamList = {
  [Navigators.ReferralProgramTab]: undefined;
  [Screens.ReferralWelcome]: undefined;
};

export type ReferralTabNavigatorScreenProps<
  T extends keyof ReferralTabParamList,
> = CompositeScreenProps<
  MaterialTopTabScreenProps<ReferralTabParamList, T>,
  ReferralNavigatorScreenProps<keyof ReferralStackParamList>
>;

const ReferralProgramStackNavigator =
  createStackNavigator<ReferralStackParamList>();

export const ReferralProgramStack = () => {
  const isLoggedIn = !!useSelector(userSelector);

  return (
    <ReferralProgramStackNavigator.Navigator
      initialRouteName={
        isLoggedIn ? Navigators.ReferralProgramTab : Screens.ReferralWelcome
      }
      screenOptions={() => ({
        ...defaultNavigationOptions,
        headerTransparent: false,
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: appColors.black,
          borderWidth: 0,
        },
        title: Strings.REFERRAL_PROGRAM.REFER_A_FRIEND,
        headerTitleStyle: styles.headerTitle,
        cardStyle: styles.card,
        headerLeft: () => null,
      })}>
      <ReferralProgramStackNavigator.Screen
        name={Navigators.ReferralProgramTab}
        component={ReferralProgramTab}
      />

      <ReferralProgramStackNavigator.Screen
        name={Screens.ReferralWelcome}
        component={ReferralWelcomeScreen}
      />
    </ReferralProgramStackNavigator.Navigator>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    fontWeight: '700',
    color: appColors.white,
    textTransform: 'uppercase',
  },
  card: {
    backgroundColor: appColors.black,
  },
});

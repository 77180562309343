import React from 'react';
import {Image, ImageSourcePropType} from 'react-native';

import styled from '../lib/styled';

type SideButtonIconProps = {
  imageSrc: ImageSourcePropType;
};

export const SideButtonIcon = ({imageSrc}: SideButtonIconProps) => {
  return <ButtonIcon source={imageSrc} />;
};

const ButtonIcon = styled(Image)({
  width: 20,
  height: 20,
  resizeMode: 'contain',
  marginRight: 5,
});

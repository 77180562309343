import {
  CustomMoodLightSnapshot,
  ExclusiveMoodLightSnapshot,
  MoodLightCategory,
} from 'puffco-api-axios-client';

export type MoodLightSnapshot =
  | CustomMoodLightSnapshot
  | ExclusiveMoodLightSnapshot;

export const isCustomMoodLightSnapshot = (
  moodlight: MoodLightSnapshot,
): moodlight is CustomMoodLightSnapshot =>
  moodlight.category === MoodLightCategory.Custom;

export const isExclusiveMoodLightSnapshot = (
  moodlight: MoodLightSnapshot,
): moodlight is ExclusiveMoodLightSnapshot =>
  moodlight.category === MoodLightCategory.Exclusive;

import {useIsFocused} from '@react-navigation/native';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {Animated, AppState} from 'react-native';

import {mixColor} from '../utilityFunctions';

export type UseMixColorsProps = {
  colors: string[];
  transitionDuration: number;
  isAnimating?: boolean;
};

export function useMixColors({
  colors,
  isAnimating = false,
  transitionDuration,
}: UseMixColorsProps): [string[], number] {
  const isAppStateActive = AppState.currentState === 'active';
  const animation = React.useRef(new Animated.Value(0));
  const [animationValue, setAnimationValue] = React.useState(0);
  const animationValueRef = React.useRef(animationValue);
  const transitionDurationRef = React.useRef(transitionDuration);
  const isAppStateActiveRef = React.useRef(isAppStateActive);
  const animSequence = React.useRef<Animated.CompositeAnimation>();
  const colorArrayState = React.useRef(cloneDeep(colors));
  const [outputArray, setOutputArray] = React.useState(cloneDeep(colors));
  const isFocused = useIsFocused();
  const resetAnim = React.useRef(
    Animated.timing(animation.current, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }),
  );

  React.useEffect(() => {
    const current = animation.current;
    const listener = current.addListener(({value}) => {
      setAnimationValue(value);
      animationValueRef.current = value;
    });
    return () => current.removeListener(listener);
  }, [animation]);

  const startLoop = React.useCallback(() => {
    if (animSequence.current) {
      animSequence.current.start();
      Animated.loop(animSequence.current).start();
    }
  }, []);

  const resume = React.useCallback(
    () =>
      isFinite(transitionDurationRef.current) &&
      Animated.sequence([
        Animated.timing(animation.current, {
          toValue: 1,
          duration:
            (1 - animationValueRef.current) * transitionDurationRef.current,
          useNativeDriver: false,
        }),
        resetAnim.current,
      ]).start(({finished}) => finished && startLoop()),
    [startLoop],
  );

  const stop = React.useCallback(
    () => animSequence?.current?.stop(),
    [animSequence],
  );

  React.useEffect(() => {
    transitionDurationRef.current = transitionDuration;
    colorArrayState.current = cloneDeep(colors);
    setOutputArray(cloneDeep(colors));
    resetAnim.current.start();
  }, [colors, isAnimating, transitionDuration]);

  React.useEffect(() => {
    if (
      isAnimating &&
      isAppStateActive &&
      isFocused &&
      colors.length > 1 &&
      isFinite(transitionDuration)
    ) {
      if (isAppStateActiveRef.current === isAppStateActive) {
        animSequence.current = Animated.sequence([
          Animated.timing(animation.current, {
            toValue: 1,
            duration: transitionDuration,
            useNativeDriver: false,
          }),
          resetAnim.current,
        ]);
        startLoop();
      } else {
        resume();
      }
    } else {
      stop();
    }
    isAppStateActiveRef.current = isAppStateActive;
  }, [
    colors,
    isAnimating,
    isAppStateActive,
    isFocused,
    resume,
    startLoop,
    stop,
    transitionDuration,
  ]);

  React.useEffect(() => {
    if (colors.length > 1) {
      const mixedColors = colors.map((_, index) =>
        mixColor({
          colorA: colorArrayState.current[index],
          colorB:
            colorArrayState.current[
              index + 1 === colorArrayState.current.length ? 0 : index + 1
            ],
          percentage: animationValue,
        }),
      );
      setOutputArray(mixedColors);
      if (animationValue === 1) {
        colorArrayState.current = cloneDeep(mixedColors);
      }
    }
  }, [animationValue, colors]);

  return [outputArray, animationValue];
}

import {Surface as SurfaceGLRD} from 'gl-react-dom';
import React from 'react';
import {View} from 'react-native';

export const Surface = (props: any) => {
  return (
    <View style={props?.style}>
      <SurfaceGLRD
        height={props?.style?.height || 0}
        width={props?.style?.width || 0}>
        {props?.children}
      </SurfaceGLRD>
    </View>
  );
};

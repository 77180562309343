import {Text} from 'react-native';

import {appColors} from '../../../constants';
import styled from '../../../lib/styled';

export const Info = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  letterSpacing: 0.37,
  color: appColors.white,
  alignSelf: 'flex-start',
  marginLeft: 16,
  marginRight: 16,
  fontWeight: '400',
});

import React from 'react';
import {StyleSheet, Text, TextProps} from 'react-native';

import {useTheme} from '../../../lib/useTheme';

export const InfoText = ({
  children,
  style,
  ...rest
}: React.PropsWithChildren<TextProps>) => {
  const {infoText} = useTheme().controlCenterTheme;
  const textStyle = StyleSheet.flatten(style);

  return (
    <Text
      style={{
        color: infoText,
        fontFamily: 'Roboto-Regular',
        fontWeight: '400',
        fontSize: 13,
        letterSpacing: 0.38,
        ...textStyle,
      }}
      {...rest}>
      {children}
    </Text>
  );
};

import {Constants, MOOD_ARRAY_OFFSETS} from '../../constants';
import {convertHexToRgbArray} from '../convertHexToRgbArray';
import {
  CustomMoodLight,
  DeviceModelType,
  LumaAnimation,
  MoodLight,
  MoodType,
  RawMoodLight,
  TableColor,
  isCustomMoodLight,
} from '../types';
import {Triple, htmlColorToTriple, lchycle} from './';

const packTableTriples = (tableTriples: Triple[]) => {
  const tableBytes = new Array<number>(Constants.MAX_COLOR_ARRAY_LENGTH * 4);
  for (let i = 0; i < Constants.MAX_COLOR_ARRAY_LENGTH; ++i) {
    const triple = tableTriples[i] ?? [0, 0, 0];
    tableBytes.splice(i * 4, 4, ...triple.map(c => Math.round(c * 255)), 0);
  }
  return tableBytes;
};

export const calcTempoCpm = (tempo: number) =>
  tempo * tempo * Constants.MAX_TEMPO_COLORS_PER_MINUTE;

export const convertMoodLightToRaw = (
  moodLight: MoodLight,
  deviceModelType: DeviceModelType,
  nvmIndex: number,
) => {
  const tableColor = convertMoodLightToTableColor(moodLight, nvmIndex);
  if (isCustomMoodLight(moodLight)) {
    const {colors, type} = moodLight;
    const typeNum = Number(type) as MoodType;
    let offsetArray: number[];

    switch (typeNum) {
      case MoodType.NO_ANIMATION: {
        offsetArray =
          MOOD_ARRAY_OFFSETS.noAnimationDictionary[deviceModelType][
            colors.length
          ];
        break;
      }

      case MoodType.DISCO: {
        offsetArray = MOOD_ARRAY_OFFSETS.disco;
        break;
      }

      case MoodType.FADE: {
        offsetArray = MOOD_ARRAY_OFFSETS.allZeros;
        break;
      }

      case MoodType.SPIN: {
        offsetArray = MOOD_ARRAY_OFFSETS.allZeros;
        break;
      }

      case MoodType.SPLIT_GRADIENT: {
        offsetArray = MOOD_ARRAY_OFFSETS.splitGradient[colors.length];
        break;
      }

      case MoodType.VERTICAL_SLIDESHOW: {
        offsetArray = MOOD_ARRAY_OFFSETS.verticalSlideShow;
        break;
      }

      case MoodType.TORNADO: {
        //TODO
        offsetArray = [];
        break;
      }

      default:
        offsetArray = [];
    }

    return {
      tableColor,
      colorArray: convertCustomMoodLightToRawColorArray(moodLight),
      offsetArray,
    } as RawMoodLight;
  } else {
    return {...moodLight.rawMoodLight, tableColor} as RawMoodLight;
  }
};

export const convertCustomMoodLightToRawColorArray = (
  moodLight: CustomMoodLight,
) => {
  const {colors: cTemp, type} = moodLight;

  //If colors has a length of one then add black as the secondary color
  const colors = cTemp.length > 1 ? cTemp : [cTemp[0], '#000000'];

  switch (type) {
    case MoodType.NO_ANIMATION: {
      const filledColors = [
        ...colors,
        ...new Array(6 - colors.length).fill('#000000'),
      ];
      return filledColors
        .map(colors => convertHexToRgbArray(colors))
        .reduce((accumulator, color) => {
          color.forEach(color => accumulator.push(color));
          return accumulator;
        });
    }

    case MoodType.DISCO: {
      return packTableTriples(
        lchycle({
          colors: colors.map(htmlColorToTriple),
          nOut: colors.length * 5,
          zeroDeriv: true,
          luv: true,
        }),
      );
    }

    case MoodType.FADE: {
      return packTableTriples(
        lchycle({
          colors: colors.map(htmlColorToTriple),
          nOut: colors.length * 5,
          steady: 0.3,
          zeroDeriv: true,
          luv: true,
        }),
      );
    }

    case MoodType.SPIN: {
      return packTableTriples(
        lchycle({
          colors: colors.map(htmlColorToTriple),
          nOut: colors.length * 5,
          steady: 0.3,
          zeroDeriv: true,
          luv: true,
        }),
      );
    }

    case MoodType.SPLIT_GRADIENT: {
      return packTableTriples(
        lchycle({
          colors: colors.map(htmlColorToTriple),
          nOut: colors.length * 5,
          zeroDeriv: true,
          luv: true,
        }),
      );
    }

    case MoodType.VERTICAL_SLIDESHOW: {
      return packTableTriples(
        lchycle({
          colors: colors.map(htmlColorToTriple),
          nOut: colors.length * 5,
          zeroDeriv: true,
          luv: true,
        }),
      );
    }

    case MoodType.TORNADO: {
      //TODO
      return packTableTriples(
        lchycle({
          colors: colors.map(htmlColorToTriple),
          nOut: colors.length * 5,
          zeroDeriv: true,
          luv: true,
        }),
      );
    }

    default:
      return [];
  }
};

export const convertMoodLightToTableColor: (
  moodLight: MoodLight,
  nvmIndex: number,
) => TableColor = (moodLight, nvmIndex) => {
  const arrayIndices = combineIndices({
    offsetIndex: nvmIndex,
    colorIndex: nvmIndex,
  });
  if (isCustomMoodLight(moodLight)) {
    const {colors: cTemp, type, tempo} = moodLight;
    const tempoCpm = calcTempoCpm(tempo);

    //If colors has a length of one then add black as the secondary color
    const colors = cTemp.length > 1 ? cTemp : [cTemp[0], '#000000'];

    const commonTableColorValues = {
      brightness: Constants.DEFAULT_TABLE_COLOR_BRIGHTNESS,
      arrayIndices,
    };

    switch (type) {
      case MoodType.NO_ANIMATION: {
        return {
          ...commonTableColorValues,
          speed: 64,
          lumaAnimation: LumaAnimation.STEADY,
          phaseLockNumerator: 0,
          phaseLockDenominator: 1,
          colorArrayLength: 32,
        };
      }

      case MoodType.DISCO: {
        return {
          ...commonTableColorValues,
          speed: tempoCpm ? Math.round(tempoCpm / 3) : 64,
          lumaAnimation: LumaAnimation.STEADY,
          phaseLockNumerator: 0,
          phaseLockDenominator: tempoCpm > 0 ? 0 : 1,
          colorArrayLength: colors.length * 5,
        };
      }

      case MoodType.FADE: {
        return {
          ...commonTableColorValues,
          speed: Math.round(tempoCpm / 3),
          lumaAnimation: LumaAnimation.STEADY,
          phaseLockNumerator: 0,
          phaseLockDenominator: 0,
          colorArrayLength: colors.length * 5,
        };
      }

      case MoodType.SPIN: {
        return {
          ...commonTableColorValues,
          speed: Math.min(Math.round((tempoCpm * 256) / 480), 255),
          lumaAnimation: LumaAnimation.CIRCLING,
          phaseLockNumerator: 1,
          phaseLockDenominator: colors.length,
          colorArrayLength: colors.length * 5,
        };
      }

      case MoodType.SPLIT_GRADIENT: {
        return {
          ...commonTableColorValues,
          speed: tempoCpm > 0 ? Math.round(tempoCpm / 3) : 64,
          lumaAnimation: LumaAnimation.STEADY,
          phaseLockNumerator: 0,
          phaseLockDenominator: tempoCpm > 0 ? 0 : 1,
          colorArrayLength: colors.length * 5,
        };
      }

      case MoodType.VERTICAL_SLIDESHOW: {
        return {
          ...commonTableColorValues,
          speed: tempoCpm > 0 ? Math.round(tempoCpm / 3) : 64,
          lumaAnimation: LumaAnimation.STEADY,
          phaseLockNumerator: 0,
          phaseLockDenominator: tempoCpm > 0 ? 0 : 1,
          colorArrayLength: colors.length * 5,
        };
      }

      case MoodType.TORNADO: {
        //TODO
        return {
          ...commonTableColorValues,
          speed: tempoCpm > 0 ? Math.round(tempoCpm / 3) : 64,
          lumaAnimation: LumaAnimation.STEADY,
          phaseLockNumerator: 0,
          phaseLockDenominator: tempoCpm > 0 ? 0 : 1,
          colorArrayLength: colors.length * 5,
        };
      }

      default: {
        // return NO_ANIMATION
        return {
          ...commonTableColorValues,
          speed: 64,
          lumaAnimation: LumaAnimation.STEADY,
          phaseLockNumerator: 0,
          phaseLockDenominator: 1,
          colorArrayLength: 32,
        };
      }
    }
  } else {
    return {...moodLight.rawMoodLight.tableColor, arrayIndices};
  }
};

export const combineIndices = ({
  offsetIndex,
  colorIndex,
}: {
  offsetIndex?: number;
  colorIndex: number;
}) =>
  (offsetIndex === undefined
    ? 0
    : (Constants.NVM_ACCESS_USER_ARRAY_STARTING_OFFSET + offsetIndex) << 4) |
  (Constants.NVM_ACCESS_USER_ARRAY_STARTING_OFFSET + colorIndex);

export const splitIndices = (combinedIndices: number) => {
  return {
    offsetIndex:
      (combinedIndices >> 4) - Constants.NVM_ACCESS_USER_ARRAY_STARTING_OFFSET,
    colorIndex:
      (combinedIndices & 0x0f) -
      Constants.NVM_ACCESS_USER_ARRAY_STARTING_OFFSET,
  };
};

const revLetters = 'ABCDEFGHJKMNPRTUVWXYZ';

export const revisionNumberToString = (value: number) => {
  if (typeof value !== 'number' || !(value >= 0)) {
    return `${value}`;
  }
  if (value === 0) {
    return 'X*';
  }

  let shift = value - 1;
  let out = '';
  while (shift >= 0) {
    out = revLetters[shift % revLetters.length] + out;
    shift = Math.floor(shift / revLetters.length) - 1;
  }
  return out;
};

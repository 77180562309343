import {useSelector} from 'react-redux';

import {
  Constants,
  POSSIBLE_XL_CHAMBER_KITS,
  XL_CHAMBER_KITS,
} from '../../constants';
import {
  currentDeviceSWRevisionSelector,
  currentDeviceSelector,
} from '../../lib/redux/bleSlice';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {ChamberType} from '../types';
import {useWatchDevice} from './useWatchDevice';

export const useChamberType = () => {
  const fw = useSelector(currentDeviceSWRevisionSelector);
  const currentDevice = useSelector(currentDeviceSelector);
  const chamberType = useWatchDevice('chamberType');

  const isMeetAC = meetsMinimumFirmware(
    fw,
    Constants.MINIMUM_FIRMWARE_VERSION.VAPOR_SETTING,
  );
  const isMeetAG = meetsMinimumFirmware(
    fw,
    Constants.MINIMUM_FIRMWARE_VERSION.XL_CHAMBER,
  );
  const is3dChamber = chamberType === ChamberType.Performance;
  const isXLChamber = chamberType === ChamberType.XL;

  const is3dVaporEnabled = isMeetAC && is3dChamber;
  const isXLEnabled = isMeetAG && isXLChamber;

  const isVaporEnabled = is3dVaporEnabled || isXLEnabled;

  const isChamberDisconnected = chamberType === ChamberType.None;
  const isPossibleXlChamberKit = currentDevice?.modelNumberString
    ? POSSIBLE_XL_CHAMBER_KITS.includes(currentDevice?.modelNumberString)
    : false;
  const isXlChamberKit = currentDevice?.modelNumberString
    ? XL_CHAMBER_KITS.includes(currentDevice?.modelNumberString)
    : false;

  return {
    isVaporEnabled,
    is3dVaporEnabled,
    isXLEnabled,
    isMeetAC,
    isChamberDisconnected,
    is3dChamber,
    isXLChamber,
    isPossibleXlChamberKit,
    isXlChamberKit,
    isMeetAG,
  };
};

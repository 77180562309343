import React from 'react';
import {StyleProp, StyleSheet, Text, ViewStyle} from 'react-native';

import {appColors} from '../../../constants';
import {PressableOpacity} from '../../../shims/PressableOpacity';

export interface Props {
  tag: string;
  selected?: boolean;
  onPress: () => void;
  style: StyleProp<ViewStyle>;
}

export const Tag = ({tag, selected, style, onPress}: Props) => (
  <PressableOpacity
    style={[
      styles.container,
      {
        borderColor: selected ? appColors.white : 'transparent',
        borderWidth: 1,
      },
      style,
    ]}
    onPress={onPress}>
    <Text style={styles.tag}>{tag}</Text>
  </PressableOpacity>
);

const styles = StyleSheet.create({
  tag: {
    flex: 1,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontWeight: '400',
    color: appColors.white,
    opacity: 0.75,
  },
  container: {
    backgroundColor: appColors.darkGray,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 8,
  },
});

import React from 'react';
import {
  ImageBackground,
  ImageBackgroundProps,
  ImageProps,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import {fillStyle} from '../constants';
import {Image} from '../shims/ImageWithFilter';
import {
  LinearGradientBackground,
  LinearGradientProps,
} from './LinearGradientBackground';

export type Background = {
  element?: JSX.Element;
  imageBackgroundProps?: ImageBackgroundProps;
  imageProps?: ImageProps;
  linearGradientProps?: LinearGradientProps;
  viewProps?: ViewProps;
};

export type LayeredBackgroundProps = React.PropsWithChildren<
  {
    backgrounds: Background[];
  } & ViewProps
>;

/*
 * Background componenent for stacking multiple layered backgrounds. Backgrounds
 * are stacked with earlier indices before subsequent indicies and children on top.
 */
export const LayeredBackground = ({
  backgrounds,
  children,
  style = {},
  ...rest
}: LayeredBackgroundProps) => {
  const flattenedStyle = StyleSheet.flatten(style);
  const {
    padding,
    paddingBottom,
    paddingEnd,
    paddingHorizontal,
    paddingLeft,
    paddingRight,
    paddingStart,
    paddingTop,
    paddingVertical,
    borderRadius,
    ...restStyle
  } = flattenedStyle;
  const {alignItems, justifyContent} = restStyle;

  return (
    <View
      style={{...fillStyle, borderRadius, ...restStyle, overflow: 'hidden'}}
      {...rest}>
      {backgrounds.map(
        (
          {
            linearGradientProps,
            imageBackgroundProps,
            imageProps,
            viewProps,
            element,
          }: Background,
          index,
        ) => {
          if (linearGradientProps) {
            const {style, ...restProps} = linearGradientProps;
            return (
              <LinearGradientBackground
                key={`${index}-lingrad`}
                style={{
                  ...StyleSheet.flatten(style),
                  ...backgroundStyle,
                  borderRadius,
                }}
                {...restProps}
              />
            );
          } else if (imageBackgroundProps) {
            const {style, ...restProps} = imageBackgroundProps;
            return (
              <ImageBackground
                key={`${index}-imgbkgd`}
                style={{
                  ...StyleSheet.flatten(style),
                  ...backgroundStyle,
                  borderRadius,
                }}
                imageStyle={{
                  borderRadius,
                }}
                {...restProps}
              />
            );
          } else if (imageProps) {
            const {style, ...restProps} = imageProps;
            return (
              <Image
                key={`${index}-img`}
                style={{
                  ...backgroundStyle,
                  borderRadius,
                  ...StyleSheet.flatten(style),
                }}
                {...restProps}
              />
            );
          } else if (viewProps) {
            const {style, ...restProps} = viewProps;
            return (
              <View
                key={`${index}-view`}
                style={{
                  ...StyleSheet.flatten(style),
                  ...backgroundStyle,
                  borderRadius,
                }}
                {...restProps}
              />
            );
          } else if (element) {
            return React.cloneElement(element, {
              key: `${index}-element`,
              style: {
                ...backgroundStyle,
                borderRadius,
              },
            });
          }
          return null;
        },
      )}
      <View
        style={{
          ...backgroundStyle,
          padding,
          paddingBottom,
          paddingEnd,
          paddingHorizontal,
          paddingLeft,
          paddingRight,
          paddingStart,
          paddingTop,
          paddingVertical,
          alignItems,
          justifyContent,
          borderRadius,
        }}>
        {children}
      </View>
    </View>
  );
};

const backgroundStyle: Pick<ViewStyle, 'width' | 'height' | 'position'> = {
  ...fillStyle,
  position: 'absolute',
};

import React from 'react';

export type MultiColorOpalBackgroundProps = {
  colors: string[];
  gradientLayerOpacity?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const MultiColorOpalBackground = ({
  colors,
  children,
  style,
  gradientLayerOpacity = 0.1,
}: MultiColorOpalBackgroundProps) => (
  <div
    style={{
      ...style,
      width: '100%',
      height: '100%',
      opacity: gradientLayerOpacity,
      background: `conic-gradient(from 180deg, #F4F4F4 10%, ${colors.reduce(
        (prev, curr, currIndex) => {
          const percentage = (index: number) =>
            index * (20 / (colors.length - 1));
          return `${prev}, ${curr} ${40 + percentage(currIndex)}%`;
        },
      )}, #F4F4F4 90%)`,
    }}>
    {children}
  </div>
);

import React from 'react';

import {Countdown as CountdownComponent} from '../../../components/Countdown';

interface CountdownConfig {
  date: string;
  displayAfter?: string;
}

interface Props {
  config: CountdownConfig;
}

export const Countdown: React.FC<Props> = ({config}) => {
  return (
    <CountdownComponent
      targetDate={new Date(config.date)}
      displayAfter={
        config.displayAfter ? new Date(config.displayAfter) : undefined
      }
    />
  );
};

import React from 'react';
import {StyleSheet, View, ViewProps, ViewStyle} from 'react-native';

import {appColors} from '../constants';
import {Constants} from '../constants/Constants';
import {
  MoodLight,
  MoodLightCardTheme,
  isCustomMoodLight,
  isExclusiveMoodLight,
} from '../lib/types';
import {addOpacityToColorHex} from '../lib/utilityFunctions';
import {LayeredBackground} from './LayeredBackground';
import {LinearGradientBackground} from './LinearGradientBackground';
import {MultiGradientBackground} from './MultiGradientBackground';

const LBackground = ({
  borderRadius,
  moodLight,
  convertToBase64,
  children,
  gradientStyle,
}: {
  borderRadius: number;
  moodLight: MoodLight & {cardImageUrl: string};
  children: React.ReactNode;
  convertToBase64?: boolean;
  gradientStyle?: ViewStyle;
}) => {
  const [data, setData] = React.useState<string>();

  React.useEffect(() => {
    if (convertToBase64) {
      const getBase64FromUrl = async (url: string) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise<string>(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data as string);
          };
        });
      };

      getBase64FromUrl(moodLight.cardImageUrl).then(v => setData(v));
    } else {
      setData(moodLight.cardImageUrl);
    }
  }, [moodLight.cardImageUrl, convertToBase64]);

  return (
    <View
      style={{
        height: gradientStyle?.height ?? '100%',
        width: gradientStyle?.width ?? '100%',
        overflow: 'hidden',
      }}>
      <LayeredBackground
        style={{borderRadius, ...gradientStyle}}
        backgrounds={[
          {viewProps: {style: {backgroundColor: appColors.black}}}, // Default in case image loads slowly or errors
          {
            imageBackgroundProps: {
              source: {
                uri: data,
              },
            },
          },
          {
            linearGradientProps: {
              colors: [
                addOpacityToColorHex(appColors.black, 0.7),
                appColors.invisible,
              ],
              useAngle: true,
              angle: 90,
              angleCenter: {x: 0.5, y: 0.5},
            },
          },
        ]}>
        {children}
      </LayeredBackground>
    </View>
  );
};

export type MoodLightCardBackgroundProps = {
  moodLight: MoodLight;
  gradientShadeFunction: MoodLightCardTheme['gradientShadeFunction'];
  width: number;
  height: number;
  borderRadius?: number;
  convertToBase64?: boolean;
  gradientStyle?: ViewStyle;
} & ViewProps;

export const MoodLightCardBackground = ({
  moodLight,
  gradientShadeFunction,
  width,
  height,
  children,
  style = {},
  borderRadius = 10,
  convertToBase64,
  gradientStyle,
  ...rest
}: React.PropsWithChildren<MoodLightCardBackgroundProps>) => {
  const flattenedStyle = StyleSheet.flatten(style);

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        borderRadius,
        overflow: 'hidden',
        ...flattenedStyle,
      }}
      {...rest}>
      {!!moodLight && isExclusiveMoodLight(moodLight) ? (
        <LBackground
          borderRadius={borderRadius}
          moodLight={moodLight}
          convertToBase64={convertToBase64}
          gradientStyle={{width, height, borderRadius, ...gradientStyle}}>
          {children}
        </LBackground>
      ) : !!moodLight &&
        isCustomMoodLight(moodLight) &&
        moodLight.colors.length > 1 ? (
        <MultiGradientBackground
          gradientShadeFunction={gradientShadeFunction}
          angles={
            moodLight.colors.length === 2
              ? [...Constants.HORIZONTAL_GRADIENT_ANGLES]
              : undefined
          }
          colors={moodLight.colors}
          style={{
            width: width,
            height: height,
            borderRadius,
            ...gradientStyle,
          }}>
          {children}
        </MultiGradientBackground>
      ) : (
        <LinearGradientBackground
          colors={gradientShadeFunction(moodLight.colors[0])}
          useAngle={true}
          angle={270}
          angleCenter={{x: 0.5, y: 0.5}}
          style={{
            borderRadius,
            width,
            height,
            ...gradientStyle,
          }}>
          {children}
        </LinearGradientBackground>
      )}
    </View>
  );
};

import React from 'react';
import {StyleSheet, ViewProps} from 'react-native';

import {useAppHeaderHeight} from '../lib/hooks';
import {useSafeArea} from '../lib/useSafeArea';
import {SafeAreaView} from '../shims/SafeAreaView';

export type HeaderSafeAreaViewProps = ViewProps & {
  isHeaderSafe?: boolean;
};

export const HeaderSafeAreaView = ({
  children,
  isHeaderSafe = true,
  style = {},
  ...rest
}: React.PropsWithChildren<HeaderSafeAreaViewProps>) => {
  const {top} = useSafeArea();
  const HEADER_HEIGHT = useAppHeaderHeight();

  return (
    <SafeAreaView
      style={{
        paddingTop: top + (isHeaderSafe ? HEADER_HEIGHT : 0),
        ...StyleSheet.flatten(style),
      }}
      {...rest}>
      {children}
    </SafeAreaView>
  );
};

import {CreateSupportTicketDto} from 'puffco-api-axios-client';

import {supportApi} from './apis';
import {zendeskApi} from './zendesk-api';

export interface SubmitSupportTicketOptions
  extends Pick<CreateSupportTicketDto, 'request' | 'customFields'> {
  arrayBuffer: ArrayBuffer;
}

const uploadIfNecessary = async (
  options: SubmitSupportTicketOptions,
): Promise<CreateSupportTicketDto> => {
  if (!options.arrayBuffer)
    return {
      customFields: options.customFields,
      request: options.request,
    };

  // TODO: Legacy code here, rewrite this at a future time

  const byteArray = new Uint8Array(options.arrayBuffer);

  const {upload} = await zendeskApi.uploadFile({
    body: byteArray,
    deviceId: options.request.deviceId,
  });

  return {
    token: upload.token,
    customFields: options.customFields,
    request: options.request,
  };
};

const submitClaim = (options: SubmitSupportTicketOptions) =>
  uploadIfNecessary(options).then(createSupportTicketDto =>
    supportApi.submitTicket({createSupportTicketDto}),
  );

export const supportAccess = {
  submitClaim,
};

import type {Asset, Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypePageFields {
  title: EntryFields.Symbol;
  type: 'COMMUNITY' | 'COMMUNITY-SECONDARY';
  heroImage?: Asset;
  content: EntryFields.RichText;
  order: EntryFields.Integer;
  screenName: EntryFields.Symbol;
  fixedFooter?: EntryFields.RichText;
  backToTopButton?: EntryFields.Boolean;
  minimumVersion?: EntryFields.Symbol;
}

export type TypePage = Entry<TypePageFields>;

export function isTypePage(entry: WithContentTypeLink): entry is TypePage {
  return entry?.sys?.contentType?.sys?.id === 'page';
}

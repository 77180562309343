import html2canvas from 'html2canvas';
import React from 'react';

export type CaptureCompRefProps = {
  capture: () => Promise<string>;
};

export const CaptureContainer = React.forwardRef(function CaptureContainer(
  {children}: {children: React.ReactNode},
  ref,
) {
  const capRef = React.useRef<HTMLDivElement>(null);

  const captureImage = async () => {
    const element = capRef.current;
    const canvas = await html2canvas(element as HTMLElement, {
      useCORS: true,
    });
    const data = canvas.toDataURL('png');
    return data;
  };

  React.useImperativeHandle(ref, () => ({capture: captureImage}), []);

  return (
    <div
      style={{
        position: 'absolute',
        opacity: 0,
        flex: 1,
        zIndex: -1,
        pointerEvents: 'none',
      }}>
      <div ref={capRef} style={{flex: 1}}>
        {children}
      </div>
    </div>
  );
});

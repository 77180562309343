export const Storage = {
  async getItem(key: string) {
    const item = localStorage.getItem(key);
    let retval = null;
    try {
      if (item) {
        retval = JSON.parse(item);
      }
    } catch {
      // TOOD
    }
    return retval;
  },

  async removeItem(key: string) {
    localStorage.removeItem(key);
  },

  async setItem(key: string, newItem: any): Promise<void> {
    localStorage.setItem(key, JSON.stringify(newItem));
  },
};

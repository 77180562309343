import React from 'react';

import {backgroundXLUnlockedAlertScreen} from '../../../assets/images';
import {
  Constants,
  Messages,
  Navigators,
  Screens,
  Strings,
} from '../../../constants';
import {useWindowHeight} from '../../../lib/hooks';
import {ChamberType, Device} from '../../../lib/types';
import {StaticScreenInterstitial} from '../../../lib/types/Interstitial';
import {meetsMinimumFirmware} from '../../../lib/utilityFunctions';
import {StaticScreenInterstitialProps} from '../../StaticScreenInterstitial';
import {ImageAndContentContainer} from '../../components';

const XLUnlocked = ({
  navigation,
  onNavigatingAway,
}: StaticScreenInterstitialProps) => {
  const {windowHeight} = useWindowHeight();

  const primaryButton = {
    title: Strings.DEFAULT_BTN.toUpperCase(),
    onClick: () => {
      onNavigatingAway();

      navigation.navigate(Navigators.HomeTabNavigator, {
        screen: Screens.Home,
      });
    },
  };

  return (
    <ImageAndContentContainer
      image={backgroundXLUnlockedAlertScreen}
      imageContainerStyle={{
        maxHeight: windowHeight / 2,
      }}
      header={{content: Messages.XL_UNLOCKED_ALERT_SCREEN_TITLE}}
      body={{
        content: Messages.XL_UNLOCKED_ALERT_SCREEN_MSG,
        style: {textAlign: 'left'},
      }}
      footerContainerStyle={{marginTop: 'auto'}}
      primaryButton={primaryButton}
    />
  );
};

const hasChamberType = (
  currentDevice: Device,
  chamberType: ChamberType | ChamberType[],
) => {
  if (
    !currentDevice?.chamberType ||
    (currentDevice.chamberType && typeof chamberType === 'number'
      ? currentDevice.chamberType !== chamberType
      : !(chamberType as number[]).includes(currentDevice.chamberType))
  )
    return false;
  return true;
};

export const XLUnlockedInterstitial: StaticScreenInterstitial = {
  key: 'xl-unlocked',
  startDate: new Date('2023-06-23'),
  type: 'static',
  display: {
    as: 'screen',
    if: ({connectedDevice, currentDevice}) => {
      if (!connectedDevice || !currentDevice) return false;

      return (
        hasChamberType(currentDevice, ChamberType.XL) &&
        meetsMinimumFirmware(
          currentDevice.softwareRevisionString ?? '',
          Constants.MINIMUM_FIRMWARE_VERSION.XL_CHAMBER,
        )
      );
    },
  },
  options: {
    screen: XLUnlocked,
  },
};

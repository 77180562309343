import {ContentfulClientApi, createClient} from 'contentful';

import {Environment} from '../../../constants';
import {GuideType, PageType} from './custom-types';
import {
  TypeDeviceFields,
  TypeFaqFields,
  TypeGuideFields,
  TypePageFields,
} from './types';

export interface ContentfulClientOptions {
  accessToken?: string;
  spaceId?: string;
  environment?: string;
}

class ContentAccess {
  private readonly client: ContentfulClientApi;

  public constructor({
    accessToken,
    spaceId,
    environment,
  }: ContentfulClientOptions) {
    if (!accessToken || !spaceId || !environment)
      throw new Error('Missing credentials');

    this.client = createClient({
      accessToken,
      space: spaceId,
      environment,
    });
  }

  public getEntry({id}: {id: string}) {
    return this.client.getEntry(id);
  }

  public getDevices() {
    return this.client.getEntries<TypeDeviceFields>({
      content_type: 'device',
      order: 'fields.order',
      include: 2,
    });
  }

  public getPages({type}: {type: PageType}) {
    return this.client.getEntries<TypePageFields>({
      content_type: 'page',
      'fields.type': type,
      order: 'fields.order',
      include: 2,
    });
  }

  public getPage({screenName}: {screenName: string}) {
    return this.client
      .getEntries<TypePageFields>({
        content_type: 'page',
        'fields.screenName': screenName,
        include: 3,
      })
      .then(({items}) => items[0]);
  }

  public getGuide({type}: {type: GuideType}) {
    return this.client
      .getEntries<TypeGuideFields>({
        content_type: 'guide',
        'fields.type': type,
      })
      .then(({items}) => items[0]);
  }

  public getFaqsForReference({
    query,
    tag,
    referenceId,
  }: {
    referenceId: string;
    query?: string;
    tag?: string;
  }) {
    return this.client.getEntries<TypeFaqFields>({
      content_type: 'faq',
      query,
      'fields.references.sys.id[in]': referenceId,
      order: 'fields.order',
      'fields.tags.sys.id[in]': tag,
    });
  }
}

export const contentAccess = new ContentAccess({
  spaceId: Environment.contentful.spaceId,
  environment: Environment.contentful.environment,
  accessToken: Environment.contentful.accessToken,
});

import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, TouchableOpacityProps, View} from 'react-native';
import {useSelector} from 'react-redux';

import {Constants, appColors} from '../constants';
import {
  ProfileTitleStyle,
  ProfileDurationTitleStyle as durationStyle,
} from '../constants/Styles';
import {changeHexColorValues} from '../lib/changeHexColorValues';
import {useTemperature} from '../lib/convertTemperature';
import {getTimeFormat} from '../lib/getTimeFormat';
import {useAppHeaderHeight, useGetVaporSetting} from '../lib/hooks';
import {appSettingsSelector} from '../lib/redux/appSettingsSlice';
import styled from '../lib/styled';
import {
  MoodLight,
  Profile,
  Theme,
  isCustomMoodLight,
  isTHeatProfileMoodLight,
} from '../lib/types';
import {useColorTransition} from '../lib/useColorTransition';
import {useSafeArea} from '../lib/useSafeArea';
import {
  getProfileAnimatedHaloWHSize,
  getProfileGlowHaloWHSize,
  getProfileLogoSize,
  getProfileTempFontSize,
  isWhiteColor,
} from '../lib/utilityFunctions';
import {AnimatedHalo, calculateInnerContainerDiameter} from './AnimatedHalo';
import {AppText} from './AppText';
import {GlowingHalo} from './GlowingHalo';
import {HeatProfileScreenLayeredBackground} from './HeatProfileScreenLayeredBackground';
import {ProfileTemperature} from './ProfileTemperature';
import {StartButton} from './StartButton';
import {VaporModeText} from './VaporModeText';

const {
  SCREEN_HEIGHT: {PX_734},
} = Constants;

export type ProfileDisplayProps = TouchableOpacityProps & {
  profile: Profile;
  moodLight?: MoodLight;
  theme: Theme;
  height: number;
  width?: number;
  isAnimating?: boolean;
  isOpal: boolean;
  indicatorHeight?: number;
  isVaporEnabled: boolean;
};

export const ProfileDisplay = (props: ProfileDisplayProps) => {
  const {
    profile,
    moodLight,
    theme,
    height,
    width,
    isAnimating = true,
    isOpal,
    indicatorHeight = 100,
    isVaporEnabled,
    ...rest
  } = props;
  const {durationStyle, heatProfileSelectScreenTheme, temperatureBigStyle} =
    theme;
  const {durationColor, primaryColor, startTextStyle} =
    heatProfileSelectScreenTheme;
  const {saturation: stdS, lightness: stdL} = Constants.STD_HSL_VALUES;
  const {height: windowHeight} = Dimensions.get('window');
  const insets = useSafeArea();
  const HEADER_HEIGHT = useAppHeaderHeight();
  const settings = useSelector(appSettingsSelector);

  const maxTemp = useTemperature(
    Constants.TEMPERATURE_MAX_FAHRENHEIT,
    TemperatureUnit.Fahrenheit,
  );

  const temperature = useTemperature(profile.temperature, profile.units);

  const intensity = React.useMemo(
    () => Math.round(temperature / maxTemp),
    [temperature, maxTemp],
  );

  const isMoodLight = isTHeatProfileMoodLight(profile);
  const profileColor = isMoodLight ? '#FFFFFF' : profile.color;
  const backgroundColor = useColorTransition({
    color: isOpal && isWhiteColor(profileColor) ? '#000000' : profileColor,
  });
  const displayColors =
    isMoodLight && moodLight
      ? moodLight.colors
      : [changeHexColorValues(profileColor, stdS, stdL, true)];

  const calculateWidth = () => {
    if (Constants.IS_WEB) {
      if (width) {
        return width;
      }
      return Dimensions.get('window').width;
    }
    return '100%';
  };

  function getProfileDynamicDisplayValues(height: number) {
    const animHaloSize = getProfileAnimatedHaloWHSize(height);
    const innerHaloDiameterSize = calculateInnerContainerDiameter(animHaloSize);
    const glowHaloSize = getProfileGlowHaloWHSize(height);
    const logoSize = getProfileLogoSize(height);
    const tempFontSize = getProfileTempFontSize(height);
    return {
      animHaloSize,
      innerHaloDiameterSize,
      glowHaloSize,
      logoSize,
      tempFontSize,
    };
  }

  const {
    animHaloSize,
    innerHaloDiameterSize,
    glowHaloSize,
    logoSize,
    tempFontSize,
  } = React.useMemo(() => getProfileDynamicDisplayValues(height), [height]);

  const innerComponent = (
    <StartButton
      logoSize={logoSize}
      innerContainerDiameter={innerHaloDiameterSize}
      iconColor={primaryColor}
      {...rest}
      onPress={e => {
        rest.onPress && rest.onPress(e);
      }}
      startButtonStyle={startTextStyle}
    />
  );
  const haloPosition = (height - Constants.PROFILE_HALO_CONTAINER_HEIGHT) / 2;

  const [coreImageLayout, setCoreImageLayout] = React.useState<
    {height: number; y: number} | undefined
  >(undefined);
  const onCoreImageLayout = (e: {height: number; y: number}) => {
    setCoreImageLayout(e);
  };

  const getProfileInfoPaddingBottom = () => {
    // 734 window height for full screen iPhone X
    return windowHeight < PX_734 ? 0 : Math.min(height * 0.043, 35);
  };

  const vapor = useGetVaporSetting(profile);

  return (
    <MainContainer
      style={{
        height: height,
        width: calculateWidth(),
      }}>
      <HeatProfileScreenLayeredBackground
        useBaseBackground={false}
        theme={theme}
        backgroundScale={
          Constants.IS_WEB
            ? theme.heatProfileScreenBackgroundTheme
                .backgroundImageHeightToGemHeightRatioGlow
            : theme.heatProfileScreenBackgroundTheme
                .backgroundImageHeightToGemHeightRatioAnimated
        }
        coreImageLayout={coreImageLayout}
        {...(isTHeatProfileMoodLight(profile) && moodLight
          ? {moodLight}
          : {backgroundColor})}>
        <ContentContainer
          style={{
            width: '100%',
            height: height,
          }}>
          <TitleContainer style={{paddingTop: insets.top + HEADER_HEIGHT}}>
            <Title
              numberOfLines={Constants.IS_WEB ? 2 : 0}
              style={{
                ...(startTextStyle?.color && {color: startTextStyle.color}),
                paddingTop:
                  height * Constants.PROFILE_TITLE_TOP_PADDING_BG_RATIO,
              }}>
              {profile.name.toUpperCase()}
            </Title>
          </TitleContainer>
          <HaloContainer style={{marginTop: haloPosition}}>
            {Constants.IS_WEB ? (
              <GlowingHalo
                glowWidthHeight={glowHaloSize}
                colors={displayColors}
                theme={theme}
                seconds={profile.duration}
                innerComponent={innerComponent}
                marginTop={8}
                onCoreImageLayout={onCoreImageLayout}
              />
            ) : (
              <AnimatedHalo
                haloDiameter={animHaloSize}
                theme={theme}
                colors={displayColors}
                isMoodAnimating={
                  moodLight &&
                  isCustomMoodLight(moodLight) &&
                  Number(moodLight.type) !== 0
                }
                isMoodLight={isMoodLight}
                seconds={profile.duration}
                active={isAnimating}
                {...{intensity}}
                innerComponent={innerComponent}
                onCoreImageLayout={onCoreImageLayout}
              />
            )}
          </HaloContainer>
          <ProfileInfoContainer
            style={{paddingBottom: getProfileInfoPaddingBottom()}}>
            <ProfileTemperature
              style={{
                paddingLeft: 12,
                paddingBottom: 8,
              }}
              {...{temperature}}
              temperatureUnit={settings.tempPreference[0]}
              textStyle={{
                fontSize: tempFontSize,
                ...(startTextStyle?.color && {color: startTextStyle.color}),
                ...temperatureBigStyle,
              }}
            />
            <Time style={{color: durationColor, ...durationStyle}}>
              {getTimeFormat(profile.duration, {
                padMinutes: true,
                padSeconds: true,
              })}
            </Time>
            {isVaporEnabled && (
              <VaporModeContainer>
                <VaporModeText {...{vapor}} />
              </VaporModeContainer>
            )}
          </ProfileInfoContainer>
          <ScrollIndicatorSpacer style={{height: indicatorHeight}} />
        </ContentContainer>
      </HeatProfileScreenLayeredBackground>
    </MainContainer>
  );
};

const MainContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'visible',
  flex: 1,
});
const ContentContainer = styled(View)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

// Header
const TitleContainer = styled(View)({
  maxWidth: '80%',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  flex: 0.5,
});

const Title = styled(AppText)({
  ...ProfileTitleStyle,
});

const ProfileInfoContainer = styled(View)({
  display: 'flex',
  flex: 0.5,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

// Halo
const HaloContainer = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  height: Constants.PROFILE_HALO_CONTAINER_HEIGHT,
  position: 'absolute',
  overflow: 'visible',
  width: '100%',
  zIndex: 1,
});

const Time = styled(AppText)({
  ...durationStyle,
  letterSpacing: 0.75,
  lineHeight: 15,
  textAlignVertical: 'center',
  alignSelf: 'center',
  fontFamily: 'Roboto-Regular',
});

// Footer
const ScrollIndicatorSpacer = styled(View)({
  height: 100,
});

const VaporModeContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: 10,
  paddingVertical: 4,
  backgroundColor: appColors.black,
  borderRadius: 100,
  marginTop: 8,
});

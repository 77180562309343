import React from 'react';

import {StyledIcon, StyledIconProps} from './';

export type CloseButtonProps = Pick<
  StyledIconProps,
  Exclude<keyof StyledIconProps, 'name'>
>;

export const CloseButton = (props: CloseButtonProps) => (
  <StyledIcon name={'close'} size={17} {...props} />
);

import {ImageStyle} from 'react-native';

export const isImageStyle = (style: object): style is ImageStyle => {
  if (typeof style !== 'object' || !style) return false;

  const validImageStyleProps = [
    'width',
    'height',
    'aspectRatio',
    'resizeMode',
    'margin',
    'marginBottom',
    'marginTop',
    'marginLeft',
    'marginRight',
    'marginHorizontal',
    'marginVertical',
    'padding',
    'paddingBottom',
    'paddingTop',
    'paddingLeft',
    'paddingRight',
    'paddingHorizontal',
    'paddingVertical',
    'left',
    'right',
    'top',
    'bottom',
    'position',
    'borderWidth',
    'borderTopWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderRightWidth',
    'borderRadius',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'borderBottomLeftRadius',
    'borderBottomRightRadius',
    'borderColor',
    'shadowColor',
    'shadowOffset',
    'shadowOpacity',
    'shadowRadius',
    'opacity',
    'tintColor',
    'overflow',
    'transform',
    'backgroundColor',
  ];

  return Object.keys(style).every(key => validImageStyleProps.includes(key));
};

import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';

import {useSafeArea} from '../lib/useSafeArea';

export const SafeAreaView = ({
  children,
  style = {},
  ...rest
}: React.PropsWithChildren<ViewProps>) => {
  const insets = useSafeArea();

  return (
    <View
      style={{
        paddingTop: insets.top,
        paddingBottom: insets.bottom,
        flex: 1,
        ...StyleSheet.flatten(style),
      }}
      {...rest}>
      {children}
    </View>
  );
};

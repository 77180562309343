import {Led3ColorPointer, RgbtColor} from 'pikaparam';

import {
  HEAT_CYCLE_ARRAY_INDICES,
  NVM_INDICES,
  TEMP_HEAT_CYCLE_ARRAY_INDEX,
} from './heat-cycle';
import {AnyDeviceProperties} from './properties';
import {HeatCycleArrayIndex, NvmIndex} from './types';

export const isLed3ColorPointer = (
  c: RgbtColor | Led3ColorPointer,
): c is Led3ColorPointer => !!(c as Led3ColorPointer).source;

export const isRgbtColor = (c: RgbtColor | ArrayBuffer): c is RgbtColor =>
  !(c instanceof ArrayBuffer);

export const isHeatCycleArrayIndex = <T extends AnyDeviceProperties>(
  n: number,
): n is HeatCycleArrayIndex<T> =>
  HEAT_CYCLE_ARRAY_INDICES.includes(n as HeatCycleArrayIndex);

export const isTempHeatProfileIndex = (
  n: number,
): n is typeof TEMP_HEAT_CYCLE_ARRAY_INDEX => n === TEMP_HEAT_CYCLE_ARRAY_INDEX;

export const isNvmIndex = <T extends AnyDeviceProperties>(
  n: number,
): n is NvmIndex<T> => NVM_INDICES.includes(n as NvmIndex);

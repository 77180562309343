import React from 'react';

import {ManualPdf} from '../../components';
import {Screens} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.CleaningManual
>;

export const CleaningManualScreen = ({route}: ScreenProps) => {
  useAdaptiveSafeArea();

  const source = route.params.source;

  return <ManualPdf source={source} />;
};

import React from 'react';
import {useSelector} from 'react-redux';

import {currentDeviceSelector} from '../redux/bleSlice';
import {hasLed3Api} from '../utilityFunctions/hasLed3Api';

export const useHasLed3Api = () => {
  const device = useSelector(currentDeviceSelector);

  return React.useMemo(() => !!device && hasLed3Api(device), [device]);
};

import {
  le04Peak,
  legacyPeakPeachBlackLightsBaseLeft,
  legacyPeakPeachBlackLightsBaseRight,
  legacyPeakPeachLightsGlassFarLeft,
  legacyPeakPeachLightsGlassFarRight,
  legacyPeakPeachLightsGlassMidLeft,
  legacyPeakPeachLightsGlassMidRight,
} from '../assets/images';
import {Constants} from '../constants';
import {appColors} from '../constants/Colors';
// Import from exact file to prevent cycle
import {
  ConnectScreenTheme,
  ControlCenterTheme,
  DabbingScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  StatusBarTheme,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from '../lib/types';
import {addOpacityToColorHex} from '../lib/utilityFunctions/addOpacityToColorHex';
// Import from exact file to prevent cycle
import {
  DarkStatusBarTheme,
  LightStatusBarTheme,
  defaultTheme,
} from './DefaultTheme';

const BACKGROUND_COLOR = '#F5ECE1';
const LINEAR_GRADIENT_ARRAY = [
  'rgba(158, 139, 118, 0)',
  'rgba(158, 139, 118, 1)',
];

/* region: Components */
const navMenuTitleStyle: NavMenuTitleStyle = {
  ...defaultTheme.navMenuTitleStyle,
  color: appColors.black,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: le04Peak,
  lightLayers: {
    glass: {
      farLeft: legacyPeakPeachLightsGlassFarLeft,
      farRight: legacyPeakPeachLightsGlassFarRight,
      midLeft: legacyPeakPeachLightsGlassMidLeft,
      midRight: legacyPeakPeachLightsGlassMidRight,
    },
    base: {
      left: legacyPeakPeachBlackLightsBaseLeft,
      right: legacyPeakPeachBlackLightsBaseRight,
    },
    ring: {},
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: le04Peak,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: appColors.black,
  backgroundColor: 'transparent',
};

const statusBarTheme: StatusBarTheme = {
  ...defaultTheme.statusBarTheme,
  themed: Constants.IS_NATIVE_ANDROID
    ? DarkStatusBarTheme
    : LightStatusBarTheme,
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: appColors.black,
  buttonTextColor: appColors.white,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: appColors.black,
  durationFontColor: appColors.white,
  specularColorStart: appColors.clear,
  specularColorEnd: appColors.clear,
};
/* endregion: Components */

/* region: Screens */
const connectScreenTheme: ConnectScreenTheme = {
  peakNameBackgroundColor: appColors.white,
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl: 'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/desert.mp4',
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
  hoverBackgroundColor: appColors.gray,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,

  // General
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },

  // Status info
  batteryBarBackgroundColor: appColors.mediumGray79,
  batteryDisconnectedColor: addOpacityToColorHex(appColors.black, 0.2),
  preserveIndicator: appColors.black,
  statusTextStealthColor: appColors.black,
  statusTitleTextColor: appColors.black,
  stealthIconColor: appColors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: '#FAF3EBFF', location: 0.15},
    {color: '#FAF3EB00', location: 0.75},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(appColors.black, 0.2),
  metricTextColor: appColors.black,
  metricTitleTextColor: appColors.black50,
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: appColors.white,
  dabbingScreenFadedText: appColors.mediumGray50,
  dabbingButtonBackground: appColors.bone,
  dabbingButtonBackgroundPressed: appColors.black,
  dabbingButtonForeground: appColors.black,
  dabbingButtonForegroundPressed: appColors.bone,
  iconColor: appColors.white,
  textColor: appColors.white,
  fadedTextColor: appColors.mediumGray50,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: appColors.white,
  iconColor: appColors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: appColors.white,
  },
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  primaryColor: appColors.white,
  durationColor: appColors.white50,
  startTextStyle: {color: appColors.white, fontWeight: '400'},
  backgroundLineColor: appColors.gray,
};

const controlCenterTheme: ControlCenterTheme = {
  titleText: appColors.white,
  infoText: appColors.white,
  fatSliderColor: appColors.white,
  fatSliderBGColor: appColors.black,
  buttonActiveForeground: appColors.black,
  buttonInactiveForeground: appColors.white,
  buttonActiveBackground: appColors.white,
  buttonInactiveBackground: appColors.black50,
  blurBackgroundColor: appColors.transluscentGray,
  background: {
    color: appColors.transluscentGray,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
  boostScreenLabel: appColors.baseText,
};
/* endregion: Screens */

export const desertTheme: Theme = {
  ...defaultTheme,

  // Components
  primaryColor: appColors.black,
  primaryTextColor: appColors.black,
  logoTint: appColors.invisible,
  navMenuTitleStyle,
  navMenuIconColor: appColors.black,
  peakImageTheme,
  heatProfileCardTheme,
  sectionDividerColor: appColors.black20,
  sectionTitleTextColor: appColors.black,
  statusDisplayTextColor: appColors.black,
  spinnerColor: appColors.black,
  tabBarTheme,
  statusBarTheme,
  styledButtonTheme,

  // Screens
  assignToAHeatProfileScreenTheme: {
    ...defaultTheme.assignToAHeatProfileScreenTheme,
    background: {color: BACKGROUND_COLOR, gradient: LINEAR_GRADIENT_ARRAY},
  },
  connectScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  homeScreenTheme,
  heatProfileListScreenTheme,
  dabbingScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileSelectScreenTheme,
  controlCenterTheme,

  dabTickTheme: {
    color: appColors.white,
  },
};

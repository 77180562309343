import {View} from 'react-native';

import {appColors} from '../../constants';
import styled from '../../lib/styled';

export const FixedFooter = styled(View)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  paddingHorizontal: 24,
  paddingTop: 28,
  paddingBottom: 32,
  backgroundColor: appColors.black,
  justifyContent: 'center',
  alignItems: 'center',
});

import React from 'react';

import {TypeComponent} from '../../../lib/api/content-access/types';
import {Countdown} from './Countdown';
import {LinkButton} from './LinkButton';
import {SocialLink} from './SocialLink';

interface Props {
  component: TypeComponent;
}

enum ComponentTitle {
  LINK_BUTTON = 'LINK_BUTTON',
  SOCIAL_LINK = 'SOCIAL_LINK',
  COUNTDOWN = 'COUNTDOWN',
}

const map = {
  [ComponentTitle.LINK_BUTTON]: LinkButton,
  [ComponentTitle.SOCIAL_LINK]: SocialLink,
  [ComponentTitle.COUNTDOWN]: Countdown,
};

export const Component: React.FC<Props> = ({component}) => {
  const Component = map[component.fields.title as ComponentTitle];

  if (!Component) return null;

  return <Component config={component.fields.config} />;
};

import {Formik, FormikHelpers, FormikProps} from 'formik';
import React from 'react';
import {ActivityIndicator, View} from 'react-native';
import * as Yup from 'yup';

import {backgroundForgotPassword} from '../../assets/images';
import {
  AppText,
  StyledField,
  displayRightIconMessage,
  getRightIconName,
} from '../../components';
import {
  ErrorMessages,
  Messages,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {userApi} from '../../lib/api/apis';
import {useWindowHeight} from '../../lib/hooks';
import styled from '../../lib/styled';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {Alert} from '../../shims/alert';
import {ImageAndContentContainer, TermsAndPrivacy} from '../components';

const {
  FORGOT_PASSWORD_TITLE,
  REQUEST_RESET_PASSWORD_BTN,
  RESET_PASSWORD_PROMPT,
  PLEASE_WAIT_MSG,
} = Messages;

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(ErrorMessages.REQUIRED_EMAIL)
    .email(ErrorMessages.INVALID_EMAIL),
});

export interface Props {
  email?: string;
}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.ForgotPassword> {}

export const ForgotPasswordScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const ref = React.useRef<FormikProps<{email: string}>>(null);

  React.useEffect(() => {
    if (!route.params?.email) return;

    ref.current?.setFieldValue('email', route.params?.email);
  }, [route.params?.email]);

  const {isVeryLarge, isMedium} = useWindowHeight();

  const handleSubmit = async (
    values: {email: string},
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);

    try {
      await userApi.requestPasswordReset({
        userRequestResetPasswordDto: {email: values.email},
      });

      navigation.navigate(Screens.PasswordResetSent, {
        email: ref.current?.values.email,
      });
    } catch (error) {
      Alert.alert(
        'Error',
        (error as Error).message ||
          'Something went wrong. Please try again later.',
      );
    }

    actions.setSubmitting(false);
  };

  return (
    <ImageAndContentContainer
      image={backgroundForgotPassword}
      header={{content: FORGOT_PASSWORD_TITLE}}
      body={{content: RESET_PASSWORD_PROMPT}}
      imageContainerStyle={{maxHeight: isVeryLarge ? undefined : 461}}
      bodyContainerStyle={{flex: isVeryLarge ? undefined : 0.5}}
      primaryButton={{
        title: REQUEST_RESET_PASSWORD_BTN,
        onClick: () => {
          if (!ref.current?.isValid || ref.current.isSubmitting) return;
          ref.current?.submitForm();
        },
      }}
      onBack={() => {
        navigation.pop();
      }}
      bottom={{content: <TermsAndPrivacy />, style: {marginTop: 24}}}>
      <Formik
        innerRef={ref}
        initialValues={{email: ''}}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={EmailSchema}>
        {({
          setFieldValue,
          setFieldTouched,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          errors,
        }) => (
          <FieldContainer>
            <StyledField
              name={'mail'}
              isBordered={true}
              height={isMedium ? 45 : 56}
              onChangeText={!isSubmitting ? handleChange('email') : undefined}
              onBlur={
                !isSubmitting
                  ? e => {
                      setFieldTouched('email');
                      handleBlur('email')(e);
                    }
                  : undefined
              }
              value={values.email}
              keyboardType="email-address"
              textColor={appColors.black50}
              leftIconColor={appColors.black30}
              borderColor={appColors.lightMediumGray}
              iconPress={
                errors.email ? () => setFieldValue('email', '') : undefined
              }
              rightIcon={getRightIconName(values.email, errors.email, true)}
              placeholder={Strings.EMAIL}
              rightIconColor={errors.email ? appColors.red : appColors.green}
              rightIconMessage={displayRightIconMessage(
                values.email,
                errors.email,
                touched.email,
              )}
            />
            {isSubmitting && (
              <SpinnerContainer>
                <ActivityIndicator size={'large'} color="#ffffff" />
                <SpinnerText>{PLEASE_WAIT_MSG}</SpinnerText>
              </SpinnerContainer>
            )}
          </FieldContainer>
        )}
      </Formik>
    </ImageAndContentContainer>
  );
};

const FieldContainer = styled(View)({
  position: 'relative',
  paddingTop: 24,
  width: '100%',
});

const SpinnerText = styled(AppText)({
  alignSelf: 'center',
  color: appColors.gray,
  fontFamily: 'Roboto-Regular',
  textAlign: 'center',
  fontSize: 12,
});

const SpinnerContainer = styled(View)({
  top: '25%',
  alignSelf: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute',
  width: 85,
  padding: 10,
  borderRadius: 10,
  backgroundColor: appColors.mediumDarkGray,
  marginBottom: 10,
});

import {Buffer} from 'buffer';
import {CBOR} from 'cbor-redux';
import {Ulid} from 'id128';
import {omit} from 'lodash';
import {ProfileVersion, TemperatureUnit} from 'puffco-api-axios-client';

import {Constants, ScratchpadVersions, appColors} from '../../../../constants';
import {convertRgbArrayToHex} from '../../../convertRgbArrayToHex';
import {LED3Data, LED3PartyModeData} from '../../../redux/led3data';
import {
  Led3Config,
  Led3MoodLight,
  Led3Value,
  LightingPattern,
  MoodLight,
  MoodType,
  Profile,
  ProfileNewest,
  ProfileScratchpadId,
  Scratchpad,
  isCustomMoodLight,
  isPreTHeatProfile,
  isTHeatProfile,
  isTHeatProfileNoMoodLight,
} from '../../../types';
import {
  convertHexStringToNumArray,
  convertMoodLightToProjector,
  getSecondsFromMilliseconds,
} from '../../../utilityFunctions';
import {Temperature} from '../../../utils/temperature';
import {PATH_CONFIG} from '../../paths';
import {
  LoraxProperties,
  NvmIndex,
  isHeatCycleArrayIndex,
  isRgbtColor,
  isTempHeatProfileIndex,
} from '../pikaparam';
import {HeatProfileIndex} from './IPeakDevice';
import {PeakLoraxDevice} from './PeakLoraxDevice';

const fallbackLed3MoodLight = {
  lamp: {
    name: 'solid',
    param: {
      color: Buffer.from('FFFFFF', 'hex'),
    },
  },
} as Led3MoodLight;

export class PeakLoraxPeachAFDevice extends PeakLoraxDevice {
  public async clearScratchpad(): Promise<void> {
    // do nothing, no scratchpad
    // TODO refactor - remove clearScratchpad
  }

  public async setPartyMode() {
    // Color is left intentionally empty to satisfy params
    await this.writeColor('', LightingPattern.PARTY_MODE, true);
  }

  public async readHeatProfile(
    index: HeatProfileIndex,
  ): Promise<{profile: Profile; moodLight: MoodLight | undefined} | undefined> {
    const base = isTempHeatProfileIndex(index)
      ? ('tempHeatCycle' as const)
      : (`heatCycle${index}` as const);

    const projectorBufferProperty =
      `${base}ColorApi${this.getLedApiVersion()}` as const;
    const projectorBuffer = await this.readCommand(projectorBufferProperty);

    if (isRgbtColor(projectorBuffer)) {
      return super.readHeatProfile(index);
    }

    let name: string | undefined;
    if (!isTempHeatProfileIndex(index)) {
      name = await this.readCommand(`${base}Name`);
    }

    const temperature = await this.readCommand(`${base}Temp`);

    const duration = await this.readCommand(`${base}Time`);

    const intensity = await this.readCommand(`${base}Intensity`);

    /* After installing AG firmware on a device that previously had AH firmware on, the projector buffer is empty
     * causing the app to not read the device settings properly. This fallback value fixes it
     */
    const projector = !projectorBuffer.byteLength
      ? fallbackLed3MoodLight
      : (CBOR.decode(projectorBuffer) as Led3MoodLight);

    let id: string | null = null;
    let moodLight: MoodLight | undefined;
    let moodLightId: string | undefined;
    let modified: number | undefined;
    let isMoodLight: boolean | undefined;
    let color: string | undefined;

    const version = ProfileVersion.T;

    if (this.isPartyModeProjector(projector)) {
      color = appColors.defaultColor;
      isMoodLight = false;
    }

    if (this.isSingleColorSolidProjector(projector)) {
      color = projector.lamp.param.color
        ? convertRgbArrayToHex(
            projector.lamp.param.color as unknown as number[],
          )
        : '#ffffff';
      isMoodLight = false;
    } else if (this.isSingleColorPikaProjector(projector)) {
      color = projector.meta?.userColors?.[0]
        ? convertRgbArrayToHex(projector.meta.userColors[0])
        : '#ffffff';
      isMoodLight = false;
    }

    if (projector.meta && Object.keys(projector.meta).length > 0) {
      const meta = this.unbinary(projector.meta);
      // read mood light data
      if (meta.moodUlid && meta.version !== ScratchpadVersions.ProfileNewest) {
        isMoodLight = true;
        moodLight = this.getMoodLightFromPeakMeta(meta);
        moodLightId = meta.moodUlid;
      }

      // read heat profile id and modified date
      if (
        meta.version === ScratchpadVersions.ProfileNewest ||
        meta.version === ScratchpadVersions.ProfileExclusiveNewest ||
        meta.version === ScratchpadVersions.ProfileCustomNewest
      ) {
        id = meta.heatProfileUlid ?? null;
        const metaDate: number | undefined = meta.heatProfileDateModified;
        modified = (
          metaDate === undefined
            ? new Date()
            : new Date(
                metaDate * Constants.UNIT_CONVERSION.SECONDS_TO_MILLISECONDS,
              )
        ).getTime();
      }
    } else {
      // no meta data, factory reset?
      // TODO can we actually read the factory reset heat profiles colors?
      color = appColors.heatProfileColor[index];
      isMoodLight = false;
    }

    if (
      (name === undefined || typeof name === 'string') &&
      typeof temperature === 'number' &&
      typeof duration === 'number'
    ) {
      return {
        profile: {
          version,
          id,
          order: index,
          ...(name !== undefined && {name}),
          temperature,
          duration: Math.floor(duration),
          units: TemperatureUnit.Celsius,
          ...(modified && {modified}),
          ...(isMoodLight && {isMoodLight}),
          ...(color && {color}),
          ...(moodLightId !== undefined && {moodLightId}),
          ...(intensity !== undefined && {vaporSetting: intensity}),
        } as Profile,
        moodLight,
      };
    }
  }

  public async writeHeatProfile(
    profile: Profile,
    moodLight?: MoodLight | undefined,
  ): Promise<void> {
    // TODO LED API 3 refactor
    const temperature = Temperature.convert(profile.temperature, {
      from: profile.units,
      to: TemperatureUnit.Celsius,
    });

    const order =
      isHeatCycleArrayIndex(profile.order) ||
      isTempHeatProfileIndex(profile.order)
        ? profile.order
        : undefined;

    if (order === undefined) {
      throw new Error(
        `Can't write profile at non-existent index ${profile.order}`,
      );
    }

    await this.loraxWriteHeatProfileBase(order, {
      name: profile.name,
      temperature,
      duration: profile.duration,
      vaporSetting: isTHeatProfile(profile) ? profile.vaporSetting : 0,
    });

    if (moodLight) {
      await this.writeMoodLight(
        PATH_CONFIG.heatCycleColor.path,
        this.addNvmIndices(Constants.NVM_ARRAY_INDICES.HEAT_PROFILE_0, order),
        moodLight,
        profile,
      );
    } else if (
      isPreTHeatProfile(profile) ||
      isTHeatProfileNoMoodLight(profile)
    ) {
      const scratchpad: ProfileNewest = {
        version: ProfileScratchpadId.PROFILE_NEWEST,
        heatProfileUlid: profile.id,
        heatProfileDateModified: getSecondsFromMilliseconds(
          profile.version === ProfileVersion.T
            ? profile.modified
            : new Date().getTime(),
        ),
      };

      // Assuming LumaAnimation isn't defined
      await this.writeColor(
        profile.color,
        LightingPattern.STEADY,
        false,
        order,
        scratchpad,
      );
    }
  }

  // used when user is using non-moodlight lantern mode or heat profiles
  public async writeColor(
    color: string,
    lightingPattern: LightingPattern,
    isLantern: boolean,
    index?: -1 | 0 | 1 | 2 | 3,
    scratchpad?: Scratchpad,
  ): Promise<void> {
    // LightingPatterns that are used from lantern mode
    // STEADY = LumaAnimation.STEADY
    // BREATHING = LumaAnimation.BREATHING
    // CIRCLING = LumaAnimation.CIRCLING_SLOW
    // PARTY_MODE = LumaAnimation.STEADY

    const configMapping = {
      [LightingPattern.STEADY]: LED3Data[MoodType.NO_ANIMATION],
      [LightingPattern.BREATHING]: LED3Data[MoodType.BREATHING],
      [LightingPattern.CIRCLING]: LED3Data[MoodType.CIRCLING_SLOW],
      [LightingPattern.PARTY_MODE]: LED3PartyModeData,
    };

    const moodLightValues = {
      [LightingPattern.STEADY]: [],
      [LightingPattern.BREATHING]: [
        {key: 'tempoFrac', value: 0.5},
        {key: 'dynamicInhale', value: 0},
      ],
      [LightingPattern.CIRCLING]: [
        {key: 'tempoFrac', value: 0.5},
        {key: 'dynamicInhale', value: 0},
      ],
      [LightingPattern.PARTY_MODE]: [],
    };

    const led3Config =
      configMapping[lightingPattern as keyof typeof configMapping] ??
      configMapping[LightingPattern.STEADY];

    const buffer = this.makeCBORBuffer(
      moodLightValues[lightingPattern as keyof typeof moodLightValues],
      led3Config,
      color,
      scratchpad,
    );

    if (isLantern) {
      const property = `lanternColorApi${this.getLedApiVersion()}` as const;

      await this.writeCommand(property, buffer);
    } else {
      if (index === undefined) return;

      if (index === -1) {
        const property =
          `tempHeatCycleColorApi${this.getLedApiVersion()}` as const;

        await this.writeCommand(property, buffer);
        return;
      }

      const property =
        `heatCycle${index}ColorApi${this.getLedApiVersion()}` as const;

      await this.writeCommand(property, buffer);
    }
  }

  // TODO refactor, this interface should not depend on characteristicUuid/nvmIndex
  // These are Flat specific and leaking in to other implementations
  public async writeMoodLight(
    _characteristicUuid: string,
    nvmIndex: NvmIndex<LoraxProperties>,
    moodLight: MoodLight,
    profile?: Profile | undefined,
  ): Promise<void> {
    let led3Config: Led3Config;
    let moodLightValues: Led3Value[] = [];

    let path: string;
    if (nvmIndex === Constants.NVM_ARRAY_INDICES.DEFAULT_LANTERN_ARRAY_INDEX) {
      path = `lanternColorApi${this.getLedApiVersion()}`;
    } else if (nvmIndex === Constants.NVM_ARRAY_INDICES.HEAT_PROFILE_TEMP) {
      path = `tempHeatCycleColorApi${this.getLedApiVersion()}`;
    } else {
      const index = nvmIndex - Constants.NVM_ARRAY_INDICES.HEAT_PROFILE_0;

      if (!isHeatCycleArrayIndex(index)) {
        throw new Error(`${index} is not a valid heat cycle index`);
      }

      path = `heatCycle${index}ColorApi${this.getLedApiVersion()}` as const;
    }

    const scratchpad = this.makeScratchpad(moodLight, profile);

    if (isCustomMoodLight(moodLight)) {
      moodLight = {
        ...moodLight,
        tempo: Number(moodLight.tempo),
        type: Number(moodLight.type),
      };
      led3Config =
        LED3Data[moodLight.type as keyof typeof LED3Data] ||
        LED3Data[MoodType.NO_ANIMATION];

      moodLightValues = Object.entries(moodLight.led3Meta ?? {}).map(
        ([key, value]) => ({
          key,
          value,
        }),
      ) as Led3Value[];
      led3Config.ui.forEach(ui => {
        const configValue = moodLightValues.find(v => v.key === ui.key);
        if (!configValue || configValue.value === undefined) {
          moodLightValues.push({
            key: ui.key,
            value: ui.default,
          });
        }
      });

      const buffer = this.makeCBORBuffer(
        moodLightValues,
        led3Config,
        moodLight.colors.join(','),
        scratchpad,
      );

      await this.pikaparam.param.getInApiThrow<Buffer>(path).set(buffer);
    } else {
      // Candle Flicker and Hologram do not currently work, but they don't trigger the error pattern
      // Checking with Doug

      // Generate the projector directly from the rawMoodLight
      // We don't need to do mappings/etc
      const moodLight3: Led3MoodLight = {
        lamp: {
          name: 'pikaled2',
          param: {
            bright: moodLight.rawMoodLight.tableColor.brightness,
            speed: moodLight.rawMoodLight.tableColor.speed,
            anim: moodLight.rawMoodLight.tableColor.lumaAnimation,
            plNum: moodLight.rawMoodLight.tableColor.phaseLockNumerator,
            plDenom: moodLight.rawMoodLight.tableColor.phaseLockDenominator,
            offset: moodLight.rawMoodLight.offsetArray,
            color: Buffer.from(
              new Uint8Array(
                this.convertColorArray4to3(moodLight.rawMoodLight.colorArray),
              ),
            ),
            colorLen: moodLight.rawMoodLight.tableColor.colorArrayLength,
          },
        },
        meta: {
          led3name: moodLight.name,
          led3tag: moodLight.id,
          ...scratchpad,
        },
      };

      // fix moodLight3.meta so that ULIDs are binary strings
      for (const key of ['moodUlid', 'originalMoodUlid', 'heatProfileUlid']) {
        if (moodLight3.meta[key]) {
          moodLight3.meta[key] = Ulid.fromCanonical(moodLight3.meta[key]).bytes;
        }
      }

      const buffer = Buffer.from(CBOR.encode(moodLight3));

      await this.pikaparam.param.getInApiThrow<Buffer>(path).set(buffer);
    }
  }

  private makeCBORBuffer(
    moodLightValues: Led3Value[],
    led3Config: Led3Config,
    color: string,
    scratchpad?: Scratchpad,
  ): Buffer {
    const {params, projector} = convertMoodLightToProjector(
      [
        ...moodLightValues,
        {key: 'userColors', value: color === '' ? undefined : color}, // Party Mode doesn't have a color
        {key: 'variant', value: 1}, // all Peaches are variant 1/Lightning
      ],
      led3Config,
    );

    const moodLight3: Led3MoodLight = {
      lamp: {
        name: led3Config.projector.name,
        param: projector,
      },
      meta: {
        // meta written out to Peak has scratchpad + param data
        led3Name: led3Config.name,
        led3tag: led3Config.tag,
        ...(omit(scratchpad, ['colors', 'tempo']) ?? {}),
        ...params,
        userColors: params.userColors.map(
          (c: string) => new Uint8Array(convertHexStringToNumArray(c)),
        ),
      },
    };

    // remove moodLight3.meta.colors specifically for Party Mode
    if (this.isPartyModeProjector(moodLight3)) {
      delete moodLight3.meta.userColors;
    }

    // fix moodLight3.meta so that ULIDs are binary strings
    for (const key of ['moodUlid', 'originalMoodUlid', 'heatProfileUlid']) {
      if (moodLight3.meta[key]) {
        moodLight3.meta[key] = Ulid.fromCanonical(moodLight3.meta[key]).bytes;
      }
    }

    const buffer = Buffer.from(CBOR.encode(moodLight3));

    return buffer;
  }

  private convertColorArray4to3(colorArray: number[]): number[] {
    // prior to LED API 3, the color array was a stream of 4 byte colors
    // now in LED API 3, the color array is a stream of 3 byte colors

    const returnArray: number[] = [];
    for (let i = 0; i < colorArray.length; i += 4) {
      returnArray.push(colorArray[i]);
      returnArray.push(colorArray[i + 1]);
      returnArray.push(colorArray[i + 2]);
    }
    return returnArray;
  }
}

import debounce from 'lodash/debounce';
import React from 'react';

export const useDebouncedState = <T>(initialValue: T, delay: number = 500) => {
  const [state, setState] = React.useState<T>(initialValue);
  const [debouncedState, setDebouncedInstantly] = React.useState<T>(state);

  const setDebouncedState = React.useMemo(
    () => debounce(setDebouncedInstantly, delay),
    [],
  );

  const setValues = React.useCallback(
    (value: T) => {
      setDebouncedInstantly(value);
      setState(value);
    },
    [setState, setDebouncedInstantly],
  );

  return {
    state,
    setState,
    debouncedState,
    setDebouncedState,
    setDebouncedInstantly,
    setValues,
  } as const;
};

import {Environment} from '../../../../constants';
import {FirebaseAnalytics} from '../firebase/firebase-analytics';
import {LogAnalytics} from '../log-analytics';
import {DataLayerAnalyticsService} from './data-layer-analytics';

// Disable analytics in dev env
const initialize = Environment.environment !== 'dev';

export const analytics = new DataLayerAnalyticsService([
  ...(initialize ? [new FirebaseAnalytics(), new LogAnalytics()] : []),
]);

import {Device} from '../types';
import {isProductLimitedEdition} from '../useTheme';
import {getProductName} from './getProductName';

export const isLimitedEdition = (
  d: Pick<Device, 'modelNumberString' | 'serialNumberString' | 'product'>,
) =>
  isProductLimitedEdition(
    getProductName({
      modelNumberString: d.modelNumberString,
      serialNumberString: d.serialNumberString,
      product: d.product,
    }),
  );

import {EntryFields} from 'contentful';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {HeadingText} from '../../../components';
import {appColors} from '../../../constants';
import {RichTextRenderer} from '../../components/contentful/RichTextRenderer';

interface Props {
  title: string;
  description: EntryFields.RichText;
}

export const Description = ({title, description}: Props) => {
  return (
    <View style={styles.container}>
      <HeadingText style={styles.title}>{title}</HeadingText>

      <RichTextRenderer content={description} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    color: appColors.white,
    marginBottom: 48,
  },
  title: {
    marginBottom: 24,
  },
});

import {
  Logger,
  Pikaparam,
  ConnectedDevice as PikaparamDevice,
  Uuids,
} from 'pikaparam';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  throwError,
  timeout,
} from 'rxjs';

import {bleLog, setDevice as setLoggerDevice} from '../../../Logger';
import {sleep} from '../../../sleep';
import {linearInterpolate} from '../../../utilityFunctions';
import {ConnectionMetadata} from '../ConnectionMetadata';
import {OtaDevice} from '../OtaDevice';
import {IPeakDevice} from '../PeakDevice/IPeakDevice';
import {PeakDeviceFactory} from '../PeakDeviceFactory';
import {ConnectionError, Progress} from '../types';

export type FirmwareUpdatePhase =
  | 'not_started'
  | 'checking_device'
  | 'updating'
  | 'done'
  | 'rebooting_to_apploader'
  | 'reconnect';

export type ConnectionPhase =
  | 'scanning'
  | 'retrieve_services'
  | 'bonding'
  | 'requesting_mtu'
  | 'setting_up'
  | 'initializing'
  | 'connecting'
  | 'reading'
  | 'done';

export interface UpdateFirmwareOptions {
  peripheral?: ConnectablePeripheral;
  firmware: Buffer;
  onProgress(progress: Progress<FirmwareUpdatePhase>): void;
}

export interface OtaConnectOptions {
  peripheral?: ConnectablePeripheral;
}

export type OnDisconnect = (unit: IPeakDevice | OtaDevice) => Promise<void>;
export type OnConnectProgress = (
  progress: Partial<Progress<ConnectionPhase>>,
) => void;

export interface ConnectOptions {
  peripheral?: ConnectablePeripheral;
  onDisconnect: OnDisconnect;
  onProgress?: OnConnectProgress;
}

export type ConnectablePeripheral =
  | {id: string; name?: string; serialNumber?: string}
  | {id?: string; name: string; serialNumber?: string};

export interface ConnectResult {
  ota: OtaDevice;
  peak?: IPeakDevice;
}

export interface IBleManager {
  peak?: IPeakDevice;
  otaDevice?: OtaDevice;

  connect(o: ConnectOptions): Promise<ConnectResult>;

  updateFirmware(o: UpdateFirmwareOptions): Promise<void>;

  scanForAdvertisements(
    callback: (
      device: any,
      advertisementData: Record<string | number, any> | any,
    ) => void,
  ): Promise<void>;

  stopScanForAdvertisements(): Promise<void>;
}

export abstract class BleManagerBase<P, D extends PikaparamDevice>
  implements IBleManager
{
  protected appServiceIdentifiers = [Uuids.loraxService, Uuids.pikachuService];

  protected otaServiceIdentifiers = [
    Uuids.pupService,
    // Unfortunately, silabs will never broadcast the ota service
    Uuids.silabsOtaService,
  ];

  protected serviceIdentifiers = [
    ...this.appServiceIdentifiers,
    ...this.otaServiceIdentifiers,
  ];

  protected uniqueIdentifiers = [...Uuids.silabsOuis, ...Uuids.atmosicOuis];

  private _peak: IPeakDevice | undefined;
  private _ota: OtaDevice | undefined;

  protected logger: Logger = {
    debug: (...args: any[]) => bleLog.info(...args),
    info: (...args: any[]) => bleLog.info(...args),
    log: (...args: any[]) => bleLog.info(...args),
    warn: (...args: any[]) => bleLog.warn(...args),
    error: (...args: any[]) => bleLog.error(...args),
  };

  public abstract connect(o: ConnectOptions): Promise<ConnectResult>;

  public abstract scanForAdvertisements(
    callback: (
      device: any,
      advertisementData: Record<string | number, any> | any,
    ) => void,
  ): Promise<void>;

  public abstract stopScanForAdvertisements(): Promise<void>;

  protected abstract createConnectedDevice(peripheral: P): Promise<D>;
  protected abstract getConnectionMetadata(peripheral: P): ConnectionMetadata;

  protected abstract bond(device: D): Promise<void>;
  protected abstract requestMtu(device: D): Promise<void>;

  protected abstract otaConnect(
    o: OtaConnectOptions,
  ): Promise<OtaDevice | undefined>;

  protected abstract stopScan(): Promise<void>;

  public get peak() {
    return this._peak;
  }

  private set peak(peak: IPeakDevice | undefined) {
    this._peak = peak;
  }

  public get otaDevice() {
    return this._ota;
  }

  private set otaDevice(ota: OtaDevice | undefined) {
    this._ota = ota;
  }

  public async updateFirmware({
    peripheral,
    firmware,
    onProgress,
  }: UpdateFirmwareOptions) {
    bleLog.info('Update firmware.', {peripheral});

    onProgress({
      value: 0.2,
      data: 'rebooting_to_apploader',
      duration: 5000,
    });

    const otaDevice = await this.ensureOtaInBootloader({peripheral});

    onProgress({
      value: 0.2,
      data: 'rebooting_to_apploader',
      duration: 2000,
    });

    await firstValueFrom(
      otaDevice.flashStateObs.pipe(
        filter(v => v === 'idle'),
        timeout({
          each: 5000,
          with: () =>
            throwError(
              () =>
                new Error(
                  "Ota device flash state didn't become idle in 5 seconds.",
                ),
            ),
        }),
      ),
    );

    const progressSubscription = otaDevice.subscribeToFlashProgress(
      progress => {
        const value = linearInterpolate(
          progress,
          {min: 0, max: 100},
          {min: 0.2, max: 0.8},
        );

        onProgress({value, data: 'updating', duration: 500});
      },
    );

    bleLog.info('Flashing device...');

    await otaDevice
      .flash(firmware.buffer)
      .finally(() => progressSubscription.unsubscribe());

    onProgress({value: 0.9, data: 'updating', duration: 1000});

    await firstValueFrom(
      otaDevice.flashStateObs.pipe(
        filter(v => ['finish', 'idle'].includes(v)),
        timeout({
          each: 5000,
          with: () =>
            throwError(
              () =>
                new Error(
                  "Ota device flash state didn't become finish or idle in 5 seconds.",
                ),
            ),
        }),
      ),
    );

    await otaDevice.disconnect();

    onProgress({value: 1, data: 'updating', duration: 10000});

    // We need this sleep to wait for the peak to reboot so we can reconnect to it
    await sleep(10000);
  }

  protected async initiateConnection(
    {peripheral, ...o}: ConnectOptions,
    callback: () => Promise<P>,
  ): Promise<ConnectResult> {
    await (this.peak ?? this.otaDevice)?.disconnect().catch(() => void 0);

    if (peripheral) setLoggerDevice({device: peripheral, metadata: {}});

    this.logger.info('Searching for device.', {peripheral});

    o.onProgress?.({
      value: 0.1,
      data: 'scanning',
      duration: 3000,
    });

    const now = performance.now();

    const device = await callback();

    this.logger.info('Search completed.', {
      duration: performance.now() - now,
    });

    return this.connectToDevice(device, o);
  }

  protected async connectToDevice(
    _device: P,
    {
      onProgress,
      onDisconnect,
    }: Pick<ConnectOptions, 'onDisconnect' | 'onProgress'>,
  ): Promise<ConnectResult> {
    bleLog.info('Connect to device.');

    let device: D | undefined;

    try {
      const now = performance.now();

      onProgress?.({value: 0.2, data: 'retrieve_services', duration: 1000});

      const metadata = this.getConnectionMetadata(_device);
      device = await this.createConnectedDevice(_device);

      setLoggerDevice({device, metadata});

      const pikaparam = new Pikaparam(this.logger);

      const serviceUuid = Uuids.silabsOtaService;
      const characteristicUuid = Uuids.silabsOtaLoaderVersionChar;

      const bootloader = device.hasChar(serviceUuid, characteristicUuid);

      if (!bootloader) {
        onProgress?.({value: 0.4, data: 'bonding', duration: 1000});
        await this.bond(device);
      } else {
        bleLog.info('Device is in bootloader state. Bonding is skipped.', {
          peripheralId: device.id,
        });
      }

      onProgress?.({value: 0.5, data: 'requesting_mtu', duration: 1000});

      await this.requestMtu(device);

      onProgress?.({value: 0.8, data: 'connecting', duration: 4500});

      await pikaparam.connect(device);

      const ota = new OtaDevice({device, pikaparam, metadata});
      let peak: IPeakDevice | undefined = undefined;

      this.otaDevice = ota;
      setLoggerDevice(ota);

      bleLog.info('Ota device connected.', {
        peripheralId: this.otaDevice.peripheralId,
        deviceState: this.otaDevice.deviceState,
        flashState: this.otaDevice.flashState,
        duration: Date.now() - now,
      });

      pikaparam.conn.deviceStateObs
        .pipe(
          filter(v => v === 'disconnected'),
          distinctUntilChanged(),
        )
        .subscribe(async () => {
          await onDisconnect?.(peak ?? ota).catch(() => void 0);

          this.otaDevice = undefined;
          this.peak = undefined;
        });

      if (this.otaDevice.isInBootloaderMode()) {
        bleLog.info('Ota device is in bootloader state. Peak is not created.', {
          peripheralId: this.otaDevice.peripheralId,
        });

        return {ota};
      }

      onProgress?.({value: 0.85, data: 'setting_up', duration: 1000});

      peak = await PeakDeviceFactory.create({
        device,
        pikaparam,
        metadata,
      });

      this.peak = peak;
      setLoggerDevice(peak);

      onProgress?.({value: 0.9, data: 'initializing', duration: 4000});

      try {
        await peak.initialize();
      } catch (error) {
        bleLog.error(`Couldn't connect to peak.`, {error});
        throw new Error(ConnectionError.CONNECTION_ERROR);
      }

      bleLog.info('Peak connected.', {
        id: peak.peripheralId,
        name: peak.name,
        duration: performance.now() - now,
      });

      onProgress?.({value: 1, duration: 4000, data: 'reading'});

      return {ota, peak};
    } catch (error) {
      await device?.startDisconnect().catch(() => void 0);
      throw error;
    }
  }

  private async getOrConnectOtaDevice({peripheral}: OtaConnectOptions) {
    if (!this.otaDevice) {
      bleLog.info('Ota device is not connected. Connecting.', {peripheral});

      return await this.otaConnect({peripheral});
    }

    if (this.otaDevice.deviceState === 'offline') {
      bleLog.info('Ota device is currently offline. Connecting.', {
        peripheral,
      });

      await this.otaDevice.disconnect().catch(() => void 0);

      return await this.otaConnect({peripheral});
    }

    if (!peripheral?.name?.length) {
      bleLog.info(
        'Ota device name has not been provided. Using currently connected device',
        {peripheral},
      );

      return this.otaDevice;
    }

    const isInBootloader = this.otaDevice.isInBootloaderMode();
    const otaName = isInBootloader ? this.otaDevice.name : this.peak?.euid;

    if (otaName && otaName !== peripheral.name) {
      bleLog.info('Ota device has a different name. Connecting.', {
        peripheral,
        otaName,
        isInBootloader,
      });

      await this.otaDevice.disconnect().catch(() => void 0);

      return await this.otaConnect({peripheral});
    }

    bleLog.info('Ota device is already connected. Skipping connection.', {
      peripheral,
      isInBootloader,
    });

    return this.otaDevice;
  }

  private async ensureOtaInBootloader(
    {peripheral}: OtaConnectOptions,
    attempt = 1,
  ): Promise<OtaDevice> {
    const otaDevice = await this.getOrConnectOtaDevice({peripheral});

    if (!otaDevice)
      return this.ensureOtaInBootloader({peripheral}, attempt + 1);

    if (otaDevice.isInBootloaderMode()) return otaDevice;

    if (attempt >= 3)
      throw new Error(
        `Device couldn't enter bootloader state after ${attempt} attempts.`,
      );

    const serialNumber = otaDevice.serialNumber;
    const name = await otaDevice.rebootToBootloader();

    return this.ensureOtaInBootloader(
      {peripheral: {name, serialNumber}},
      attempt + 1,
    );
  }
}

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {pushService} from '../../src/services/push';
import {appLog} from '../Logger';
import {deviceTokenApi, userApi} from '../api/apis';
import {appSettingsSelector} from '../redux/appSettingsSlice';
import {updateUser, userSelector} from '../redux/userSlice';
import {useAppState} from './useAppState';

export const usePushNotification = () => {
  const state = useAppState();
  const {agreeAgreement} = useSelector(appSettingsSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const loggedIn = !!user;
  const enabledInStore = user?.pushNotifications;

  const agreedLegal = agreeAgreement.PP && agreeAgreement.TC;
  const turnedOff = user?.pushNotifications === false;
  const shouldAsk = agreedLegal && !turnedOff;

  React.useEffect(() => {
    if (!shouldAsk) return;

    pushService
      .getToken()
      .then(token => {
        if (!token) return;
        appLog.info('Sync device token.');
        return deviceTokenApi.updateDeviceToken({token});
      })
      .catch();

    return pushService.onTokenUpdated(token => {
      appLog.info('Sync device token.');
      return deviceTokenApi.updateDeviceToken({token});
    });
  }, [shouldAsk]);

  React.useEffect(() => {
    // Push notification toggle is updataed on OS level.
    // We want to check whether it changed, every time the app comes in foreground.
    if (state !== 'active') return;
    if (!loggedIn) return;

    pushService.enabled().then(pushNotifications => {
      if (enabledInStore === pushNotifications) return;

      dispatch(updateUser({pushNotifications}));

      return userApi.updateUserById({
        id: 'me',
        userUpdateDto: {pushNotifications},
      });
    });
  }, [state, enabledInStore, loggedIn]);
};

import {Constants} from '../../constants';

/*
 * Adds delay so that nav buttons do not activate parent nav button underneath
 */

export const delayNavBtn = (onPress: () => void) => {
  if (Constants.IS_WEB) {
    setTimeout(() => {
      onPress();
    }, 3);
  } else {
    onPress();
  }
};

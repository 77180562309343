import React from 'react';
import {StyleProp, StyleSheet, Text, View, ViewStyle} from 'react-native';

import {Constants, Strings, appColors} from '../constants';
import {TypeGuideStep} from '../lib/api/content-access/types';
import {Description} from './Description';
import {StyledButton} from './StyledButton';

const cardHeight =
  Constants.DIMENSIONS.HEIGHT > Constants.SCREEN_HEIGHT.PX_785 ? 223 : 212;

const offset = 24;

export enum ArrowPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ArrowOrientation {
  UP = 'up',
  DOWN = 'down',
}

interface Props {
  step: TypeGuideStep;
  index: number;
  onPress: () => void;
  total?: number;
  arrowPosition?: ArrowPosition;
  arrowOrientation?: ArrowOrientation;
  style?: StyleProp<ViewStyle>;
}

const map: {
  position: Record<ArrowPosition, ViewStyle>;
  orientation: Record<ArrowOrientation, ViewStyle>;
} = {
  position: {
    [ArrowPosition.LEFT]: {left: '10%', transform: []},
    [ArrowPosition.RIGHT]: {right: '10%', transform: []},
    [ArrowPosition.CENTER]: {
      right: '50%',
      transform: [
        // Because the element is rotated 45 degrees, the arrow is translated diagonally and need to be adjusted on both axes
        // Also right:'50%' seems to position the element slightly differently on Android and Web
        {translateX: Constants.IS_NATIVE_ANDROID ? -10 : 10},
        {translateY: Constants.IS_NATIVE_ANDROID ? 10 : -10},
      ],
    },
  },
  orientation: {
    [ArrowOrientation.UP]: {top: -10},
    [ArrowOrientation.DOWN]: {bottom: -10},
  },
};

export const GuideCard: React.FC<Props> = ({
  step,
  index,
  onPress,
  total = 3,
  arrowPosition = ArrowPosition.CENTER,
  arrowOrientation = ArrowOrientation.UP,
  style,
}) => {
  const positionStyle = map.position[arrowPosition];
  const orientationStyle = map.orientation[arrowOrientation];

  return (
    <View
      style={[
        styles.container,
        // Position below the target if the arrow points up and vice versa
        arrowOrientation === ArrowOrientation.UP
          ? {bottom: -cardHeight - offset}
          : {top: -cardHeight - offset},
        style,
      ]}>
      <View style={styles.card}>
        <View style={styles.content}>
          <Text style={styles.count}>
            {index + 1}/{total}
          </Text>

          <Text style={styles.title}>{step.fields.title}</Text>

          <Description style={styles.description}>
            {step.fields.description}
          </Description>
        </View>

        <StyledButton
          style={styles.button}
          textStyle={{color: appColors.white}}
          title={index === total - 1 ? Strings.DEFAULT_BTN : Strings.NEXT}
          onPress={onPress}
        />

        <View
          style={[
            styles.arrow,
            positionStyle,
            orientationStyle,
            {
              transform: [
                {rotate: '45deg'},
                ...(positionStyle.transform as []),
              ],
            },
          ]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 10,
  },
  card: {
    backgroundColor: appColors.white,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 24,
    height: cardHeight,
  },
  content: {
    gap: 12,
    flex: 1,
  },
  count: {
    fontSize: 16,
    color: appColors.textOnSurfaceLight,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: appColors.black,
    textTransform: 'uppercase',
  },
  description: {
    color: appColors.textOnSurfaceLight,
  },
  button: {
    marginTop: 28,
    marginBottom: 8,
    width: '100%',
    backgroundColor: appColors.black,
  },
  arrow: {
    position: 'absolute',
    width: 20,
    height: 20,
    backgroundColor: appColors.white,
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  Animated,
  FlatList,
  LayoutAnimation,
  LogBox,
  PanResponder,
  StyleSheet,
  UIManager,
  View,
} from 'react-native';

import {appLog} from '../lib/Logger';

LogBox.ignoreLogs(['Warning: isMounted(...) is deprecated']);
UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

const initialState = {
  activeRow: -1,
  showHoverComponent: false,
  spacerIndex: -1,
  scroll: false,
  hoverComponent: null,
  extraData: null,
};

interface Props {
  data: any;
  keyExtractor: any;
  onMoveEnd: any;
  scrollPercent: any;
  scrollSpeed: any;
  extraData: any;
  onScroll: any;
  onScrollEnd: any;
  moveEnd: any;
  onMoveBegin: any;
  isActiveRow: any;
  renderItem: any;
  item: any;
  index: any;
  setRef: any;
  hoverBackgroundColor: string;
}

interface State {
  activeRow: any;
  spacerIndex: any;
  showHoverComponent: any;
  hoverComponent: any;
  extraData: any;
}

const layoutAnimConfig = {
  duration: 300,
  create: {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.scaleXY,
  },
  update: {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.scaleXY,
  },
};

export class DraggableFlatList extends React.Component<Props, State> {
  _moveAnim = new Animated.Value(0);
  _offset = new Animated.Value(0);
  _hoverAnim = Animated.add(this._moveAnim, this._offset);
  _spacerIndex = -1;
  _pixels = [];
  _measurements = [];
  _scrollOffset = 0;
  _containerSize = 0;
  _containerOffset = 0;
  _containerView: View | undefined = undefined;
  _move = 0;
  _hasMoved = false;
  _refs = [];
  _additionalOffset = 0;
  _androidStatusBarOffset = 0;
  _releaseVal = null;
  _releaseAnim = null;
  _scrollingTimer: any;
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line
    // @ts-ignore
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponderCapture: evt => {
        const {pageY} = evt.nativeEvent;
        const tappedPixel = pageY;
        const tappedRow =
          this._pixels[Math.floor(this._scrollOffset + tappedPixel)];
        if (tappedRow === undefined) return false;
        this._additionalOffset =
          tappedPixel + this._scrollOffset - this._measurements[tappedRow]['y'];
        if (this._releaseAnim) {
          return false;
        }
        if (this._containerSize) {
          this._moveAnim.setValue(tappedPixel);
        } else {
          this.measureContainer();
          this._moveAnim.setValue(this._additionalOffset);
        }
        this._move = tappedPixel;
        this._offset.setValue(
          (this._additionalOffset +
            this._containerOffset -
            this._androidStatusBarOffset) *
            -1,
        );
        return false;
      },
      onMoveShouldSetPanResponder: (_evt, gestureState) => {
        this._offset.setValue(
          (this._additionalOffset + this._containerOffset) * -1,
        );
        const {activeRow} = this.state;
        const {moveY} = gestureState;
        const move = moveY;
        const shouldSet = activeRow > -1;
        this._moveAnim.setValue(move);
        if (shouldSet) {
          this.setState({showHoverComponent: true});
          this.animate();
          this._hasMoved = true;
        }
        return shouldSet;
      },
      onPanResponderMove: Animated.event([null, {['moveY']: this._moveAnim}], {
        // eslint-disable-next-line
        // @ts-ignore
        listener: (_evt, gestureState) => {
          const {moveY} = gestureState;
          this._move = moveY;
        },
      }),
      onPanResponderTerminationRequest: () => false,
      onPanResponderRelease: () => {
        const {activeRow, spacerIndex} = this.state;
        const {data} = this.props;
        const activeMeasurements: any = this._measurements[activeRow];
        const spacerMeasurements = this._measurements[spacerIndex];
        const lastElementMeasurements = this._measurements[data.length - 1];
        if (activeRow === -1) return;
        const isAfterActive = false;
        const isLastElement = spacerIndex >= data.length;
        const spacerElement = isLastElement
          ? lastElementMeasurements
          : spacerMeasurements;
        if (!spacerElement) return;
        const {y, height} = spacerElement;
        const size = height;
        const offset = y;
        const pos =
          offset -
          this._scrollOffset +
          this._additionalOffset +
          (isLastElement ? size : 0);

        const activeItemSize = activeMeasurements.height;
        // eslint-disable-next-line
        // @ts-ignore
        this._releaseVal = pos - (isAfterActive ? activeItemSize : 0);
        // eslint-disable-next-line
        // @ts-ignore
        if (this._releaseAnim) this._releaseAnim.stop();
        // eslint-disable-next-line
        // @ts-ignore
        this._releaseAnim = Animated.spring(this._moveAnim, {
          // eslint-disable-next-line
          // @ts-ignore
          toValue: this._releaseVal,
          stiffness: 5000,
          damping: 500,
          mass: 3,
          useNativeDriver: true,
        });
        // eslint-disable-next-line
        // @ts-ignore
        this._releaseAnim.start(this.onReleaseAnimationEnd);
      },
    });
    this.state = initialState;
  }

  onReleaseAnimationEnd = () => {
    const {data, onMoveEnd} = this.props;
    const {activeRow, spacerIndex} = this.state;
    const sortedData = this.getSortedList(data, activeRow, spacerIndex);
    const from = activeRow > 3 ? activeRow - 1 : activeRow;
    const to = spacerIndex > 3 ? spacerIndex - 1 : spacerIndex;
    // eslint-disable-next-line
    // @ts-ignore
    this._moveAnim.setValue(this._releaseVal);
    this._spacerIndex = -1;
    this._hasMoved = false;
    this._move = 0;
    this._releaseAnim = null;
    this.setState(initialState, () => {
      onMoveEnd &&
        onMoveEnd({
          row: data[activeRow],
          from,
          to,
          data: sortedData,
        });
    });
  };
  getSortedList = (data: any, activeRow: number, spacerIndex: number) => {
    if (activeRow === spacerIndex) return data;
    // eslint-disable-next-line
    // @ts-ignore
    const sortedData = data.reduce((acc, cur, i, arr) => {
      if (i === activeRow) return acc;
      else if (i === spacerIndex) {
        acc = [...acc, arr[activeRow], cur];
      } else acc.push(cur);
      return acc;
    }, []);
    if (spacerIndex >= data.length) sortedData.push(data[activeRow]);
    return sortedData;
  };

  animate = () => {
    const {activeRow} = this.state;
    const {scrollPercent, data, scrollSpeed} = this.props;
    const scrollRatio = scrollPercent / 100;
    if (activeRow === -1) return;
    const nextSpacerIndex = this.getSpacerIndex(this._move, activeRow);
    if (nextSpacerIndex > -1 && nextSpacerIndex !== this._spacerIndex) {
      LayoutAnimation.configureNext(layoutAnimConfig);
      if (this.state.spacerIndex === 3 && nextSpacerIndex === 4) {
        this.setState({spacerIndex: nextSpacerIndex + 1});
      } else if (this.state.spacerIndex === 5 && nextSpacerIndex === 4) {
        this.setState({spacerIndex: nextSpacerIndex - 1});
      } else {
        this.setState({spacerIndex: nextSpacerIndex});
      }
      this._spacerIndex = nextSpacerIndex;
      // eslint-disable-next-line
      // @ts-ignore
      if (nextSpacerIndex === data.length) this._flatList.scrollToEnd();
    }

    // Scroll if hovering in top or bottom of container and have set a scroll %
    const isLastItem =
      activeRow === data.length - 1 || nextSpacerIndex === data.length;
    const isFirstItem = activeRow === 0;
    if (this._measurements[activeRow]) {
      const fingerPosition = Math.max(0, this._move - this._containerOffset);
      const shouldScrollUp =
        !isFirstItem && fingerPosition < this._containerSize * scrollRatio;
      const shouldScrollDown =
        !isLastItem && fingerPosition > this._containerSize * (1 - scrollRatio);
      if (shouldScrollUp) this.scroll(-scrollSpeed, nextSpacerIndex);
      else if (shouldScrollDown) this.scroll(scrollSpeed, nextSpacerIndex);
    }

    requestAnimationFrame(this.animate);
  };

  scroll = (scrollAmt: any, spacerIndex: any) => {
    if (spacerIndex >= this.props.data.length)
      // eslint-disable-next-line
      // @ts-ignore
      return this._flatList.scrollToEnd();
    if (spacerIndex === -1) return;
    const currentScrollOffset = this._scrollOffset;
    const newOffset = currentScrollOffset + scrollAmt;
    const offset = Math.max(0, newOffset);
    // eslint-disable-next-line
    // @ts-ignore
    this._flatList.scrollToOffset({offset, animated: false});
  };

  getSpacerIndex = (move: any, activeRow: any) => {
    if (activeRow === -1 || !this._measurements[activeRow]) return -1;
    const hoverItemSize = this._measurements[activeRow]['height'];
    const hoverItemMidpoint = move - this._additionalOffset + hoverItemSize / 2;
    const hoverPoint = Math.floor(hoverItemMidpoint + this._scrollOffset);
    let spacerIndex: any = this._pixels[hoverPoint];
    if (spacerIndex === undefined) {
      spacerIndex = this._measurements.findIndex(({height, y}) => {
        const itemOffset = y;
        const itemSize = height;
        return hoverPoint > itemOffset && hoverPoint < itemOffset + itemSize;
      });
    }
    return spacerIndex > activeRow ? spacerIndex : spacerIndex;
  };

  measureItem = (index: any) => {
    const {activeRow} = this.state;
    !!this._refs[index] &&
      setTimeout(() => {
        try {
          !!this._refs[index] &&
            // eslint-disable-next-line
            // @ts-ignore
            this._refs[index].measureInWindow((x, y, width, height) => {
              if ((width || height) && activeRow === -1) {
                const ypos = y + this._scrollOffset;
                const xpos = x + this._scrollOffset;
                const pos = ypos;
                const size = height;
                const rowMeasurements = {y: ypos, x: xpos, width, height};
                // eslint-disable-next-line
                // @ts-ignore
                this._measurements[index] = rowMeasurements;
                for (let i = Math.floor(pos); i < pos + size; i++) {
                  // eslint-disable-next-line
                  // @ts-ignore
                  this._pixels[i] = index;
                }
              }
            });
        } catch (error) {
          // TODO: remove this
          appLog.error('measure error', {
            index,
            activeRow,
            error,
          });
        }
      }, 100);
  };

  move = (hoverComponent: any, index: any) => {
    const {onMoveBegin} = this.props;
    if (this._releaseAnim) {
      // eslint-disable-next-line
      // @ts-ignore
      this._releaseAnim.stop();
      this.onReleaseAnimationEnd();
      return;
    }
    // eslint-disable-next-line
    // @ts-ignore
    this._refs.forEach((ref, index) => this.measureItem(ref, index));
    this._spacerIndex = index;
    this.setState(
      {
        activeRow: index,
        spacerIndex: index,
        hoverComponent,
      },
      () => onMoveBegin && onMoveBegin(index),
    );
  };

  moveEnd = () => {
    if (!this._hasMoved) this.setState(initialState);
  };
  // eslint-disable-next-line
  // @ts-ignore
  setRef = index => ref => {
    if (ref) {
      // eslint-disable-next-line
      // @ts-ignore
      this._refs[index] = ref;
      this.measureItem(index);
    }
  };

  renderItem = ({item, index}: any) => {
    const {renderItem, data, hoverBackgroundColor} = this.props;
    const {activeRow, spacerIndex} = this.state;
    const isActiveRow = activeRow === index;
    const isSpacerRow = spacerIndex === index;
    const isLastItem = index === data.length - 1;
    const spacerAfterLastItem = spacerIndex >= data.length;
    const activeRowSize = this._measurements[activeRow]
      ? this._measurements[activeRow]['height']
      : 0;
    const endPadding = isLastItem && spacerAfterLastItem;
    const spacerStyle = {height: activeRowSize};

    return (
      <View
        // eslint-disable-next-line
        // @ts-ignore
        style={{
          backgroundColor: isSpacerRow ? hoverBackgroundColor : null,
          opacity: isSpacerRow ? 0.2 : 1,
          zIndex: 0,
        }}>
        <View
          style={[
            styles.fullOpacity,
            {flexDirection: 'column'},
            {zIndex: isActiveRow ? 10 : 1, opacity: 1},
          ]}>
          {
            // eslint-disable-next-line
            // @ts-ignore
            isSpacerRow && <View style={{spacerStyle, opacity: 1}} />
          }

          <RowItem
            index={index}
            isActiveRow={isActiveRow}
            renderItem={renderItem}
            item={item}
            setRef={this.setRef}
            move={this.move}
            moveEnd={this.moveEnd}
            extraData={this.state.extraData}
          />
          {endPadding && <View style={spacerStyle} />}
        </View>
      </View>
    );
  };

  renderHoverComponent = () => {
    const {hoverComponent} = this.state;
    return (
      !!hoverComponent && (
        <Animated.View
          style={[
            styles.hoverComponentVertical,
            {
              transform: [{translateY: this._hoverAnim}],
            },
          ]}>
          {hoverComponent}
        </Animated.View>
      )
    );
  };

  measureContainer = () => {
    if (this._containerView) {
      this._containerView.measure((_x, _y, _width, height, _pageX, pageY) => {
        this._containerOffset = pageY;
        this._containerSize = height;
      });
    }
  };
  // eslint-disable-next-line
  // @ts-ignore
  keyExtractor = (_item, index) => `sortable-flatlist-item-${index}`;
  // eslint-disable-next-line
  // @ts-ignore
  componentDidUpdate = prevProps => {
    if (prevProps.extraData !== this.props.extraData) {
      // eslint-disable-next-line
      // @ts-ignore
      this.setState({extraData: this.props.extraData});
    }
  };

  onScroll = (props: any) => {
    const {onScroll} = this.props;
    this._scrollOffset = props.nativeEvent.contentOffset['y'];
    onScroll && onScroll(props);
    this._scrollingTimer && clearTimeout(this._scrollingTimer);
    this._scrollingTimer = setTimeout(() => {
      //If no other scrolling events are detected withing 100ms, then
      //they are no longer scrolling
      this.onScrollEnd(this.props);
    }, 100);
  };

  onScrollEnd = (props: any) => {
    const {onScrollEnd} = this.props;
    onScrollEnd && onScrollEnd(props);
  };

  render() {
    const {keyExtractor} = this.props;
    return (
      <View
        ref={ref => {
          if (ref) {
            this._containerView = ref;
          }
        }}
        onLayout={this.measureContainer}
        // eslint-disable-next-line
        // @ts-ignore
        {...this._panResponder.panHandlers}
        style={styles.wrapper}>
        <FlatList
          {...this.props}
          contentContainerStyle={{
            marginBottom: 10,
          }}
          scrollEnabled={this.state.activeRow === -1}
          // eslint-disable-next-line
          // @ts-ignore
          ref={ref => (this._flatList = ref)}
          renderItem={this.renderItem}
          extraData={this.state}
          keyExtractor={keyExtractor || this.keyExtractor}
          onScroll={this.onScroll}
          scrollEventThrottle={16}
        />
        {this.renderHoverComponent()}
      </View>
    );
  }
}

// eslint-disable-next-line
// @ts-ignore
DraggableFlatList.defaultProps = {
  scrollPercent: 5,
  scrollSpeed: 20,
  contentContainerStyle: {},
};

interface RowItemProps {
  move: any;
  moveEnd: any;
  renderItem: any;
  item: any;
  index: any;
  isActiveRow: boolean;
  setRef: any;
  extraData: any;
}

class RowItem extends React.PureComponent<RowItemProps> {
  move = () => {
    const {move, moveEnd, renderItem, item, index} = this.props;
    const hoverComponent = renderItem({
      isActive: true,
      item,
      index,
      move: () => null,
      moveEnd,
    });
    move(hoverComponent, index);
  };

  render() {
    // eslint-disable-next-line
    // @ts-ignore
    const {moveEnd, isActiveRow, renderItem, item, index, setRef} = this.props;
    const component = renderItem({
      isActive: false,
      item,
      index,
      move: this.move,
      moveEnd,
    });
    let wrapperStyle: any = {opacity: 1};
    if (isActiveRow)
      wrapperStyle = {backgroundColor: 'blue', zIndex: 10, opacity: 0};
    return (
      <View
        ref={setRef(index)}
        collapsable={false}
        style={{opacity: 1, flexDirection: 'column'}}>
        <View style={wrapperStyle}>{component}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  hoverComponentVertical: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  wrapper: {flex: 1, opacity: 1},
  fullOpacity: {opacity: 1},
});

import {
  Analytics as FirebaseAnalyticsType,
  initializeAnalytics,
  logEvent,
  setUserId,
} from 'firebase/analytics';

import {firebaseApp} from '../../firebase/firebase.web';
import {formatType} from '../common';
import {EventProperties, EventType} from '../event-properties';
import {Analytics} from '../types';
import {UserTracker, UserTrackerCallback} from '../user-tracker';
import {FirebaseUserTracker} from './firebase-user-tracker.web';

export class FirebaseAnalytics implements Analytics {
  private analytics?: FirebaseAnalyticsType;
  private userTracker?: UserTracker;

  constructor() {
    this.analytics = undefined;
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E]): void {
    if (!this.analytics) return;

    return logEvent(this.analytics, formatType(type), event);
  }

  setCurrentUser(userId?: string): void {
    if (!this.analytics) return;

    return setUserId(this.analytics, userId as string);
  }

  trackUser(callback: UserTrackerCallback) {
    if (!this.userTracker) return;

    callback(this.userTracker);
  }

  setEnabled(enabled: boolean): void {
    // On web we can't disabled analytics through the sdk
    if (!enabled) return;

    this.analytics = initializeAnalytics(firebaseApp, {
      config: {send_page_view: false},
    });
    this.userTracker = new FirebaseUserTracker(this.analytics);
  }
}

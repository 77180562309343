import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import {TimeFilterPeriod} from 'puffco-api-axios-client';

export const getStartDay = (period: TimeFilterPeriod, startDate?: Date) => {
  const start = startDate ? new Date(startDate) : startOfDay(new Date());
  switch (period) {
    case 'd':
      return addDays(
        startOfHour(startDate ? new Date(startDate) : new Date()),
        -1,
      );
    case 'w':
      return addWeeks(start, -1);
    case 'm':
      return addMonths(start, -1);
    case 'y':
      return addYears(startOfMonth(start), -1);
    default:
      return startOfYear(start);
  }
};

/**
 * Performs linear interpolation to map a value from one interval to another.
 *
 * @param {number} value - The value to be mapped.
 * @param {Object} from - The original interval.
 * @param {number} from.min - The minimum value of the original interval.
 * @param {number} from.max - The maximum value of the original interval.
 * @param {Object} to - The target interval.
 * @param {number} to.min - The minimum value of the target interval.
 * @param {number} to.max - The maximum value of the target interval.
 * @returns {number} The mapped value within the target interval.
 * @throws {Error} Throws an error if the intervals are invalid (from.min > from.max or to.min > to.max).
 *
 * @example
 * const mappedValue = linearInterpolation(5, { min: 0, max: 10 }, { min: 20, max: 40 });
 * console.log(mappedValue); // Output: 30
 */
export const linearInterpolate = (
  value: number,
  from: {min: number; max: number},
  to: {min: number; max: number},
) => {
  if (from.min > from.max || to.min > to.max) {
    throw new Error('Invalid intervals');
  }

  const mappedValue =
    to.min + ((value - from.min) / (from.max - from.min)) * (to.max - to.min);

  return Math.max(to.min, Math.min(to.max, mappedValue));
};

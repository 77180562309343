import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';
import Animated, {useAnimatedStyle} from 'react-native-reanimated';

import {appColors} from '../../constants';
import {Slider} from './Slider';

const trackHeight = 2;
const handleSize = 18;
const gestureHeight = handleSize * 5;

interface Props
  extends Pick<
    React.ComponentProps<typeof Slider>,
    'value' | 'disabled' | 'onBegin' | 'onChange' | 'onEnd'
  > {
  track?: React.FC<React.ComponentProps<typeof View>>;
  activeTrackStyle?: ViewProps['style'];
}

export const HorizontalSlider: React.FC<Props> = ({
  value,
  track: Track = View,
  activeTrackStyle,
  disabled,
  onBegin,
  onChange,
  onEnd,
}) => {
  const [width, setWidth] = React.useState(1);

  const animatedTrackStyle = useAnimatedStyle(() => ({
    transform: [{scaleX: value.value}],
  }));

  const animatedHandleStyle = useAnimatedStyle(() => ({
    transform: [{translateX: value.value * (width - handleSize + 1)}],
  }));

  return (
    <Slider
      {...{value, disabled, onBegin, onChange, onEnd}}
      style={styles.container}
      onEvent={event => {
        'worklet';
        return event.x / width;
      }}
      onLayout={event => setWidth(event.nativeEvent.layout.width)}>
      <Track style={{...styles.track, ...styles.trackContainer}} />

      <Animated.View
        style={[
          styles.track,
          !!disabled && styles.disabledTrack,
          activeTrackStyle,
          animatedTrackStyle,
        ]}
      />

      <Animated.View
        style={[
          styles.handle,
          !!disabled && styles.disabledHandle,
          animatedHandleStyle,
        ]}
      />
    </Slider>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: -(gestureHeight - handleSize) / 2,
    height: gestureHeight,
  },
  trackContainer: {
    backgroundColor: appColors.white20,
  },
  track: {
    position: 'absolute',
    top: (gestureHeight - trackHeight) / 2,
    left: 0,
    width: '100%',
    height: trackHeight,
    backgroundColor: 'white',
    transformOrigin: 'left',
  },
  disabledTrack: {
    backgroundColor: appColors.darkGreyMedium33,
  },
  handle: {
    position: 'absolute',
    top: (gestureHeight - handleSize) / 2,
    left: 0,
    width: handleSize,
    height: handleSize,
    borderRadius: handleSize / 2,
    backgroundColor: 'white',
    transformOrigin: 'bottom',
  },
  disabledHandle: {
    backgroundColor: appColors.darkGreyMedium33,
  },
});

import React from 'react';
import {useAsync} from 'react-use';

import {contentAccess} from '../../../../lib/api';

export interface Options {
  referenceId: string;
  query?: string;
  tag?: string;
}

export const useLoadFaqs = ({referenceId, query, tag}: Options) => {
  const {value, loading, error} = useAsync(
    () => contentAccess.getFaqsForReference({referenceId, query, tag}),
    [referenceId, query, tag],
  );

  return React.useMemo(
    () => ({
      loading,
      error,
      faqs: value?.items ?? [],
    }),
    [value],
  );
};

import {useIsFocused, useNavigation, useRoute} from '@react-navigation/native';
import decode from 'jwt-decode';
import {
  AuthTokensDto,
  MarketOptIn,
  UserLoginDto,
  UserUpdateDto,
} from 'puffco-api-axios-client';
import {useDispatch, useSelector} from 'react-redux';
import {useAsync} from 'react-use';

import {Alert} from '../../components';
import {Screens} from '../../constants';
import {ErrorMessages} from '../../constants/Strings';
import {Auth} from '../../contexts/auth';
import {useAsyncFn} from '../../lib/hooks/useAsyncFn';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {RedirectionParameter} from '../../navigation/navigators/params';
import {toHomeRedirect, toStartPairing} from '../../navigation/navigators/util';
import {analytics} from '../../src/services/analytics';
import {updateAppSettings} from '../redux/appSettingsSlice';
import {currentDeviceSelector} from '../redux/bleSlice';
import {ProviderStore} from '../redux/persistor';
import {setUser, userSelector} from '../redux/userSlice';
import {AppSettingsStore, User} from '../types';
import {authApi, userApi} from './apis';

const decodeUser = (message: any): User => ({
  id: message.sub,
  firstName: message.firstName,
  lastName: message.lastName,
  username: message.username,
  defaultUsername: message.defaultUsername,
  email: message.email,
  created: message.created,
  modified: '',
  roles: message.roles,
  pushNotifications: message.pushNotifications,
  verified: message.verified,
  marketOptIn: message.marketOptIn,
  enableBugfender: message.enableBugfender,
});

const decodeAppSettings = (message: any): Partial<AppSettingsStore> => ({
  tempPreference: message.tempPreference,
  enablePeakAnimation: message.enablePeakAnimation,
  batteryPreservationOn: message.batteryPreservation,
});

const decodeAccessToken = (accessToken: string) => {
  const message: any = decode(accessToken);

  return {user: decodeUser(message), appSettings: decodeAppSettings(message)};
};

type ScreenProps = RootStackScreenProps<
  typeof Screens.Login | typeof Screens.Register
>;

type Navigation = ScreenProps['navigation'];
type Route = ScreenProps['route'];

export const useLogin = () => {
  const dispatch = useDispatch();
  const {createSession} = Auth.useContainer();

  return useAsyncFn(
    async ({email, password}: UserLoginDto) => {
      const {
        data: {accessToken, refreshToken},
      } = await authApi.login({userLoginDto: {email, password}});

      await createSession({accessToken, refreshToken});

      const {user, appSettings} = decodeAccessToken(accessToken);

      dispatch(setUser(user));
      dispatch(updateAppSettings(appSettings));

      return user;
    },
    [createSession],
  );
};

const useSocialLogin = (
  method: 'apple' | 'google',
  callback: (code: string) => Promise<AuthTokensDto>,
) => {
  const dispatch = useDispatch();
  const route = useRoute<Route>();
  const navigation = useNavigation<Navigation>();
  const hasCurrentDevice = !!useSelector(currentDeviceSelector);
  const {createSession} = Auth.useContainer();

  return useAsyncFn(
    async (code: string) => {
      const {accessToken, refreshToken, newUser} = await callback(code);

      analytics.trackEvent(newUser ? 'sign up' : 'login', {method});

      const {redirect} = route.params ?? {};

      await createSession({accessToken, refreshToken});

      const {user, appSettings} = decodeAccessToken(accessToken);

      dispatch(setUser(user));
      dispatch(updateAppSettings(appSettings));

      if (newUser) {
        navigation.navigate(Screens.AccountCreated, {
          isSocial: true,
          redirect: redirect ?? toHomeRedirect.encode(),
          email: user.email,
        });
      } else {
        navigation.navigate(
          ...(redirect
            ? RedirectionParameter.decode(redirect)
            : hasCurrentDevice
              ? toHomeRedirect.value
              : toStartPairing()),
        );
      }

      return user;
    },
    [createSession, route.params],
  );
};

export const useAppleLogin = () => {
  const loggedIn = !!useSelector(userSelector);
  const isFocused = useIsFocused();
  const route = useRoute<Route>();
  const code = route?.params?.appleCode;

  const [state, login] = useSocialLogin('apple', (code: string) =>
    authApi
      .appleAuth({userAppleAuthDto: {code, legacy: false}})
      .then(r => r.data),
  );

  useAsync(async () => {
    if (!code || loggedIn || !isFocused) return;

    login(code).catch();
  }, [code, isFocused, loggedIn, login]);

  return [state, login] as const;
};

export const useGoogleLogin = () => {
  const loggedIn = !!useSelector(userSelector);
  const isFocused = useIsFocused();
  const route = useRoute<Route>();
  const code = route?.params?.googleCode;

  const [state, login] = useSocialLogin('google', (code: string) =>
    authApi.googleAuth({userGoogleAuthDto: {code}}).then(r => r.data),
  );

  useAsync(async () => {
    if (!code || loggedIn || !isFocused) return;

    login(code).catch();
  }, [code, isFocused, loggedIn, login]);

  return [state, login] as const;
};

export type ChangeMarketOptInDTO = {
  marketOptIn?: MarketOptIn;
};

export const updateUser = async (
  dto: UserUpdateDto,
  badRequestErrorMessage = ErrorMessages.BAD_REQUEST,
) => {
  try {
    const {data} = await userApi.updateUserById({id: 'me', userUpdateDto: dto});
    ProviderStore.dispatch(setUser(data));
    return data;
  } catch (error) {
    if (!(error instanceof Error)) throw new Error(`Unknown error: ${error}`);

    if (error?.name === 'Unauthorized') {
      Alert.alert('Error', ErrorMessages.INVALID_USER_AUTHORIZATION);
    } else if (error?.name === 'Forbidden') {
      Alert.alert('Error', ErrorMessages.EMAIL_NOT_VERIFIED);
    } else if (error?.name === 'Bad Request') {
      Alert.alert('Error', badRequestErrorMessage);
    } else {
      Alert.alert('Error', ErrorMessages.NETWORK_ERROR);
    }

    throw error;
  }
};

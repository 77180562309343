import {interpolate} from 'react-native-reanimated';

// These types will allow us to validate that input and output are the same length
export const createInterpolate = <T extends readonly number[] | readonly []>(
  input: T,
  output: {[K in keyof T]: number},
) => ({
  from: (value: number) => interpolate(value, input, output),
  to: (value: number) => interpolate(value, output, input),
});

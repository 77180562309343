import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import {CompositeScreenProps, ParamListBase} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {StyleSheet} from 'react-native';

import {Navigators, Strings, appColors} from '../../constants';
import {PageType} from '../../lib/api';
import {useLoadPages} from '../../lib/hooks/useLoadPages';
import {LoadingContainer} from '../../screens/Education/components/LoadingContainer';
import {Page} from '../../screens/components/contentful';
import {CommunityTab} from './CommunityTabNavigator';
import {CommunityNavigatorScreenProps} from './HomeDrawerNavigator';
import {fadeStackOptions, withBackButton, withHamburgerMenu} from './common';

export type CommunityStackParamList = {
  [Navigators.CommunityTab]: undefined;
} & Record<string, undefined>;

export type CommunityTabNavigatorScreenProps = CompositeScreenProps<
  MaterialTopTabScreenProps<ParamListBase>,
  CommunityNavigatorScreenProps<keyof CommunityStackParamList>
>;

const CommunityStackNavigator = createStackNavigator();

export const CommunityStack = () => {
  const {pages: secondaryPages, loading} = useLoadPages({
    type: PageType.COMMUNITY_SECONDARY,
  });

  if (loading) return <LoadingContainer />;

  return (
    <CommunityStackNavigator.Navigator
      screenOptions={() => ({
        ...fadeStackOptions,
        headerTransparent: false,
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: appColors.black,
          borderWidth: 0,
        },
        title: Strings.COMMUNITY.TITLE,
        headerTitleStyle: styles.headerTitle,
        cardStyle: styles.card,
      })}>
      <CommunityStackNavigator.Screen
        name={Navigators.CommunityTab}
        component={CommunityTab}
        options={{...withHamburgerMenu()}}
      />

      {secondaryPages.map(page => (
        <CommunityStackNavigator.Screen
          key={page.sys.id}
          name={page.fields.screenName}
          component={Page}
          options={{
            title: page.fields.title,
            ...withBackButton(),
          }}
        />
      ))}
    </CommunityStackNavigator.Navigator>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    fontWeight: '700',
    color: appColors.white,
    textTransform: 'uppercase',
  },
  card: {
    backgroundColor: appColors.black,
  },
});

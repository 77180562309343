import {ProductName} from 'pikaparam';
import React from 'react';
import {useSelector} from 'react-redux';

import {RECORDS} from '../constants';
import {currentDeviceSelector} from './redux/bleSlice';
import {Store} from './types';
import {getProductName} from './utilityFunctions/getProductName';

const getTheme = (productName: ProductName) => {
  return RECORDS.THEME_RECORD[productName];
};

export const useTheme = () => {
  const modelNumberString = useSelector(
    (state: Store) => currentDeviceSelector(state)?.modelNumberString,
  );
  const serialNumberString = useSelector(
    (state: Store) => currentDeviceSelector(state)?.serialNumberString,
  );
  const product = useSelector(
    (state: Store) => currentDeviceSelector(state)?.product,
  );

  return React.useMemo(
    () =>
      getTheme(
        getProductName({modelNumberString, serialNumberString, product}),
      ),
    [modelNumberString, serialNumberString, product],
  );
};

export const isProductLimitedEdition = (productName: ProductName) => {
  return !!getTheme(productName).limitedEditionModalScreenTheme;
};

import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useSelector} from 'react-redux';

import {Navigators, Screens} from '../../../../constants';
import {GuideType, contentAccess} from '../../../../lib/api';
import {getDaysPast} from '../../../../lib/getDaysPast';
import {useAppDispatch} from '../../../../lib/hooks';
import {
  appFlagsSelector,
  updateAppFlags,
} from '../../../../lib/redux/appFlagsSlice';
import {userSelector} from '../../../../lib/redux/userSlice';
import {HomeTabNavigatorScreenProps} from '../../../../navigation/navigators/HomeTabNavigator';

interface Options {
  type: GuideType;
}

type Navigation = HomeTabNavigatorScreenProps<
  typeof Screens.Home
>['navigation'];

export const useGuide = ({type}: Options) => {
  const navigation = useNavigation<Navigation>();
  const {hasSeenGuide} = useSelector(appFlagsSelector);
  const user = useSelector(userSelector);
  const dispatch = useAppDispatch();

  const created = user?.created;

  const isNewUser = React.useMemo(
    () => !created || getDaysPast(created) < 20,
    [created],
  );

  const shouldDisplay = isNewUser && !hasSeenGuide?.[type];

  const display = async ({onExit}: {onExit?(): void}) => {
    const guide = await contentAccess.getGuide({type});

    navigation.navigate(Navigators.Guide, {
      screen: Screens.Guide,
      params: {guide, onExit},
    });

    dispatch(
      updateAppFlags({
        hasSeenGuide: {...hasSeenGuide, [type]: true},
      }),
    );
  };

  return {display, shouldDisplay};
};

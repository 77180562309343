import {Text} from 'react-native';

import {appColors} from '../../../constants';
import styled from '../../../lib/styled';

export const Title = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 30,
  letterSpacing: 0.1,
  color: appColors.white,
  alignSelf: 'flex-start',
  marginTop: 25,
  marginBottom: 15,
  marginLeft: 16,
  marginRight: 16,
  fontWeight: '400',
});

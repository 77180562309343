import {TemperatureUnit} from 'puffco-api-axios-client';

export interface TemperatureConversionOptions {
  from: TemperatureUnit;
  to: TemperatureUnit;
}

export class Temperature {
  /**
   * Convert the temperature between two units.
   *
   * @param {number} value - The temperature to be converted.
   * @param {TemperatureConversionOptions} o - An object containing the units needed for the conversion.
   * @param {TemperatureUnit} o.from - The unit of the input temperature.
   * @param {TemperatureUnit} o.to - The unit to which the temperature should be converted.
   *
   * @returns {number} The resulting temperature.
   *
   * @example
   * const temperature = Temperature.convert(10, { from: TemperatureUnit.Celsius, to: TemperatureUnit.Fahrenheit });
   */
  public static convert(
    value: number,
    {from, to}: TemperatureConversionOptions,
  ): number {
    if (from === to) return value;

    if (from === TemperatureUnit.Celsius) {
      return (value * 9) / 5 + 32;
    }

    return ((value - 32) * 5) / 9;
  }

  /**
   * Convert the temperature difference between two units.
   *
   * @param {number} diff - The temperature difference to be converted.
   * @param {TemperatureConversionOptions} o - An object containing the units needed for the conversion.
   * @param {TemperatureUnit} o.from - The unit of the input temperature difference.
   * @param {TemperatureUnit} o.to - The unit to which the temperature difference should be converted.
   *
   * @returns {number} The resulting temperature difference.
   *
   * @example
   * const temperatureDifference = Temperature.convertDifference(10, { from: TemperatureUnit.Celsius, to: TemperatureUnit.Fahrenheit });
   */
  public static convertDifference(
    diff: number,
    {from, to}: TemperatureConversionOptions,
  ): number {
    if (from === to) return diff;

    if (from === TemperatureUnit.Celsius) {
      return (diff * 9) / 5;
    }

    return (diff * 5) / 9;
  }
}

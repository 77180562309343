import {addDays, addHours, addMonths} from 'date-fns';
import {TimeFilterPeriod} from 'puffco-api-axios-client';

import {Constants} from '../../../../../constants';

export type XAndY<T extends string | number = string | number> = {
  x: T;
  y: number;
};

const {WEEKS, MONTHS} = Constants;

export const getDabXAndYAxis = (
  time: TimeFilterPeriod,
  callback: (v: number) => number,
  startDate: Date,
): XAndY[] => {
  switch (time) {
    case 'd':
      return Array(25)
        .fill(null)
        .map((_, i) => {
          const date = addHours(startDate, i);
          const hours = date.getHours();
          const m = hours < 12 ? 'am' : 'pm';
          const mod = hours % 12;
          const xValue = (mod === 0 ? 12 : mod) + m;
          return {x: xValue, y: callback(date.getTime())};
        });

    case 'w':
      return Array(WEEKS.length)
        .fill(null)
        .map((_, i) => {
          const date = addDays(startDate, i + 1);
          const day = date.getDay();
          return {x: WEEKS[day], y: callback(date.getTime())};
        });

    case 'y':
      return Array(MONTHS.length)
        .fill(null)
        .map((_, i) => {
          const date = addMonths(startDate, i + 1);
          const month = date.getMonth();
          return {x: MONTHS[month], y: callback(date.getTime())};
        });

    case 'm': {
      const firstDay = new Date(startDate);
      const lastDay = new Date();
      const dateNum: XAndY[] = [];
      while (firstDay <= lastDay) {
        const d = firstDay;
        const date = d.getDate();
        dateNum.push({x: date.toString(), y: callback(d.getTime())});
        firstDay.setDate(firstDay.getDate() + 1);
      }
      return dateNum;
    }

    default:
      return [];
  }
};

import React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {appColors} from '../../constants';

interface ColonProps {
  dotSize?: number;
  spacing?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
}

export const Colon: React.FC<ColonProps> = ({
  dotSize = 8,
  spacing = 20,
  color = appColors.white,
  style,
}) => {
  return (
    <View
      style={[styles.colonContainer, {height: dotSize * 2 + spacing}, style]}>
      <View
        style={[
          styles.dot,
          {width: dotSize, height: dotSize, backgroundColor: color},
        ]}
      />

      <View
        style={[
          styles.dot,
          {
            width: dotSize,
            height: dotSize,
            backgroundColor: color,
            marginTop: spacing,
          },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  colonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    borderRadius: 50,
  },
});

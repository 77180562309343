import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Linking, StyleSheet, View, ViewProps} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {OnShouldStartLoadWithRequest} from 'react-native-webview/lib/WebViewTypes';

import {Constants, Navigators} from '../constants';
import {appLog} from '../lib/Logger';
import {useMoodLight} from '../lib/hooks';
import {openDeeplinkPath} from '../lib/openDeeplink';
import {RemoteModalInterstitial} from '../lib/types/Interstitial';
import {WebView} from '../shims/WebView';
import {analytics} from '../src/services/analytics';
import {Modal} from './Modal';

export type InterstitialProps = {
  interstitial: RemoteModalInterstitial;
} & ViewProps;

interface ActionPayload {
  action: INTERSTITIAL_ACTION;
  path: string;
}

const WEBVIEW_BORDER_RADIUS = 20;

enum INTERSTITIAL_ACTION {
  NAVIGATE_MOODLIGHT = 'navigateMoodLight',
  NAVIGATE_EDUCATION = 'navigateEducation',
  DEEPLINK = 'deeplink',
  CLICK = 'click',
  DISMISS = 'dismiss',
}

const parseMessage = (message: string) => {
  try {
    const parsed = JSON.parse(message) as ActionPayload;
    return parsed;
  } catch (_) {
    return message;
  }
};

const getInterstitialName = (url: string) =>
  url.replace(/^.+\/(.+)\.html.*$/, '$1');

export const WebviewInterstitial = ({
  interstitial: {
    options: {url},
  },
  ...rest
}: InterstitialProps) => {
  const {accessMoodLight} = useMoodLight();
  const handler = React.useRef<(event: any) => void>();
  const [loading, setLoading] = React.useState(true);
  const navigation = useNavigation<DrawerContentComponentProps['navigation']>();

  React.useEffect(() => {
    analytics.trackEvent('interstitial view', {
      content_id: getInterstitialName(url),
    });
  }, []);

  const onShouldStartLoadWithRequest =
    React.useCallback<OnShouldStartLoadWithRequest>(({url}) => {
      const urlPrefixes = ['http://', 'https://'];
      const shouldLoad = urlPrefixes.some(prefix => url.startsWith(prefix));

      if (!shouldLoad) {
        Linking.openURL(url);
      }

      return shouldLoad;
    }, []);

  const handleEventAction = React.useCallback(
    (event: any) => {
      Modal.close();

      const message = event.nativeEvent?.data || event.data || event.message;
      const data = parseMessage(message);

      if (typeof data === 'string') {
        switch (data) {
          case INTERSTITIAL_ACTION.NAVIGATE_MOODLIGHT:
            accessMoodLight({});
            break;
          case INTERSTITIAL_ACTION.NAVIGATE_EDUCATION:
            navigation.navigate(Navigators.DeviceEducation);
            break;
          case INTERSTITIAL_ACTION.DISMISS:
            break;
          default:
            appLog.info(`Unknown action: ${data}`);
        }

        return;
      }

      switch (data.action) {
        case INTERSTITIAL_ACTION.CLICK: {
          analytics.trackEvent('interstitial click', {
            content_id: getInterstitialName(url),
            link_url: data.path,
          });

          break;
        }
        case INTERSTITIAL_ACTION.DEEPLINK: {
          analytics.trackEvent('interstitial click', {
            content_id: getInterstitialName(url),
            link_url: data.path,
          });

          openDeeplinkPath(data.path);

          break;
        }
        default:
          appLog.info(`Unknown action: ${data}`);
      }
    },
    [accessMoodLight, navigation],
  );

  React.useEffect(() => {
    if (Constants.IS_WEB) {
      if (handler.current) {
        window.removeEventListener('message', handler.current);
      }
      handler.current = (event: any) => handleEventAction(event);
      window.addEventListener('message', handler.current);

      return () => {
        window.removeEventListener('message', handler.current!);
      };
    }
  }, [handleEventAction]);

  return (
    <View style={styles.window} {...rest}>
      <FadeInView {...{loading}}>
        <WebView
          source={{
            uri: url,
          }}
          onLoad={() => setLoading(false)}
          {...(Constants.IS_NATIVE_ANDROID && {
            containerStyle: {borderRadius: WEBVIEW_BORDER_RADIUS},
          })}
          style={{
            margin: -1,
            ...(Constants.IS_WEB && {
              borderRadius: WEBVIEW_BORDER_RADIUS,
            }),
          }}
          {...{onShouldStartLoadWithRequest}}
          onMessage={event => {
            handleEventAction(event);
          }}
        />
      </FadeInView>
    </View>
  );
};

const FadeInView = ({
  loading,
  ...rest
}: React.PropsWithChildren<{loading: boolean}>) => {
  const opacity = useSharedValue(0);

  React.useEffect(() => {
    opacity.value = withTiming(loading ? 0 : 1, {duration: 250});
  }, [opacity, loading]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  return <Animated.View style={[styles.fadeInView, animatedStyle]} {...rest} />;
};

const styles = StyleSheet.create({
  window: {
    width: '85%',
    height: '100%',
    position: 'absolute',
    paddingVertical: 60,
    borderRadius: WEBVIEW_BORDER_RADIUS,
    zIndex: 1,
    overflow: 'hidden',
  },
  fadeInView: {
    width: '100%',
    position: 'absolute',
    top: 59,
    left: 0,
    right: 0,
    bottom: 59,
    ...(Constants.IS_WEB && {
      borderRadius: WEBVIEW_BORDER_RADIUS,
    }),
  },
});

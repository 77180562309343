import React from 'react';
import {Image, StyleSheet} from 'react-native';

import {appColors} from '../../../../constants';
import {addOpacityToColorHex} from '../../../../lib/utilityFunctions';
import {PressableOpacity} from '../../../../shims/PressableOpacity';
import {onItemPress} from './utils';

interface Props {
  thumbnail: string;
  title?: string;
  link?: string;
  onPress?: () => void;
}

export const GridGalleryItem = ({thumbnail, link, onPress}: Props) => (
  <PressableOpacity
    style={styles.gridItem}
    onPress={() => onItemPress(onPress, link)}>
    <Image source={{uri: thumbnail}} style={styles.thumbnail} />
  </PressableOpacity>
);

const styles = StyleSheet.create({
  gridItem: {
    padding: 4,
    width: '30%',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: addOpacityToColorHex(appColors.white, 0.3),
  },
  thumbnail: {
    width: '100%',
    aspectRatio: 1,
  },
});

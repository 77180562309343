import React from 'react';
import {Dimensions, View} from 'react-native';
import {useAsync} from 'react-use';

import {
  ImgBackground,
  PeakImageWithStaticChamber,
  StyledButton,
} from '../../components';
import {Messages, Screens, Strings, appColors} from '../../constants';
import {appLog} from '../../lib/Logger';
import styled from '../../lib/styled';
import {MainNavigatorScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {
  IBluetoothService,
  bluetoothService,
} from '../../src/services/bluetooth';
import {ILocationService, locationService} from '../../src/services/location';
import {Info, Title} from './components';

const WIDTH = Dimensions.get('window').width;

type InstructionState = keyof (typeof Messages)['PAIRING_INSTRUCTIONS'];

const checkEnabledAndGranted = async (
  service: ILocationService | IBluetoothService,
) => {
  if (!service.required) return true;

  return (await service.enabled()) && (await service.granted());
};

const inferInstructionState = async (
  state: InstructionState,
): Promise<InstructionState> => {
  switch (state) {
    case 'location': {
      const completed = await checkEnabledAndGranted(locationService);

      return completed ? await inferInstructionState('bluetooth') : state;
    }
    case 'bluetooth': {
      const completed = await checkEnabledAndGranted(bluetoothService);

      return completed ? await inferInstructionState('pairing') : state;
    }
  }

  return 'pairing';
};

interface Props
  extends MainNavigatorScreenProps<typeof Screens.PairInstructions> {}

export const PairInstructionsScreen = ({navigation, route}: Props) => {
  const redirect = route.params?.redirect;

  const [state, setState] = React.useState<InstructionState>();

  const {value} = useAsync(
    () => inferInstructionState(state ?? 'location'),
    [state],
  );

  const onSubmit = async () => {
    switch (value) {
      case 'location':
        await locationService.request();
        return setState('bluetooth');
      case 'bluetooth':
        await bluetoothService.request();
        return setState('pairing');
      case 'pairing':
        navigation.navigate(Screens.Connect, {redirect});
    }
  };

  return (
    <ImgBackground>
      {!!value && (
        <SafeAreaView
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flex: 1,
          }}>
          <HeaderContainer>
            <Title>{Messages.PAIRING_INSTRUCTIONS[value].title}</Title>

            <Info>{Messages.PAIRING_INSTRUCTIONS[value].body}</Info>
          </HeaderContainer>

          <BodyContainer style={{flex: 1}}>
            <PeakContainer>
              <PeakImageWithStaticChamber
                colorSource={appColors.white}
                useDefaultTheme
                style={{width: '100%', height: '110%'}}
              />
            </PeakContainer>
          </BodyContainer>

          <ButtonContainer>
            <StyledButton
              title={value === 'pairing' ? Strings.NEXT : Strings.ALLOW}
              onPress={() =>
                onSubmit().catch(error =>
                  appLog.error('Pair instruction submit failed.', {error}),
                )
              }
            />
          </ButtonContainer>
        </SafeAreaView>
      )}
    </ImgBackground>
  );
};

const HeaderContainer = styled(View)({
  height: 190,
  width: '100%',
});

const ButtonContainer = styled(View)({
  width: '100%',
  height: 80,
  bottom: 0,
  alignItems: 'center',
  justifyContent: 'center',
});

const BodyContainer = styled(View)({
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const PeakContainer = styled(View)({
  flexDirection: 'column',
  height: WIDTH,
  width: WIDTH,
});

import {Platform} from 'react-native';

import {Constants} from '../../constants';
import {DeviceOperatingSystem} from '../types/DeviceOperatingSystem';

export enum Comparison {
  LESS_THAN_OR_EQUAL_TO = '<=',
  EQUAL_TO = '===',
}

export const isDeviceVersion = (
  deviceOs: DeviceOperatingSystem,
  majorVersion: number,
  versionComparison: Comparison = Comparison.LESS_THAN_OR_EQUAL_TO,
) => {
  if (Platform.OS !== 'web') {
    return false;
  }
  const platformString = navigator?.userAgent;
  const deviceOsIndex = platformString?.indexOf(deviceOs);
  if (deviceOsIndex === Constants.INDEX_NOT_FOUND) {
    return false;
  }
  const versionString = platformString.substring(deviceOsIndex);
  const deviceVersion = parseInt(
    versionString.substring(deviceOs.length + 1, versionString.indexOf('_')),
  );
  switch (versionComparison) {
    case Comparison.EQUAL_TO:
      return deviceVersion === majorVersion;
    default:
      return deviceVersion <= majorVersion;
  }
};

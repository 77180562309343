import React from 'react';
import {
  Easing,
  SharedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {cancelAnimation} from 'react-native-reanimated';

import {Progress, ProgressData} from '../ble2/v2/types';

export interface AnimatedProgress<T> extends ProgressData<T> {
  value: SharedValue<number>;
}

export const useProgress = <T>({
  value: initialValue,
  ...initial
}: Progress<T>) => {
  const animated = useSharedValue(initialValue);

  const [progress, setProgress] = React.useState<AnimatedProgress<T>>(() => ({
    ...initial,
    value: animated,
  }));

  const updateProgress = React.useCallback(
    ({value, ...progress}: Partial<Progress<T>>) => {
      setProgress(p => ({...p, ...progress}));

      if (value === undefined) return;

      animated.value = withTiming(value, {
        duration: progress.duration ?? 500,
        easing: Easing.linear,
      });
    },
    [animated],
  );

  const resetProgress = React.useCallback(
    (progress?: Omit<Partial<Progress<T>>, 'value'>) => {
      cancelAnimation(animated);
      animated.value = initialValue;
      setProgress({...initial, ...progress, value: animated});
    },
    [animated],
  );

  return [progress, updateProgress, resetProgress] as const;
};

import omit from 'lodash/omit';
import React from 'react';
import {Dimensions, StyleSheet, ViewProps} from 'react-native';

import {Constants, appColors} from '../constants';
import {getTransparentGradientColors} from '../lib/getTransparentGradientColors';
import {MoodLight, Theme} from '../lib/types';
import {useSafeArea} from '../lib/useSafeArea';
import {addOpacityToColorHex} from '../lib/utilityFunctions';
import {ConicGradientView} from '../shims/ConicGradientView';
import {MultiColorOpalBackground} from '../shims/MultiColorOpalBackground';
import {guardianTheme, indiglowTheme, opalTheme} from '../themes';
import {Background, LayeredBackground} from './LayeredBackground';
import {LinearGradientBackground} from './LinearGradientBackground';
import {MultiGradientBackground} from './MultiGradientBackground';

const {black, white} = appColors;
const {IOS_CONIC_START_ANGLE} = Constants;

interface Props extends ViewProps {
  theme: Theme;
  useBaseBackground?: boolean;
  moodLight?: Pick<MoodLight, 'colors'>;
  backgroundColor?: string;
  backgroundScale?: number; // relative to coreImageLayout
  coreImageLayout?: {
    height: number;
    y: number;
  };
}

/*
 * Background componenent for stacking multiple layered backgrounds. Backgrounds
 * are stacked with earlier indices before subsequent indicies and children on top.
 */
export const HeatProfileScreenLayeredBackground: React.FC<
  React.PropsWithChildren<Props>
> = props => {
  const {
    theme,
    children,
    style,
    useBaseBackground = true,
    backgroundScale = 1,
    coreImageLayout,
    ...rest
  } = props;
  const {background, gradientShadeFunction, backgroundOpacityFactor} =
    theme.heatProfileScreenBackgroundTheme;

  const getCurrentProduct = () => {
    switch (theme) {
      case opalTheme:
        return 'Opal' as const;
      case indiglowTheme:
        return 'Indiglow' as const;
      case guardianTheme:
        return 'Guardian' as const;
      default:
        return 'OG' as const;
    }
  };

  const currentProduct = getCurrentProduct();
  const [isBackgroundLoaded, setIsBackgroundLoaded] = React.useState(false);
  const moodLightColors = props.moodLight?.colors;

  const safeAreas = useSafeArea();

  const getGuardianBackgrounds = React.useCallback(() => {
    const GuardianBgs: Background[] = useBaseBackground
      ? [{viewProps: {style: {backgroundColor: white}}}]
      : [{}];
    // Add gradient bgs

    if (props.moodLight) {
      if (moodLightColors) {
        GuardianBgs.push({
          element: (
            <ConicGradientView
              colors={moodLightColors}
              startAngle={IOS_CONIC_START_ANGLE[moodLightColors.length]}
            />
          ),
        });
      } else {
        isBackgroundLoaded &&
          GuardianBgs.push({
            viewProps: {style: {backgroundColor: props.backgroundColor}},
          });
      }
    } else if (props.backgroundColor) {
      GuardianBgs.push({
        linearGradientProps: {
          colors: gradientShadeFunction(
            props.backgroundColor,
            backgroundOpacityFactor,
          ),
        },
      });
    }

    const calculateLayout = (): {top: number; height: number} => {
      // for path fullscreen with SafeAreas, I think we want to use 'screen' instead of 'window'
      // but for Android and Path otherwise, we want to use 'window'
      const windowHeight =
        safeAreas.top > 0
          ? Dimensions.get('screen').height
          : Dimensions.get('window').height;
      const bgHeight = coreImageLayout?.height
        ? coreImageLayout.height * backgroundScale
        : windowHeight;

      const bgTop =
        coreImageLayout?.y && coreImageLayout?.height
          ? coreImageLayout.y + coreImageLayout.height / 2 - bgHeight / 2
          : (windowHeight - bgHeight) / 2;

      return {
        top: bgTop,
        height: bgHeight,
      };
    };

    // Add regular bgs
    GuardianBgs.push(
      background && coreImageLayout
        ? {
            imageProps: {
              source: background,
              onLoadEnd: () => setIsBackgroundLoaded(true),
              style: {
                ...calculateLayout(),
                right: 0,
              },
              resizeMode: 'cover',
            },
          }
        : {},
    );

    return GuardianBgs;
  }, [
    props.moodLight,
    props.backgroundColor,
    background,
    moodLightColors,
    isBackgroundLoaded,
    gradientShadeFunction,
    backgroundOpacityFactor,
    coreImageLayout,
    backgroundScale,
  ]);

  const getIndiglowBackgrounds = React.useCallback(() => {
    const IndiglowBgs: Background[] = [];
    if (moodLightColors) {
      IndiglowBgs.push({
        element: (
          <ConicGradientView
            colors={moodLightColors}
            startAngle={IOS_CONIC_START_ANGLE[moodLightColors.length]}
          />
        ),
      });
    } else {
      isBackgroundLoaded &&
        IndiglowBgs.push({
          viewProps: {style: {backgroundColor: props.backgroundColor}},
        });
    }

    IndiglowBgs.push(
      background
        ? {
            imageBackgroundProps: {
              source: background,
              onLoadEnd: () => {
                setIsBackgroundLoaded(true);
              },
            },
          }
        : {},
    );

    return IndiglowBgs;
  }, [background, isBackgroundLoaded, props.backgroundColor, moodLightColors]);

  const getOpalBackgrounds = React.useCallback(() => {
    const opalBgs: Background[] = useBaseBackground
      ? [
          {viewProps: {style: {backgroundColor: white}}},
          background ? {imageBackgroundProps: {source: background}} : {},
        ]
      : [{}];
    if (props.moodLight) {
      if (props.moodLight.colors.length === 1) {
        opalBgs.push({
          linearGradientProps: {
            colors: getTransparentGradientColors(props.moodLight.colors[0]),
          },
        });
      } else {
        opalBgs.push({
          element: <MultiColorOpalBackground colors={props.moodLight.colors} />,
        });
      }
    } else if (props.backgroundColor) {
      opalBgs.push({
        linearGradientProps: {
          colors: getTransparentGradientColors(props.backgroundColor),
        },
      });
    }
    return opalBgs;
  }, [background, props.backgroundColor, props.moodLight, useBaseBackground]);

  const getDefaultBackgrounds = React.useCallback(() => {
    const defaultBgs: Background[] = useBaseBackground
      ? [{viewProps: {style: {backgroundColor: black}}}]
      : [{}];
    if (props.moodLight) {
      if (props.moodLight.colors.length > 1) {
        defaultBgs.push({
          element: (
            <MultiGradientBackground
              gradientShadeFunction={gradientShadeFunction}
              colors={props.moodLight.colors}
            />
          ),
        });
        defaultBgs.push({
          linearGradientProps: {
            locations: [0, 0.4, 1],
            colors: [
              addOpacityToColorHex(black, 0.8),
              addOpacityToColorHex(black, 0.1),
              addOpacityToColorHex(black, 0),
            ],
            useAngle: true,
            angle: 0,
            angleCenter: {x: 0.5, y: 0.5},
          },
        });
      } else {
        defaultBgs.push({
          element: (
            <LinearGradientBackground
              colors={gradientShadeFunction(props.moodLight.colors[0])}
            />
          ),
        });
      }
    } else if (props.backgroundColor) {
      defaultBgs.push({
        linearGradientProps: {
          colors: gradientShadeFunction(props.backgroundColor),
        },
      });
    }
    return defaultBgs;
  }, [
    gradientShadeFunction,
    props.backgroundColor,
    props.moodLight,
    useBaseBackground,
  ]);

  const backgrounds = React.useMemo(() => {
    const backgroundTheme: Record<
      typeof currentProduct,
      typeof getDefaultBackgrounds
    > = {
      OG: getDefaultBackgrounds,
      Opal: getOpalBackgrounds,
      Indiglow: getIndiglowBackgrounds,
      Guardian: getGuardianBackgrounds,
    };

    return backgroundTheme[currentProduct]();
  }, [
    currentProduct,
    getDefaultBackgrounds,
    getIndiglowBackgrounds,
    getOpalBackgrounds,
    getGuardianBackgrounds,
  ]);

  return (
    <LayeredBackground
      style={{
        flex: 1,
        ...StyleSheet.flatten(style),
      }}
      {...{backgrounds}}
      {...omit(rest, ['moodLight', 'backgroundColor'])}>
      {children}
    </LayeredBackground>
  );
};

import type {Asset, Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeDeviceDocumentFields {
  title: 'Error Codes' | 'Tips';
  content: EntryFields.RichText;
  showSupportQuestion?: EntryFields.Boolean;
  name: EntryFields.Symbol;
  background: Asset;
}

export type TypeDeviceDocument = Entry<TypeDeviceDocumentFields>;

export function isTypeDeviceDocument(
  entry: WithContentTypeLink,
): entry is TypeDeviceDocument {
  return entry?.sys?.contentType?.sys?.id === 'deviceDocument';
}

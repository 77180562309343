import {ImageSourcePropType} from 'react-native';

import {
  multiColorOpalBackground2Color1,
  multiColorOpalBackground2Color2,
  multiColorOpalBackground3Color1,
  multiColorOpalBackground3Color2,
  multiColorOpalBackground3Color3,
  multiColorOpalBackground4Color1,
  multiColorOpalBackground4Color2,
  multiColorOpalBackground4Color3,
  multiColorOpalBackground4Color4,
  multiColorOpalBackground5Color1,
  multiColorOpalBackground5Color2,
  multiColorOpalBackground5Color3,
  multiColorOpalBackground5Color4,
  multiColorOpalBackground5Color5,
  multiColorOpalBackground6Color1,
  multiColorOpalBackground6Color2,
  multiColorOpalBackground6Color3,
  multiColorOpalBackground6Color4,
  multiColorOpalBackground6Color5,
  multiColorOpalBackground6Color6,
} from '../assets/images';

export const MULTI_COLOR_OPAL_BACKGROUND_SOURCES = [
  [],
  [],
  [multiColorOpalBackground2Color1, multiColorOpalBackground2Color2],
  [
    multiColorOpalBackground3Color1,
    multiColorOpalBackground3Color2,
    multiColorOpalBackground3Color3,
  ],
  [
    multiColorOpalBackground4Color1,
    multiColorOpalBackground4Color2,
    multiColorOpalBackground4Color3,
    multiColorOpalBackground4Color4,
  ],
  [
    multiColorOpalBackground5Color1,
    multiColorOpalBackground5Color2,
    multiColorOpalBackground5Color3,
    multiColorOpalBackground5Color4,
    multiColorOpalBackground5Color5,
  ],
  [
    multiColorOpalBackground6Color1,
    multiColorOpalBackground6Color2,
    multiColorOpalBackground6Color3,
    multiColorOpalBackground6Color4,
    multiColorOpalBackground6Color5,
    multiColorOpalBackground6Color6,
  ],
] as ImageSourcePropType[][];

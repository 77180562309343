import {ConnectedDevice, Pikaparam, revisionNumberToString} from 'pikaparam';

import {ConnectionMetadata} from './ConnectionMetadata';
import {FirmwareType, IPeakDevice} from './PeakDevice/IPeakDevice';
import {PeakFlatDevice} from './PeakDevice/PeakFlatDevice';
import {PeakLoraxDevice} from './PeakDevice/PeakLoraxDevice';
import {PeakLoraxPeachAFDevice} from './PeakDevice/PeakLoraxPeachAFDevice';

export interface CreatePeakDeviceOptions {
  device: ConnectedDevice;
  pikaparam: Pikaparam;
  metadata: ConnectionMetadata;
}

export class PeakDeviceFactory {
  public static async create({
    device,
    pikaparam,
    metadata,
  }: CreatePeakDeviceOptions): Promise<IPeakDevice> {
    const info = pikaparam.devInfo.info;

    if (!info) throw new Error(`Version is missing. Peak creation aborted.`);

    const firmwareType = pikaparam.conn.isLoraxConnected
      ? FirmwareType.Lorax
      : FirmwareType.Flat;

    const softwareRevision = revisionNumberToString(info.apiVersion);

    const o = {device, pikaparam, softwareRevision, metadata};

    switch (firmwareType) {
      case FirmwareType.Flat:
        return new PeakFlatDevice(o);
      case FirmwareType.Lorax: {
        if (info.features.includes('led-api-3'))
          return new PeakLoraxPeachAFDevice(o);

        return new PeakLoraxDevice(o);
      }
    }

    throw new Error(`Unknown firmware type: ${firmwareType}`);
  }
}

import React from 'react';

export const useTimeoutTriggerFn = (fn: () => void, ms: number) => {
  const timerId = React.useRef<NodeJS.Timeout>();

  const trigger = React.useCallback(() => {
    if (timerId.current) clearTimeout(timerId.current);

    timerId.current = setTimeout(fn, ms);
  }, [fn, ms]);

  const clear = React.useCallback(() => {
    if (timerId.current) clearTimeout(timerId.current);
  }, []);

  return React.useMemo(() => ({trigger, clear}), [trigger, clear]);
};

import {Constants, appColors} from '../constants';
import {convertRgbArrayToHex} from './convertRgbArrayToHex';
import {mixColor} from './utilityFunctions';

export const convertHslToHex = (
  h: number,
  saturation?: number,
  lightness?: number,
  colorCreatedWithSlider?: boolean,
) => {
  const {whiteArea} = Constants.SLIDER_WHITE_VALUES;
  const {saturation: stdS, lightness: stdL} = Constants.STD_HSL_VALUES;
  const hue: number = h;

  if (colorCreatedWithSlider && hue > whiteArea) {
    return appColors.white;
  }
  h = h * 360;
  const s = saturation ? saturation : stdS,
    l = lightness ? lightness : stdL,
    c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  let hex = convertRgbArrayToHex([r, g, b]).toUpperCase();

  if (colorCreatedWithSlider) {
    const blendRange = [330, 345];
    if (blendRange[0] <= h && h <= blendRange[1]) {
      hex = mixColor({
        colorA: hex,
        colorB: appColors.white,
        percentage: (h - blendRange[0]) / (blendRange[1] - blendRange[0]),
      });
    } else if (blendRange[1] < h && h < 360) {
      hex = appColors.white;
    }
  }
  return hex;
};

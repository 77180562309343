import * as Sentry from '@sentry/react';
import type {Primitive, TransactionContext} from '@sentry/types';
import Constants from 'expo-constants';

import {Environment} from '../constants';

Sentry.init({
  dsn: Environment.sentry.dsn,
  environment: Environment.environment,
  release: Environment.version,
  sampleRate: Number(Environment.sentry.sampleRate),
  tracesSampleRate: Number(Environment.sentry.sampleRate),
  enableTracing: true,
  tracePropagationTargets: [Environment.apiUrl],
  integrations: [
    ...Sentry.getDefaultIntegrations({
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.httpContextIntegration(),
      ],
    }),
  ],
});

interface SentryUser {
  id: number;
  email: string;
}

export const ErrorBoundary = Sentry.ErrorBoundary;

export const setUser = (user?: SentryUser) => {
  Sentry.setUser(user ? {id: user.id.toString(), email: user.email} : null);
};

export const registerAppContainer = () => {};

// TODO: remove this once we update the @sentry/react-native package
export const configureScope = (callback: (scope: Sentry.Scope) => void) => {
  callback(Sentry.getCurrentScope());
};

export const captureError = (error: Error) => {
  configureScope(scope => {
    scope.setTag('sessionId', Constants.sessionId);
    Sentry.captureException(error);
  });
};

export const startSpan = <T>(
  ctx: TransactionContext,
  callback: (scope?: Sentry.Scope) => Promise<T>,
) => {
  return Sentry.startSpan(ctx, () => callback(Sentry.getCurrentScope()));
};

export const setDevice = (
  scope: Sentry.Scope | undefined,
  device: Record<string, Primitive>,
) => {
  scope?.setTags(
    Object.fromEntries(
      Object.entries(device).map(([key, value]) => [`peak.${key}`, value]),
    ),
  );

  scope?.setContext('Connected Device', device);
};

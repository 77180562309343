export const Environment = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  apiUrl: process.env.REACT_API_URL!,
  hostUrl: process.env.REACT_APP_URL!,
  version: process.env.REACT_APP_VERSION,
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
  },
  apple: {
    clientId: process.env.REACT_APP_APPLE_CLIENT_ID!,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    sampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  },
  contentful: {
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID,
    spaceId: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  },
  firebase: {
    // This property is used to initialize firebase in Path Browser.
    // Because of that we need to use an iOS app as an appId. Otherwise, Path Browser will crash
    pathBrowser: {
      appId: '1:924079499832:ios:2ae6fcd70b048649ec54cd',
    },
    app: {
      apiKey: 'AIzaSyAn1PHzNwYc74qq4Nh_maoyKndWY7Sung0',
      projectId: 'puffco-pikachu',
      messagingSenderId: '924079499832',
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
  },
};

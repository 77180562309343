import React from 'react';
import {FlatList, View} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';

export interface CustomMenuListProps {
  menuItemValues: number[];
  renderItem: any;
  extraData?: number[];
}

export const CustomMenuList = ({
  menuItemValues,
  renderItem,
  extraData,
}: CustomMenuListProps) => {
  return (
    <Container>
      <FlatListContainer
        data={menuItemValues}
        renderItem={renderItem}
        extraData={extraData}
        keyExtractor={(item: any) => item}
        ItemSeparatorComponent={ButtonSeparator}
      />
    </Container>
  );
};

const ButtonSeparator = () => (
  <View style={{height: 1, backgroundColor: appColors.greySettings20}} />
);

const Container = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const FlatListContainer = styled(FlatList)({
  display: 'flex',
  backgroundColor: appColors.coal,
  flexDirection: 'column',
  width: '88%',
  borderRadius: 6,
  marginTop: 5,
});

import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {ChamberType, Store} from '../types';

const viewedChamberTypesSlice = createSlice({
  name: 'viewedChamberTypes',
  initialState: [] as ChamberType[],
  reducers: {
    addViewedChamberType(state, action: PayloadAction<ChamberType>) {
      const id = action.payload;
      !state.includes(id) && state.push(id);
    },
  },
});

export const viewedChamberTypesSelector = (state: Store) =>
  state.viewedChamberTypes;

export const {addViewedChamberType} = viewedChamberTypesSlice.actions;

export const viewedChamberTypesReducer = viewedChamberTypesSlice.reducer;

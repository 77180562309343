import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';

import {ImgBackground, StyledIcon} from '../../components';
import {Screens, appColors} from '../../constants';
import {updateUser} from '../../lib/api';
import {useAppDispatch} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  appSettingsSelector,
  updateAppSettings,
} from '../../lib/redux/appSettingsSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.TemperatureSettings
>;

export const TemperatureSettingsScreen = (_props: ScreenProps) => {
  useAdaptiveSafeArea();

  const dispatch = useAppDispatch();

  const settings = useSelector(appSettingsSelector);
  const user = useSelector(userSelector);

  const handleSelection = React.useCallback(
    async (tempPreference: TemperatureUnit) => {
      if (settings.tempPreference === tempPreference) return;

      dispatch(updateAppSettings({...settings, tempPreference}));

      if (user) updateUser({tempPreference});
    },
    [user, settings],
  );

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <SectionContainer>
          <TemperatureSectionContainer>
            <SectionItem
              onPress={() => handleSelection(TemperatureUnit.Fahrenheit)}>
              <SectionHeader>FAHRENHEIT</SectionHeader>
              <StyledIcon
                name={
                  settings.tempPreference === TemperatureUnit.Fahrenheit
                    ? 'filledCheckBox'
                    : 'emptyCheckBox'
                }
              />
            </SectionItem>

            <SectionItem
              onPress={() => handleSelection(TemperatureUnit.Celsius)}>
              <SectionHeader>CELSIUS</SectionHeader>
              <StyledIcon
                name={
                  settings.tempPreference === TemperatureUnit.Celsius
                    ? 'filledCheckBox'
                    : 'emptyCheckBox'
                }
              />
            </SectionItem>
          </TemperatureSectionContainer>
        </SectionContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const SectionContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 30,
});

const TemperatureSectionContainer = styled(View)({
  borderBottomWidth: 1,
  borderTopWidth: 1,
  borderColor: appColors.greySettings10,
  flexDirection: 'column',
  height: 88,
  marginTop: 64,
});

const SectionItem = styled(TouchableOpacity)({
  borderBottomWidth: 1,
  borderColor: appColors.greySettings20,
  flexDirection: 'row',
  height: 44,
  justifyContent: 'space-between',
});

const SectionHeader = styled(Text)({
  alignSelf: 'center',
  color: appColors.baseText,
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  marginLeft: 18,
  fontWeight: '400',
});

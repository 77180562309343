import {Buffer} from 'buffer';

import {KeyEntry, Led3Config, Led3Value, ProjectorLampParams} from '../types';
import {convertHexStringToNumArray} from './convertHexStringToNumArray';
import {math} from './math';

function userColorsToArray(userColors: string | string[]) {
  let colors: string[] = [];
  if (Array.isArray(userColors)) {
    colors = userColors;
  }
  if (typeof userColors === 'string') {
    if (userColors.includes(',')) {
      colors = userColors.split(',');
    } else {
      colors = [userColors];
    }
  }

  // make sure there are no #s
  colors = colors.map(color => color.replace('#', ''));

  return colors;
}

export const convertMoodLightToProjector: (
  moodLightValues: Led3Value[],
  led3Config: Led3Config,
) => {params: any; projector: ProjectorLampParams} = (
  moodLightValues,
  led3Config,
) => {
  // Mappings
  const mappings = led3Config.mappings;

  const scope = moodLightValues.reduce(
    (acc: {[x: string]: string | string[] | number | undefined}, cur) => {
      acc[cur.key] =
        cur.key === 'userColors'
          ? userColorsToArray(
              typeof cur.value === 'string' || Array.isArray(cur.value)
                ? cur.value
                : [],
            )
          : cur.value;
      return acc;
    },
    {},
  );

  const inputParams = {...scope};
  Object.keys(mappings).forEach(key => {
    math.evaluate(`${key} = ${mappings[key]}`, scope);
  });

  const params = Object.keys(scope).reduce((acc: {[x: string]: any}, cur) => {
    acc[cur] = scope[cur]?.valueOf();
    return acc;
  }, {});

  // projection
  const projectionTemplateParam: {[x: string]: any} =
    led3Config.projector.param;

  const projections = Object.keys(projectionTemplateParam).reduce(
    (acc: {[x: string]: any}, cur) => {
      if (
        typeof projectionTemplateParam[cur] === 'object' &&
        !Array.isArray(projectionTemplateParam[cur])
      ) {
        const keyEntry = projectionTemplateParam[cur] as KeyEntry;
        if (keyEntry._format && keyEntry._format === 'rgbArray') {
          // rgbArray is one long continuous buffer
          acc[cur] = Buffer.from(
            new Uint8Array(
              convertHexStringToNumArray(params[keyEntry._key].join('')),
            ),
          );
        } else {
          acc[cur] = params[keyEntry._key];
        }
      } else acc[cur] = projectionTemplateParam[cur];

      return acc;
    },
    {},
  );

  // Filter out params that are not needed
  const deleteKeys = ['variant'];
  for (const key of deleteKeys) {
    delete inputParams[key];
  }

  return {params: inputParams, projector: projections as ProjectorLampParams};
};

import React from 'react';
import {ViewProps} from 'react-native';
import {interpolate} from 'react-native-reanimated';

import {Strings} from '../../constants';
import {HorizontalSlider} from '../components/HorizontalSlider';
import {useSyncedSharedValue} from '../components/Slider';
import {
  SliderContainer,
  SliderValue,
  SliderValues,
} from '../components/SliderContainer';

interface Props extends ViewProps {
  value: number;
  min: number;
  max: number;
  onEnd(value: number): void;
}

const timeFormat = (val: number) =>
  `${Math.floor(val / 60)}:${`${val % 60}`.padStart(2, '0')}`;

const fromValue = (value: number, min: number, max: number) =>
  Math.round(interpolate(value, [0, 1], [min, max]));
const toValue = (value: number, min: number, max: number) =>
  interpolate(value, [min, max], [0, 1]);

export const DurationSlider = ({
  value: initialValue,
  min,
  max,
  style,
  onEnd,
}: Props) => {
  const [duration, setDuration] = React.useState(initialValue);

  const value = useSyncedSharedValue(
    initialValue,
    value => toValue(value, min, max),
    [initialValue, min, max],
  );

  return (
    <SliderContainer {...{style}}>
      <SliderValues>
        <SliderValue highlighted>{Strings.SLIDER.DURATION}</SliderValue>
        <SliderValue highlighted>{timeFormat(duration)}</SliderValue>
      </SliderValues>

      <HorizontalSlider
        {...{value}}
        onChange={value => setDuration(fromValue(value, min, max))}
        onEnd={value => onEnd?.(fromValue(value, min, max))}
      />

      <SliderValues>
        <SliderValue>{timeFormat(min)}</SliderValue>
        <SliderValue>{timeFormat(max)}</SliderValue>
      </SliderValues>
    </SliderContainer>
  );
};

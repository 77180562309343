import type {Asset, Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeVideoFields {
  title: EntryFields.Symbol;
  thumbnailImage: Asset;
  link: EntryFields.Symbol;
}

export type TypeVideo = Entry<TypeVideoFields>;

export function isTypeVideo(entry: WithContentTypeLink): entry is TypeVideo {
  return entry?.sys?.contentType?.sys?.id === 'video';
}

import React from 'react';
import {StyleProp, StyleSheet, Text, View, ViewStyle} from 'react-native';

import {StyledIcon} from '../../components';
import {Messages, appColors} from '../../constants';

interface Props {
  isMeetAC: boolean;
  is3dChamber: boolean;
  isVaporEnabled: boolean;
  style?: StyleProp<ViewStyle>;
}

export const VaporControlUpsellText = ({
  isMeetAC,
  is3dChamber,
  isVaporEnabled,
  style,
}: Props) => {
  const getVaporSubText = React.useCallback(() => {
    if (!isMeetAC && !is3dChamber) {
      return Messages.HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_CHAMBER_AND_FIRMWARE;
    } else if (!is3dChamber) {
      return Messages.HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_CHAMBER;
    }

    return Messages.HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_FIRMWARE;
  }, [isMeetAC, is3dChamber]);

  return (
    <View style={[styles.container, style]}>
      <StyledIcon
        size={15}
        name="infoOutline"
        color={!isVaporEnabled ? appColors.white50 : appColors.black}
      />

      {!isVaporEnabled && <Text style={styles.text}>{getVaporSubText()}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -10,
    width: '90%',
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    fontSize: 12,
    color: appColors.white,
  },
});

import React from 'react';

export type LinearGradientProps = {
  colors: (string | number)[];
  start?: {x: number; y: number};
  end?: {x: number; y: number};
  locations?: number[];
  useAngle?: boolean;
  angleCenter?: {x: number; y: number};
  angle?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const LinearGradient = ({
  colors,
  angle,
  useAngle,
  locations,
  children,
  style,
}: React.PropsWithChildren<LinearGradientProps>) => (
  <div
    {...{children}}
    style={{
      ...(Array.isArray(style)
        ? // Style might be an array which is not supported on divs, so we merge it
          style.reduce((s, v) => ({...s, ...v}), {})
        : style),
      background: `linear-gradient(${
        useAngle && angle !== undefined ? `${angle}deg, ` : ''
      }${colors.reduce((accumulator, color, index) => {
        const location = (index: number) =>
          (locations &&
            index <= locations.length &&
            ` ${locations[index] * 100}%`) ??
          '';
        return `${accumulator}${
          index === 1 ? location(0) : ''
        }, ${color}${location(index)}`;
      })})`,
    }}
  />
);

import React from 'react';
import {Dimensions, StyleSheet} from 'react-native';
import {PdfProps} from 'react-native-pdf';

import {appColors} from '../constants';
import {PdfView} from '../shims/PdfView';
import {SafeAreaView} from '../shims/SafeAreaView';

export interface ManualPdfProps extends Pick<PdfProps, 'source'> {}

export const ManualPdf = (props: ManualPdfProps) => {
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: appColors.baseBackground}}>
      <PdfView source={props.source} trustAllCerts={false} style={styles.pdf} />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  pdf: {
    flex: 1,
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
});

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {currentDeviceIdSelector, updateDeviceSettings} from '../redux/bleSlice';
import {updateTempProfile} from '../redux/profilesSlice';
import {Profile} from '../types';

export const useUpdateDabbingReduxValues = () => {
  const deviceId = useSelector(currentDeviceIdSelector);
  const dispatch = useDispatch();

  return React.useCallback(
    (profile: Profile) => {
      if (deviceId !== undefined && deviceId !== null) {
        dispatch(
          updateDeviceSettings({
            id: deviceId,
            settings: {
              targetTemp: profile.temperature,
              stateTotalTime: profile.duration,
            },
          }),
        );
      }
      dispatch(updateTempProfile(profile));
    },
    [deviceId],
  );
};

import React from 'react';
import {Text, TextStyle, View, ViewProps, ViewStyle} from 'react-native';

import styled from '../lib/styled';
import {useTheme} from '../lib/useTheme';

export type SectionTitleProps = {
  title: string;
  titleStyle?: TextStyle;
  fillerStyle?: ViewStyle;
} & ViewProps;

export const SectionTitle = ({
  title,
  style,
  titleStyle,
  fillerStyle,
  ...rest
}: SectionTitleProps) => {
  const {sectionTitleTextColor, sectionDividerColor} = useTheme();

  return (
    <Container style={style} {...rest}>
      <Title
        style={{
          color: sectionTitleTextColor,
          ...titleStyle,
        }}>
        {title}
      </Title>
      <Filler style={{backgroundColor: sectionDividerColor, ...fillerStyle}} />
    </Container>
  );
};

const Container = styled(View)({
  flexDirection: 'row',
  marginHorizontal: 12,
  marginVertical: 12,
});

const Title = styled(Text)({
  alignSelf: 'center',
  fontSize: 12,
  marginRight: 11,
  fontFamily: 'BigShouldersDisplay-Bold',
  letterSpacing: 0.6,
  textTransform: 'uppercase',
  fontWeight: '400',
});

const Filler = styled(View)({
  flex: 1,
  alignSelf: 'center',
  height: 1,
});

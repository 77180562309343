import type {Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeTagFields {
  name: EntryFields.Symbol;
}

export type TypeTag = Entry<TypeTagFields>;

export function isTypeTag(entry: WithContentTypeLink): entry is TypeTag {
  return entry?.sys?.contentType?.sys?.id === 'tag';
}

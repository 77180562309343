import {LogNamespace, analyticsLog, log} from '../../../lib/Logger';
import {formatType} from './common';
import {EventProperties, EventType} from './event-properties';
import {Analytics} from './types';

export class LogAnalytics implements Analytics {
  private logger: typeof analyticsLog;

  constructor() {
    this.logger = analyticsLog;
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E]): void {
    this.logger.info(formatType(type), event);
  }

  setCurrentUser(): void {}

  trackUser() {}

  setEnabled(enabled: boolean): void {
    const callback = enabled ? log.enable.bind(log) : log.disable.bind(log);

    callback(LogNamespace.Analytics);
  }
}

import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';

import {LayeredBackground, LinearGradientBackground} from '../components';
import {appColors, fillStyle} from '../constants';
import {GradientShadeFunction} from '../lib/types';
import {addOpacityToColorHex} from '../lib/utilityFunctions';
import {LinearGradientProps} from '../shims/LinearGradient';

export type MultiGradientBackgroundProps = {
  colors: string[];
  gradientShadeFunction: GradientShadeFunction;
  BaseBackground?: React.FC<ViewProps>;
  gradientLayerOpacity?: number;
  angles?: number[] | undefined;
} & ViewProps;

export const MultiGradientBackground = ({
  colors,
  gradientShadeFunction,
  gradientLayerOpacity,
  BaseBackground = DefaultBaseBackground,
  children,
  style = {},
  angles,
  ...rest
}: React.PropsWithChildren<MultiGradientBackgroundProps>) => {
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const {width: layersWidth, height: layersHeight} = StyleSheet.flatten(style);
  const w = typeof layersWidth === 'number' ? layersWidth : width;
  const h = typeof layersHeight === 'number' ? layersHeight : height;

  const {startAngles, displayColors} = React.useMemo(() => {
    const startAngles = getStartAnglesForColors(colors, w, h, angles);
    const displayColors =
      colors.length === 1
        ? gradientShadeFunction(colors[0])
        : colors.length === 2 && !angles
          ? [colors[1], colors[0]]
          : colors;
    return {startAngles, displayColors};
  }, [colors, w, h, angles, gradientShadeFunction]);

  return (
    <LayeredBackground
      backgrounds={[
        {element: <BaseBackground />},
        ...(colors.length === 1
          ? [
              {
                element: (
                  <LinearGradientBackground
                    {...{
                      colors: displayColors,
                      useAngle: true,
                      angle: 180,
                      angleCenter: {x: 0.5, y: 0.5},
                    }}
                  />
                ),
              },
            ]
          : displayColors.map((color, index) => ({
              linearGradientProps: {
                locations: [0, 0.7, 1],
                colors: [
                  color,
                  addOpacityToColorHex(color, 0.1),
                  addOpacityToColorHex(color, 0),
                ],
                angle: startAngles[index],
                useAngle: true,
                angleCenter: {x: 0.5, y: 0.5},
                onLayout: layoutChangeEvent => {
                  const {width, height} = layoutChangeEvent.nativeEvent.layout;
                  setWidth(width);
                  setHeight(height);
                },
                style: {
                  opacity:
                    gradientLayerOpacity ??
                    (index === 0 && displayColors.length < 5
                      ? 0.8
                      : (1 +
                          (displayColors.length < 2
                            ? 2
                            : displayColors.length - 2) *
                            0.15) /
                        displayColors.length),
                },
              } as LinearGradientProps,
            }))),
      ]}
      style={style}
      {...rest}>
      {children}
    </LayeredBackground>
  );
};

const DefaultBaseBackground = ({
  children,
  style,
  ...rest
}: React.PropsWithChildren<ViewProps>) => (
  <View
    style={[{...fillStyle, backgroundColor: appColors.black}, style]}
    {...rest}>
    {children}
  </View>
);

const getStartAnglesForColors = (
  clrs: string[],
  w: number,
  h: number,
  angles?: number[] | undefined,
) => {
  if (angles && angles.length === clrs.length) {
    return angles;
  }
  const anglesForColors = [];
  for (let i = 0; i < clrs.length; i++) {
    const trueAngle = (360 / clrs.length) * i;
    anglesForColors.push(calcAdjustedAngle(trueAngle, w, h));
  }
  return anglesForColors;
};

const calcAdjustedAngle = (
  trueAngle: number,
  width: number,
  height: number,
) => {
  let adjustedAngle = trueAngle;
  if (height > width) {
    const adjustmentFactor = height / (height + width);
    const startingAngle = Math.atan(width / 2 / (height / 2)) * (180 / Math.PI);
    if (
      (trueAngle > startingAngle && trueAngle < 90) ||
      (trueAngle > 180 + startingAngle && trueAngle < 270)
    ) {
      adjustedAngle =
        trueAngle +
        ((trueAngle < 90 ? 90 : 270) - trueAngle) * adjustmentFactor;
    } else if (
      (trueAngle > 90 && trueAngle < 180 - startingAngle) ||
      (trueAngle > 270 && trueAngle < 360 - startingAngle)
    ) {
      adjustedAngle =
        trueAngle -
        (trueAngle - (trueAngle < 180 ? 90 : 270)) * adjustmentFactor;
    }
  } else if (height < width) {
    const adjustmentFactor = width / (height + width);
    const startingAngle = Math.atan(width / 2 / (height / 2)) * (180 / Math.PI);
    if (
      (trueAngle > 180 - startingAngle && trueAngle < 180) ||
      (trueAngle > 360 - startingAngle && trueAngle < 360)
    ) {
      adjustedAngle =
        trueAngle +
        ((trueAngle < 180 ? 180 : 360) - trueAngle) * adjustmentFactor;
    } else if (
      (trueAngle > 0 && trueAngle < startingAngle) ||
      (trueAngle > 180 && trueAngle < 180 + startingAngle)
    ) {
      adjustedAngle =
        trueAngle -
        (trueAngle - (trueAngle < 180 ? 0 : 180)) * adjustmentFactor;
    }
  }
  return adjustedAngle;
};

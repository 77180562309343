import type {
  BackHandler as IBackHandler,
  NativeEventSubscription,
} from 'react-native';

export const BackHandler: IBackHandler = {
  exitApp() {
    // noop
  },

  addEventListener(): NativeEventSubscription {
    // noop
    return {remove: () => void 0};
  },

  removeEventListener() {
    // noop
  },
};

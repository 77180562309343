import {appLog} from '../../../lib/Logger';
import {DisposableCallback} from './DisposableCallback';

export interface MessagePayloadMap {
  url: {url: string};
}

export type MessagePayload<T extends keyof MessagePayloadMap> = {
  type: T;
} & MessagePayloadMap[T];

export type MessageType = 'open' | 'foreground' | 'background';

export interface MessageNotification {
  title?: string;
  body?: string;
}

export interface Message {
  notification?: MessageNotification;
  data?: object;
}

export interface IPushService {
  getInitialUrl(): Promise<string | undefined>;
  getToken(): Promise<string | undefined>;
  onTokenUpdated(callback: (token: string) => void): () => void;
  onAppOpen(callback: (url: string) => void): () => void;
  deleteToken(): Promise<void>;
  supported(): boolean;
  enabled(): Promise<boolean>;
  openSettings(): Promise<void>;
}

export abstract class PushService<T> {
  protected onAppOpenCallback = new DisposableCallback<string>();

  protected abstract getMessage(notification: T): Message | undefined;

  onAppOpen(callback: (url: string) => void): () => void {
    return this.onAppOpenCallback.register(callback);
  }

  protected async onMessage(
    message: Message | undefined,
    type: MessageType,
  ): Promise<void> {
    appLog.info('Push message received.', {
      type,
      message: JSON.parse(JSON.stringify(message)),
    });
  }

  protected isValidPayload(
    payload: unknown,
  ): payload is MessagePayload<keyof MessagePayloadMap> {
    return (
      !!payload &&
      typeof payload === 'object' &&
      'type' in payload &&
      typeof payload.type === 'string'
    );
  }

  protected getUrlFromPayload(payload: unknown): string | undefined {
    if (!this.isValidPayload(payload)) return;

    switch (payload.type) {
      case 'url' as const: {
        const url = (payload as MessagePayload<'url'>).url;

        if (typeof url !== 'string') return;

        return url;
      }
    }
  }
}

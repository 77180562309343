import React from 'react';
import {ViewStyle} from 'react-native';

import {googleLogo} from '../../assets/images';
import {StyledButton} from '../../components';
import {Messages, appColors} from '../../constants';
import {appLog} from '../../lib/Logger';
import {useGoogleLogin} from '../../lib/auth/google/useGoogleLogin';

interface Props {
  title?: string;
  disabled?: boolean;
  screen: 'login' | 'register';
  buttonStyle?: ViewStyle;
  onSubmit(code: string): void;
}

export const GoogleSignIn: React.FC<Props> = ({
  title,
  disabled,
  screen,
  buttonStyle,
  onSubmit,
}) => {
  const initiate = useGoogleLogin({state: screen});

  const onPress = React.useCallback(async () => {
    try {
      const code = await initiate();

      onSubmit(code);
    } catch (error) {
      appLog.error(`Failed to login with Google.`, {error});
    }
  }, [initiate, onSubmit]);

  return (
    <StyledButton
      title={title ?? Messages.CONTINUE_WITH_GOOGLE}
      style={{
        backgroundColor: appColors.white,
        width: '100%',
        borderWidth: 1,
        borderColor: appColors.fieldDivider,
        ...buttonStyle,
      }}
      textStyle={{
        color: appColors.black,
        fontFamily: 'Roboto-Bold',
        fontWeight: '400',
      }}
      buttonIcon={googleLogo}
      {...{onPress, disabled}}
    />
  );
};

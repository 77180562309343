import {Text} from 'react-native';

import {appColors} from '../../../constants';
import styled from '../../../lib/styled';

export const HeaderText = styled(Text)({
  color: appColors.baseText,
  fontFamily: 'Roboto-Bold',
  fontSize: 20,
  lineHeight: 25,
  fontWeight: '400',
});

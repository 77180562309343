import React from 'react';
import {ImageSourcePropType, Text, TouchableOpacity, View} from 'react-native';

import {ImgBackground} from '../../components';
import {appColors} from '../../constants';
import styled from '../../lib/styled';

export interface Props {
  background: ImageSourcePropType;
  title: string;
  body: string;
  buttonTitle?: string;
  onPress(): void;
}

export const DesktopConfirmationScreen: React.FC<Props> = ({
  background: source,
  title,
  body,
  onPress,
  buttonTitle,
}) => (
  <Background {...{source}}>
    <Container>
      <Header>{title}</Header>

      <Body>{body}</Body>

      <Button {...{onPress}}>
        <ButtonText>{buttonTitle || 'Got it!'}</ButtonText>
      </Button>
    </Container>
  </Background>
);

const Background = styled(ImgBackground)({
  flex: 1,
  flexBasis: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const Container = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  gap: 24,
  flex: 0.5,
  maxWidth: 400,
});

const Header = styled(Text)({
  textAlign: 'center',
  color: appColors.baseText,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 32,
  fontWeight: '100',
  textTransform: 'uppercase',
  letterSpacing: 0.75,
});

const Body = styled(Text)({
  textAlign: 'center',
  color: appColors.baseText,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 16,
  letterSpacing: 0.25,
});

const Button = styled(TouchableOpacity)({
  backgroundColor: appColors.baseText,
  borderRadius: 25,
  height: 50,
  textAlign: 'center',
  justifyContent: 'center',
  letterSpacing: 0.75,
  width: 320,
});

export const ButtonText = styled(Text)({
  textAlign: 'center',
  color: appColors.black,
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
  fontSize: 16,
  textTransform: 'uppercase',
  letterSpacing: 0.75,
});

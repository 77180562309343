import {Platform} from 'react-native';
import {useSafeArea as rnsacUseSafeArea} from 'react-native-safe-area-context';

import {Constants} from '../constants/Constants';

const isWeb = Platform.OS === 'web';

export function useSafeArea() {
  const nativeInsets = rnsacUseSafeArea();

  // Get safe area insets from css min properties
  const top = isWeb
    ? parseInt(
        getComputedStyle(document.documentElement)
          .getPropertyValue('min-height')
          .slice(0, -2) ?? Constants.SAFE_AREA_INSET_TOP,
      )
    : nativeInsets?.top;
  const bottom = isWeb
    ? parseInt(
        getComputedStyle(document.documentElement)
          .getPropertyValue('min-width')
          .slice(0, -2) ?? Constants.SAFE_AREA_INSET_BOTTOM,
      )
    : nativeInsets?.bottom;

  return {
    bottom: bottom,
    left: Constants.SAFE_AREA_INSET_LEFT,
    right: Constants.SAFE_AREA_INSET_RIGHT,
    top: top,
  };
}

import {
  CardStyleInterpolators,
  HeaderStyleInterpolators,
  TransitionSpecs,
} from '@react-navigation/stack';

/**
 * Custom iOS navigation transition for modals.
 */

// TransitionSpec
const specOpen: any = {
  animation: 'spring',
  config: {
    ...TransitionSpecs.TransitionIOSSpec.config,
    mass: 3,
  },
};

// TransitionSpec
const specClose: any = {
  animation: 'spring',
  config: {
    ...TransitionSpecs.TransitionIOSSpec.config,
    mass: 20,
  },
};

// TransitionPreset
export const CustomModalSlideFromBottomIOS: any = {
  gestureDirection: 'vertical',
  transitionSpec: {
    open: specOpen,
    close: specClose,
  },
  cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
  headerStyleInterpolator: HeaderStyleInterpolators.forFade,
};

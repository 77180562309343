import React from 'react';
import {Image, ImageSourcePropType} from 'react-native';

import {Constants} from '../../constants';

export const usePrefetchImages = (urls: ImageSourcePropType[]) => {
  React.useEffect(() => {
    // Android / iOS assets don't need to be preloaded because they are bundled into the app
    if (!urls.length || !Constants.IS_WEB) return undefined;

    Promise.all(urls.map(image => Image.prefetch(image as string)));
  }, [urls]);
};

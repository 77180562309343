// @ts-nocheck
// TODO fix types
import React from 'react';
import {
  LayoutRectangle,
  PanResponder,
  PanResponderInstance,
  SectionList,
  SectionListData,
  SectionListProps,
  SectionListRenderItem,
  SectionListRenderItemInfo,
  TouchableWithoutFeedbackProps,
  View,
} from 'react-native';

import {appLog} from '../lib/Logger';
import {defaultTheme} from '../themes';

export type RenderItemProps<T> = {
  info: SectionListRenderItemInfo<T>;
  startDrag: () => void;
  endDrag: () => void;
};

export type SwappableSectionListRenderItem<T> = (
  props: RenderItemProps<T>,
) => React.ReactElement | null;

export type InsertionMapping = {
  from: string;
  to: string;
};

export type SwappableSectionListProps<T> = Omit<
  SectionListProps<T>,
  'renderItem'
> &
  TouchableWithoutFeedbackProps & {
    /*
     * Same renderItem as Sectionlist, but call startDrag and endDrag with whatever mechanism you wish, otherwise drag and drop will not work.
     */
    renderItem: SwappableSectionListRenderItem<T>;
    /*
     * Called if change is a replacement.
     * Swapping/replacing after animation is not automatic, the data prop must be modified to reflect these effects.
     * If the change was replacement (default), from and to will be the items relevant.
     * If the change was Insertion, to will be the item below the insertion divider.
     */
    onChange?: (from: SectionListData<T>, to: SectionListData<T>) => void;
    /*
     * How an item that is picked up will be rendered.
     * Need to specify startDrag and endDrag like renderItem.
     * Default to a wrapper with shadow effect for a normal renderItem.
     */
    renderHoverItem?: SwappableSectionListRenderItem<T>;
    /*
     * what will be rendered in place of the item that is picked up.
     * No need to specify startDrag and endDrag unlike renderItem and renderHoverItem.
     * Default to nothing.
     */
    renderFromItem?: SectionListRenderItem<T>;
    /*
     * what will be rendered for the item that is being dropped onto.
     * No need to specify startDrag and endDrag unlike renderItem and renderHoverItem.
     * Default to a gray highlight.
     */
    renderToItem?: SectionListRenderItem<T>;
    /*
     * Pass this in if the hoverItem is not where it should be,
     * Positive value will move it up more, negative will more it down,
     * Default to half of the item height.
     */
    forceOffset?: number;
    /*
     * If it is an insertion, what the divider to be rendered between items will look like.
     */
    renderInsertionDivider?: () => React.ReactElement | null;
    /*
     * The default render for dragging hovering item around is to replace,
     * If the section keys are supplied in this prop dragging from 'from' to 'to' will instead render renderInsertItem
     */
    insertionMapping?: InsertionMapping[];
    /*
     *
     * Flag that denoted list is being edited
     *
     */
    editingList: boolean;

    hoverBackgroundColor?: string;
  };

export type SwappableSectionListFunctionalComponent = <T extends object>(
  prop: SwappableSectionListProps<T>,
) => React.ReactElement<SwappableSectionListProps<T>>;

type ListLayout = {
  type: 'item' | 'sectionHeader' | 'sectionFooter' | 'header' | 'footer';
  height: number;
  width: number;
};

export const SwappableSectionList: SwappableSectionListFunctionalComponent =
  props => {
    const {
      renderItem,
      onChange,
      renderHoverItem,
      renderFromItem,
      renderToItem,
      sections,
      ListHeaderComponent,
      ListFooterComponent,
      renderSectionHeader,
      renderSectionFooter,
      forceOffset,
      insertionMapping,
      editingList,
      hoverBackgroundColor = defaultTheme.heatProfileListScreenTheme
        .hoverBackgroundColor,
    } = props;

    const [panResponder, setPanResponder] =
      React.useState<PanResponderInstance>();
    const [shouldIntercept, setShouldIntercept] = React.useState(false); // Prevent scrolling if handle is touched
    const [numOfActiveTouches, setNumOfActiveTouches] = React.useState(0);
    const scroll = React.useRef(0); // list content scroll offset
    const containerRef = React.useRef<View>(); // used for component.measure() && y coord. calculation
    const listTopOffset = React.useRef(0); // list top offset in relation to screen
    const listBottomOffset = React.useRef(0);
    const listItemOffset = React.useRef(0); // position of the touched handle to to of the item
    const listRef = React.useRef<SectionList<any>>();
    const scrollThrottle = React.useRef(false);
    const fromToIndices = React.useRef({
      from: {sectionIndex: null, itemIndex: null},
      to: {sectionIndex: null, itemIndex: null},
      isInsert: false,
    });

    const listLayoutRaw = React.useRef<LayoutRectangle[][]>([
      [],
      [],
      [],
      [],
      [],
    ]);
    const listLayout = React.useRef<ListLayout[]>([]);
    // items, sectionHeader, sectionFooter, header, footer layouts
    // Dynamically calculate which item in the list is being handled

    type HoverInfo = {
      info: SectionListRenderItemInfo<any>;
      y: number; // Location of the handle in list coordinates
      itemHeight: number;
    };

    const defaultHoverInfo = {
      info: null,
      y: null,
      itemHeight: null,
    };

    const [hoverInfo, setHoverInfo] =
      React.useState<HoverInfo>(defaultHoverInfo);

    const calculateListLayout = (): ListLayout[] => {
      const ret: ListLayout[] = [{type: 'header', height: 1, width: 1}];
      ret.push({
        type: 'header',
        ...listLayoutRaw.current[3][0],
      });
      let itemCount = 0;
      for (const [index, section] of sections.entries()) {
        listLayoutRaw.current[1][index] &&
          ret.push({
            type: 'sectionHeader',
            ...listLayoutRaw.current[1][index],
          });
        for (let i = section.data.length; i > 0; i--) {
          listLayoutRaw.current[0][itemCount] &&
            ret.push({
              type: 'item',
              ...listLayoutRaw.current[0][itemCount++],
            });
        }
        listLayoutRaw.current[2][index] &&
          ret.push({
            type: 'sectionFooter',
            ...listLayoutRaw.current[2][index],
          });
      }

      listLayoutRaw.current[4] &&
        ret.push({
          type: 'footer',
          ...listLayoutRaw.current[4][0],
        });

      ret.splice(0, 1);
      return ret;
    };

    const getItemInfoByTouchY = (
      y: number,
      isTo?: boolean,
    ): {
      info: SectionListRenderItemInfo<any>;
      itemHeight: number;
    } => {
      let i = 0;
      let pageY = y - listTopOffset.current;
      let sectionIndex = 0;
      let itemIndex = 0;

      while (i < listLayout.current.length) {
        pageY -= listLayout.current[i].height;
        if (
          listLayout.current[i].type === 'sectionFooter' &&
          listLayout.current[i + 1].type !== 'footer'
        ) {
          sectionIndex++;
          itemIndex = 0;
        }
        if (pageY <= 0) {
          if (listLayout.current[i].type === 'item') {
            listItemOffset.current = listLayout.current[i].height / 2;
            break;
          }
        }
        if (listLayout.current[i].type === 'footer') {
          pageY = 0;
          break;
        }
        if (listLayout.current[i].type === 'item') itemIndex++;
        i++;
      }

      if (sectionIndex >= sections.length) sectionIndex = sections.length - 1;

      if (itemIndex >= sections[sectionIndex].data.length)
        itemIndex = sections[sectionIndex].data.length - 1;

      isTo
        ? (fromToIndices.current.to = {sectionIndex, itemIndex})
        : (fromToIndices.current.from = {sectionIndex, itemIndex});
      if (
        isTo &&
        insertionMapping &&
        insertionMapping
          .filter(
            im =>
              im.from === sections[fromToIndices.current.from.sectionIndex].key,
          )
          .find(im => im.to === sections[sectionIndex].key)
      ) {
        fromToIndices.current.isInsert = true;
      } else {
        fromToIndices.current.isInsert = false;
      }

      return {
        info: {
          item: sections[sectionIndex].data[itemIndex],
          index: itemIndex,
          section: sections[sectionIndex],
          separators: null,
        },
        itemHeight: listLayout.current[i].height,
      };
    };

    const startDrag: RenderItemProps<any>['startDrag'] = () => {
      setShouldIntercept(true);
    };

    const endDrag: RenderItemProps<any>['endDrag'] = () => {
      setShouldIntercept(false);
    };

    React.useEffect(() => {
      setPanResponder(
        PanResponder.create({
          onStartShouldSetPanResponder: () => shouldIntercept,
          onMoveShouldSetPanResponder: () => shouldIntercept,
          onPanResponderTerminationRequest: () => false,
          onPanResponderGrant: (evt, gestureState) => {
            listLayout.current = calculateListLayout();
            setNumOfActiveTouches(gestureState.numberActiveTouches);

            setHoverInfo({
              ...getItemInfoByTouchY(evt.nativeEvent.pageY + scroll.current),
              y:
                evt.nativeEvent.pageY -
                listTopOffset.current -
                listItemOffset.current -
                (forceOffset === undefined ? 0 : forceOffset),
            });
          },
          onPanResponderMove: (evt, gestureState) => {
            setNumOfActiveTouches(gestureState.numberActiveTouches);

            setHoverInfo({
              ...hoverInfo,
              y:
                evt.nativeEvent.pageY -
                listTopOffset.current -
                listItemOffset.current -
                (forceOffset === undefined ? 0 : forceOffset),
            });
            getItemInfoByTouchY(evt.nativeEvent.pageY + scroll.current, true);
          },
          onPanResponderEnd: (_evt, gestureState) => {
            setNumOfActiveTouches(gestureState.numberActiveTouches);
            if (
              fromToIndices.current.from.sectionIndex !== null &&
              fromToIndices.current.to.sectionIndex !== null
            ) {
              onChange &&
                onChange(
                  {
                    ...sections[fromToIndices.current.from.sectionIndex],
                    data: [
                      sections[fromToIndices.current.from.sectionIndex].data[
                        fromToIndices.current.from.itemIndex
                      ],
                    ],
                  },
                  {
                    ...sections[fromToIndices.current.to.sectionIndex],
                    data: [
                      sections[fromToIndices.current.to.sectionIndex].data[
                        fromToIndices.current.to.itemIndex
                      ],
                    ],
                  },
                );
            }
            fromToIndices.current = {
              from: {sectionIndex: null, itemIndex: null},
              to: {sectionIndex: null, itemIndex: null},
              isInsert: false,
            };
            setHoverInfo({y: null, info: null, itemHeight: null});
          },
        }),
      );
    }, [shouldIntercept]);

    React.useEffect(() => {
      if (editingList) {
        measureContainer();
      }
    }, [editingList]);

    function measureContainer() {
      do {
        if (
          containerRef.current !== undefined &&
          containerRef.current !== null
        ) {
          containerRef.current.measure(
            (_x, _y, _width, height, _pageX, pageY) => {
              listTopOffset.current = pageY;
              listBottomOffset.current = pageY + height;
            },
          );
        } else {
          appLog.info(
            'ContainerRef is undefined or null...will try to measure again.',
          );
        }
      } while (
        listTopOffset.current == undefined ||
        listBottomOffset.current == undefined
      );
    }

    React.useEffect(() => {
      if (
        !scrollThrottle.current &&
        listRef.current &&
        fromToIndices.current.to.sectionIndex !== null
      ) {
        let sectionIndex = fromToIndices.current.to.sectionIndex;
        let itemIndex = fromToIndices.current.to.itemIndex;

        const scroll = () => {
          listRef.current.scrollToLocation({
            sectionIndex,
            itemIndex,
          });
          scrollThrottle.current = true;
          setTimeout(() => {
            scrollThrottle.current = false;
          }, 500);
        };
        if (hoverInfo.y < 0) {
          if (itemIndex > 0) {
            itemIndex--;
            scroll();
          } else if (sectionIndex > 0) {
            sectionIndex--;
            itemIndex = sections[sectionIndex].data.length;
            scroll();
          }
        } else if (
          hoverInfo.y >
          listBottomOffset.current - listTopOffset.current - 100
        ) {
          if (itemIndex < sections[sectionIndex].data.length - 1) {
            itemIndex++;
            scroll();
          } else if (sectionIndex < sections.length - 1) {
            sectionIndex++;
            itemIndex = 0;
            scroll();
          }
        }
      }
    }, [hoverInfo.y]);

    const renderItemWrapper = (
      props: RenderItemProps<any>,
      skipLayout?: boolean,
      hover?: boolean,
    ): React.ReactElement | null => {
      const hoveringInPlace =
        props.info.index === fromToIndices.current.from.itemIndex &&
        props.info.section.key ===
          sections[fromToIndices.current.from.sectionIndex].key &&
        !hover &&
        fromToIndices.current.from.sectionIndex !== null;

      if (hoveringInPlace) {
        return renderFromItem ? (
          renderFromItem(props.info)
        ) : (
          <View
            style={{height: hoverInfo.itemHeight}}
            {...panResponder.panHandlers}
          />
        );
      }

      const hoveringOverActives =
        !fromToIndices.current.isInsert &&
        props.info.index === fromToIndices.current.to.itemIndex &&
        props.info.section.key ===
          sections[fromToIndices.current.to.sectionIndex].key &&
        !hover &&
        fromToIndices.current.to.itemIndex !== null;

      const hoveringOverArchives =
        fromToIndices.current.isInsert &&
        props.info.index === fromToIndices.current.to.itemIndex &&
        props.info.section.key ===
          sections[fromToIndices.current.to.sectionIndex].key &&
        !hover &&
        fromToIndices.current.to.itemIndex !== null;

      if (hoveringOverActives || hoveringOverArchives) {
        return renderToItem ? (
          renderToItem(props.info)
        ) : (
          <View>
            <View style={{zIndex: 0}} {...panResponder.panHandlers}>
              {renderItem(props)}
            </View>
            <View
              style={{
                height: hoverInfo.itemHeight,
                marginTop: -hoverInfo.itemHeight,
                backgroundColor: hoverBackgroundColor,
                opacity: 0.2,
                zIndex: 1,
              }}
            />
          </View>
        );
      }

      if (hoveringOverArchives) {
        return (
          <View>
            <View style={{height: 0, overflow: 'visible'}}>
              <View
                style={{
                  transform: [{translateY: -2}],
                  height: 4,
                  backgroundColor: 'gray',
                }}
              />
            </View>
            {renderItem(props)}
          </View>
        );
      }
      if (props.info)
        return (
          <View
            onLayout={a => {
              if (!skipLayout)
                listLayoutRaw.current[0].push(a.nativeEvent.layout);
            }}
            {...panResponder.panHandlers}>
            {renderItem(props)}
          </View>
        );
    };

    const HoverItem = () => {
      if (hoverInfo && hoverInfo.y && hoverInfo.info) {
        return (
          <View
            style={{
              zIndex: 1,
              position: 'absolute',
              top: hoverInfo.y,
              width: '100%',
            }}>
            {renderHoverItem ? (
              renderHoverItem({
                info: hoverInfo.info,
                startDrag,
                endDrag,
              })
            ) : (
              <View>
                {renderItemWrapper(
                  {
                    info: hoverInfo.info,
                    startDrag,
                    endDrag,
                  },
                  true,
                  true,
                )}
              </View>
            )}
          </View>
        );
      } else {
        return null;
      }
    };

    if (!panResponder) return null;
    return (
      <View collapsable={false} ref={containerRef} style={{flex: 1}}>
        <HoverItem />
        <SectionList
          ref={listRef}
          onScroll={event => {
            scroll.current = event.nativeEvent.contentOffset.y;
          }}
          scrollEnabled={numOfActiveTouches === 0}
          style={{zIndex: 0}}
          {...props}
          ListHeaderComponent={() => (
            <View
              onLayout={b => {
                listLayoutRaw.current[3][0] = b.nativeEvent.layout;
              }}>
              {ListHeaderComponent && <ListHeaderComponent />}
            </View>
          )}
          ListFooterComponent={() => (
            <View
              onLayout={b => {
                listLayoutRaw.current[4][0] = b.nativeEvent.layout;
              }}>
              {ListFooterComponent && <ListFooterComponent />}
            </View>
          )}
          renderSectionHeader={a => (
            <View
              onLayout={b => {
                listLayoutRaw.current[1].push(b.nativeEvent.layout);
              }}>
              {renderSectionHeader && renderSectionHeader(a)}
            </View>
          )}
          renderSectionFooter={a => (
            <View
              onLayout={b => {
                listLayoutRaw.current[2].push(b.nativeEvent.layout);
              }}>
              {renderSectionFooter && renderSectionFooter(a)}
            </View>
          )}
          renderItem={info =>
            renderItemWrapper({
              info,
              startDrag,
              endDrag,
            })
          }
        />
      </View>
    );
  };

import React from 'react';
import {StyleSheet, Text, TextInput, TextInputProps, View} from 'react-native';

import {StyledIcon} from '../../components';
import {
  Constants,
  ErrorMessages,
  ProfileTitleStyle,
  appColors,
} from '../../constants';
import {checkStringBytes} from '../../lib/checkStringBytes';

interface Props
  extends Pick<TextInputProps, 'value' | 'onChangeText' | 'style'> {}

export const isValid = (value?: string) => {
  if (!value) return false;

  return value.trim().length !== 0 && checkStringBytes(value, 'profile');
};

export const HeatProfileNameTextInput = ({
  value,
  onChangeText,
  style,
}: Props) => {
  const nameRef = React.useRef<TextInput>(null);

  const [shouldOpen, setShouldOpen] = React.useState(false);

  return (
    <>
      <View style={styles.container}>
        <TextInput
          allowFontScaling={false}
          autoCapitalize="characters"
          ref={nameRef}
          {...{value, onChangeText}}
          keyboardType={
            Constants.IS_NATIVE_ANDROID ? 'visible-password' : 'default'
          }
          maxLength={Constants.PROFILE_NAME_MAX_LENGTH}
          onBlur={e => {
            if (shouldOpen) {
              nameRef?.current?.focus();
              setShouldOpen(false);
            }

            onChangeText?.(e.nativeEvent.text.trim());
          }}
          style={[ProfileTitleStyle, styles.input, style]}
        />

        <StyledIcon
          style={styles.icon}
          iconStyle={{tintColor: appColors.white}}
          size={12}
          name="pencil"
          onPress={() => {
            nameRef.current?.focus();
            Constants.IS_WEB && setShouldOpen(true);
          }}
        />
      </View>

      {!isValid(value) && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>
            {value?.trim().length === 0
              ? ErrorMessages.PROFILE_NAME_EMPTY
              : ErrorMessages.PROFILE_NAME_LONG}
          </Text>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  input: {
    fontSize: 24,
    fontWeight: '500',
    letterSpacing: 0.18,
    color: appColors.white,
    maxWidth: '80%',
    textTransform: 'uppercase',
  },
  icon: {width: 10},
  errorContainer: {
    height: 30,
  },
  errorText: {
    color: appColors.errorColor,
    fontSize: 14,
    fontFamily: 'BigShouldersDisplay-Medium',
    fontWeight: '400',
  },
});

import React from 'react';
import {ImageProps, ImageResizeMode, StyleSheet, View} from 'react-native';

const useTintedImageSource = (source: string, tintColor: string) => {
  const [imgOutSource, setImgOutSource] = React.useState<string>();

  React.useEffect(() => {
    if (!source) {
      return;
    }
    if (!tintColor) {
      setImgOutSource(source);
      return;
    }

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      ctx.drawImage(img, 0, 0);

      ctx.globalCompositeOperation = 'source-in';
      ctx.fillStyle = tintColor;
      ctx.fillRect(0, 0, img.width, img.height);

      setImgOutSource(canvas.toDataURL());
    };
    img.src = source;
  }, [source, tintColor]);

  return imgOutSource;
};

const ImageWithFilter = ({
  resizeMode,
  source,
  style = {},
  onLoad,
}: ImageProps & {onLoad: () => void}) => {
  const {tintColor} = StyleSheet.flatten(style);
  const tintedSource = useTintedImageSource(
    source as string,
    tintColor as string,
  );

  return (
    <View style={style}>
      <svg width="100%" height="100%">
        <image
          x={0}
          y={0}
          {...{onLoad}}
          width="100%"
          height="100%"
          href={tintedSource}
          style={{position: 'absolute'}}
          {...(resizeMode && {
            preserveAspectRatio: PRESERVE_ASPECT_RATIO_DICTIONARY[resizeMode],
          })}
        />
      </svg>
    </View>
  );
};

const PRESERVE_ASPECT_RATIO_DICTIONARY: Record<ImageResizeMode, string> = {
  stretch: 'none',
  cover: 'xMidYMid slice',
  contain: 'xMidYMid',
  repeat: '',
  center: 'xMidYMid',
};

export {ImageWithFilter as Image};

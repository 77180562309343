import {createAsyncThunk} from '@reduxjs/toolkit';

import {Constants} from '../../../constants';
import {Store} from '../../types';
import {connectedPeakSelector} from '../bleSlice';
import {updateTempProfile} from '../profilesSlice';

const {TEMP_HEAT_PROFILE_INDEX} = Constants;

export const bleReadTempHeatProfile = createAsyncThunk<void>(
  'ble/readTempHeatProfile',
  async (_, {dispatch, getState}) => {
    const state = getState() as Store;
    const peak = connectedPeakSelector(state);

    const profile = (await peak?.readHeatProfile(TEMP_HEAT_PROFILE_INDEX))
      ?.profile;

    if (!profile) return;

    dispatch(updateTempProfile(profile));
  },
);

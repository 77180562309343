import type {Asset, Entry, EntryFields} from 'contentful';

import {TypeGuideFields} from './TypeGuide';
import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeGalleryFields {
  title: EntryFields.Symbol;
  elements?: Entry<Record<string, any>>[];
  type: 'CAROUSEL' | 'CONTEST' | 'DEFAULT' | 'GRID' | 'SUPPORT_ITEM';
  displayName?: EntryFields.RichText;
  thumbnailImage?: Asset;
  guide?: Entry<TypeGuideFields>;
}

export type TypeGallery = Entry<TypeGalleryFields>;

export function isTypeGallery(
  entry: WithContentTypeLink,
): entry is TypeGallery {
  return entry?.sys?.contentType?.sys?.id === 'gallery';
}

import React from 'react';
import {TouchableOpacity, TouchableOpacityProps, View} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';
import {AppText} from './AppText';
import {IconName} from './StyledIcon';
import {StyledIcon} from './index';

type SupportCardProps = TouchableOpacityProps & {
  text: string;
  icon?: IconName;
};

export const SupportCard = (props: SupportCardProps) => {
  const {text, icon, onPress, disabled} = props;

  return (
    <Card onPress={onPress} disabled={disabled}>
      <MiddleContainer>
        {icon && <StyledIcon name={icon} />}
        <CardText>{text}</CardText>
      </MiddleContainer>
    </Card>
  );
};

const Card = styled(TouchableOpacity)({
  width: '100%',
  borderRadius: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginVertical: 10,
  flex: 1,
});

const MiddleContainer = styled(View)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: 5,
});

const CardText = styled(AppText)({
  color: appColors.white,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 14,
  letterSpacing: 0.75,
  textAlign: 'center',
  fontWeight: '400',
});

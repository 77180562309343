import {Linking} from 'react-native';

import {appLog} from '../../../lib/Logger';
import {BaseSettingsService} from './BaseSettingsService';
import {ISettingsService} from './ISettingsService';

const navigator: Puffco.Navigator = window.navigator;

abstract class WebSettingsService
  extends BaseSettingsService
  implements ISettingsService
{
  async open(): Promise<void> {
    appLog.info('Open settings.');

    // TODO: why do we have 2 ways to open settings??
    (await navigator.services?.openSettings()) ??
      (await navigator.permissions.request?.({name: 'openAppSettings'}));
  }

  async openBluetooth(): Promise<void> {
    appLog.info('Open bluetooth settings.');
    await this.open();
  }

  async openLocation(): Promise<void> {
    appLog.info('Open location settings.');
    await this.open();
  }
}

class BrowserSettingsService extends WebSettingsService {
  async openUrlInBrowser(url: string) {
    return Linking.openURL(url);
  }
}

class PathSettingsService extends WebSettingsService {
  constructor(
    private openDefaultBrowser: Required<Puffco.Services>['openDefaultBrowser'],
  ) {
    super();
  }

  async openUrlInBrowser(url: string) {
    return this.openDefaultBrowser(url);
  }
}

export const settingsService: ISettingsService = navigator.services
  ?.openDefaultBrowser
  ? new PathSettingsService(navigator.services?.openDefaultBrowser)
  : new BrowserSettingsService();

export const convertRgbArrayToHex = (color: number[]) => {
  const r = color[0];
  const g = color[1];
  const b = color[2];

  let rs = r.toString(16);
  let gs = g.toString(16);
  let bs = b.toString(16);

  if (rs.length === 1) rs = '0' + rs;
  if (gs.length === 1) gs = '0' + gs;
  if (bs.length === 1) bs = '0' + bs;

  return `#${rs}${gs}${bs}`;
};

import React from 'react';
import {Pressable, PressableProps, StyleProp, ViewStyle} from 'react-native';

export interface Props extends PressableProps {
  style?: StyleProp<ViewStyle>;
}

export const PressableOpacity = ({
  style,
  ...rest
}: React.PropsWithChildren<Props>) => {
  return (
    <Pressable
      style={({pressed}) => [
        {
          opacity: pressed ? 0.5 : 1,
        },
        style,
      ]}
      {...rest}
    />
  );
};

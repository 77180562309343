import {Formik} from 'formik';
import React from 'react';
import {Keyboard, KeyboardAvoidingView, Text, View} from 'react-native';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';

import {
  ImgBackground,
  SegmentedProgress,
  StyledButton,
  StyledField,
} from '../../components';
import {ErrorMessages, Screens, appColors} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {addAnswer} from '../../lib/redux/warrantySlice';
import styled from '../../lib/styled';
import {WarrantyNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {RedirectOptions} from '../../navigation/navigators/params';
import {SafeAreaView} from '../../shims/SafeAreaView';

const UserInfoSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(ErrorMessages.REQUIRED_EMAIL)
    .email(ErrorMessages.INVALID_EMAIL),
  name: Yup.string().trim().required(ErrorMessages.REQUIRED_NAME),
});

interface UserInfoValues {
  email: string;
  name: string;
}

export interface WarrantyClaimUserInfoScreenProps {
  headerLeft?: RedirectOptions;
}

interface ScreenProps
  extends WarrantyNavigatorScreenProps<
    typeof Screens.WarrantyClaimScreenUserInfo
  > {}

export const WarrantyClaimScreenUserInfo: React.FC<ScreenProps> = ({
  navigation,
}) => {
  useAdaptiveSafeArea();
  const dispatch = useDispatch();

  const handleSubmit = async (values: UserInfoValues) => {
    dispatch(addAnswer({userInfo: {name: values.name, email: values.email}}));
    navigation.navigate(Screens.WarrantyClaimScreenMessage);
    Keyboard.dismiss();
  };

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1, alignItems: 'center'}}>
        <KeyboardAvoidingView
          behavior={'height'}
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            height: '100%',
            flex: 1,
          }}>
          <TitleContainer>
            <TitleText>User Information</TitleText>
            <SegmentedProgress progress={3} />
          </TitleContainer>
          <Formik
            initialValues={{email: '', name: ''}}
            onSubmit={handleSubmit}
            validationSchema={UserInfoSchema}>
            {({
              handleBlur,
              handleChange,
              isValid,
              submitForm,
              values,
              errors,
            }) => (
              <FieldsContainer>
                <StyledField
                  name={'eye'}
                  placeholder="Name"
                  onChangeText={handleChange('name')}
                  onBlur={handleBlur('name')}
                  value={values.name}
                  leftIconColor={appColors.mediumGray}
                  borderColor={appColors.mediumGray}
                  textColor={appColors.white}
                />
                {values?.name !== '' && errors?.name && (
                  <ErrorText>{errors.name}</ErrorText>
                )}
                <StyledField
                  name={'mail'}
                  placeholder="Email Address"
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  keyboardType="email-address"
                  leftIconColor={appColors.mediumGray}
                  borderColor={appColors.mediumGray}
                  textColor={appColors.white}
                />
                {values?.email !== '' && errors?.email && (
                  <ErrorText>{errors.email}</ErrorText>
                )}
                <ButtonContainer>
                  <StyledButton
                    disabled={!isValid}
                    onPress={submitForm}
                    title="Next"
                  />
                </ButtonContainer>
              </FieldsContainer>
            )}
          </Formik>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ImgBackground>
  );
};

const TitleContainer = styled(View)({
  marginTop: '20%',
  width: '100%',
  display: 'flex',
  flex: 0.25,
});

const TitleText = styled(Text)({
  color: appColors.white,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  fontSize: 30,
  marginLeft: '5%',
  marginBottom: 18,
  height: 40,
});

const ErrorText = styled(Text)({
  color: appColors.errorColor,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  marginTop: 5,
});

const FieldsContainer = styled(View)({
  width: '90%',
  marginVertical: 30,
  height: '100%',
  flex: 1,
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  flex: 1,
  paddingBottom: '10%',
});

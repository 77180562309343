import React from 'react';
import {StyleSheet, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import {GalleryElement} from '../../../../lib/api/content-access';
import {PossibleText} from '../RichTextString';
import {Section} from '../Section';
import {CarouselItem} from './CarouselItem';

interface Props {
  items: GalleryElement[];
  title?: PossibleText;
}

export const Carousel = ({items, title}: Props) => (
  <Section {...{title}}>
    <FlatList
      style={styles.flatList}
      data={items}
      renderItem={({item, index}) => (
        <CarouselItem {...{item, index, all: items}} />
      )}
      onTouchStart={e => e.stopPropagation()}
      keyExtractor={item => item.sys.id}
      extraData={items}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      ItemSeparatorComponent={() => <View style={styles.separator} />}
    />
  </Section>
);

const styles = StyleSheet.create({
  separator: {width: 16},
  flatList: {
    marginHorizontal: -24,
    paddingHorizontal: 24,
  },
});

import React from 'react';
import {HandlerStateChangeEvent} from 'react-native-gesture-handler';

export interface SwipeHandlerOptions {
  onLeft?: () => void;
  onRight?: () => void;
  onUp?: () => void;
  onDown?: () => void;
  vertical?: boolean;
}

export const useSwipe = ({
  onLeft,
  onRight,
  onUp,
  onDown,
  vertical = false,
}: SwipeHandlerOptions) =>
  React.useCallback(
    (event: HandlerStateChangeEvent<Record<string, unknown>>) => {
      const translationX = event.nativeEvent.translationX as number;
      const translationY = event.nativeEvent.translationY as number;

      // Do nothing if the swipe direction is not the indended one
      const isVertical = Math.abs(translationY) > Math.abs(translationX);

      if (isVertical !== vertical) return;

      if (isVertical) {
        if (translationY < 0 && onUp) {
          onUp(); // Swiped up
          return;
        } else if (translationY > 0 && onDown) {
          onDown(); // Swiped down
          return;
        }
      }

      if (translationX < 0 && onLeft) {
        onLeft(); // Swiped left
      } else if (translationX > 0 && onRight) {
        onRight(); // Swiped right
      }
    },
    [onLeft, onRight, onUp, onDown, vertical],
  );

import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {useSelector} from 'react-redux';

import {stopwatch, thermometer} from '../../assets/images';
import {Constants, ProfileTitleStyle} from '../../constants';
import {appSettingsSelector} from '../../lib/redux/appSettingsSlice';
import {currentDeviceSelector} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {useTheme} from '../../lib/useTheme';
import {Temperature} from '../../lib/utils/temperature';
import {analytics} from '../../src/services/analytics';
import {AppText} from '../AppText';
import {DabbingButton} from '../DabbingButton';
import {useBoostValue} from './useBoostValue';
import {useDetectValueChange} from './useDetectValueChange';

interface Props {
  targetTemperature: number;
  backgroundHeight: number;
  onTemperatureButtonPress?: (step: number) => void;
  onTimeButtonPress?: (step: number) => void;
  message?: string;
  style: {
    boostContainer?: StyleProp<ViewStyle>;
    message?: StyleProp<ViewStyle>;
  };
}

const getIncrementValue = (value: number | undefined, defaultValue: number) => {
  // Is this check really needed?
  if (typeof value === 'number' && Number.isFinite(value)) return value;

  return defaultValue;
};

const getDefaultTemperatureIncrementValue = (unit: TemperatureUnit) => {
  if (unit === TemperatureUnit.Celsius)
    return Constants.DABBING_ADDED_TEMP_CELSIUS;

  return Constants.DABBING_ADDED_TEMP_FAHRENHEIT;
};

// Fahrenheit => F, Celsius => C
const formatTemperatureUnit = (unit: TemperatureUnit) => unit[0];

export const DabberFooter = ({
  targetTemperature,
  backgroundHeight,
  onTemperatureButtonPress,
  onTimeButtonPress,
  message,
  style,
}: Props) => {
  const {dabbingScreenTheme, primaryTextColor, fadedTextColor} = useTheme();
  const device = useSelector(currentDeviceSelector);
  const preferences = useSelector(appSettingsSelector);
  const appSettings = useSelector(appSettingsSelector);

  const inAppButtonInteractionRef = React.useRef(false);

  const timeBoost = useBoostValue({formatter: value => `+${value}s`});
  const temperatureBoost = useBoostValue({
    formatter: value =>
      `+${value}°${formatTemperatureUnit(preferences.tempPreference)}`,
  });

  const addTimeIncrement = getIncrementValue(
    device?.settings?.boostDuration,
    Constants.DABBING_ADDED_TIME,
  );

  const addTempIncrement = getIncrementValue(
    device?.settings?.boostTemp !== undefined
      ? Math.round(
          Temperature.convertDifference(device?.settings?.boostTemp, {
            from: TemperatureUnit.Celsius,
            to: appSettings.tempPreference,
          }),
        )
      : undefined,
    getDefaultTemperatureIncrementValue(preferences.tempPreference),
  );

  const showBooster = !!onTemperatureButtonPress || !!onTimeButtonPress;

  const hasUserBoostedFromDevice = useDetectValueChange(
    targetTemperature,
    device?.settings?.boostTemp ?? 10,
  );

  React.useEffect(() => {
    if (!hasUserBoostedFromDevice || inAppButtonInteractionRef.current) return;

    const temperatureInCelsius = Temperature.convertDifference(
      addTempIncrement,
      {
        from: appSettings.tempPreference,
        to: TemperatureUnit.Celsius,
      },
    );

    temperatureBoost.show(addTempIncrement);
    analytics.trackEvent(
      'heat cycle add temperature',
      {
        value: temperatureInCelsius,
      },
      undefined,
      (properties, {value, ...values}) => ({
        ...properties,
        ...values,
        ...(properties.temperature && {
          temperature: properties.temperature + value,
        }),
      }),
    );

    timeBoost.show(addTimeIncrement);
    analytics.trackEvent(
      'heat cycle add time',
      {value: addTimeIncrement},
      undefined,
      (properties, {value, ...values}) => ({
        ...properties,
        ...values,
        ...(properties.duration && {
          duration: properties.duration + value,
        }),
      }),
    );
  }, [
    appSettings.tempPreference,
    hasUserBoostedFromDevice,
    inAppButtonInteractionRef.current,
    temperatureBoost,
    timeBoost,
    addTempIncrement,
    addTimeIncrement,
  ]);

  const detectButtonInteraction = (cb: () => void) => {
    inAppButtonInteractionRef.current = true;

    cb();

    setTimeout(() => {
      inAppButtonInteractionRef.current = false;
    }, 1000);
  };

  return (
    <BottomContainer>
      {showBooster ? (
        <>
          <RowContainer
            style={{
              marginBottom: 15,
            }}>
            <LeftBoostContainer style={style.boostContainer}>
              <BoostText
                style={{
                  color: dabbingScreenTheme.textColor ?? primaryTextColor,
                }}>
                {temperatureBoost.value ?? ''}
              </BoostText>
            </LeftBoostContainer>

            <RightBoostContainer style={style.boostContainer}>
              <BoostText
                style={{
                  color: dabbingScreenTheme.textColor ?? primaryTextColor,
                }}>
                {timeBoost.value ?? ''}
              </BoostText>
            </RightBoostContainer>
          </RowContainer>

          <RowContainer
            style={{
              paddingBottom: backgroundHeight * 0.087,
            }}>
            <LeftBoostContainer style={style.boostContainer}>
              <DabbingButton
                iconSource={thermometer}
                onPress={() =>
                  detectButtonInteraction(() => {
                    temperatureBoost.show(addTempIncrement);
                    onTemperatureButtonPress?.(
                      (temperatureBoost.times + 1) * addTempIncrement,
                    );
                  })
                }
                theme={dabbingScreenTheme}
                disabled={!onTemperatureButtonPress}
              />
            </LeftBoostContainer>

            <RightBoostContainer style={style.boostContainer}>
              <DabbingButton
                iconSource={stopwatch}
                onPress={() =>
                  detectButtonInteraction(() => {
                    timeBoost.show(addTimeIncrement);
                    onTimeButtonPress?.(addTimeIncrement);
                  })
                }
                plusPadding={36}
                disabled={!onTimeButtonPress}
                theme={dabbingScreenTheme}
              />
            </RightBoostContainer>
          </RowContainer>
        </>
      ) : (
        <RowContainer />
      )}

      {message && (
        <TipContainer style={style.message}>
          <TipText
            style={{
              color: dabbingScreenTheme.fadedTextColor ?? fadedTextColor,
            }}>
            {message}
          </TipText>
        </TipContainer>
      )}
    </BottomContainer>
  );
};

const Title = styled(AppText)({
  ...ProfileTitleStyle,
  zIndex: 1,
});

const BottomContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 0.45,
  width: '100%',
});

const RowContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 20,
});

const LeftBoostContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: 135,
});

const RightBoostContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: 135,
});

const BoostText = styled(Title)({
  paddingTop: 12,
  textAlign: 'center',
  justifyContent: 'center',
  fontSize: 20,
  letterSpacing: 0.18,
});

const TipContainer = styled(View)({
  alignItems: 'flex-end',
  justifyContent: 'center',
});

const TipText = styled(AppText)({
  fontSize: 16,
  letterSpacing: 0.32,
  fontWeight: '500',
  fontFamily: 'BigShouldersDisplay-Regular',
});

import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Screens} from '../../constants';
import {AssignToAHeatProfileScreen, EpilepsyWarningScreen} from '../../screens';
import {
  MoodLightCreateScreen,
  Props as MoodLightCreateScreenProps,
} from '../../screens/controlCenter/MoodLightCreateScreen';
import {
  HeatProfileCreateScreen,
  Props as HeatProfileCreateScreenProps,
} from '../../screens/main/HeatProfileCreateScreen';
import {
  HeatProfileEditScreen,
  Props as HeatProfileEditScreenProps,
} from '../../screens/main/HeatProfileEditScreen';
import {defaultNavigationOptions, withBackButton} from './common';
import {WithRedirect} from './params';

export type HeatProfileEditStackParamList = {
  [Screens.HeatProfileCreate]?: HeatProfileCreateScreenProps;
  [Screens.HeatProfileEdit]?: HeatProfileEditScreenProps;
  [Screens.MoodLightCreate]?: MoodLightCreateScreenProps;
  [Screens.EpilepsyWarning]: WithRedirect;
  [Screens.AssignToAHeatProfile]: undefined;
};

const HeatProfileEditStack =
  createStackNavigator<HeatProfileEditStackParamList>();

export const HeatProfileEditStackNavigator = () => {
  return (
    <HeatProfileEditStack.Navigator
      screenOptions={{...defaultNavigationOptions, headerTitle: ''}}>
      <HeatProfileEditStack.Screen
        name={Screens.HeatProfileCreate}
        initialParams={{showHeader: true}}
        component={HeatProfileCreateScreen}
      />

      <HeatProfileEditStack.Screen
        name={Screens.HeatProfileEdit}
        initialParams={{showHeader: true}}
        component={HeatProfileEditScreen}
      />

      <HeatProfileEditStack.Screen
        name={Screens.MoodLightCreate}
        component={MoodLightCreateScreen}
        options={{gestureEnabled: false, ...withBackButton()}}
      />

      <HeatProfileEditStack.Screen
        name={Screens.EpilepsyWarning}
        component={EpilepsyWarningScreen}
        options={{headerShown: false}}
      />

      <HeatProfileEditStack.Screen
        name={Screens.AssignToAHeatProfile}
        // @ts-expect-error because of the screen being part of two separate stacks, the type can not be infered correctly
        component={AssignToAHeatProfileScreen}
      />
    </HeatProfileEditStack.Navigator>
  );
};

export enum MiddlewareValue {
  STRING = 'string',
  FLOAT = 'float',
  U_INT_8 = 'uInt8',
  U_INT_16 = 'uInt16',
  U_INT_32 = 'uInt32',
  INT_8 = 'int8',
  INT_32 = 'int32',
  BUFFER = 'buffer',
  ULID = 'ulid',
  COLORS = 'colors',
}

import React from 'react';
import {useDispatch} from 'react-redux';

import {authApi} from '../lib/api/apis';
import {
  clearTokens,
  getAccessToken,
  getRefreshToken,
  setTokens,
} from '../lib/api/tokens';
import {updateAppFlags} from '../lib/redux/appFlagsSlice';
import {clearAccountMoodLights} from '../lib/redux/moodLightSlice';
import {resetProfileArchives} from '../lib/redux/profilesSlice';
import {referralsActions} from '../lib/redux/referralsSlice';
import {setUser} from '../lib/redux/userSlice';
import {authorizedApiClient} from '../lib/util/authorized-api-client';
import {createContainer} from './unstated-next';

interface Session {
  accessToken: string;
  refreshToken?: string;
}

authorizedApiClient.token = getAccessToken();

interface UseAuthOptions {
  onLogout?(): void;
}

const useAuth = ({onLogout}: UseAuthOptions = {}) => {
  const dispatch = useDispatch();

  const logout = React.useCallback(async () => {
    const token: string | null = await getRefreshToken();

    if (token)
      await authApi
        .logout({headers: {Authorization: `Bearer ${token}`}})
        .catch(() => void 0);

    authorizedApiClient.token = null;
    await clearTokens();

    dispatch(setUser(null));
    dispatch(resetProfileArchives());
    dispatch(clearAccountMoodLights());
    dispatch(referralsActions.reset());
    dispatch(updateAppFlags({hasSeenNewUsername: false}));

    onLogout?.();
  }, [onLogout]);

  const createSession = React.useCallback(async (session: Session) => {
    authorizedApiClient.token = session.accessToken;
    await setTokens(session);
  }, []);

  React.useEffect(() => {
    authorizedApiClient.configure({
      onUnauthorized: async () => logout(),
      onAccessTokenExpired: async () => {
        const token: string | null = await getRefreshToken();

        if (!token) throw new Error(`No refresh token found.`);

        return authApi
          .renewTokens({authorization: `Bearer ${token}`})
          .then(({data}) => data)
          .then(createSession);
      },
    });
  }, [logout, createSession]);

  return {logout, createSession, onLogout};
};

export const Auth = createContainer(useAuth);

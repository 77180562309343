import {IPeakDevice} from '../../lib/ble2/v2/PeakDevice/IPeakDevice';
import {Device} from '../types';

export const getDeviceFromPeak = (peak: IPeakDevice): Device => ({
  id: peak.peripheralId,
  name: peak.name,
  product: {
    name: peak.product.name,
    type: peak.product.type,
    features: peak.product.features,
    consistent: peak.product.consistent,
  },
  selectedHeatCycle: peak.attributes?.selectedHeatCycle ?? 0,
  ...(peak.euid && {euid: peak.euid}),
  ...(peak.gitHash && {gitHashString: peak.gitHash}),
  ...(peak.softwareRevision && {softwareRevisionString: peak.softwareRevision}),
  ...(peak.modelNumber && {modelNumberString: peak.modelNumber}),
  ...(peak.serialNumber && {serialNumberString: peak.serialNumber}),
  ...(peak.broadcast && {
    broadcastingCounter: peak.broadcast.counter,
    broadcastingKey: peak.broadcast.key,
  }),
  ...(peak.attributes?.dateOfBirth && {
    dob: peak.attributes?.dateOfBirth * 1000,
  }),
  ...(peak.attributes?.operatingState && {
    state: peak.attributes?.operatingState,
  }),
  ...(peak.attributes?.batteryLevel && {
    battery: peak.attributes?.batteryLevel,
  }),
  ...(peak.attributes?.totalHeatCycles && {
    hits: peak.attributes?.totalHeatCycles,
  }),
  ...(peak.attributes?.dabTotalTime && {
    totalTime: peak.attributes?.dabTotalTime,
  }),
  ...(peak.attributes?.batteryCapacity && {
    batteryCapacity: peak.attributes?.batteryCapacity,
  }),
  ...(peak.attributes?.chargeState && {
    batteryChargeState: peak.attributes?.chargeState,
  }),
  ...(peak.attributes?.chargeEstimatedTimeToFull && {
    batteryChargeEstTimeToFull: peak.attributes?.chargeEstimatedTimeToFull,
  }),
  ...(peak.attributes?.dabsPerDay && {
    dailyDabs: peak.attributes?.dabsPerDay ?? 0,
  }),
  ...(peak.attributes?.approxDabsRemaining && {
    approxDabsRemainingCount: peak.attributes?.approxDabsRemaining,
  }),
  ...(peak.attributes?.utcTime && {utcTime: peak.attributes?.utcTime}),
  ...(peak.attributes?.chamberType && {
    chamberType: peak.attributes?.chamberType,
  }),
  settings: {
    ...(peak.attributes?.stealth && {stealth: peak.attributes?.stealth}),
    ...(peak.attributes?.currentTemperature && {
      currentTemp: peak.attributes?.currentTemperature,
    }),
    ...(peak.attributes?.targetTemperature && {
      targetTemp: peak.attributes?.targetTemperature,
    }),
    lanternMode: Boolean(peak.attributes?.lanternTime ?? 0 > 0),
  },
});

import {
  add,
  apply,
  create,
  divide,
  equal,
  evaluateDependencies,
  flatten,
  larger,
  matrix,
  max,
  min,
  multiply,
  pow,
  round,
  row,
  smaller,
  smallerEq,
  subset,
  zeros,
} from 'mathjs';

import {lchycle, tripleToHtmlColor} from './colorFunctions';

const len = (a: any[]) => a.length;

const arrayPad = (inArray: any[], length: number, value: any) => {
  const outArray = inArray.slice();
  while (outArray.length < length) {
    outArray.push(value);
  }
  return outArray;
};

const lchycleMath = (o: any) => {
  const triples = lchycle(o); // returns RGB triple [0-1, 0-1, 0-1]
  return triples.map(t => tripleToHtmlColor(t).replace('#', '')); // return as CSS RGB hexstrings
};

export const math = create(evaluateDependencies);

math.import(
  {
    len,
    multiply,
    pow,
    larger,
    smaller,
    divide,
    round,
    matrix,
    lchycle: lchycleMath,
    add,
    equal,
    min,
    max,
    smallerEq,
    zeros,
    apply,
    subset,
    row,
    flatten,
    arrayPad,
  },
  {override: true},
);

import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';

import {isMoodLightNewer} from '../moodLightFunctions';
import {
  CustomMoodLight,
  Dictionary,
  ExclusiveMoodLight,
  MoodLight,
  MoodLightStore,
  Store,
  isCustomMoodLight,
} from '../types';

const upsertAccountMoodLightHelper = (
  state: MoodLightStore,
  newMoodLight: CustomMoodLight,
) => {
  const {id, modified: dateModified} = newMoodLight;
  const modified = new Date(dateModified).getTime();
  const currentMoodLight = state.accountMoodLights.byId[id];

  if (id && !currentMoodLight) {
    state.accountMoodLights.allIds.splice(0, 0, id);
  }
  if (!currentMoodLight || currentMoodLight.modified < modified) {
    state.accountMoodLights.byId[id] = {
      ...newMoodLight,
      modified,
      tempo: +newMoodLight.tempo,
      type: +newMoodLight.type,
    };
  }
};

const upsertPeakMoodLightHelper = (
  state: MoodLightStore,
  newMoodLight: MoodLight,
) => {
  const {id} = newMoodLight;
  if (isCustomMoodLight(newMoodLight)) {
    // Ensure that modified is in milliseconds from UNIX epoch
    newMoodLight = {
      ...newMoodLight,
      modified: new Date(newMoodLight.modified).getTime(),
      tempo: +newMoodLight.tempo,
      type: +newMoodLight.type,
    };

    // Update account mood light based on time stamp
    const accountMoodLight = state.accountMoodLights.byId[id];
    if (accountMoodLight && isMoodLightNewer(newMoodLight, accountMoodLight)) {
      state.accountMoodLights.byId[id] = newMoodLight;
    }
  }

  if (id && !state.peakMoodLights.byId[id]) {
    state.peakMoodLights.allIds.push(id);
  }
  state.peakMoodLights.byId[id] = newMoodLight;
};

const moodLightSlice = createSlice({
  name: 'moodLight',
  initialState: {
    lanternMoodLightId: undefined,
    peakMoodLights: {
      byId: {},
      allIds: [],
    },
    accountMoodLights: {
      byId: {},
      allIds: [],
    },
    exclusiveMoodLights: {
      byId: {},
      allIds: [],
    },
    usernameCache: {},
  } as MoodLightStore,
  reducers: {
    setLanternMoodLightId(
      state: MoodLightStore,
      action: PayloadAction<string | undefined>,
    ) {
      state.lanternMoodLightId = action.payload;
    },
    upsertPeakMoodLight(
      state: MoodLightStore,
      action: PayloadAction<MoodLight>,
    ) {
      upsertPeakMoodLightHelper(state, action.payload);
    },
    upsertAccountMoodLight(
      state: MoodLightStore,
      action: PayloadAction<CustomMoodLight>,
    ) {
      upsertAccountMoodLightHelper(state, action.payload);
    },
    upsertAccountMoodLights(
      state: MoodLightStore,
      action: PayloadAction<CustomMoodLight[]>,
    ) {
      action.payload.forEach(updatedMoodLight =>
        upsertAccountMoodLightHelper(state, updatedMoodLight),
      );
    },
    clearAccountMoodLights(state: MoodLightStore) {
      state.accountMoodLights = {
        byId: {},
        allIds: [],
      };
    },
    setPeakMoodLights(
      state: MoodLightStore,
      action: PayloadAction<MoodLight[]>,
    ) {
      state.peakMoodLights = {
        byId: {},
        allIds: [],
      };
      action.payload.forEach(moodLight => {
        upsertPeakMoodLightHelper(state, moodLight);
      });
    },
    setExclusiveMoodLights(
      state: MoodLightStore,
      action: PayloadAction<ExclusiveMoodLight[]>,
    ) {
      state.exclusiveMoodLights = {
        byId: {},
        allIds: [],
      };
      action.payload.forEach(exclusive => {
        state.exclusiveMoodLights.byId[exclusive.id] = exclusive;
        state.exclusiveMoodLights.allIds.splice(0, 0, exclusive.id);
      });
    },
    deleteAccountMoodLight(
      state: MoodLightStore,
      action: PayloadAction<CustomMoodLight>,
    ) {
      const index = state.accountMoodLights.allIds.indexOf(action.payload.id);
      state.accountMoodLights.allIds.splice(index, 1);
      delete state.accountMoodLights.byId[action.payload.id];
    },
    updateUsernameCache(
      state: MoodLightStore,
      action: PayloadAction<{moodLightId: string; username: string}[]>,
    ) {
      state.usernameCache = {};
      action.payload.forEach(({moodLightId, username}) => {
        state.usernameCache[moodLightId] = username;
      });
    },
  },
});

const getMoodLights = <T extends MoodLight>(
  moodLightsAllIds: string[],
  moodLightsByIds: Dictionary<string, T>,
) => {
  const moodLights: T[] = [];
  moodLightsAllIds.forEach(id => {
    const moodLight = moodLightsByIds[id];
    moodLight && moodLights.push(moodLight);
  });
  return moodLights;
};

const peakMoodLightsAllIdsSelector = (state: Store) =>
  state.moodLight.peakMoodLights.allIds;

const peakMoodLightsByIdSelector = (state: Store) =>
  state.moodLight.peakMoodLights.byId;

export const getPeakMoodLightSelector = createSelector(
  peakMoodLightsByIdSelector,
  peakMoodLightsById => (id: string) => peakMoodLightsById[id],
);

export const peakMoodLightsSelector = createSelector(
  peakMoodLightsAllIdsSelector,
  peakMoodLightsByIdSelector,
  getMoodLights,
);

const accountMoodLightsAllIdsSelector = (state: Store) =>
  state.moodLight.accountMoodLights.allIds;

const accountMoodLightsByIdSelector = (state: Store) =>
  state.moodLight.accountMoodLights.byId;

export const getAccountMoodLightSelector = createSelector(
  accountMoodLightsByIdSelector,
  accountMoodLightsById => (id: string) => accountMoodLightsById[id],
);

export const accountMoodLightsSelector = createSelector(
  accountMoodLightsAllIdsSelector,
  accountMoodLightsByIdSelector,
  getMoodLights,
);

const exclusiveMoodLightsAllIdsSelector = (state: Store) =>
  state.moodLight.exclusiveMoodLights.allIds;

export const exclusiveMoodLightsByIdSelector = (state: Store) =>
  state.moodLight.exclusiveMoodLights.byId;

export const getExclusiveMoodLightSelector = createSelector(
  exclusiveMoodLightsByIdSelector,
  exclusiveMoodLightsById => (id: string) => exclusiveMoodLightsById[id],
);

export const exclusiveMoodLightsSelector = createSelector(
  exclusiveMoodLightsAllIdsSelector,
  exclusiveMoodLightsByIdSelector,
  getMoodLights,
);

export const getMoodLightSelector = createSelector(
  getPeakMoodLightSelector,
  getAccountMoodLightSelector,
  getExclusiveMoodLightSelector,
  (peakMoodLight, accountMoodLight, exclusiveMoodLight) => (id: string) =>
    peakMoodLight(id) ?? accountMoodLight(id) ?? exclusiveMoodLight(id),
);

export const lanternMoodLightIdSelector = (state: Store) =>
  state.moodLight.lanternMoodLightId;

export const lanternMoodLightSelector = createSelector(
  lanternMoodLightIdSelector,
  getMoodLightSelector,
  (lanternMoodLightId, getMoodLight) =>
    lanternMoodLightId ? getMoodLight(lanternMoodLightId) : undefined,
);

export const usernameCacheSelector = (state: Store) =>
  state.moodLight.usernameCache;

export const {
  clearAccountMoodLights,
  deleteAccountMoodLight,
  setExclusiveMoodLights,
  setLanternMoodLightId,
  setPeakMoodLights,
  upsertAccountMoodLight,
  upsertAccountMoodLights,
  upsertPeakMoodLight,
  updateUsernameCache,
} = moodLightSlice.actions;

export const moodLightReducer = moodLightSlice.reducer;

import type {Entry, EntryFields} from 'contentful';

import type {TypeDeviceFields} from './TypeDevice';
import type {TypeTagFields} from './TypeTag';
import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeFaqFields {
  question: EntryFields.Symbol;
  answer: EntryFields.RichText;
  tags?: Entry<TypeTagFields>[];
  references?: Entry<TypeDeviceFields>[];
  order?: EntryFields.Integer;
}

export type TypeFaq = Entry<TypeFaqFields>;

export function isTypeFaq(entry: WithContentTypeLink): entry is TypeFaq {
  return entry?.sys?.contentType?.sys?.id === 'faq';
}

import {Dimensions} from 'react-native';

import {Constants} from '../../constants';

const {height} = Dimensions.get('window');
const {SCREEN_HEIGHT, IS_WEB} = Constants;
const {IPHONE_8_ZOOMED} = SCREEN_HEIGHT;

export const isDisplayZoomed = () => {
  if (height <= IPHONE_8_ZOOMED && IS_WEB) {
    return true;
  } else {
    return false;
  }
};

import {ProductName} from 'pikaparam';

import {
  DICTIONARIES,
  PEAK_IMAGE_COLOR_OFFSETS_DICTIONARY,
  PEAK_IMAGE_OPACITY_OFFSETS_DICTIONARY,
} from '../../constants';
import {
  DeviceModelType,
  LightingPattern,
  LumaAnimation,
  MiddlewareValue,
} from '../../lib/types';

export const getDeviceModelType = (product: ProductName) =>
  DICTIONARIES.DEVICE_MODEL_TYPE[product] ?? DeviceModelType.OG;

export const getLightingPattern = (lumaAnimation: LumaAnimation) =>
  DICTIONARIES.LIGHTING_PATTERN_DICTIONARY[lumaAnimation] ??
  LightingPattern.STEADY;

export const getLumaAnimation = (lightingPattern: LightingPattern) =>
  DICTIONARIES.LUMA_ANIMATION_DICTIONARY[lightingPattern] ??
  LumaAnimation.STEADY;

export const getMiddlewareValueByteSize = (middlewareValue: MiddlewareValue) =>
  DICTIONARIES.MIDDLEWARE_VALUE_BYTE_SIZE[middlewareValue] ?? 0;

export const getPeakImageColorOffsetsDictionary = (
  deviceModelType: DeviceModelType,
) =>
  PEAK_IMAGE_COLOR_OFFSETS_DICTIONARY[deviceModelType] ??
  PEAK_IMAGE_COLOR_OFFSETS_DICTIONARY[DeviceModelType.OG];

export const getPeakImageOpacityOffsetsDictionary = (
  deviceModelType: DeviceModelType,
) =>
  PEAK_IMAGE_OPACITY_OFFSETS_DICTIONARY[deviceModelType] ??
  PEAK_IMAGE_OPACITY_OFFSETS_DICTIONARY[DeviceModelType.OG];

import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {Store} from '../types';
import {InterstitialStore} from '../types/InterstitialStore';

const interstitialsSlice = createSlice({
  name: 'interstitials',
  initialState: {history: {}, metadata: {}} as InterstitialStore,
  reducers: {
    markAsSeen: (state, action: PayloadAction<{key: string}>) => {
      state.history[action.payload.key] = {
        lastSeenAt: new Date().toISOString(),
      };

      return state;
    },
  },
});

export const interstitialsSelector = (state: Store) => state.interstitials;

export const {markAsSeen} = interstitialsSlice.actions;

export const interstitialsReducer = interstitialsSlice.reducer;

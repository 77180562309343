import React from 'react';
import {
  ActivityIndicator,
  ActivityIndicatorProps,
  Text,
  View,
} from 'react-native';

import {SimpleEmitter} from '../lib/SimpleEmitter';
import styled from '../lib/styled';
import {EmitterEvent} from '../lib/types';
import {useTheme} from '../lib/useTheme';

export type SpinnerProps = {
  isVisible: boolean;
  text?: string;
  props?: ActivityIndicatorProps;
  color?: string;
};

/*
  Spinner component that can load over the main app. Manually call display()
  or close with Spinner or use the useSpinner() hook to automatically display
  or close based on isVisible prop.
*/

const currentSpinner = new SimpleEmitter<SpinnerProps>();

const Spinner = {
  display: (spinnerProps: SpinnerProps) => currentSpinner.emit(spinnerProps),
  close: () => currentSpinner.emit(EmitterEvent.CLOSE),
};

export const useSpinner = (props: SpinnerProps) =>
  React.useEffect(() => {
    Spinner.display(props);
    return () => Spinner.close();
  }, [props]);

export const SpinnerProvider = () => {
  const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps | null>(
    null,
  );

  React.useEffect(() => {
    currentSpinner.setHandler(spinnerProps =>
      setSpinnerProps(typeof spinnerProps === 'string' ? null : spinnerProps),
    );

    return () => {
      currentSpinner.setHandler(() => null);
    };
  }, [setSpinnerProps]);

  const {spinnerColor} = useTheme();

  return spinnerProps?.isVisible ? (
    <SpinnerContainer>
      <ActivityIndicator
        size={'large'}
        color={spinnerProps?.color ?? spinnerColor}
        {...spinnerProps.props}
      />
      {spinnerProps.text && (
        <LoadingText style={{color: spinnerProps?.color ?? spinnerColor}}>
          {spinnerProps.text}
        </LoadingText>
      )}
    </SpinnerContainer>
  ) : null;
};

const SpinnerContainer = styled(View)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 99999,
});

const LoadingText = styled(Text)({
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  letterSpacing: 0.75,
  textTransform: 'uppercase',
  fontWeight: '400',
  marginTop: 10,
});

import {XAndY} from './getDabXAndYAxis';

export const getMaximumXAndYDomain = (xAndY: XAndY<number>) => {
  const v = [2, 4, 10];
  let count = 10;
  let y = 0;
  let temp = 0;
  let curr = count;

  while (y === 0) {
    for (const n of v) {
      if (
        (temp === 0 && xAndY.y <= curr) ||
        (xAndY.y > temp && xAndY.y <= curr)
      ) {
        y = curr;
        break;
      }
      temp = curr;
      curr = count * n;
    }
    count = curr;
  }

  return {
    x: xAndY.x,
    y,
  };
};

import React from 'react';

const EPSILON = 1;

/**
 * Detects when a value changes by a specified amount of units.
 *
 * @param value - The value to be monitored.
 * @param threshold - The threshold of units that, when exceeded, triggers the callback.
 * @returns Boolean indicating if the value has changed by the specified threshold.
 */
export const useDetectValueChange = (value: number, threshold: number) => {
  const [hasChanged, setHasChanged] = React.useState(false);
  const ref = React.useRef(value);

  React.useEffect(() => {
    if (!hasChanged) return;

    setHasChanged(false);
  }, [hasChanged]);

  React.useEffect(() => {
    const previousValue = ref.current;
    const difference = value - previousValue;

    if (
      threshold - EPSILON <= difference &&
      difference <= threshold + EPSILON
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }

    ref.current = value;
  }, [value, threshold]);

  return hasChanged;
};

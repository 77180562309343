import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Text, View, ViewProps} from 'react-native';

import {Screens, appColors} from '../../constants';
import styled from '../../lib/styled';
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigation/navigators/RootStackNavigator';

interface TermsAndPrivacyProps extends ViewProps {}

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const TermsAndPrivacy = ({style}: TermsAndPrivacyProps) => {
  const navigation = useNavigation<Navigation>();

  return (
    <Container style={style}>
      <GrayText>
        <UnderlinedGrayText
          onPress={() => {
            navigation.navigate(Screens.TermsConditions, {});
          }}>
          Terms of Service
        </UnderlinedGrayText>
        {' & '}
        <UnderlinedGrayText
          onPress={() => {
            navigation.navigate(Screens.PrivacyPolicy, {});
          }}>
          Privacy Policy
        </UnderlinedGrayText>
      </GrayText>
    </Container>
  );
};

const Container = styled(View)({
  alignItems: 'center',
  flex: 1,
});

const GrayText = styled(Text)({
  color: appColors.gray67,
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  letterSpacing: 0.43,
  fontWeight: '400',
});

const UnderlinedGrayText = styled(Text)({
  color: appColors.gray67,
  textDecorationLine: 'underline',
});

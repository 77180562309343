import {ILocationService} from './ILocationService';

class WebLocationService implements ILocationService {
  required = false;

  async enabled(): Promise<boolean> {
    return true;
  }

  async granted(): Promise<boolean> {
    return true;
  }

  async request(): Promise<void> {}
}

export const locationService: ILocationService = new WebLocationService();

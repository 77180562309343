import type {Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeComponentFields {
  title: EntryFields.Symbol;
  config?: EntryFields.Object;
  slug?: EntryFields.Symbol;
}

export type TypeComponent = Entry<TypeComponentFields>;

export function isTypeComponent(
  entry: WithContentTypeLink,
): entry is TypeComponent {
  return entry?.sys?.contentType?.sys?.id === 'component';
}

import React from 'react';
import {
  Dimensions,
  PanResponder,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import {ImageAndContentContainer} from '.';
import {ImageAndContentContainerProps} from '.';
import {appColors} from '../../constants';
import styled from '../../lib/styled';

const {width} = Dimensions.get('window');

export type CarouselProps = {
  data: ImageAndContentContainerProps[];
  loop?: boolean;
  loopInterval?: number;
  onSnapToItem?: (index: number) => void;
  index?: number;
  footerContainerStyle?: StyleProp<ViewStyle>;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  bottom?: React.ReactNode;
};

export const ImageAndContentCarousel = ({
  data,
  onSnapToItem,
  index,
  loop,
  loopInterval,
  footerContainerStyle,
  primary,
  secondary,
  bottom,
}: CarouselProps) => {
  const scrollRef = React.useRef<ScrollView>(null);
  const [current, setCurrent] = React.useState(index ?? 0);
  const [autoScroll, setAutoScroll] = React.useState(true);
  const [imgHeight, setImgHeight] = React.useState(0);

  const panResponder = React.useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderMove: () => {
        setAutoScroll(false);
      },
    }),
  ).current;

  const scrollTo = (index: number, animated?: boolean) => {
    scrollRef?.current?.scrollTo({
      x: index * width,
      y: 0,
      animated: !!animated,
    });
  };

  React.useEffect(() => {
    if (loop && autoScroll) {
      scrollTo(current, true);
    }
  }, [current, loop, autoScroll]);

  React.useEffect(() => {
    if (index) scrollTo(index, false);
  }, [index]);

  React.useEffect(() => {
    if (index !== undefined) setCurrent(index);
  }, [index]);

  React.useEffect(() => {
    if (loop && autoScroll) {
      const interval = setInterval(() => {
        setCurrent(v => (v >= data.length - 1 ? 0 : v + 1));
      }, loopInterval ?? 1500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [data, loop, loopInterval, autoScroll]);

  return (
    <>
      <Container>
        <ScrollView
          ref={scrollRef}
          {...panResponder.panHandlers}
          style={{
            width,
            direction: 'ltr',
          }}
          showsHorizontalScrollIndicator={false}
          disableIntervalMomentum={true}
          scrollEventThrottle={1}
          onScroll={e => {
            const newIndex = Math.round(e.nativeEvent.contentOffset.x / width);
            if (newIndex < 0 || newIndex >= data.length) return;
            if ((!loop && autoScroll) || !autoScroll) {
              setCurrent(newIndex);
              onSnapToItem?.(newIndex);
            }
          }}
          horizontal={true}
          decelerationRate={'normal'}
          snapToInterval={width}
          snapToAlignment={'start'}
          pagingEnabled={true}>
          {data.map((d, i) => (
            <ImageAndContentContainer
              key={i}
              onBack={d.onBack}
              style={[{width}, d.style]}
              image={d.image}
              imageContainerStyle={{
                maxHeight: imgHeight === 0 ? undefined : imgHeight,
              }}
              onImgLayout={height => {
                if (imgHeight === 0 || height < imgHeight) setImgHeight(height);
              }}
              bodyContainerStyle={d.bodyContainerStyle}
              header={d.header}
              subHeader={d.subHeader}
              body={d.body}>
              {d.children}
            </ImageAndContentContainer>
          ))}
        </ScrollView>

        <FooterContainer style={footerContainerStyle}>
          {primary}

          {secondary}

          {!!bottom && <BottomChildContainer>{bottom}</BottomChildContainer>}
        </FooterContainer>
      </Container>

      <View
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          height: imgHeight,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'flex-end',
          paddingBottom: 24,
          zIndex: 0,
        }}
        pointerEvents="none">
        {data.map((_, i) => (
          <Dot
            key={i}
            style={{
              backgroundColor:
                current === i ? appColors.white : appColors.black,
              zIndex: 2,
              ...(i === data.length - 1 ? {marginRight: 0} : {}),
            }}
          />
        ))}
      </View>
    </>
  );
};

const Container = styled(View)({
  flexDirection: 'column',
  backgroundColor: appColors.white,
  height: '100%',
});

const Dot = styled(View)({
  height: 11,
  width: 11,
  borderWidth: 1,
  borderRadius: 100,
  borderColor: appColors.white,
  borderStyle: 'solid',
  marginRight: 8.5,
});

const FooterContainer = styled(View)({
  height: 'auto',
  padding: 24,
  paddingTop: 12,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const BottomChildContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

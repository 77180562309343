import React from 'react';
import {ImageSourcePropType, StyleSheet, View, ViewStyle} from 'react-native';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {SharedValue, clamp, runOnJS} from 'react-native-reanimated';
import {useAnimatedStyle} from 'react-native-reanimated';

import {appColors} from '../constants';
import {Image} from '../shims/ImageWithFilter';

interface Props {
  color: string;
  value: SharedValue<number>;
  min?: number;
  max?: number;
  iconSource?: ImageSourcePropType;
  style?: ViewStyle;
  onChange?(value: number): void;
  onBegin?(value: number): void;
  onEnd?(value: number): void;
}

export const FatVerticalSlider: React.FC<Props> = ({
  value,
  min = 0,
  max = 1,
  iconSource,
  color,
  style,
  onBegin,
  onChange,
  onEnd,
}) => {
  const [height, setHeight] = React.useState(1);

  const clampValue = React.useCallback(
    (value: number) => {
      'worklet';
      const clampedMin = clamp(min, 0, 1);
      const clampedMax = clamp(max, 0, 1);

      return clamp(value, clampedMin, clampedMax);
    },
    [min, max],
  );

  const gesture = Gesture.Pan()
    .onChange(event => {
      value.value = clampValue(value.value - event.changeY / height);
      if (!onChange) return;
      runOnJS(onChange)(value.value);
    })
    .onBegin(() => {
      if (!onBegin) return;
      runOnJS(onBegin)(clampValue(value.value));
    })
    .onEnd(() => {
      if (!onEnd) return;
      runOnJS(onEnd)(clampValue(value.value));
    });

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{scaleY: value.value}],
  }));

  return (
    <View
      style={[styles.container, style]}
      onLayout={event => setHeight(event.nativeEvent.layout.height)}>
      <GestureDetector {...{gesture}}>
        <View style={styles.gestureContainer}>
          <Animated.View
            style={[styles.slider, {backgroundColor: color}, animatedStyle]}
          />
        </View>
      </GestureDetector>

      {iconSource && (
        <View pointerEvents="none">
          <Image source={iconSource} style={styles.icon} resizeMode="contain" />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    overflow: 'hidden',
    borderRadius: 9999,
    backgroundColor: appColors.black,
  },
  gestureContainer: {
    width: '100%',
    height: '100%',
  },
  slider: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transformOrigin: 'bottom',
  },
  icon: {
    position: 'absolute',
    width: '100%',
    height: 25,
    bottom: 25,
  },
});

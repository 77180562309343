import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {ReferralDto} from 'puffco-api-axios-client';

import {ReferralsStore, Store} from '../types';

const referralsSlice = createSlice({
  name: 'referrals',
  initialState: {
    all: [],
    new: undefined,
  } as ReferralsStore,
  reducers: {
    add(state, action: PayloadAction<{all: ReferralDto[]}>) {
      const {all} = action.payload;

      const newReferrals = all.filter(r => {
        if (r.isUsed) return false;

        return !state.all?.some(c => c.code === r.code);
      });

      return {
        all,
        new:
          newReferrals.length > 0
            ? newReferrals[newReferrals.length - 1]?.code
            : state.new,
      };
    },
    reset() {
      return {all: [], new: undefined};
    },
    clearNew(state) {
      return {...state, new: undefined};
    },
  },
});

export const newReferralCodeSelector = (state: Store) => state.referrals.new;

export const referralsActions = referralsSlice.actions;

export const referralsReducer = referralsSlice.reducer;

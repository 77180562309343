import {useNavigation} from '@react-navigation/core';
import React from 'react';

import {useOpenApp} from '../../lib/hooks/useOpenApp';
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigation/navigators/RootStackNavigator';
import {
  getNavigationRoute,
  getUrlFromOptions,
  routeToOptions,
} from '../../navigation/navigators/util';

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const withDeeplink = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const ComponentWithHOC: React.FC<P> = (props: P) => {
    const navigation = useNavigation<Navigation>();

    const redirect = getUrlFromOptions(
      routeToOptions(getNavigationRoute(navigation)),
    );

    useOpenApp(redirect);

    return <Component {...props} />;
  };

  ComponentWithHOC.displayName = `withDeeplink(${Component.displayName ?? ''})`;

  return ComponentWithHOC;
};

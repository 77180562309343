import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {IconName, StyledIcon} from '../../components';
import {appColors} from '../../constants';

export interface Props {
  title: string;
  iconName: IconName;
  onPress: () => void;
  badge?: React.ReactNode;
}

export const DrawerItem = ({onPress, title, iconName, badge}: Props) => (
  <View style={styles.container}>
    <TouchableOpacity style={styles.item} {...{onPress}}>
      <StyledIcon name={iconName} style={styles.icon} />

      <Text style={styles.text}>{title}</Text>

      {badge && <View style={styles.badge}>{badge}</View>}
    </TouchableOpacity>
  </View>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
  },
  item: {
    alignItems: 'center',
    width: '100%',
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  icon: {
    opacity: 0.5,
    tintColor: appColors.black,
  },
  text: {
    fontSize: 16,
    color: appColors.baseText,
    marginLeft: 6,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
  },
  badge: {marginLeft: 8},
});

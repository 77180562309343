import React from 'react';
import {FlatList, Text, View} from 'react-native';

import {CloseButton, Title} from '../../../../../components';
import {Constants, Strings, appColors} from '../../../../../constants';
import {TypeStyledAsset} from '../../../../../lib/api/content-access/types';
import styled from '../../../../../lib/styled';
import {addOpacityToColorHex} from '../../../../../lib/utilityFunctions';
import {AssetSlider} from './AssetSlider';
import {GalleryNavigationButton} from './GalleryNavigationButton';

const narrowScreen = Constants.DIMENSIONS.WIDTH < 400;

interface GalleryViewerModalProps {
  onClose: () => void;
  assets: TypeStyledAsset[];
  index?: number;
  showTitle?: boolean;
}

export const GalleryViewer: React.FC<GalleryViewerModalProps> = ({
  onClose,
  assets,
  index = 0,
  showTitle = false,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(index);

  const sliderRef = React.useRef<FlatList>(null);

  const scrollToIndex = (index: number) => {
    if (!sliderRef.current) return;

    sliderRef.current.scrollToIndex({index});
  };

  const onNext = () => {
    if (currentIndex === assets.length - 1) return;

    scrollToIndex(currentIndex + 1);
    setCurrentIndex(currentIndex + 1);
  };

  const onPrevious = () => {
    if (currentIndex === 0) return;

    scrollToIndex(currentIndex - 1);
    setCurrentIndex(currentIndex - 1);
  };

  if (!assets[currentIndex]) return;

  return (
    <GalleryContainer>
      <GalleryHeader>
        <CountText>
          {currentIndex + 1} / {assets.length}
        </CountText>

        {showTitle && (
          <GalleryTitle numberOfLines={1}>
            {assets[currentIndex].fields.title}
          </GalleryTitle>
        )}

        <CloseButton
          size={20}
          containerSize={25}
          color={appColors.white}
          onPress={onClose}
        />
      </GalleryHeader>

      <AssetSlider
        ref={sliderRef}
        assets={assets}
        index={currentIndex}
        onIndexChange={setCurrentIndex}
        animated
      />

      <GalleryFooter>
        {currentIndex > 0 && (
          <GalleryNavigationButton
            title={Strings.PREVIOUS}
            theme={{
              buttonColor: addOpacityToColorHex(appColors.white, 0.2),
              buttonTextColor: addOpacityToColorHex(appColors.white, 0.5),
            }}
            previous
            onPress={onPrevious}
          />
        )}

        {currentIndex < assets.length - 1 && (
          <GalleryNavigationButton title={Strings.NEXT} onPress={onNext} />
        )}
      </GalleryFooter>
    </GalleryContainer>
  );
};

export const GalleryContainer = styled(View)({
  width: '100%',
  flex: 1,
  backgroundColor: appColors.black,
  paddingTop: Constants.IS_NATIVE_ANDROID ? 24 : 64,
});

export const GalleryHeader = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,
  width: '100%',
  paddingHorizontal: 20,
});

export const GalleryFooter = styled(View)({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 12,
  marginTop: '10%',
  paddingHorizontal: 28,
  marginBottom: 40,
});

export const CountText = styled(Text)({
  color: addOpacityToColorHex(appColors.white, 0.5),
  fontSize: 16,
});

export const GalleryTitle = styled(Title)({
  fontSize: narrowScreen ? 14 : 16,
  textTransform: 'uppercase',
  fontFamily: 'Roboto-Bold',
});

import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Constants, Screens, appColors} from '../../constants';
import {
  MarketOptInConfirmedScreen,
  MarketOptInExistScreen,
} from '../../screens';
import {defaultNavigationOptions, withBackButton} from './common';

export type MarketOptInStackParamList = {
  [Screens.MarketOptInExist]: undefined;
  [Screens.MarketOptInConfirmed]: {
    userData: {
      email?: string;
      phoneNumber?: string;
      phoneNumberNewUser?: string;
    };
  };
};

const MarketOptInStack = createStackNavigator<MarketOptInStackParamList>();

export const MarketOptInStackNavigator = () => {
  return (
    <MarketOptInStack.Navigator
      screenOptions={{
        ...defaultNavigationOptions,
      }}
      initialRouteName={Screens.MarketOptInExist}>
      <MarketOptInStack.Screen
        name={Screens.MarketOptInExist}
        component={MarketOptInExistScreen}
        options={{
          ...defaultNavigationOptions,
          headerStatusBarHeight: 10,
          headerStyle: {
            height: Constants.IS_WEB ? 150 : undefined,
          },
          headerTitle: '',
          ...withBackButton({iconStyle: {tintColor: appColors.black}}),
        }}
      />
      <MarketOptInStack.Screen
        name={Screens.MarketOptInConfirmed}
        component={MarketOptInConfirmedScreen}
        options={{headerShown: false}}
      />
    </MarketOptInStack.Navigator>
  );
};

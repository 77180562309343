import {
  peakBaseShadow,
  peakPeachBlackLightsBaseLeft,
  peakPeachBlackLightsBaseRight,
  peakStorm,
  peakStormBase,
  peakStormChamberRegular,
  peakStormChamberXl,
  peakStormGlass,
} from '../assets/images';
// Import from exact file to prevent cycle
import {appColors} from '../constants/Colors';
import {
  ConnectScreenTheme,
  ControlCenterTheme,
  DabbingScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  PeakImageTheme,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from '../lib/types';
// Import from exact file to prevent cycle
import {addOpacityToColorHex} from '../lib/utilityFunctions/addOpacityToColorHex';
import {defaultTheme} from './DefaultTheme';
import {whitePeachTheme} from './WhitePeachTheme';

const BACKGROUND_COLOR = '#64687F';
const LINEAR_GRADIENT_ARRAY = ['rgba(49, 54, 78, 0)', 'rgba(49, 54, 78, 1)'];

const {
  lightLayers: {glass},
} = whitePeachTheme.peakImageTheme;

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: peakStorm,
  peakLayers: {
    glass: peakStormGlass,
    base: peakStormBase,
    chamber: {
      regular: peakStormChamberRegular,
      xl: peakStormChamberXl,
    },
    shadow: peakBaseShadow,
  },
  lightLayers: {
    glass,
    base: {
      left: peakPeachBlackLightsBaseLeft,
      right: peakPeachBlackLightsBaseRight,
    },
    ring: {},
  },
  peakDarkScreen: peakStorm,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: appColors.white,
  inactiveColor: 'rgba(255, 255, 255, 0.5)',
  backgroundColor: 'transparent',
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: appColors.white,
  buttonTextColor: appColors.black,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: appColors.black,
  durationFontColor: appColors.white,
  specularColorStart: appColors.clear,
  specularColorEnd: appColors.clear,
};
/* endregion: Components */

/* region: Screens */
const connectScreenTheme: ConnectScreenTheme = {
  ...defaultTheme.connectScreenTheme,
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl: 'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/storm.mp4',
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
  hoverBackgroundColor: appColors.gray,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,

  // General
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },

  // Status info
  batteryBarBackgroundColor: appColors.mediumGray50,
  batteryDisconnectedColor: addOpacityToColorHex(appColors.white, 0.5),
  preserveIndicator: appColors.black,
  statusTextStealthColor: appColors.black,
  statusTitleTextColor: appColors.white,
  stealthIconColor: appColors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: '#35384FFF', location: 0.15},
    {color: '#5B5F76FF', location: 0.75},
    {color: '#00000000', location: 1},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(appColors.white, 0.5),
  metricTextColor: appColors.white,
  metricTitleTextColor: addOpacityToColorHex(appColors.white, 0.5),
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: appColors.white,
  dabbingScreenFadedText: appColors.mediumGray50,
  dabbingButtonBackground: appColors.bone,
  dabbingButtonBackgroundPressed: appColors.black,
  dabbingButtonForeground: appColors.black,
  dabbingButtonForegroundPressed: appColors.bone,
  iconColor: appColors.white,
  textColor: appColors.white,
  fadedTextColor: appColors.mediumGray50,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: appColors.white,
  iconColor: appColors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: appColors.white,
  },
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  primaryColor: appColors.white,
  durationColor: appColors.white50,
  startTextStyle: {color: appColors.white, fontWeight: '400'},
  backgroundLineColor: appColors.gray,
};

const controlCenterTheme: ControlCenterTheme = {
  titleText: appColors.white,
  infoText: appColors.white,
  fatSliderColor: appColors.white,
  fatSliderBGColor: appColors.black,
  buttonActiveForeground: appColors.black,
  buttonInactiveForeground: appColors.white,
  buttonActiveBackground: appColors.white,
  buttonInactiveBackground: appColors.black50,
  blurBackgroundColor: 'rgba(51, 51, 51, 0.8)',
  background: {
    color: 'rgba(51, 51, 51, 0.8)',
  },
  boostScreenLabel: appColors.baseText,
};
/* endregion: Screens */

export const stormTheme: Theme = {
  ...defaultTheme,

  // Components
  primaryColor: appColors.white,
  primaryTextColor: appColors.white,
  logoTint: appColors.invisible,
  peakImageTheme,
  heatProfileCardTheme,
  sectionDividerColor: appColors.white20,
  sectionTitleTextColor: appColors.white,
  statusDisplayTextColor: appColors.white,
  spinnerColor: appColors.black,
  tabBarTheme,
  styledButtonTheme,

  // Screens
  assignToAHeatProfileScreenTheme: {
    ...defaultTheme.assignToAHeatProfileScreenTheme,
    background: {color: BACKGROUND_COLOR, gradient: LINEAR_GRADIENT_ARRAY},
  },
  connectScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  homeScreenTheme,
  heatProfileListScreenTheme,
  dabbingScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileSelectScreenTheme,
  controlCenterTheme,

  dabTickTheme: {
    color: appColors.white,
  },
};

import {MiddlewareValue} from './MiddlewareValue';
import {MoodType} from './MoodType';

// Scratchpad version notes:
// Doug: Do you think we could do something like breaking the version code into bitfields,
// so that if version & 0xF is 0 or 1 I know it's a lantern and the UUID can be found
// at byte 9, and if it's 3 or 4 it's a heat cycle and the UUID is at byte 29?

// So after the original 0, 1, 2, 3, 4 we started counting from the upper 4 bits

export enum LanternScratchpadId {
  LANTERN_EXCLUSIVE_NEWEST = 0, // Should match newest scratchpad version
  LANTERN_CUSTOM_NEWEST = 16,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  LANTERN_EXCLUSIVE_0 = 0,
  LANTERN_CUSTOM_0 = 1,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  LANTERN_CUSTOM_1 = 16,
}
export enum ProfileScratchpadId {
  PROFILE_NEWEST = 2,
  PROFILE_EXCLUSIVE_NEWEST = 3, // Should match newest scratchpad version
  PROFILE_CUSTOM_NEWEST = 19,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PROFILE_0 = 2,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PROFILE_EXCLUSIVE_0 = 3,
  PROFILE_CUSTOM_0 = 4,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PROFILE_CUSTOM_1 = 19,
}

export type ScratchpadId = LanternScratchpadId | ProfileScratchpadId;

export type LanternExclusive0 = {
  version: 0; // 0000 0000
  moodUlid: string;
};
export type LanternCustom0 = Omit<LanternExclusive0, 'version'> & {
  version: 1; // 0000 0001
  moodName: string;
  moodDateModified: number; // Date as seconds from UNIX epoch
  moodType: MoodType;
  tempo: number;
  colors: string[];
};
export type LanternCustom1 = Omit<LanternCustom0, 'version'> & {
  version: 16; // 0001 0000 -- see note at top of file
  originalMoodUlid: string;
};

export type Profile0 = {
  version: 2; // 0000 0010
  heatProfileUlid: string;
  heatProfileDateModified: number; // Date as seconds from UNIX epoch
};
export type ProfileExclusive0 = Omit<Profile0, 'version'> &
  Omit<LanternExclusive0, 'version'> & {
    version: 3; // 0000 0011
  };
export type ProfileCustom0 = Omit<Profile0, 'version'> &
  Omit<LanternCustom0, 'version'> & {
    version: 4; // 0000 0100
  };
export type ProfileCustom1 = Omit<ProfileCustom0, 'version'> &
  Omit<LanternCustom1, 'version'> & {
    version: 19; // 0001 0011
  };

// Should match newest scratchpad versions
export type LanternExclusiveNewest = LanternExclusive0;
export type LanternCustomNewest = LanternCustom1;
export type ProfileNewest = Profile0;
export type ProfileExclusiveNewest = ProfileExclusive0;
export type ProfileCustomNewest = ProfileCustom1;

export type Scratchpad =
  | LanternCustomNewest
  | LanternExclusiveNewest
  | ProfileNewest
  | ProfileCustomNewest
  | ProfileExclusiveNewest
  | LanternCustom0
  | LanternCustom1
  | LanternExclusive0
  | Profile0
  | ProfileCustom0
  | ProfileCustom1
  | ProfileExclusive0;

export type ScratchpadField<
  T extends Omit<Scratchpad, 'version'> | {scratchpadId: number},
> = {
  key: keyof T;
  type: MiddlewareValue;
  length?: number;
};

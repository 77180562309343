import React from 'react';
import {StyleSheet, View} from 'react-native';

import {Title} from '../../../components';
import {ErrorMessages, appColors} from '../../../constants';

export const ErrorContainer = () => (
  <View style={styles.container}>
    <Title style={styles.text}>{ErrorMessages.SOMETHING_WENT_WRONG}</Title>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: appColors.black,
  },
  text: {
    textAlign: 'center',
    fontWeight: '700',
    marginTop: 22,
  },
});

import BottomSheet from '@gorhom/bottom-sheet';
import React, {useMemo, useRef, useState} from 'react';
import {TouchableWithoutFeedback} from 'react-native';
import Animated from 'react-native-reanimated';

import {appColors, fillStyle} from '../constants';
import {MultiEmitter} from '../lib/MultiEmitter';
import {EmitterEvent} from '../lib/types';
import {BackHandler} from '../shims/BackHandler';

export const bottomSheetEmitter = new MultiEmitter<JSX.Element>();

export const bottomSheetBackPress = () => {
  BottomSheetComponent.close();
  return true;
};

export const BottomSheetComponent = {
  display: (element: JSX.Element) => bottomSheetEmitter.emit(element),
  close: () => bottomSheetEmitter.emit(EmitterEvent.CLOSE),
};

export const BottomSheetProvider = () => {
  const [currentElement, setCurrentElement] =
    React.useState<JSX.Element | null>(null);

  // Used as a workaround to remove Reanimated Bottom Sheet from the DOM after
  // use to prevent Safari web bug that pushes the keyboard up permanently.
  const [isVisible, setIsVisible] = React.useState(false);

  // web
  const snapPointsWeb = useMemo(() => ['50%'], []);
  const bottomSheetRef = useRef<BottomSheet>(null);
  const [sheetIndex] = useState<number>(0);

  React.useEffect(() => {
    const bottomSheetEventHandler = (element: JSX.Element | EmitterEvent) => {
      if (typeof element === 'string') {
        BackHandler.removeEventListener(
          'hardwareBackPress',
          bottomSheetBackPress,
        );

        bottomSheetRef.current?.snapToIndex(0);
        bottomSheetRef.current?.close();
        setIsVisible(false);
      } else {
        setIsVisible(true);
        setCurrentElement(element);
      }
    };

    bottomSheetEmitter.subscribe(bottomSheetEventHandler);
    return () => bottomSheetEmitter.unsubscribe(bottomSheetEventHandler);
  }, []);

  React.useEffect(() => {
    const handler = BackHandler.addEventListener(
      'hardwareBackPress',
      bottomSheetBackPress,
    );

    if (currentElement) {
      bottomSheetRef.current?.snapToIndex(0);
      bottomSheetRef.current?.close();
    }
    return () => handler.remove();
  }, [currentElement]);

  return isVisible ? (
    <>
      <TouchableWithoutFeedback
        onPress={() => {
          bottomSheetRef.current?.snapToIndex(0);
          bottomSheetRef.current?.close();
          setIsVisible(false);
        }}
        style={{...fillStyle, position: 'absolute'}}>
        <Animated.View
          style={[
            {
              ...fillStyle,
              position: 'absolute',
              backgroundColor: appColors.black50,
            },
          ]}
        />
      </TouchableWithoutFeedback>
      <BottomSheet
        ref={bottomSheetRef}
        index={sheetIndex}
        snapPoints={snapPointsWeb}
        handleIndicatorStyle={{display: 'none'}}
        handleComponent={null}
        backgroundStyle={{backgroundColor: 'transparent'}}>
        {currentElement}
      </BottomSheet>
    </>
  ) : null;
};

import {convertHexToRgbArray} from './convertHexToRgbArray';

/*
 * Function that shades a given hex color string by a given percentage.
 * Positive values will brighten while negative values will darken.
 * Example: shadeColor(color, -80) will darken by 80%.
 */
export function shadeColor(color: string, percent: number) {
  let [R, G, B] = convertHexToRgbArray(color);

  R = Math.round((R * (100 + percent)) / 100);
  G = Math.round((G * (100 + percent)) / 100);
  B = Math.round((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

import React from 'react';
import {Linking, View} from 'react-native';

import {base64 as PeakInstructionManualBase64} from '../../assets/pdf/Peak_Instruction_Manual_base64.json';
import {ImgBackground, SupportCard} from '../../components';
import {
  Constants,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {navigateToWebsite} from '../../lib/navigateToWebsite';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';

const source = {
  uri: `data:application/pdf;base64,${PeakInstructionManualBase64}`,
};

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<typeof Screens.Support>;

export const SupportScreen = ({navigation}: ScreenProps) => {
  useAdaptiveSafeArea();

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1, backgroundColor: appColors.black}}>
        <MainContainer>
          <SupportCard
            text="HOW-TO-VIDEOS"
            icon="play"
            onPress={() => navigateToWebsite('pages/the-peak-pro-support-page')}
          />

          <SupportCard
            text="TROUBLE-SHOOT"
            icon="exclamation"
            onPress={() => navigateToWebsite('pages/support')}
          />

          <SupportCard
            text="CONTACT US"
            icon="mail"
            onPress={() => navigation.navigate(Screens.ContactUs)}
          />

          <SupportCard
            text={Strings.USER_MANUAL}
            icon="book"
            onPress={() => {
              if (Constants.IS_WEB) {
                Linking.openURL(Constants.USER_MANUAL_URL);
              } else {
                navigation.navigate(Navigators.DeviceEducation, {
                  screen: Screens.UserManual,
                  params: {
                    source,
                  },
                });
              }
            }}
          />
        </MainContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const MainContainer = styled(View)({
  flex: 1,
  paddingHorizontal: 17,
  flexDirection: 'column',
  marginVertical: 50,
  justifyContent: 'flex-start',
});

import {Constants} from '../../constants';
import {Device} from '../types';
import {meetsMinimumFirmware} from '../utilityFunctions';
import {isPeach} from './isPeach';

export const hasLed3Api = (device: Device) => {
  if (device.product?.features?.includes('led-api-3')) return true;

  // TODO: remove these once users updated to the latest version
  const isAF = meetsMinimumFirmware(
    device.softwareRevisionString,
    Constants.MINIMUM_FIRMWARE_VERSION.LED3,
  );

  return isAF && isPeach(device);
};

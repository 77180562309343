import React from 'react';
import semver from 'semver';

import {appVersion} from '../../shims/AppVersion';
import {PageType, contentAccess} from '../api';
import {TypePage} from '../api/content-access/types';
import {useImmutableRemoteData} from './useRemoteData';

export interface LoadPagesOptions {
  type: PageType;
}

export interface LoadPageOptions {
  screenName: string;
}

const meetsMinimumVersion = (item: TypePage) => {
  if (!item.fields.minimumVersion) return true;

  return semver.gte(appVersion, item.fields.minimumVersion);
};

export const useLoadPages = ({type}: LoadPagesOptions) => {
  const {
    data,
    isLoading: loading,
    error,
  } = useImmutableRemoteData({key: 'useLoadPages', type}, () =>
    contentAccess
      .getPages({type})
      .then(r => r.items.filter(meetsMinimumVersion)),
  );

  return React.useMemo(
    () => ({
      loading,
      error,
      pages: data ?? [],
    }),
    [data, loading, error],
  );
};

export const useLoadPage = ({screenName}: LoadPageOptions) => {
  const {
    data,
    isLoading: loading,
    error,
  } = useImmutableRemoteData({key: 'useLoadPage', screenName}, () =>
    contentAccess.getPage({screenName}).then(item => {
      if (!meetsMinimumVersion(item)) return undefined;

      return item;
    }),
  );

  return React.useMemo(
    () => ({
      loading,
      error,
      page: data,
    }),
    [data, loading, error],
  );
};

import React from 'react';

import {ManualPdf, ManualPdfProps} from '../../components';
import {Screens} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {DeviceEducationNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';

export interface UserManualScreenProps {
  source: ManualPdfProps['source'];
}

interface ScreenProps
  extends DeviceEducationNavigatorScreenProps<typeof Screens.UserManual> {}

export const UserManualScreen: React.FC<ScreenProps> = ({route}) => {
  const source = route.params.source;

  useAdaptiveSafeArea();

  return <ManualPdf source={source} />;
};

import React from 'react';

import {GLOW_WD_HT_DEFAULT} from '../components/GlowingHalo';

export type RingProps = {
  colors: string[];
  strokeWidth?: number;
  glowWidthHeight?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const Ring = (props: RingProps) => {
  const {colors, glowWidthHeight = GLOW_WD_HT_DEFAULT, style, children} = props;
  const iOSConicStartAngle = [0, 0, -90, -80, -90, -90, -90];
  const size = glowWidthHeight * 0.85;
  const clrs = colors.length === 2 ? [...colors, colors[1]] : colors;
  const maskSize = size * 0.49;

  return (
    <div
      style={{
        ...style,
        width: size,
        height: size,
        borderRadius: '50%',
        opacity: size < 45 ? 0 : 1, // Hide ring if size is too small because of aliasing
        background:
          colors.length === 1
            ? `${colors[0]}`
            : `conic-gradient(from ${
                iOSConicStartAngle[colors.length]
              }deg, ${clrs.join(', ')}, ${clrs[0]})`,
        WebkitMask: `radial-gradient(transparent ${maskSize}px,#000 ${maskSize}px)`,
        mask: `radial-gradient(transparent ${maskSize}px,#000 ${maskSize}px)`,
      }}>
      {children}
    </div>
  );
};

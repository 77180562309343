import React from 'react';
import {StyleSheet} from 'react-native';

import {StyledButton, StyledButtonProps} from '../../../components';

export const CtaButton = ({...rest}: StyledButtonProps) => (
  <StyledButton textStyle={{...styles.text, ...rest.textStyle}} {...rest} />
);

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0.48,
  },
});

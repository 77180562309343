import React from 'react';
import {Linking, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {Constants, Messages, Strings, appColors} from '../../../constants';

export const SupportSection = () => (
  <View style={styles.questionText}>
    <Text style={styles.contactSupportText}>{Messages.HAVE_A_QUESTION} </Text>

    <TouchableOpacity onPress={() => Linking.openURL(Constants.CONTACT_URL)}>
      <Text
        style={[
          styles.contactSupportText,
          {
            textDecorationLine: 'underline',
            textTransform: 'capitalize',
          },
        ]}>
        {Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.CONTACT_SUPPORT}
      </Text>
    </TouchableOpacity>
  </View>
);

const styles = StyleSheet.create({
  questionText: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    letterSpacing: 0.32,
  },
  contactSupportText: {
    color: appColors.white,
    fontSize: 16,
    letterSpacing: 0.32,
    lineHeight: 22,
  },
});

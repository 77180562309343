import {appColors} from '../constants';
import {shadeColor} from './shadeColor';
import {isWhiteColor} from './utilityFunctions';

// Opacity factor: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export const getTransparentGradientColors = (
  color: string,
  opacityFactor = '30',
): string[] => [
  shadeColor(isWhiteColor(color) ? appColors.black : color, 0) + opacityFactor,
  appColors.opalWhite + '00',
];

export enum OperatingState {
  INIT_MEMORY = 0,
  INIT_VERSION_DISP = 1,
  INIT_BATTERY_DISP = 2,
  MASTER_OFF = 3,
  SLEEP = 4,
  IDLE = 5,
  TEMP_SELECT = 6,
  HEAT_CYCLE_PREHEAT = 7,
  HEAT_CYCLE_ACTIVE = 8,
  HEAT_CYCLE_FADE = 9,
  VERSION_DISP = 10,
  BATTERY_DISP = 11,
  FACTORY_TEST = 12,
  BLE_BONDING_ = 13,
}

import React from 'react';
import {useDebounce} from 'use-debounce';

import {StyledSlider, StyledSliderProps} from '../../../components';
import {appColors} from '../../../constants';
import {SliderUI} from '../../../lib/types';

export const Slider: React.FC<{
  title: string;
  ui: SliderUI;
  value: number;
  setValue: (val: number) => void;
  delay?: number;
  trackColor?: string;
  containerWidth?: StyledSliderProps['containerWidth'];
  containerMarginLeft?: StyledSliderProps['containerMarginLeft'];
  paddingHorizontal?: number;
}> = ({
  title,
  ui,
  value,
  setValue,
  containerWidth,
  trackColor,
  delay = 500,
  containerMarginLeft,
  paddingHorizontal,
}) => {
  const [sliderValue, setSliderValue] = React.useState(value);
  const [debouncedSliderValue] = useDebounce(sliderValue, delay);

  React.useEffect(() => {
    setSliderValue(value);
  }, [value]);

  React.useEffect(() => {
    setValue(debouncedSliderValue);
  }, [debouncedSliderValue]);

  return (
    <StyledSlider
      title={title}
      fontSize={12}
      fontFamily={'Roboto-Medium'}
      fontLetterSpacing={0.35}
      fontColor={appColors.white}
      maximumValue={ui?.max ?? 0}
      minimumValue={ui?.min ?? 0}
      step={ui?.step || 0.1}
      maximumTrackTintColor={trackColor ?? appColors.black}
      thumbTintColor={appColors.white}
      color={appColors.white}
      containerWidth={containerWidth ?? '94%'}
      containerMarginLeft={containerMarginLeft ?? 10}
      paddingHorizontal={paddingHorizontal}
      value={sliderValue}
      onValueChange={(value: number) =>
        setSliderValue(Number(Number(value).toFixed(2)))
      }
      message={
        ui.max === 1 ? `${Math.round(sliderValue * 100)}%` : `${sliderValue}`
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSlidingComplete={() => {}}
      hideTickers={true}
      titleMarginBottom={10}
      paddingBottom={10}
    />
  );
};

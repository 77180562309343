import {useIsFocused} from '@react-navigation/native';
import {ProfileVersion, TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Platform, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsync} from 'react-use';
import {ulid} from 'ulid';

import {
  AppText,
  DraggableFlatList,
  HeatProfileCard,
  SectionTitle,
  StyledIcon,
  SwappableSectionList,
  SwappableSectionListProps,
  SwappableSectionListRenderItem,
  SwitchableBackground,
} from '../../components';
import {
  Alerts,
  Constants,
  Messages,
  Navigators,
  Screens,
  interpolate,
} from '../../constants';
import {appLog} from '../../lib/Logger';
import {useGetMoodLights} from '../../lib/api/MoodLight.hooks';
import {userApi} from '../../lib/api/apis';
import {useAppDispatch, useChamberType} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  enforceProfileOrder,
  insertProfile,
  maxProfileGuard,
  mergeProfilesImmutable,
  migrateProfileToT,
  profilesMatch,
  swapProfiles,
  transformApiProfileToProfile,
} from '../../lib/profileFunctions';
import {recursiveDeepCopy} from '../../lib/recursiveDeepCopy';
import {
  connectedPeakSelector,
  currentDeviceSWRevisionSelector,
} from '../../lib/redux/bleSlice';
import {
  getMoodLightSelector,
  upsertAccountMoodLights,
} from '../../lib/redux/moodLightSlice';
import {
  activeProfilesSelector,
  archiveProfilesSelector,
  setArchiveProfiles,
} from '../../lib/redux/profilesSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {
  HeatProfileType,
  Profile,
  ProfilePreT,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {useTheme} from '../../lib/useTheme';
import {isLegacyUser, verifyGuard} from '../../lib/userFunctions';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {HomeTabNavigatorScreenProps} from '../../navigation/navigators/HomeTabNavigator';
import {
  toHeatProfileCreate,
  toHeatProfileList,
} from '../../navigation/navigators/util';
import {Alert} from '../../shims/alert';
import {guardianTheme} from '../../themes';

export interface Props {
  shareId?: string;
}

interface ScreenProps
  extends HomeTabNavigatorScreenProps<typeof Screens.HeatProfileList> {}

export const HeatProfileListScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const dispatch = useAppDispatch();
  const getMoodLight = useSelector(getMoodLightSelector);
  const isWeb = Platform.OS === 'web';
  const insets = useAdaptiveSafeArea();

  const isScrolling = React.useRef(false);

  const theme = useTheme();
  const {
    navMenuIconColor,
    navMenuTitleStyle,
    primaryTextColor,
    heatProfileListScreenTheme: {background, hoverBackgroundColor},
    tabBarTheme,
  } = theme;

  // Redux
  const user = useSelector(userSelector);
  const archives: Profile[] = useSelector(archiveProfilesSelector);
  const actives: Profile[] = useSelector(activeProfilesSelector);
  const peak = useSelector(connectedPeakSelector);
  const deviceSWRevision = useSelector(currentDeviceSWRevisionSelector);
  const [tempArchives, setTempArchives] = React.useState<Profile[]>(archives);
  const [tempActives, setTempActives] = React.useState<Profile[]>(actives);
  const archiveSectionTitle = `ARCHIVE ${tempArchives.length}/${Constants.MAX_HEAT_PROFILES}`;

  const {isVaporEnabled} = useChamberType();

  const withDisconnectedAlert = React.useCallback(
    (cb: () => void) => {
      if (!peak) {
        Alert.alert(Alerts.NOT_CONNECTED, Alerts.CONNECT_TO_DEVICE);
        return;
      }

      cb();
    },
    [peak],
  );

  const onPressAdd = React.useCallback(() => {
    if (user) {
      verifyGuard(
        user,
        () => {
          withDisconnectedAlert(() =>
            navigation.navigate(...toHeatProfileCreate().value),
          );
        },
        navigation.navigate,
        user.defaultUsername || isLegacyUser(user), // legacy user can create
      );
    } else {
      navigation.navigate(Screens.Login, {
        redirect: toHeatProfileList.encode(),
      });
    }
  }, [user, navigation, withDisconnectedAlert]);

  const {shareId} = route.params ?? {};

  const onAddButtonPress = React.useCallback(() => {
    if (!onPressAdd || !archives) return;

    maxProfileGuard(archives.length, onPressAdd);
  }, [onPressAdd, archives]);

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: 'HEAT PROFILES',
      headerTitleStyle: navMenuTitleStyle,
      headerRight: () => (
        <StyledIcon
          name="add"
          onPress={onAddButtonPress}
          iconStyle={{tintColor: navMenuIconColor}}
        />
      ),
    });
  }, [navMenuIconColor, navMenuTitleStyle, navigation, onAddButtonPress]);

  useAsync(async () => {
    if (!user) return;

    const profiles = await userApi
      .getHeatProfiles({id: 'me'})
      .then(({data}) => data as unknown[] as Profile[])
      .then(profiles =>
        profiles.sort((a: Profile, b: Profile) => a.order - b.order),
      );

    const mergedProfiles = mergeProfilesImmutable(archives, profiles).filter(
      profile => !profile.deleted,
    );

    dispatch(
      setArchiveProfiles(
        mergedProfiles.map(profile =>
          transformApiProfileToProfile(
            meetsMinimumFirmware(
              deviceSWRevision,
              Constants.MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
            )
              ? migrateProfileToT(profile)
              : profile,
          ),
        ),
      ),
    );
  }, [deviceSWRevision, user]);

  const [{value: moodLights, error}, getMoodLights] = useGetMoodLights();

  const filteredArchives =
    tempActives &&
    tempArchives &&
    tempArchives.filter(
      archiveProfile =>
        tempActives.findIndex(activeProfile =>
          profilesMatch(activeProfile, archiveProfile),
        ) === Constants.INDEX_NOT_FOUND && !archiveProfile.deleted,
    );
  const isFocused = useIsFocused();

  React.useEffect(() => {
    if (!shareId) return;

    // Clear shareId, so we won't navigate to SharedHeatProfileScreen again
    navigation.setParams({shareId: undefined});

    navigation.navigate(Navigators.SharedHeatProfile, {
      screen: Screens.SharedHeatProfile,
      params: {shareId},
    });
  }, [shareId, navigation]);

  React.useEffect(() => {
    if (user) {
      setTempActives(actives);
      setTempArchives(archives);
    } else {
      setTempActives(actives);
      setTempArchives([]);
    }
  }, [user]);

  React.useEffect(() => {
    setTempArchives(archives);
  }, [archives]);

  React.useEffect(() => {
    setTempActives(actives);
    ////////////TODO: REVIST is there a more explicit way to refresh archives when user is editing after profile save
  }, [actives]);

  React.useEffect(() => {
    if (user && isFocused) {
      getMoodLights();
    }
  }, [user, isFocused]);

  React.useEffect(() => {
    if (moodLights && !error) {
      dispatch(upsertAccountMoodLights(moodLights));
    }
  }, [moodLights]);

  const removeProfile = React.useCallback(
    (id: string) => {
      const index = tempArchives.findIndex(item => item.id === id);
      Alert.alert(
        Alerts.CONFIRM_DELETION,
        interpolate(Alerts.CONFIRM_DELETE_PROFILE_BODY, {
          profileName: tempArchives[index].name.toUpperCase(),
        }),
        [
          {text: 'Cancel', style: 'cancel'},
          {
            text: 'OK',
            onPress: () => {
              const newArchives = recursiveDeepCopy(tempArchives);
              newArchives[index].deleted = true;
              setTempArchives(newArchives);
            },
          },
        ],
        {cancelable: false},
      );
    },
    [tempArchives],
  );

  const renderItem: SwappableSectionListRenderItem<Profile> = React.useCallback(
    ({info, startDrag, endDrag}) => {
      const {item: profile, section} = info;
      const active = section.key === 'ACTIVE';
      const moodLightId =
        isTHeatProfileMoodLight(profile) && profile.moodLightId;
      const moodLight = getMoodLight(moodLightId || '');

      return (
        <HeatProfileCard
          moodLight={moodLight}
          theme={theme}
          profile={profile}
          profileDelete={removeProfile}
          active={active}
          editMode={false}
          onLongPress={startDrag}
          onPressOut={endDrag}
          isVaporEnabled={isVaporEnabled}
          onPress={() => {
            withDisconnectedAlert(() =>
              navigation.navigate(Screens.HeatProfileSelect, {
                profile,
                type: active ? HeatProfileType.Active : HeatProfileType.Archive,
              }),
            );
          }}
        />
      );
    },
    [
      withDisconnectedAlert,
      getMoodLight,
      navigation,
      removeProfile,
      theme,
      isVaporEnabled,
    ],
  );

  const renderItemWeb = React.useCallback(
    ({item, index, move, moveEnd}: any) => {
      const active = index >= 0 && index < 4 ? true : false;
      if (index === 4) {
        return (
          <SectionTitle
            titleStyle={{
              fontFamily: 'Roboto-Regular',
              letterSpacing: 0.05,
              fontWeight: '400',
            }}
            title={archiveSectionTitle}
          />
        );
      }
      const moodLightId = isTHeatProfileMoodLight(item) && item.moodLightId;
      const moodLight = getMoodLight(moodLightId || '');

      return (
        <HeatProfileCard
          moodLight={moodLight}
          borderColor="transparent"
          theme={theme}
          profile={item}
          profileDelete={removeProfile}
          active={active}
          editMode={false}
          onLongPress={move}
          onPressOut={moveEnd}
          isVaporEnabled={isVaporEnabled}
          onPress={() => {
            setTimeout(() => {
              if (!isScrolling.current) {
                withDisconnectedAlert(() =>
                  navigation.navigate(Screens.HeatProfileSelect, {
                    profile: item,
                    type: active
                      ? HeatProfileType.Active
                      : HeatProfileType.Archive,
                  }),
                );
              }
            }, 100);
          }}
        />
      );
    },
    [
      archiveSectionTitle,
      withDisconnectedAlert,
      getMoodLight,
      navigation,
      removeProfile,
      theme,
      isVaporEnabled,
    ],
  );

  const getProfilesForListWeb = () => {
    const list = recursiveDeepCopy(tempActives)
      .sort((a: Profile, b: Profile) => a.order - b.order)
      .concat(
        recursiveDeepCopy(filteredArchives).sort(
          (a: Profile, b: Profile) => a.order - b.order,
        ),
      );
    const headerItem: ProfilePreT = {
      id: 'header-id',
      order: 4,
      name: 'Header',
      temperature: 0,
      duration: 0,
      color: '#000000',
      units: TemperatureUnit.Fahrenheit,
      version: ProfileVersion.PreT,
      modified: 0,
    };
    list.splice(4, 0, headerItem);
    return list;
  };

  const replace: SwappableSectionListProps<Profile>['onChange'] = (
    from,
    to,
  ) => {
    // active -> active = swap (reorder in aws)
    // archive -> archive = swap (reorder in awas)
    // active -> archive = swap/insert ( only if there is no equal heat profile in archives (match except: id, created, modified, order)) || swap/reorder if already in aws
    // archive -> active = swap/insert ( only if there is no equal heat profile in archives (match except: id, created, modified, order)) || swap/reorder if already in aws
    const newActives: Profile[] = recursiveDeepCopy(tempActives);
    const newArchives: Profile[] = recursiveDeepCopy(tempArchives);
    const fromIndex = from.data[0]?.order;
    const toIndex = to.data[0]?.order;

    if (from.key === 'ACTIVE' && to.key === 'ACTIVE') {
      swapProfiles(newActives, newActives, fromIndex, toIndex);
    } else if (from.key === 'ARCHIVE' && to.key === 'ARCHIVE') {
      swapProfiles(newArchives, newArchives, fromIndex, toIndex);
    } else if (from.key === 'ACTIVE' && to.key === 'ARCHIVE' && user) {
      insertProfile({
        activeIndex: fromIndex,
        archiveIndex: toIndex,
        currentArchives: tempArchives,
        newActives,
        newArchives,
        userId: user.id,
      });
    } else if (from.key === 'ARCHIVE' && to.key === 'ACTIVE' && user) {
      insertProfile({
        activeIndex: toIndex,
        archiveIndex: fromIndex,
        currentArchives: tempArchives,
        newActives,
        newArchives,
        userId: user.id,
      });
    } else {
      appLog.error(
        'something wrong happened in reorder() in HeatProfileListScreen.tsx',
      );
      return;
    }

    enforceProfileOrder(newActives);
    enforceProfileOrder(newArchives);

    setTempActives(newActives);
    setTempArchives(newArchives);
  };

  if (!actives || !archives || !tempArchives || !tempActives) {
    return null;
  }

  const replaceWeb = (fromIndex: number, toIndex: number) => {
    if (filteredArchives.length === 0) return;
    const fromKey = fromIndex < 4 ? 'ACTIVE' : 'ARCHIVE';
    const toKey = toIndex < 4 ? 'ACTIVE' : 'ARCHIVE';
    const newActives: Profile[] = recursiveDeepCopy(tempActives);
    const newArchives: Profile[] = recursiveDeepCopy(
      fromKey === 'ARCHIVE' && toKey === 'ARCHIVE'
        ? filteredArchives
        : tempArchives,
    );

    const insertProfile = (activeIndex: number, archiveIndex: number) => {
      if (activeIndex >= 0 && archiveIndex >= 0 && user) {
        const activeProfile = recursiveDeepCopy(newActives[activeIndex]);
        const archiveProfile = recursiveDeepCopy(
          filteredArchives[archiveIndex],
        );

        //check if profile is in archive list already
        const duplicateIndex = tempArchives.findIndex(archiveProfile =>
          profilesMatch(archiveProfile, activeProfile),
        );
        if (duplicateIndex === Constants.INDEX_NOT_FOUND) {
          maxProfileGuard(
            tempArchives.filter(profile => !profile.deleted)?.length,
            () => {
              //prepare insert by shifting archives
              for (let i = archiveIndex; i < newArchives.length; i++) {
                newArchives[i].order++;
              }
              //insert
              newArchives.splice(archiveIndex, 0, activeProfile);
              newArchives[archiveIndex].id = ulid();
              newArchives[archiveIndex].userId = user.id;
              newActives[activeIndex] = archiveProfile;
            },
          );
        } else {
          swapProfiles(newArchives, newArchives, duplicateIndex, archiveIndex);
          newActives[activeIndex] = archiveProfile;
        }
      }
    };

    if (fromKey === 'ACTIVE' && toKey === 'ACTIVE') {
      swapProfiles(newActives, newActives, fromIndex, toIndex);
    } else if (fromKey === 'ARCHIVE' && toKey === 'ARCHIVE') {
      swapProfiles(newArchives, newArchives, fromIndex - 4, toIndex - 4);
    } else if (fromKey === 'ACTIVE' && toKey === 'ARCHIVE') {
      insertProfile(fromIndex, toIndex - 4);
    } else if (fromKey === 'ARCHIVE' && toKey === 'ACTIVE') {
      insertProfile(toIndex, fromIndex - 4);
    } else {
      appLog.error(
        'something wrong happened in reorder() in HeatProfileListScreen.tsx',
      );
      return;
    }

    enforceProfileOrder(newActives);
    enforceProfileOrder(newArchives);

    setTempActives(newActives);
    setTempArchives(newArchives);
  };

  return (
    <SwitchableBackground {...{background}}>
      {isFocused ? (
        <View
          style={{
            flex: 1,
            height: isWeb ? Dimensions.get('screen').height : '100%',
            paddingTop: insets.top,
            paddingBottom:
              insets.bottom +
              tabBarTheme.height -
              (isWeb || theme !== guardianTheme ? 0 : 4),
          }}>
          <ScreenContainer>
            <View
              style={{
                flex: 1,
                width: '100%',
                paddingHorizontal: 10,
              }}>
              {isWeb ? (
                <DraggableFlatList
                  // eslint-disable-next-line
                  // @ts-ignore
                  ListHeaderComponent={
                    <SectionTitle
                      titleStyle={{
                        fontFamily: 'Roboto-Regular',
                        fontWeight: '400',
                        letterSpacing: 0.05,
                      }}
                      title={'ACTIVE'}
                    />
                  }
                  data={getProfilesForListWeb()}
                  renderItem={renderItemWeb}
                  keyExtractor={(item: Profile) => item.id + item.order}
                  onScroll={() => (isScrolling.current = true)}
                  onScrollEnd={() => (isScrolling.current = false)}
                  onMoveEnd={({from, to}: any) => {
                    replaceWeb(from, to);
                  }}
                  hoverBackgroundColor={hoverBackgroundColor}
                />
              ) : (
                <SwappableSectionList
                  editingList={false}
                  sections={[
                    {
                      key: 'ACTIVE',
                      data: recursiveDeepCopy(tempActives).sort(
                        (a: Profile, b: Profile) => a.order - b.order,
                      ),
                    },
                    {
                      key: `ARCHIVE`,
                      data: recursiveDeepCopy(filteredArchives).sort(
                        (a: Profile, b: Profile) => a.order - b.order,
                      ),
                    },
                  ]}
                  renderItem={renderItem}
                  contentContainerStyle={
                    theme !== guardianTheme && {paddingBottom: 4}
                  }
                  renderSectionHeader={item => (
                    <SectionTitle
                      titleStyle={{
                        fontFamily: 'Roboto-Regular',
                        fontWeight: '400',
                        letterSpacing: 0.05,
                      }}
                      title={
                        (item.section.key === 'ARCHIVE'
                          ? archiveSectionTitle
                          : item.section.key) || ''
                      }
                    />
                  )}
                  insertionMapping={[
                    {from: 'ARCHIVE', to: 'ARCHIVE'},
                    {from: 'ACTIVE', to: 'ARCHIVE'},
                  ]}
                  keyExtractor={item => item.id + item.order}
                  onChange={replace}
                  stickySectionHeadersEnabled={false}
                  hoverBackgroundColor={hoverBackgroundColor}
                />
              )}
            </View>

            {!user && (
              <View
                style={{
                  width: '100%',
                  paddingVertical: 12,
                }}>
                <AppText
                  style={{
                    textAlign: 'center',
                    paddingHorizontal: 40,
                    color: primaryTextColor,
                  }}>
                  {Messages.HEAT_PROFILE_NOTICE}
                </AppText>
                <AppText
                  onPress={() => {
                    navigation.navigate(Screens.Login, {
                      redirect: toHeatProfileList.encode(),
                    });
                  }}
                  style={{
                    textDecorationLine: 'underline',
                    textAlign: 'center',
                    color: primaryTextColor,
                  }}>
                  Log in
                </AppText>
              </View>
            )}
          </ScreenContainer>
        </View>
      ) : null}
    </SwitchableBackground>
  );
};

const ScreenContainer = styled(View)({
  alignItems: 'center',
  paddingTop: 70,
  flex: 1,
});

import {
  HeatProfileUpdateDto,
  MoodLightCategory,
  TemperatureUnit,
} from 'puffco-api-axios-client';
import {useDispatch, useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';

import {updateArchiveProfile} from '../redux/profilesSlice';
import {userSelector} from '../redux/userSlice';
import {MoodLight} from '../types/MoodLight';
import {Profile} from '../types/Profile';
import {heatprofileApi} from './apis';
import {AsyncWrapperOptions, withAsyncWrapper} from './asyncWrapper';

export type UpdateHeatProfileDto = Profile & {
  userId: number;
  moodLight?: MoodLight & {userId: number};
};

const convertUpdateHeatProfileToDto = ({
  units,
  modified,
  moodLight,
  ...dto
}: UpdateHeatProfileDto): HeatProfileUpdateDto => ({
  ...dto,
  units: TemperatureUnit[units],
  ...(modified && {modified: new Date(modified).getTime()}),
  ...(moodLight &&
    moodLight.category === MoodLightCategory.Custom && {
      moodLight: {
        ...moodLight,
        category: moodLight.category,
        userId: dto.userId,
      },
    }),
});

export const updateHeatProfiles = async (profiles: UpdateHeatProfileDto[]) => {
  const heatProfileUpdateDto = profiles.map<HeatProfileUpdateDto>(
    convertUpdateHeatProfileToDto,
  );

  const {data} = await heatprofileApi.updateProfiles({heatProfileUpdateDto});

  return data;
};

export function useUpdateHeatProfiles() {
  return useAsyncFn(updateHeatProfiles, []);
}

export function useUpdateHeatProfileWithSuccessError({
  onSuccess,
  onError,
}: AsyncWrapperOptions<Profile | undefined>) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return useAsyncFn(
    withAsyncWrapper(
      async (profile: UpdateHeatProfileDto) => {
        if (!user) return;

        const heatProfileUpdateDto = [convertUpdateHeatProfileToDto(profile)];

        const data = await heatprofileApi
          .updateProfiles({
            heatProfileUpdateDto,
          })
          .then(({data}) => data[0] as unknown as Profile);

        dispatch(updateArchiveProfile(data));

        return data;
      },
      {onSuccess, onError},
    ),
    [user],
  );
}

import {
  Analytics as FirebaseAnalyticsType,
  setUserProperties,
} from 'firebase/analytics';

import {UserProperties} from '../user-properties';
import {UserTracker} from '../user-tracker';

export class FirebaseUserTracker implements UserTracker {
  constructor(private analytics?: FirebaseAnalyticsType) {}

  set<T extends keyof UserProperties>(key: T, value: UserProperties[T]): this {
    if (!this.analytics) return this;

    setUserProperties(this.analytics, {[key]: value});
    return this;
  }
}

import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {ActivityIndicator, Text, View} from 'react-native';
import {useSelector} from 'react-redux';

import {
  HeatProfileCard,
  SectionTitle,
  StyledIcon,
  SwappableSectionList,
  SwappableSectionListRenderItem,
  SwitchableBackground,
} from '../../components';
import {Alerts, ErrorMessages, Messages, Screens} from '../../constants';
import {useUpdateHeatProfiles} from '../../lib/api/HeatProfile.hooks';
import {
  useAppDispatch,
  useGetSwappedProfileValues,
  useLantern,
} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  enforceProfileOrder,
  getActiveProfileMoodLights,
  insertProfile,
} from '../../lib/profileFunctions';
import {recursiveDeepCopy} from '../../lib/recursiveDeepCopy';
import {connectedPeakSelector} from '../../lib/redux/bleSlice';
import {
  getMoodLightSelector,
  setPeakMoodLights,
  upsertAccountMoodLights,
} from '../../lib/redux/moodLightSlice';
import {
  activeProfilesSelector,
  archiveProfilesSelector,
  setActiveProfiles,
  setArchiveProfiles,
} from '../../lib/redux/profilesSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {Profile, isTHeatProfileMoodLight} from '../../lib/types';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useTheme} from '../../lib/useTheme';
import {HomeEmulatedDrawerNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Alert} from '../../shims/alert';

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<typeof Screens.SaveToDevice> {}

export const SaveToDeviceScreen = ({route, navigation}: Props) => {
  useDisconnectGuard();
  const theme = useTheme();
  const {navMenuIconColor, saveToDeviceScreenTheme, spinnerColor} = theme;
  const {background, infoLabelColor} = saveToDeviceScreenTheme;

  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);
  const peak = useSelector(connectedPeakSelector);
  const actives = useSelector(activeProfilesSelector);
  const archives = useSelector(archiveProfilesSelector);
  const getMoodLight = useSelector(getMoodLightSelector); // Requires a minimum of firmware T
  const [{loading: isLoading}, updateApiArchives] = useUpdateHeatProfiles();
  const [hasUpdated, setHasUpdated] = React.useState(false);
  useAdaptiveSafeArea();

  const archiveIndex: number = route.params?.archiveIndex;
  const profile = archives[archiveIndex];
  useLantern(
    isTHeatProfileMoodLight(profile)
      ? getMoodLight(profile.moodLightId)
      : profile?.color,
  );

  React.useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <StyledIcon
          name={'chevronLeft'}
          onPress={() => navigation.goBack()}
          iconStyle={{tintColor: navMenuIconColor}}
        />
      ),
    });
  }, [navigation, navMenuIconColor]);

  const getSwappedValues = useGetSwappedProfileValues();

  const updateProfileSwap = (profile: Profile) => {
    if (!user) return;

    const activeIndex = profile.order;
    const newActives = cloneDeep(actives);
    const newArchives = cloneDeep(archives);

    if (
      insertProfile({
        activeIndex,
        archiveIndex,
        currentArchives: archives,
        newActives,
        newArchives,
        userId: user.id,
      })
    ) {
      enforceProfileOrder(newActives);
      enforceProfileOrder(newArchives);

      const {
        newAccountMoodLights,
        newPeakMoodLights,
        updatedArchives,
        updateHeatProfileDtos,
      } = getSwappedValues({
        swappedActives: newActives,
        swappedArchives: newArchives,
        userId: user?.id,
      });

      if (!updateHeatProfileDtos.length) return;

      updateApiArchives(updateHeatProfileDtos)
        .then(() => {
          if (newActives && updatedArchives) {
            const saveActives = cloneDeep(newActives);

            // Update profiles and mood lights in Redux
            dispatch(upsertAccountMoodLights(newAccountMoodLights));
            dispatch(setPeakMoodLights(newPeakMoodLights));
            dispatch(setArchiveProfiles(cloneDeep(updatedArchives)));
            dispatch(setActiveProfiles(saveActives));

            // Update profiles and mood lights on peak
            peak?.writeHeatProfiles(
              saveActives,
              getActiveProfileMoodLights(saveActives, getMoodLight),
            );

            setHasUpdated(true);

            navigation.popToTop();
          }
        })
        .catch(() => {
          Alert.alert(Alerts.NETWORK_ERROR, ErrorMessages.NETWORK_ERROR);
        });
    }
  };

  const renderItem: SwappableSectionListRenderItem<Profile> = ({info}) => {
    const {item: profile} = info;
    const moodLightId = isTHeatProfileMoodLight(profile) && profile.moodLightId;
    const moodLight = getMoodLight(moodLightId || '');

    return (
      <HeatProfileCard
        moodLight={moodLight}
        theme={theme}
        profile={profile}
        active={true}
        {...(!isLoading &&
          !hasUpdated && {
            onPress: () => updateProfileSwap(profile),
          })}
      />
    );
  };

  return (
    <SwitchableBackground background={background}>
      <SafeAreaView
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {isLoading && <Spinner size={'large'} color={spinnerColor} />}

        <Container>
          <InfoLabel style={{color: infoLabelColor}}>
            {Messages.SAVE_TO_DEVICE_TEXT}
          </InfoLabel>

          <ListContainer>
            <SwappableSectionList
              editingList={false}
              sections={[
                {
                  key: 'ACTIVE',
                  data: recursiveDeepCopy(actives).sort(
                    (a: Profile, b: Profile) => a.order - b.order,
                  ),
                },
              ]}
              keyExtractor={item => item.id + item.order}
              renderItem={renderItem}
              renderSectionHeader={item => (
                <SectionTitle title={item.section.key ?? ''} />
              )}
              stickySectionHeadersEnabled={false}
            />
          </ListContainer>
        </Container>
      </SafeAreaView>
    </SwitchableBackground>
  );
};

const Spinner = styled(ActivityIndicator)({
  position: 'absolute',
  zIndex: 1,
});

const Container = styled(View)({
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 20,
});

const InfoLabel = styled(Text)({
  marginTop: 80,
  marginBottom: 20,
  marginHorizontal: 20,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 12,
  letterSpacing: 0.37,
  lineHeight: 15,
});

const ListContainer = styled(View)({
  width: '100%',
  height: '100%',
  paddingHorizontal: 10,
});

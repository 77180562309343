import {useIsFocused} from '@react-navigation/native';
import colorsys from 'colorsys';
import React from 'react';
import {
  Animated,
  Image,
  PanResponder,
  PanResponderGestureState,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
import {Path, Svg} from 'react-native-svg';

import {colorWheel} from '../assets/images';
import {
  addPicker,
  pickerShadow,
  pickerThickBorder,
  pickerThinBorder,
  removePicker,
} from '../assets/images';
import {AppText} from '../components';
import {Constants, appColors, centeredColumnStyle} from '../constants';
import {getColorBrightness} from '../lib/getColorBrightness';
import {useAppDispatch, useWindowWidth} from '../lib/hooks';
import {bleReadDeviceSettings} from '../lib/redux/thunk';
import styled from '../lib/styled';
import {
  convertCartesianToPolar,
  convertDegreesToRadians,
  convertPolarToCartesian,
  convertRadiansToDegrees,
} from '../lib/utilityFunctions';

const PICKER_HEIGHT = 39;
const PICKER_WIDTH = 26;
const PICKER_MARGIN_TOP = 15;
const PICKER_OFFSET = PICKER_HEIGHT / 2 + PICKER_MARGIN_TOP;
const BORDER_OFFSET = 50; // Allows picker to leave edge on top and left
const NEW_COLORS = Constants.STOCK_MOOD_COLORS;
const COLOR_WHEEL_ROTATION_ANGLE = 270; // In degrees

export type MoodLightPickerProps = {
  diameter: number;
  onValueChange: (colorArray: string[]) => void;
  onValueChangeRelease: (colorArray: string[]) => void;
  colors?: string[];
};

export const MoodLightPicker = (props: MoodLightPickerProps) => {
  const {windowWidth: width} = useWindowWidth();
  const dispatch = useAppDispatch();
  const [pans] = React.useState([
    new Animated.ValueXY(),
    new Animated.ValueXY(),
    new Animated.ValueXY(),
    new Animated.ValueXY(),
    new Animated.ValueXY(),
    new Animated.ValueXY(),
  ]);
  const isFocused = useIsFocused();
  const {diameter, onValueChange, onValueChangeRelease, colors} = props;
  const radius = diameter / 2;
  const startingColors = colors ?? [NEW_COLORS[0]];
  const [tempColors, setTempColors] = React.useState<string[]>(startingColors);
  const [totalOffset, setTotalOffset] = React.useState({x: 0, y: 0});
  const pickerRef = React.useRef<Image>();
  const [activePicker, setActivePicker] = React.useState(1);

  React.useEffect(() => {
    if (!isFocused) dispatch(bleReadDeviceSettings());
  }, [isFocused]);

  React.useEffect(() => {
    setTempColors(colors ?? [NEW_COLORS[0]]);
  }, [colors]);

  const getBoundedValues = React.useCallback(
    ({moveX, moveY}: PanResponderGestureState) => {
      const xOffset = -totalOffset.x - radius;
      const yOffset = -totalOffset.y - radius + PICKER_OFFSET;

      // Convert to Cartesian
      const {cartesianX, cartesianY} = {
        cartesianX: moveX + xOffset,
        cartesianY: moveY + yOffset,
      };

      // Convert to Polar
      const {radius: polarRadius, angle} = convertCartesianToPolar(
        cartesianX,
        cartesianY,
      );

      // Bound radius length and convert back to cartesian
      const {x: boundedX, y: boundedY} = convertPolarToCartesian(
        Math.min(polarRadius, radius - 4),
        angle,
      );

      return {x: boundedX - xOffset, y: boundedY - yOffset}; // Convert back to pan responder coordinates
    },
    [radius, totalOffset],
  );

  const getCoordinates = (startColor: string) => {
    const {h, s} = colorsys.hex2Hsv(startColor);
    const r = (s / 100) * radius; // was normalized
    const angle = convertDegreesToRadians(h + COLOR_WHEEL_ROTATION_ANGLE);
    const {x, y} = convertPolarToCartesian(r, angle);
    const textX = -x + radius - PICKER_WIDTH / 2 + BORDER_OFFSET / 2 - 2;
    const textY =
      y + radius - PICKER_HEIGHT / 2 + BORDER_OFFSET / 2 - PICKER_OFFSET;

    return {x: textX, y: textY};
  };

  const handleLayoutChange = () => {
    if (pickerRef?.current && pickerRef.current.measure) {
      pickerRef.current.measure(
        (
          _fx: number,
          _fy: number,
          _width: number,
          _height: number,
          px: number,
          py: number,
        ) => {
          setTotalOffset({x: px, y: py});
        },
      );
    }
  };

  const updateNewPanLocation = (num: number) => {
    const {x, y} = getCoordinates(tempColors[num]);
    pans[num].setOffset({x, y});
  };

  React.useEffect(() => {
    for (let i = 0; i < NEW_COLORS.length; i++) {
      const {x, y} = tempColors[i]
        ? getCoordinates(tempColors[i])
        : getCoordinates(NEW_COLORS[i]);
      pans[i].setOffset({x, y});
    }
  }, [tempColors]);

  return (
    <Container style={{width: diameter + BORDER_OFFSET}}>
      <CircleContainer
        style={{
          height: diameter + BORDER_OFFSET,
          width: diameter + BORDER_OFFSET,
        }}>
        <Image
          source={colorWheel}
          style={{
            height: diameter,
            width: diameter,
            position: 'absolute',
            transform: [{rotate: `${COLOR_WHEEL_ROTATION_ANGLE}deg`}],
          }}
          ref={ref => {
            if (ref) {
              pickerRef.current = ref;
            }
          }}
          onLayout={handleLayoutChange}
        />
        {tempColors.map((_, index) => (
          <Picker
            key={index}
            isActive={activePicker === index}
            num={index}
            pan={pans[index]}
            colors={tempColors}
            panResponderCallbacks={{
              onStartShouldSetPanResponderCapture: () => true,
              onStartShouldSetPanResponder: () => true,
              onMoveShouldSetPanResponderCapture: () => true,
              onMoveShouldSetPanResponder: () => index <= tempColors.length,
              onPanResponderGrant: () => true,
              onPanResponderMove: (_, gestureState) => {
                index !== activePicker && setActivePicker(index);
                const {x, y} = getBoundedValues(gestureState);

                const {radius: polarRadius, angle} = convertCartesianToPolar(
                  x - totalOffset.x - radius,
                  -(y - totalOffset.y - radius) - PICKER_OFFSET,
                );
                const currentColor = colorsys.hsv2Hex({
                  h:
                    convertRadiansToDegrees(angle) + COLOR_WHEEL_ROTATION_ANGLE,
                  s: 100 * Math.min(polarRadius / radius, 1),
                  v: 100,
                });
                const newArray = tempColors.slice();
                newArray[index] = currentColor;

                onValueChange(newArray);
                setTempColors(newArray);
              },
              onPanResponderRelease: () => {
                index !== activePicker && setActivePicker(index);
                onValueChangeRelease(tempColors);
              },
            }}
          />
        ))}
      </CircleContainer>
      <ButtonContainer style={{width}}>
        {tempColors.length > 1 ? (
          <PickerButton
            onPress={() => {
              const tempArray = tempColors.slice(0, tempColors.length);
              activePicker >= tempArray.length - 1 &&
                setActivePicker(tempArray.length - 2);
              tempArray.pop();
              onValueChangeRelease(tempArray);
              setTempColors(tempArray);
            }}>
            <Image
              source={removePicker}
              style={{
                width: 25,
                height: 25,
                alignSelf: 'center',
                tintColor: appColors.white,
              }}
            />
          </PickerButton>
        ) : (
          <PlaceHolder />
        )}
        {tempColors.length < 6 ? (
          <PickerButton
            onPress={() => {
              const tempArray = tempColors.slice(0, tempColors.length);
              tempArray.length <= tempColors.length &&
                tempArray.push(NEW_COLORS[tempColors.length]);
              setTempColors(tempArray);
              updateNewPanLocation(tempColors.length - 1);
              onValueChangeRelease(tempArray);
              setActivePicker(tempColors.length);
            }}>
            <Image
              source={addPicker}
              style={{
                width: 25,
                height: 25,
                alignSelf: 'center',
                tintColor: appColors.white,
              }}
            />
          </PickerButton>
        ) : (
          <PlaceHolder />
        )}
      </ButtonContainer>
    </Container>
  );
};

type PickerProps = {
  colors: string[];
  isActive: boolean;
  num: number;
  pan: Animated.ValueXY;
  panResponderCallbacks: Parameters<PanResponder['create']>[0];
} & ViewProps;

const Picker = ({
  colors,
  isActive,
  num,
  pan,
  panResponderCallbacks,
  ...rest
}: PickerProps) => {
  return (
    <Animated.View
      {...rest}
      {...PanResponder.create(panResponderCallbacks).panHandlers}
      style={[
        {
          ...centeredColumnStyle,
          position: 'absolute',
          left: pan.x,
          top: pan.y,
          zIndex: 99,
          marginTop: PICKER_MARGIN_TOP,
        },
      ]}>
      <Image
        source={isActive ? pickerThickBorder : pickerThinBorder}
        style={{
          width: isActive ? 28 : 32,
          height: isActive ? 44 : 47,
          position: 'absolute',
          top: -2,
          zIndex: 103,
        }}
      />
      <Svg
        width="30px"
        height={`${PICKER_HEIGHT}px`}
        viewBox={`0 0 ${PICKER_WIDTH} ${PICKER_HEIGHT}`}
        style={{zIndex: 100}}>
        <Path
          d={`M13.4262366,${PICKER_HEIGHT} C22.0895318,${PICKER_WIDTH}.5008847 ${PICKER_WIDTH}.4211794,17.8566367 ${PICKER_WIDTH}.4211794,13.0672561 C${PICKER_WIDTH}.4211794,5.88318515 20.9928899,0 13.4262366,0 C5.85958333,0 0.421179367,5.88318515 0.421179367,13.0672561 C0.421179367,17.8566367 4.75619844,${PICKER_WIDTH}.5008847 13.4262366,${PICKER_HEIGHT} Z`}
          id="Path-3"
          stroke="none"
          fill={colors[num]}
          fillRule="evenodd"
        />
      </Svg>
      <AppText
        style={{
          fontFamily: 'Roboto-Medium',
          position: 'absolute',
          fontSize: 12,
          zIndex: 101,
          width: '100%',
          top: 3,
          alignSelf: 'center',
          textAlign: 'center',
          color: getColorBrightness(colors[num]) > 150 ? 'black' : 'white',
        }}>
        {num + 1}
      </AppText>
      <Image
        source={pickerShadow}
        style={{
          width: isActive ? 37 : 35,
          height: isActive ? 50 : 48,
          alignSelf: 'center',
          position: 'absolute',
          left: -1,
          top: -3,
          zIndex: 0,
        }}
      />
    </Animated.View>
  );
};

const Container = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const CircleContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: 'transparent',
  flexDirection: 'row',
  marginTop: -BORDER_OFFSET + 10,
  paddingBottom: 20,
  paddingHorizontal: 16,
});

const PlaceHolder = styled(View)({
  height: 40,
  width: 40,
});

const PickerButton = styled(TouchableOpacity)({
  height: 40,
  width: 40,
  backgroundColor: appColors.white20,
  borderRadius: 45,
  paddingTop: -34,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

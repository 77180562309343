import React from 'react';

import {AlertWindow} from '../components';
import {AlertPromptProps} from './AlertPrompt';

export {AlertPromptProps} from './AlertPrompt';

export const AlertPrompt = (props: AlertPromptProps) => {
  const {
    title,
    description,
    btn1Label,
    btn2Label,
    onPress1,
    onPress2,
    btn2IsEnabled,
    onChangeText,
    value,
    placeholder,
    maxLength,
    error,
    errorMsg,
    keyboardAppearance,
    keyboardType,
  } = props;

  return (
    <AlertWindow
      {...{
        title,
        message: description,
        buttons: [
          {
            text: btn1Label,
            onPress: onPress1,
            style: 'cancel',
          },
          {
            text: btn2Label,
            onPress: onPress2,
            isEnabled: btn2IsEnabled,
          },
        ],
        value,
        placeholder,
        maxLength,
        onChangeText,
        error,
        errorMsg,
        keyboardAppearance,
        keyboardType,
      }}
    />
  );
};

import React from 'react';
import {
  ImageStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';

import * as icons from '../assets/images';
import {appColors} from '../constants';
import {Image} from '../shims/ImageWithFilter';

export type IconName =
  | 'account'
  | 'add'
  | 'addUser'
  | 'arrowsOpposing'
  | 'battery10'
  | 'battery25'
  | 'battery50'
  | 'battery75'
  | 'batteryFull'
  | 'batteryCharging10'
  | 'batteryCharging25'
  | 'batteryCharging50'
  | 'batteryCharging75'
  | 'batteryChargingFull'
  | 'batteryUnknown'
  | 'book'
  | 'brightness'
  | 'bluetoothBordered'
  | 'calendar'
  | 'check'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronDown'
  | 'chevronUp'
  | 'circular'
  | 'clock'
  | 'close'
  | 'community'
  | 'contact'
  | 'delete'
  | 'devicesSwitch'
  | 'dictation'
  | 'download'
  | 'duplicate'
  | 'emptyAccount'
  | 'emptyCheckBox'
  | 'emptyCheckCircle'
  | 'emptyCloseCircle'
  | 'envelope'
  | 'exclamation'
  | 'eye'
  | 'eyeSlash'
  | 'filledCheckBox'
  | 'filledCircle'
  | 'home'
  | 'gear'
  | 'infoOutline'
  | 'light'
  | 'lightBulb'
  | 'lightningBolt'
  | 'lock'
  | 'logoInverted'
  | 'logout'
  | 'mail'
  | 'menuTriple'
  | 'more'
  | 'noLightbulb'
  | 'openBook'
  | 'pencil'
  | 'pencilOutlined'
  | 'phone'
  | 'play'
  | 'phone'
  | 'phoneBordered'
  | 'power'
  | 'question'
  | 'questionMark'
  | 'reorder'
  | 'rocket'
  | 'search'
  | 'settings'
  | 'settingsBordered'
  | 'share'
  | 'shareV2'
  | 'shopBordered'
  | 'speechBubble'
  | 'stat'
  | 'stopwatch'
  | 'stopwatchFilled'
  | 'telephonePlus'
  | 'thermometer'
  | 'thermometerFilled'
  | 'trashcan'
  | 'trophy'
  | 'unlock'
  | 'userBordered'
  | 'logoClose'
  | 'logoFar'
  | 'whiteCircle'
  | 'whiteCircleFilled';

export type StyledIconProps = TouchableOpacityProps & {
  /**
   * Name of the icon
   */
  name: IconName | null;
  /**
   * style of the container
   */
  style?: ViewStyle;
  /**
   * side length of the icon, defualt to 20
   */
  size?: number;
  /**
   * size of the container, default to size + 20
   */
  containerSize?: number;
  /**
   * color of the icon
   */
  color?: string;
  /**
   * icon style
   */
  iconStyle?: ImageStyle;
};

const tintExceptionArray = [
  'emptyCloseCircle',
  'emptyCheckBox',
  'filledCheckBox',
  'battery10',
  'battery25',
  'battery50',
  'battery75',
  'batteryFull',
  'batteryCharging10',
  'batteryCharging25',
  'batteryCharging50',
  'batteryCharging75',
  'batteryChargingFull',
  'noLightbulb',
];

export const StyledIcon = ({
  name,
  onPress,
  disabled,
  color,
  size,
  style,
  containerSize,
  onPressIn,
  onPressOut,
  onLongPress,
  iconStyle,
}: StyledIconProps) => {
  const sideLength = containerSize ? containerSize : size ? size + 20 : 40;

  if (!name || !icons[name]) {
    return null;
  }

  disabled =
    disabled || (!onPress && !onPressIn && !onPressOut && !onLongPress);

  return (
    <TouchableOpacity
      style={[
        {
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: (containerSize && containerSize / 2) || size || 10,
          height: sideLength,
          width: sideLength,
        },
        style,
      ]}
      {...{disabled, onPress, onPressIn, onPressOut, onLongPress}}>
      <Image
        source={icons[name]}
        style={{
          tintColor: tintExceptionArray.includes(name)
            ? undefined
            : color ?? appColors.iconColor,
          width: size || 18,
          height: size || 18,
          resizeMode: 'contain',
          ...iconStyle,
        }}
      />
    </TouchableOpacity>
  );
};

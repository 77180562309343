import React from 'react';
import {Text, View} from 'react-native';

import {backgroundMarketOptInConfirmed} from '../../assets/images';
import {
  Constants,
  Messages,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import styled from '../../lib/styled';
import {MarketOptInNavigatorScreenProps} from '../../navigation/navigators/MainStackNavigator';
import {ConfirmationScreen} from './ConfirmationScreen';

const {IS_WEB} = Constants;
const {
  MARKET_OPT_IN_CONFIRMED_TITLE,
  MARKET_OPT_IN_CONFIRMED_DESC,
  MARKET_OPT_IN_CONFIRMED_DESC_SMS,
} = Messages;

interface Props
  extends MarketOptInNavigatorScreenProps<
    typeof Screens.MarketOptInConfirmed
  > {}

export const MarketOptInConfirmedScreen = ({route, navigation}: Props) => {
  const data = route.params?.userData;

  return (
    <ConfirmationScreen
      headerStyle={{
        marginBottom: IS_WEB ? 10 : 5,
        fontFamily: 'BigShouldersDisplay-Regular',
        fontSize: IS_WEB ? 45 : 40,
      }}
      bodyStyle={{marginBottom: 15}}
      background={backgroundMarketOptInConfirmed}
      title={MARKET_OPT_IN_CONFIRMED_TITLE}
      body={
        data?.phoneNumberNewUser
          ? MARKET_OPT_IN_CONFIRMED_DESC_SMS
          : MARKET_OPT_IN_CONFIRMED_DESC
      }
      buttonTitle={Strings.CLOSE}
      onButtonClick={() =>
        navigation.navigate(Navigators.HomeDrawerNavigator, {
          screen: Navigators.HomeEmulatedDrawer,
          params: {
            screen: Navigators.HomeTabNavigator,
            params: {
              screen: Screens.Home,
            },
          },
        })
      }>
      <Container userData={data} />
    </ConfirmationScreen>
  );
};

const Container = ({
  userData,
}: {
  userData: {email?: string; phoneNumber?: string; phoneNumberNewUser?: string};
}) => {
  return (
    <UserDataContainer>
      {userData &&
        Object.values(userData).map((value, index) => (
          <UserData key={index}>{value}</UserData>
        ))}
    </UserDataContainer>
  );
};

const UserDataContainer = styled(View)({
  marginBottom: 40,
  marginHorizontal: 48,
});

const UserData = styled(Text)({
  textAlign: 'center',
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 20,
  color: appColors.white,
  lineHeight: 26,
  letterSpacing: 0.62,
});

import React from 'react';
import {Text, TextStyle, View, ViewStyle} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';
import {useTheme} from '../lib/useTheme';

type ProgressBarProps = {
  width: ViewStyle['width'];
  text?: string;
  textStyle?: TextStyle;
  wrapperStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  innerStyle?: ViewStyle;
  verticalBar?: number;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    width,
    text,
    textStyle,
    wrapperStyle,
    containerStyle,
    innerStyle,
    verticalBar,
  } = props;

  const currentTheme = useTheme();
  const {
    homeScreenTheme: {preserveIndicator},
  } = currentTheme;

  return (
    <View style={{...containerStyle, width: '100%'}}>
      <ProgessBarWrapper style={wrapperStyle}>
        {verticalBar !== undefined && (
          <PreserveIndicator
            style={{
              left: `${verticalBar * 100}%`,
              backgroundColor: preserveIndicator,
            }}
          />
        )}
        <ProgressBarInner style={{...innerStyle, width}} />
      </ProgessBarWrapper>
      {text ? <Text style={textStyle}>{text}</Text> : null}
    </View>
  );
};

const ProgessBarWrapper = styled(View)({
  height: 10,
  backgroundColor: appColors.progressBarGray,
  marginRight: 12,
  borderRadius: 12,
  position: 'relative',
  width: '90%',
});

const ProgressBarInner = styled(ProgessBarWrapper)({
  backgroundColor: 'green',
  position: 'absolute',
});

const PreserveIndicator = styled(View)({
  position: 'absolute',
  height: 12,
  top: -1,
  width: 1,
  zIndex: 99,
});

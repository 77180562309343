import React from 'react';
import {AppState, AppStateStatus} from 'react-native';

export const useAppState = () => {
  const [state, setState] = React.useState<AppStateStatus>(
    AppState.currentState,
  );

  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', setState);

    return () => subscription.remove();
  }, []);

  return state;
};

export const useAppStateRef = () => {
  const ref = React.useRef<AppStateStatus>(AppState.currentState);

  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', state => {
      ref.current = state;
    });

    return () => subscription.remove();
  }, []);

  return ref;
};

export const useAppStateFn = (callback: (state: AppStateStatus) => void) => {
  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', callback);

    return () => subscription.remove();
  }, []);
};

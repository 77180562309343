import {Formik, FormikHelpers, FormikProps} from 'formik';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  Text,
  View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {StyledButton, StyledField} from '../../components';
import {
  Constants,
  ErrorMessages,
  Messages,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import NavigationService from '../../lib/NavigationService';
import {useAppleLogin, useGoogleLogin, useLogin} from '../../lib/api';
import {isHttpError} from '../../lib/api/apis';
import {useBackPress} from '../../lib/hooks';
import {updateAppFlags} from '../../lib/redux/appFlagsSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {WithOptionalRedirect} from '../../navigation/navigators/params';
import {optionsToRoute, toHome} from '../../navigation/navigators/util';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Alert} from '../../shims/alert';
import {analytics} from '../../src/services/analytics';
import {
  AppleSignIn,
  GoogleSignIn,
  LogoHeader,
  LogoTitle,
  TermsAndPrivacy,
} from '../components';
import {AccountStatus} from './components/AccountStatus';

export type UserAttemptData = {
  countAttempt?: number;
  accountStatus?: boolean;
  invalidPass?: boolean;
};

interface LoginValues {
  email: string;
  password: string;
}

const {GALAXY_S8, PX_785} = Constants.SCREEN_HEIGHT;
const {height} = Dimensions.get('window');
const isSmall = height <= GALAXY_S8;
const isMedium = height <= PX_785;

const schema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(ErrorMessages.REQUIRED_EMAIL)
    .email(ErrorMessages.INVALID_EMAIL),
  password: Yup.string().required(ErrorMessages.REQUIRED_PASSWORD),
});

export interface Props extends WithOptionalRedirect {
  token?: string;
  email?: string;
  appleCode?: string;
  googleCode?: string;
}

interface ScreenProps extends RootStackScreenProps<typeof Screens.Login> {}

export const LoginScreen: React.FC<ScreenProps> = ({route, navigation}) => {
  const [{loading}, login] = useLogin();

  const ref =
    React.useRef<FormikProps<{email: string; password: string}>>(null);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [displayPassword, setDisplayPassword] = React.useState(false);
  const [attemptData, setAttemptData] = React.useState<UserAttemptData>({
    countAttempt: 5,
    accountStatus: false,
    invalidPass: false,
  });

  const [google, loginWithGoogle] = useGoogleLogin();
  const [apple, loginWithApple] = useAppleLogin();

  const {redirect} = route.params ?? {};

  const handleSubmit = React.useCallback(
    async (values: LoginValues, actions: FormikHelpers<typeof values>) => {
      Keyboard.dismiss();
      actions.setSubmitting(true);

      try {
        await login({email: values.email.trim(), password: values.password});

        analytics.trackEvent('login', {method: 'credentials'});
      } catch (error) {
        if (!isHttpError(error)) throw error;

        interface LoginStatus {
          type?: string;
          remainAttempts?: number;
          unlockTime?: number;
        }

        const status = (error.response?.data ?? {}) as LoginStatus | undefined;

        if (status?.type === 'Attempt') {
          setAttemptData({
            countAttempt: status.remainAttempts,
            invalidPass: true,
            accountStatus: true,
          });

          if (!!status.remainAttempts && status.remainAttempts > 2) {
            Alert.alert('Error', ErrorMessages.INVALID_CREDENTIALS);
          }
        } else if (status?.type === 'Temporary') {
          setAttemptData({
            countAttempt: 0,
            invalidPass: true,
            accountStatus: true,
          });
        } else {
          Alert.alert('Error', ErrorMessages.INVALID_CREDENTIALS);
        }

        throw error;
      }

      actions.setSubmitting(false);

      // Check if there is a legacy account
      dispatch(updateAppFlags({hasSeenNewUsername: !user?.defaultUsername}));

      NavigationService.instance()?.resetRoot({
        index: 0,
        routes: [optionsToRoute(redirect ?? toHome)],
      });
    },
    [login, redirect, user?.defaultUsername],
  );

  useBackPress(() => {
    navigation.goBack();
    return true;
  });

  React.useEffect(() => {
    ref.current?.setFieldValue('email', route.params?.email);
  }, [route.params?.email]);

  return (
    <Container>
      <InnerContainer>
        <Formik
          initialValues={{email: route?.params?.email ?? '', password: ''}}
          innerRef={ref}
          onSubmit={handleSubmit}
          validationSchema={schema}>
          {({
            handleBlur,
            handleChange,
            isSubmitting,
            isValid,
            submitForm,
            values,
          }) => (
            <FormContainer>
              <SpinnerContainer>
                {(isSubmitting ||
                  loading ||
                  google.loading ||
                  apple.loading) && (
                  <ActivityIndicator size="large" color={appColors.black} />
                )}
              </SpinnerContainer>

              <LogoHeader style={{marginTop: Constants.IS_WEB ? 34 : 40}}>
                <LogoTitle>{Messages.LOGIN_TITLE}</LogoTitle>
              </LogoHeader>

              <BodyContainer>
                <FieldsContainer>
                  <FieldContainer>
                    <StyledField
                      name={'mail'}
                      borderColor={appColors.lightMediumGray}
                      height={isMedium ? 45 : 56}
                      placeholder={'Email'}
                      onChangeText={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      keyboardType="email-address"
                      isBordered={true}
                      leftIconColor={appColors.black30}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <StyledField
                      name={'lock'}
                      borderColor={appColors.lightMediumGray}
                      height={isMedium ? 45 : 56}
                      password={!displayPassword}
                      onChangeText={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      leftIconColor={appColors.black30}
                      placeholder={'Password'}
                      isBordered={true}
                      rightIcon={!displayPassword ? 'eyeSlash' : 'eye'}
                      rightIconColor={appColors.black30}
                      iconPress={() => setDisplayPassword(pass => !pass)}
                    />
                  </FieldContainer>
                  <ForgotBlackText
                    onPress={() =>
                      navigation.navigate(Screens.ForgotPassword, {
                        email: values.email,
                      })
                    }>
                    Forgot password?
                  </ForgotBlackText>
                </FieldsContainer>
                <ButtonContainer
                  style={
                    isSmall ? {flex: 0.5, justifyContent: 'center'} : undefined
                  }>
                  <StyledButton
                    title={Strings.SIGN_IN}
                    disabled={!isValid || isSubmitting}
                    onPress={submitForm}
                    style={{
                      backgroundColor: appColors.black,
                      width: '100%',
                      height: isMedium ? 45 : 56,
                    }}
                    textStyle={{color: appColors.white}}
                  />
                </ButtonContainer>
                <BlackTextContainer>
                  <BlackText>
                    {Messages.NO_ACCOUNT}{' '}
                    <UnderlinedSignUpText
                      onPress={() => {
                        navigation.navigate(Screens.Register, {
                          redirect,
                          email: ref.current?.values.email,
                        });
                      }}>
                      {Strings.SIGN_UP}
                    </UnderlinedSignUpText>
                  </BlackText>
                </BlackTextContainer>
                <ButtonContainer
                  style={
                    isSmall ? {flex: 1, justifyContent: 'center'} : undefined
                  }>
                  <FieldContainer>
                    <AppleSignIn
                      buttonStyle={{height: isMedium ? 45 : 56}}
                      disabled={
                        isSubmitting ||
                        loading ||
                        apple.loading ||
                        google.loading
                      }
                      screen="login"
                      onSubmit={loginWithApple}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <GoogleSignIn
                      title={Strings.GOOGLE_SIGN_IN}
                      buttonStyle={{height: isMedium ? 45 : 56}}
                      disabled={
                        isSubmitting ||
                        loading ||
                        apple.loading ||
                        google.loading
                      }
                      screen="login"
                      onSubmit={loginWithGoogle}
                    />
                  </FieldContainer>
                </ButtonContainer>
              </BodyContainer>

              <BottomContainer>
                <TermsAndPrivacy />
              </BottomContainer>
            </FormContainer>
          )}
        </Formik>
        {(attemptData.countAttempt === 2 ||
          attemptData.countAttempt === 1 ||
          attemptData.countAttempt === 0) &&
        attemptData.accountStatus ? (
          <AccountStatus
            setAttemptData={setAttemptData}
            attemptData={attemptData}
          />
        ) : null}
      </InnerContainer>
    </Container>
  );
};

const BlackText = styled(Text)({
  color: appColors.black50,
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  letterSpacing: 0.1,
  lineHeight: 16.41,
  fontWeight: '400',
});

const BodyContainer = styled(View)({
  flex: 1,
  paddingTop: isSmall ? 8 : 24,
  width: '100%',
  alignItems: 'center',
});

const BottomContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: 24,
  zIndex: 2,
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: isSmall ? 8 : 16,
  paddingBottom: isSmall ? 8 : 16,
  width: '100%',
});

const Container = styled(KeyboardAvoidingView)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flex: 1,
});

const InnerContainer = styled(SafeAreaView)({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: appColors.white,
});

const FieldContainer = styled(View)({
  paddingBottom: 8,
  width: '100%',
});

const FieldsContainer = styled(View)({
  width: '100%',
});

const FormContainer = styled(View)({
  position: 'relative',
  alignItems: 'center',
  paddingHorizontal: 24,
  flex: 1,
});

const SpinnerContainer = styled(View)({
  position: 'absolute',
  top: '30%',
  zIndex: 2,
});

const UnderlinedSignUpText = styled(Text)({
  color: appColors.black50,
  textDecorationLine: 'underline',
});

const ForgotBlackText = styled(Text)({
  color: appColors.gray67,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  lineHeight: 14,
  letterSpacing: 0.1,
  fontWeight: '400',
  alignSelf: 'flex-end',
  marginRight: 8,
});

const BlackTextContainer = styled(View)({
  ...(isSmall
    ? {paddingBottom: 0, flex: 0.3, justifyContent: 'center'}
    : {paddingBottom: 8}),
});

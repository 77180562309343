import {Text} from 'react-native';

import {appColors} from '../constants';
import styled from '../lib/styled';

export const AppText = styled(Text)({
  marginVertical: 2,
  color: appColors.baseText,
  fontSize: 20,
  fontFamily: 'BigShouldersDisplay-Medium',
  letterSpacing: 0.6,
  fontWeight: '400',
});
